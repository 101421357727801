import { createAction, handleActions } from "redux-actions";
import axios from "axios";
import { setLocal, deleteLocal } from "../../shared/Local";


 const PAYMENTHISTORY_DATA = "PAYMENTHISTORY_DATA";

const initialState = {
  PaymentHistory: [],
};

// 회원 가입
const set_PAYMENTHISTORY_DATA = (PaymentHistory) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: PAYMENTHISTORY_DATA,
      PaymentHistory : PaymentHistory,
    });  
  };
};





const PaymentHistory = (state = initialState, action) => {
  switch (action.type) {
  
      case PAYMENTHISTORY_DATA:
        return {
          ...state,
          PaymentHistory : action.PaymentHistory,
        }
      
    default:
      return state
  }
};

export default PaymentHistory;


const actionCreators = {
  set_PAYMENTHISTORY_DATA,
}

export { actionCreators };
