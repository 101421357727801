import React, { useRef, useMemo, useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import { Footer , Editor } from "../../components/index";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { Avatar } from "@material-ui/core";
import { Grid, Button, Text, Input2 } from "../../elements/index";
import "./QuestionSearch.css";
import { dbService, storageService } from "../../shared/firebase";
import { actionCreators as QuestionActions } from "../../redux/modules/Question";
import { CircularProgress } from "@material-ui/core";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  return isDesktop ? children : null;
};

const QuestionSearch = (props) => {
  const history = useHistory();
  const { Questioncontent } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const Question = useSelector((state) => state.Question);
  const [NoticeEdit, setNoticeEdit] = useState(false);
  const [Edit_title , setEdit_title] = useState("");
  const [desc, setDesc] = useState("");


   function onEditorChange(value) {
       setDesc(value)
   }

  useEffect(() => {
    dbService
      .collection("Question")
      .doc(Questioncontent)
      .get()
      .then((snapshot) => {
        const fetchid = snapshot.id;
        const fetchQuestion = snapshot.data();
        const fethchQuestion = time(
          snapshot.data()?.Question_createdAt.toDate().toISOString()
        );
        dispatch(
          QuestionActions.set_Question_Content(
            fetchid,
            fetchQuestion,
            fethchQuestion
          )
        );
        setIsLoading(false);
        // setDesc(Notice.noticeContentData.notice_Desc)
        // setEdit_title(Notice.noticeContentData.notice_Title)
      });
  }, [Questioncontent, history, dispatch]);
  


  useEffect(() => {
    setEdit_title(Question.QuestionContentData.Question_Title)
    setDesc(Question.QuestionContentData.Question_Desc)

      
  }, [Question]);


  const time = (data) => {
    var split1 = (data || "").split("T");
    var replace = split1[0].replace(/\-/g, " ");
    var replace2 = replace.replace(" ", ".");
    var date = replace2.replace(" ", ".");
    return date;
  };


  const onClickSave = (id)  => {

    const Question = dbService.collection("Question").doc(id).update({
      Question_Desc:desc,
      Question_Title:Edit_title,

    })
    // const HistoryPage = Notice.update(data)
    // const HistoryPage = Notice.set(data);

    if(Question){
      history.push("/Home/Question")
    }
}
  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "26rem 0",
          }}
        >
          <CircularProgress color="secondary" size="5rem" />
        </div>
      ) : (
        <Desktop>
          <Grid
            position="relative"
            width="100%"
            marginT="64px"
            height="auto"
            display="flex"
          >
            <Grid display="flex" margin="auto" width="1200px" 
            // height="800px"
            >
              <Grid marginT="180px" display="flex" column>
                {/* <Grid row display="flex" marginB="10px">      */}
                {/* <Text h2 textAlign="left" width="180px" marginT="8px"></Text> */}

                {/* </Grid> */}
                <Grid
                  width="1200px"
                  bdr="1px solid #4b4b4b59"
                  borderRadius="20px"
                  padding="30px 40px"
                >
                  <Text
                    color="#202020"
                    bold
                    size="24px"
                    textAlign="left"
                    flexdirection="row"
                  >
                    {Question.QuestionContentData.Question__Type}
                  </Text>
                  <Grid
                    bdrBottom="1px solid #4b4b4b59"
                    margin="auto"
                    marginT="10px"
                    marginB="10px"
                    justify="center"
                  ></Grid>

                  
                  {NoticeEdit === true ? (
                 <Input2  value={Edit_title} type="text"  plcholder="제목"  marginB="40px" marginT="15px" _onChange={(e) => {setEdit_title(e.target.value)}} />

              ):(
                <Text
                    color="#202020"
                    size="18px"
                    textAlign="left"
                    flexdirection="row"
                    marginT="6px"
                    // bold
                  >
                    제 목 : {Question.QuestionContentData.Question_Title}
                  </Text>
              )}

                  <Grid
                    bdrBottom="1px solid #4b4b4b59"
                    margin="auto"
                    marginT="10px"
                    justify="center"
                  ></Grid>

                  <Text
                    color="#202020"
                    size="15px"
                    textAlign="left"
                    flexdirection="row"
                    marginT="6px"
                  >
                    작성자 : {Question.QuestionContentData.Question_Writer} &nbsp;
                    &nbsp; 작성일 : {Question.QuestionTime} &nbsp; &nbsp; 조회수 :{" "}
                    {Question.QuestionContentData.Question_LookUP}
                  </Text>
                  <Grid
                    bdrBottom="1px solid #4b4b4b59"
                    margin="auto"
                    marginT="10px"
                    justify="center"
                  ></Grid>

          {Question.QuestionContentData.Question_Song_SongName !== "" ? (
            <Grid display="flex" bg="rgba(224, 224, 224, 0.226)" marginB="20px" marginT="10px">
                      <img
                              src={Question.QuestionContentData?.Question_Song_Album}
                              alt=""
                              className="Question_song__image"
                            />
                            <Grid display="flex" column width="300px" marginL="40px" textAlign="left" 
                            justify="center" >
                            <Text size="24px"  bold>{Question.QuestionContentData?.Question_Song_SongName} </Text>
                            <Text size="16px" >{Question.QuestionContentData?.Question_Song_ArtistName}</Text>

                            </Grid>
                        

                      
                      </Grid>
          ):(
            null
          )
                    
                      }

              {NoticeEdit === true ? (
                <Editor value={desc} onChange={onEditorChange} />

              ):(
                <div
                    className="notice_search_content"
                    dangerouslySetInnerHTML={{
                      __html: Question.QuestionContentData.Question_Desc,
                    }}
                  ></div>
              
              )}

   
                </Grid>

                <Grid display="flex" justify="center" marginT="10px" marginB="150px">
                  <Button
                    main
                    noflex
                    width="100px"
                    height="40px"
                    display="inline-block"
                    justify="center"
               
                    _onClick={() => history.push("/Home/Question")}
                  >
                    목록
                  </Button>


                  {NoticeEdit === true ? (
                   
                  <Button
                    main
                    noflex
                    width="100px"
                    height="40px"
                    display="inline-block"
                    justify="center"
                    marginL="5px"
                    _onClick={() => onClickSave(Question.QuestionContentID)}
                  >
                    저장
                  </Button>

              ):(
                <>
                <Button
                    main
                    noflex
                    width="100px"
                    height="40px"
                    display="inline-block"
                    justify="center"
                    marginL="5px"
                    _onClick={() => setNoticeEdit(true)}
                  >
                    수정
                  </Button>
                
                  <Button
                    main
                    noflex
                    width="100px"
                    height="40px"
                    display="inline-block"
                    justify="center"
                    marginL="5px"
                    // _onClick={() => setNoticeEdit(true)}
                  >
                    삭제
                  </Button>
                
                
                </>
              )}
                 

                  
                 
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Footer />
        </Desktop>
      )}
    </>
  );
};

export default QuestionSearch;
