import React, { useEffect, useState, useRef, useCallback } from "react";
import { dbService, storageService } from "../../shared/firebase";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./CheckOut.css";
import { CircularProgress } from "@material-ui/core";
import { Grid, Button, Text, Input2 } from "../../elements/index";
// import { Iamport, Request, Enum } from 'iamport-rest-client-nodejs';
import {
  PGS,
  METHODS_FOR_INICIS,
  QUOTAS_FOR_INICIS_AND_KCP,
} from "../../shared/Checkout_constants";
// import kako from '../../assets/images/pay/kakao.png';
import ReactDOM from "react-dom";
import queryString from "query-string";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { actionCreators as CheckOutActions } from "../../redux/modules/CheckOut";
import firebase from "firebase";
import { Edit } from "@material-ui/icons";
import { PayPalButton } from "react-paypal-button-v2";

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";

import {Change_Language_currncy} from "../../shared/commonUI"

const initialOptions = {
  "client-id": "test",
  currency: "USD",
  intent: "capture",
  "data-client-token": "abc123xyz==",
};

const CheckOut = () => {
  const dispatch = useDispatch();
  const histroy = useHistory();
  const [state, setState] = useState();
  const [docked, setdocked] = useState(true);
  const user = useSelector((state) => state.user);

  const checkout = useSelector((state) => state.CheckOut);
  const [updateList, setupdateList] = useState([]);
  const [idList, setidList] = useState([]);
  const [productList, setproductList] = useState([]);
  const [productAll_Data_List, setproductAll_Data_List] = useState([]);


  const [closeList_paypal, setcloseList_paypal] = useState(false);
  const [closeList_kakopay, setcloseList_kakopay] = useState(true);
  const [closeList_html5_inicis, setcloseList_html5_inicis] = useState(false);
  const history = useHistory();
  const [currency_amount, setcurrency_amount] = useState();

  const listRef_kakopay = useRef();
  const listRef_html5_inicis = useRef();
  const listRef_paypal = useRef();

  const { t, i18n } = useTranslation();
  const [sumamount, setsumamount] = useState();

  const [Currency_code, setCurrency_code] = useState();
  const [fist_data_List, setfist_data_List] = useState([]);
  const { id } = useParams();
  const [loaded, setloaded] = useState([]);
  const [paymentHistory, setpaymentHistory] = useState();
  const [pgbank, setpgbank] = useState();

  const [isLoading, setIsLoading] = useState(true);

  // const iamport = new Iamport({
  //   apiKey: '6090072350228575',
  //   apiSecret: 'd0d661afd2cd2244c84a300e1ef30030bdcd3c76e4c0f7fe6054fd83bd7d1f388e76333ca0db5d14',
  // });
  useEffect(() => {
    let ids = [];
    let entire = [];
    checkout.checkout.map((item, i) => {
      var orgprice = 0;
      var realprice = orgprice;
      if (i18n.language === "kor") {
        orgprice = `${item.data.KOR}`;
        if (orgprice.indexOf(",") === 1) {
          realprice = orgprice.replace(/,/, "");
        } else {
          realprice = orgprice;
        }
      } else if (i18n.language === "en") {
        orgprice = item.data.USD;
        realprice = orgprice;
      } else if (i18n.language === "ja") {
        orgprice = item.data.JPY;
        realprice = orgprice;
      }

      var NumberPrice = Number(realprice);
      delete item.data.Number2;
      ids[i] = { id: item.id, update: NumberPrice };
      entire[i] = { id: item.id, data: item.data };
    });
    setupdateList(ids.map((i) => i.update));
    setidList(ids.map((i) => i.id));
    setproductList(entire.map((i) => i.data.songName));
    setproductAll_Data_List(entire);
    setIsLoading(false);

  }, [i18n.language, checkout]);




//////////////////// 합계를 위한 함수 ////////////////////
  useEffect(() => {
    var Price_reducing = [];
    var Price_setting = [];
    if (i18n.language === "kor") {
      Price_reducing = updateList.reduce((sum, cur) => sum + cur, 0);
      Price_setting = Price_reducing;
    } else if (i18n.language === "en") {
      Price_reducing = updateList.reduce((sum, cur) => sum + cur, 0);
      Price_setting = Price_reducing.toFixed(1);
    } else if (i18n.language === "ja") {
      Price_reducing = updateList.reduce((sum, cur) => sum + cur, 0);
      Price_setting = Price_reducing;
    } else{
      Price_reducing = updateList.reduce((sum, cur) => sum + cur, 0);
      Price_setting = Price_reducing.toFixed(1);
    }
    setsumamount(Price_setting);
  }, [updateList, i18n]);



  useEffect(() => {
    if (i18n.language === "kor") {
      setCurrency_code("KOR");
    } else if (i18n.language === "en") {
      setCurrency_code("USD");
    } else if (i18n.language === "ja") {
      setCurrency_code("JPY");
    } else{
      setCurrency_code("USD");
    }

    i18n.on("languageChanged", (lng) => {
      if (lng === "kor") {
        setCurrency_code("KOR");
      } else if (lng === "en") {
        setCurrency_code("USD");
      } else if (lng === "ja") {
        setCurrency_code("JPY");
      }
    });
  }, [i18n]);

  // const PayPalButton = window.paypal.Buttons.driver("react", {
  //   React,
  //   ReactDOM,
  // });

  const createOrder = (data, actions) => {
    console.log(Currency_code);
    if (i18n.language === "kor") {
      alert("다른 결제수단 혹은 다른 화폐를 이용해주세요");
      return;
    } else {
      return actions.order.create({
        intent: "CAPTURE",
        // redirect_urls: {
        //   return_url: `/Payment/CheckoutResult?${query}`,
        // },

        purchase_units: [
          {
            amount: {
              // currency_code:"KOR",
              // currency_code: `${Currency_code}`,
              currency_code: `${Currency_code}`,

              value: `${sumamount}`,
            },
          },
        ],
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      });
    }
  };

  const onPaypalSuccess = (details, data) => {
    var time = { PurchaseAt: firebase.firestore.FieldValue.serverTimestamp() };
    var data3 = [];
    var data4 = [];
    productAll_Data_List.map(
      (item, i) => (
        (data3[i] = { data: Object.assign(item.data, time), id: item.id }),
        (data4[i] = {
          data: {
            uid: item.data.uid,
            JPY: item.data.JPY,
            EUR: item.data.EUR,
            CNY: item.data.CNY,
            KOR: item.data.KOR,
            USD: item.data.USD,
          },
          id: item.id,
        })
      )
    );
    data3.map((item) =>
      dbService
        .collection("user_Public")
        .doc(user.uid)
        .collection("Purchase_Product")
        .doc(item.id)
        .set(item.data)
        .then((snapshot) => {
          // dbService
          // .collection("user_Public")
          // .doc(user.uid)
          // .collection("Purchase_Product")
          // .doc(item.id)
          // .delete();
        })
    );
    // alert("Transaction completed by " + details.payer.name.given_name);

    const dd = fetch("/paypal-transaction-complete", {
      method: "post",
      body: JSON.stringify({
        orderID: data.orderID,
      }),
    });

    update_to_artist_fee_amout(data4);
    console.log(data.orderID);
    // history.push(`/Payment/CheckoutResult?${dd}`);
    // actions.redirect(`http://localhost:3000/Payment/CheckoutResult?${query}`);
    // 쿼리보단 따로 CHECKOUT REDUX 로 데이터를 결과페이지로 보내야할듯 (물품정보)
  };

  function foldList_kakopay() {
    if (!listRef_kakopay || !listRef_kakopay.current) {
      // useRef 변수가 비었을 때
      return; // 그냥 리턴하도록 예외처리를 해주자
    }
    const style = listRef_kakopay.current.style; // 접근할 DOM 요소의 스타일 속성을 미리 선언해둔다.
    if (closeList_kakopay) {
      style.maxHeight = "0"; // maxHeight 는 0이 되고 접힌다.
    } else if (!closeList_kakopay) {
      style.maxHeight = `${listRef_kakopay.current.scrollHeight}px`; // maxHeight = scroll 길이가 되고 메뉴가 열린다.
    }
    setcloseList_kakopay(!closeList_kakopay);
  }

  function foldList_html5_inicis() {
    if (!listRef_html5_inicis || !listRef_html5_inicis.current) {
      return;
    }
    const style = listRef_html5_inicis.current.style;
    if (closeList_html5_inicis) {
      style.maxHeight = "0";
    } else if (!closeList_html5_inicis) {
      style.maxHeight = `${listRef_html5_inicis.current.scrollHeight}px`;
    }
    setcloseList_html5_inicis(!closeList_html5_inicis);
  }

  function foldList_paypal() {
    if (!listRef_paypal || !listRef_paypal.current) {
      return;
    }
    const style = listRef_paypal.current.style;
    if (closeList_paypal) {
      style.maxHeight = "0";
    } else if (!closeList_paypal) {
      style.maxHeight = `${800}px`;
    }
    setcloseList_paypal(!closeList_paypal);
  }

  const handleInput = (bank) => {
    if (i18n.language !== "kor") {
      alert("다른 결제수단 혹은 다른 화폐를 이용해주세요");
      return;
    }

    var productList_edit_result = [productList[0]];
    var prodcut_etc = productList.length - 1;

    if (productList.length === 1) {
      var product_fullname = `${productList_edit_result}`;
    } else {
      product_fullname = `${productList_edit_result} 외 ${prodcut_etc}곡`;
    }

    const userCode = "imp86736469";
    const data = {
      pg: bank,
      merchant_uid: `product_${new Date().getTime()}`,
      name: product_fullname,
      amount: sumamount,
      buyer_name: user.displayName,
      buyer_email: user.user?.email,
    };

    if (sumamount === 0) {
      payment_amount_0(data);
      return;
    }

    const { IMP } = window;
    IMP.init(userCode);
    IMP.request_pay(data, callback);
  };




  const payment_amount_0 = (data) => {
    var time = {
      PurchaseAt: firebase.firestore.FieldValue.serverTimestamp(),
      merchant_uid: data.merchant_uid,
    };
    var data3 = [];
    var data4 = [];
    productAll_Data_List.map(
      (item, i) => (
        (data3[i] = { data: Object.assign(item.data, time), id: item.id }),
        (data4[i] = {
          data: {
            uid: item.data.uid,
            JPY: item.data.JPY,
            EUR: item.data.EUR,
            CNY: item.data.CNY,
            KOR: item.data.KOR,
            USD: item.data.USD,
          },
          id: item.id,
        })
      )
    );

    const paymentHistory_data = {
      pg: data.pg,
      merchant_uid: data.merchant_uid,
      name: data.name,
      amount: data.amount,
      currency: "￦",
      buyer_name: data.buyer_name,
      buyer_email: data.buyer_email,
      paid_at: firebase.firestore.FieldValue.serverTimestamp(),
    };

    dbService
      .collection("user_Public")
      .doc(user.uid)
      .collection("paymentHistory")
      .doc(data.merchant_uid)
      .set(paymentHistory_data);

    data3.map((item) =>
      dbService
        .collection("user_Public")
        .doc(user.uid)
        .collection("Purchase_Product")
        .doc(item.id)
        .set(item.data)
        .then((snapshot) => {
          dbService
          .collection("user_Public")
          .doc(user.uid)
          .collection("product_basket")
          .doc(item.id)
          .delete();
        })
    );

    update_to_artist_fee_amout(data4, paymentHistory_data);
    history.push(`/Payment/CheckoutResult?${paymentHistory_data}`);
  };



  function callback(response) {
    const query = queryString.stringify(response);
    const search = queryString.parse(query);
    // console.log(search);
    function getIsSuccessed() {
      const { success, imp_success } = search;
      if (typeof imp_success === "string") return imp_success === "true";
      if (typeof imp_success === "boolean") return imp_success === true;
      if (typeof success === "string") return success === "true";
      if (typeof success === "boolean") return success === true;
    }

    const isSuccessed = getIsSuccessed();

    if (isSuccessed) {
      var time = {
        PurchaseAt: firebase.firestore.FieldValue.serverTimestamp(),
        merchant_uid: search.merchant_uid,
      };
      var data3 = [];
      var data4 = [];
      productAll_Data_List.map(
        (item, i) => (
          (data3[i] = { data: Object.assign(item.data, time), id: item.id }),
          (data4[i] = {
            data: {
              uid: item.data.uid,
              JPY: item.data.JPY,
              EUR: item.data.EUR,
              CNY: item.data.CNY,
              KOR: item.data.KOR,
              USD: item.data.USD,
            },
            id: item.id,
          })
        )
      );

      const paymentHistory_data = {
        pg: search.pg_provider,
        merchant_uid: search.merchant_uid,
        name: search.name,
        amount: search.paid_amount,
        currency: "￦",
        buyer_name: search.buyer_name,
        buyer_email: search.buyer_email,
        paid_at: firebase.firestore.FieldValue.serverTimestamp(),
      };

      dbService
        .collection("user_Public")
        .doc(user.uid)
        .collection("paymentHistory")
        .doc(search.merchant_uid)
        .set(paymentHistory_data);

      data3.map((item) =>
        dbService
          .collection("user_Public")
          .doc(user.uid)
          .collection("Purchase_Product")
          .doc(item.id)
          .set(item.data)
          .then((snapshot) => {

            dbService
            .collection("user_Public")
            .doc(user.uid)
            .collection("product_basket")
            .doc(item.id)
            .delete()
          })
      );
      update_to_artist_fee_amout(data4, search);
      history.push(`/Payment/CheckoutResult?${query}`);
    }
  }

  const update_to_artist_fee_amout = async (data, search) => {
    data.forEach((item, i) => {
      // data:
      //   CNY: "0.0"
      //   EUR: "0.0"
      //   JPY: 0
      //   KOR: "0,000"
      //   USD: "0.0"
      //   uid: "aYYd7twkcAP796V6DrCMrX3WrN82"
      // id: "g3Kw9tKzljYKJjf9jV0I

      var orgprice = `${item.data.KOR}`;
      var realprice = orgprice;     
        if (orgprice.indexOf(",") === 1) {
          realprice = orgprice.replace(/,/, "");
        } else {
          realprice = orgprice;
        }
      var KOR_amount = Number(realprice);

      function financial(x) {
        return Number.parseFloat(x).toFixed(1);
      }

      const data_Song_purchaseProduct = {
        merchant_uid: search.merchant_uid,
        date: new Date(),
        KOR: KOR_amount,
        JPY: item.data.JPY,
        USD: item.data.USD,
        EUR: item.data.EUR,
        CNY: item.data.CNY,
        Purchase_user_uid: user?.uid,
        Purchase_song_uid: item.id,
      };

      //아티스트의 매출업그레이드 [TODO] 수수료 업그레이트필요
      dbService
        .collection("Artist_amount")
        .doc(item.data.uid)
        .update({
          Song_purchaseProduct: firebase.firestore.FieldValue.arrayUnion(
            data_Song_purchaseProduct
          ),
          KOR: firebase.firestore.FieldValue.increment(KOR_amount),
          JPY: firebase.firestore.FieldValue.increment(Number(item.data.JPY)),
          USD: firebase.firestore.FieldValue.increment(financial(item.data.USD)),
          EUR: firebase.firestore.FieldValue.increment(financial(item.data.EUR)),  
          CNY: firebase.firestore.FieldValue.increment(financial(item.data.CNY)),
        })
        .then((snapshot)=> {
          //노래의 매출을 곡 저작권에 업데이트
                dbService
                .collection("Song_CopyRight")
                .doc(item.id)
                .update({
                  KOR: firebase.firestore.FieldValue.increment(KOR_amount),
                  JPY: firebase.firestore.FieldValue.increment(Number(item.data.JPY)),
                  USD: firebase.firestore.FieldValue.increment(financial(item.data.USD)),
                  EUR: firebase.firestore.FieldValue.increment(financial(item.data.EUR)),  
                  CNY: firebase.firestore.FieldValue.increment(financial(item.data.CNY)),
                })
                .then((snapshot)=> {
                  //아티스트 유저의 곡다운받은갯수 업그레이드
                  dbService
                  .collection("user_Public")
                  .doc(item.data.uid)
                  .update({
                    artistuser_update: firebase.firestore.FieldValue.increment(1),  
                  })
          });

        });
    });
  };

  return (
    <>
    {isLoading ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "26rem 0",
        }}
      >
        <CircularProgress color="secondary" size="5rem" />
      </div>
    ) : (


    <Grid display= "flex" margin="auto" width="85%" column >
            <Grid display= "flex" margin="auto"  width="80%" justify="center" marginT="60px">
                <Grid borderRadius="50px" width="50px" height="50px"  bg="rgb(211, 211, 211)">
                    {/* <Grid borderRadius="50px" width="50px" height="50px" bdr="3px solid #fff" display="block"  justify="center"  margin="4px auto"> */}
                      <Text color="#fff" textAlign="center" size="24px" bold padding="8px 0" justify="center" >1</Text>
                  {/* </Grid> */}
                </Grid>

                <Text color="rgb(70, 70, 70)" textAlign="center" size="18px" bold  justify="center" lineHeight="24px" display="block" padding="15px 0" marginL="10px" marginR="10px">장바구니</Text>
                 
                 <ArrowForwardIos className="ArrowForwardIos_icon"/>
                 <ArrowForwardIos className="ArrowForwardIos_icon"/>

               <Grid borderRadius="50px" width="50px" height="50px" bg="#ba4148"  marginL="10px">
                     <Text color="#fff" textAlign="center" size="24px" bold padding="8px 0" justify="center" >2</Text>
               </Grid>
              
               <Text color="rgb(70, 70, 70)" textAlign="center" size="18px" bold  justify="center" lineHeight="24px" display="block" padding="15px 0" marginL="10px" marginR="10px">결제</Text>
                
                 <ArrowForwardIos className="ArrowForwardIos_icon"/>  
                 <ArrowForwardIos className="ArrowForwardIos_icon"/>
              
               <Grid borderRadius="50px" width="50px" height="50px" bg="rgb(211, 211, 211)"  marginL="10px">
                     <Text color="#fff" textAlign="center" size="24px" bold padding="8px 0" justify="center" >3</Text>
               </Grid>
              
               <Text color="rgb(70, 70, 70)" textAlign="center" size="18px" bold  justify="center" lineHeight="24px" display="block" padding="15px 0" marginL="10px">결제완료</Text>
           </Grid>


        <Grid  width="100%"  display= "flex" margin="auto"  marginT="60px">
          <Grid display="flex" column width="70%"   marginL="auto" marginB="auto" marginR="auto">
            <Grid  paddingB="50px" width="90%" margin="auto">

            <Text h2  width="100%">
              결제확인
            </Text>

            <table className="checkout__left_table">

                       <colgroup>
                          <col width="60%" />
                          <col width="40%" /> 

                      </colgroup>

              <thead className="checkout__left_thead">
                <tr>
                  <th className="checkout__left_content_product">상품</th>
                  <th className="checkout__left_content_price">가격</th>
                </tr>
              </thead>

              {checkout?.checkout.map((item) => (
                  <tbody className="Payment_checkout__left_content_tbody">
                  
                  
                    <tr className="checkout_song_tr">


                           <td
                              className="Payment_checkout__left_content_song_artist"
                            >
                              <img
                                  src={item.data.Elbum_url}
                                  alt=""
                                  className="Payment_checkout__left_content_tbody_image"
                                />
                          
                            <span className="Payment_checkout__left_content_tbody_songName">
                                 {item.data.songName}
                                <span className="Payment_checkout__left_content_tbody_artistname">
                                {item.data.artistName}
                                </span>
                              </span>
                            </td>

                    
                      {/* <img
                        src={item.data.Elbum_url}
                        alt=""
                        className="checkout__left_content_tbody_image"
                      />

                      <td className="checkout__left_content_tbody_songName">
                        {" "}
                        {item.data.songName}
                        <td className="checkout__left_content_tbody_artistname">
                          {item.data.artistName}
                        </td>
                      </td> */}

                      <td className="checkout__left_content_tbody_price">
                        {t("Payment.Currency")} {Change_Language_currncy(i18n , item)}
                      </td>
                    </tr>
                  </tbody>
                
              ))}

              <tfoot className="checkout__left_content_tbody_total">
                <tr>
                  <td className="checkout__left_content_tbody_total_text">
                    Total
                  </td>

                  <td className="checkout__left_content_tbody_total_price">
                    {t("Payment.Currency")} {sumamount}
                  </td>

                </tr>
              </tfoot>
            </table>
          </Grid>
        </Grid>

        <Grid width="30%" >
          <Grid>
            <div className="checkout__right_content_card">
              <div className="checkout__right_content_card_total">
                <span>TOTAL</span>
              </div>

              <div className="checkout__right_content_card_total_price">
                <span>{t("Payment.Currency")} </span>
                <span>{sumamount}</span>
              </div>

              {/* 페이팔 */}

              <Grid display="flex" column width="80%" margin="auto">
                <Grid
                  bdrBottom="1px solid #97979754"
                  padding="12px"
                  paddingT="24px"
                  paddingB="24px"
                  display="flex"
                  justify="space-between"
                >
                  <div className="checkout_right_content_card_text">
                    페이팔
                  </div>
                  <Grid
                    borderRadius="6px"
                    padding="4px 4px 5px"
                    display="inline-block"
                    bg="rgb(204, 204, 204)"
                    width="50px"
                  >
                    {/* {Currency_code} */}
                    USD
                  </Grid>

                 <div className={`${closeList_paypal ? "close" : "open"}`} >
                  <ArrowDropDownIcon
                    // 상태값에 따라 class이름이 바뀐다.
                    onClick={foldList_paypal} // 클릭하면 foldList() 함수가 실행된다.
                    fontSize="large"
                  ></ArrowDropDownIcon>
                  </div>

                </Grid>
              </Grid>
              <div
                className="checkout_right_content_fold_payment_content"
                ref={listRef_paypal}
              >
                {/* <PayPalButton
                  createOrder={(data, actions) => createOrder(data, actions)}
                  onApprove={(data, actions) => onApprove(data, actions)}
                /> */}

                {Currency_code === "USD" ? (
                  <>
                    {/* <PayPalButton
                    amount={sumamount}
                    currency="USD"
                    // createOrder={createOrder}
                    onSuccess={(details, data) => {
                      onPaypalSuccess(details, data)
                 // alert("Transaction completed by " + details.payer.name.given_name);
        
                      // OPTIONAL: Call your server to save the transaction
                      // return fetch("/paypal-transaction-complete", {
                      //   method: "post",
                      //   body: JSON.stringify({
                      //     orderID: data.orderID
                      //   })
                      // });
                    }}
                    shippingPreference= "NO_SHIPPING"
                    options={{
                      clientId: "Ab42M-6KalVoHUaZR0GgQ_wvr6QRjQ6ajv_509dMsH9VK2ucCyAAxAz1TEWmR_RDXkopMSh9Km26WMgZ",
                      currency:"USD"

                      // currency:`${Currency_code}`
                    }}
                  /> */}
                  </>
                ) : (
                  <>
                    {/* <PayPalButton
                    amount={sumamount}
                    currency="USD"
                    // createOrder={createOrder}
                    onSuccess={(details, data) => {
                      onPaypalSuccess(details, data)
                 // alert("Transaction completed by " + details.payer.name.given_name);
        
                      // OPTIONAL: Call your server to save the transaction
                      // return fetch("/paypal-transaction-complete", {
                      //   method: "post",
                      //   body: JSON.stringify({
                      //     orderID: data.orderID
                      //   })
                      // });
                    }}
                    shippingPreference= "NO_SHIPPING"
                    options={{
                      clientId: "Ab42M-6KalVoHUaZR0GgQ_wvr6QRjQ6ajv_509dMsH9VK2ucCyAAxAz1TEWmR_RDXkopMSh9Km26WMgZ",
                      currency:"USD"
                    }}
                  /> */}
                  </>
                )}
              </div>

              {/* kg이니시스 */}
              <Grid display="flex" column  width= "80%"  margin="auto">
                <Grid
                  bdrBottom="1px solid #97979754"
                  padding="12px"
                  paddingT="24px"
                  paddingB="24px"
                  display="flex"
                  justify="space-between"
                >
                  <div className="checkout_right_content_card_text">
                    신용카드
                  </div>

                  <Grid
                    borderRadius="6px"
                    padding="4px 4px 5px"
                    display="inline-block"
                    bg="rgb(204, 204, 204)"
                    width="50px"
                  >
                    KRW
                  </Grid>
                  <div className={`${closeList_html5_inicis ? "close" : "open"}`}>
                  <ArrowDropDownIcon
                    // className={`${closeList_html5_inicis ? "close" : "open"}`} // 상태값에 따라 class이름이 바뀐다.

                    onClick={foldList_html5_inicis} // 클릭하면 foldList() 함수가 실행된다.
                    fontSize="large"
                  ></ArrowDropDownIcon>
                  </div>
                </Grid>
              </Grid>
              <div
                className="checkout_right_content_fold_payment_content"
                ref={listRef_html5_inicis}
              >
                <Button
                  main
                  _onClick={() => handleInput("html5_inicis")}
                  backgroundcolor="#ba4148"
                  height="60px"
                  width= "90%"
                  margin="20px auto"
                  display="flex"
                >
                  구입하기
                </Button>
              </div>

              {/* 카카오페이 */}

              <Grid display="flex" column width= "80%"  margin="auto">
                <Grid
                  bdrBottom="1px solid #97979754"
                  padding="12px"
                  paddingT="24px"
                  paddingB="24px"
                  display="flex"
                  justify="space-between"
                >
                  <div className="checkout_right_content_card_text">
                    카카오페이
                  </div>

                  <Grid
                    borderRadius="6px"
                    padding="4px 4px 5px"
                    display="inline-block"
                    bg="rgb(204, 204, 204)"
                    width="50px"
                  >
                    KRW
                  </Grid>

                  <ArrowDropDownIcon
                    className={`${closeList_kakopay ? "close" : "open"}`} // 상태값에 따라 class이름이 바뀐다.
                    onClick={foldList_kakopay} // 클릭하면 foldList() 함수가 실행된다.
                    fontSize="large"
                  ></ArrowDropDownIcon>
                </Grid>
              </Grid>
              <div
                className="checkout_right_content_fold_payment_content"
                ref={listRef_kakopay}
              >
                <Button
                  main
                  _onClick={() => handleInput("kakaopay")}
                  backgroundcolor="#ba4148"
                  height="60px"
                  width= "90%"
                  margin="20px auto"
                >
                  구입하기
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    )}
    </>

  );
};

export default CheckOut;