import React, { useEffect, useState , useRef} from "react";
import { useHistory, useParams } from "react-router-dom";
import { dbService  , storageService } from "../../shared/firebase";
import { useDispatch, useSelector } from "react-redux";
import "./SearchResult.css";
import { Grid, Button, Text, Input2 } from "../../elements/index";
import { CircularProgress } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import {Pagination} from "../../components/index";
import { capitalize , capitalizeAllWords , createNamesArrayWithCaptitalizedWords, createartistNamesArrayWithCaptitalizedWords } from "../../shared/common";
import ReactPaginate from 'react-paginate';
import {
  useTranslation,
  withTranslation,
  Trans,
  I18nContext,
} from "react-i18next";
import i18next from "i18next";
import { actionCreators as SearchResultActions } from "../../redux/modules/SearchResult";
const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];


//해야할것 : serch 가 언어 인식 x 그리고 앞글자만 따와서 검색이됨 firebase 넣을때 앞글자 array 말고 모든글자를 넣긴해야하는데
//https://firebase.google.com/docs/firestore/solutions/search?provider=algolia
//https://typesense.org/docs/guide/firebase-full-text-search.html#build-a-search-ui
function SearchResult() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const user = useSelector((state) => state.user);
  const SearchResult = useSelector((state) => state.SearchResult);

  const { t, i18n } = useTranslation();


  const [Song_postPerPage, setSong_postPerPage] = useState(14); 
  const [Artist_postPerPage, setArtist_postPerPage] = useState(7); 

  const [infoText, setInfoText] = useState("");

  const [itemOffset, setItemOffset] = useState(0);
  const [Aritst_Song_itemOffset, setAritst_Song_ItemOffset] = useState(0);


  useEffect(() => {


    let searchText = capitalize(SearchResult.keyword);
    // let songnames = createNamesArrayWithCaptitalizedWords(searchText);
    
    console.log(SearchResult.keyword)
    // console.log(songnames[0])
    // Mcqueen Fiend - Remix
    setInfoText(`"${searchText}"`);
    if( SearchResult.keyword !== ""){
    dbService
    .collection("Song_All")
    .where("songnames", "array-contains", searchText)
    // .where("songnames", "array-contains", searchText)

    .get()
    .then((snapshot) => {
       const searchProduct = snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
        dispatch(SearchResultActions.setSearch_Song_Product(searchProduct));

    });

    searchText = capitalizeAllWords(SearchResult.keyword);

    dbService
    .collection("Song_All")
    .where("Artistnames", "array-contains", searchText)
    .get()
    .then((snapshot) => {
       const searchProduct = snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
        dispatch(SearchResultActions.setSearch_Artist_relatedSong_Product(searchProduct));
        setIsLoading(false);
    });


  } else{
    setIsLoading(false);
  }
  }, [user, dispatch, history , SearchResult.keyword]);



  const endOffset = itemOffset + Song_postPerPage;
  const songSearchPosts =  (SearchResult.search_song_product.slice(itemOffset, endOffset));
  const Song_PageCount = (Math.ceil(SearchResult.search_song_product.length / Song_postPerPage));


  const endOffset2 = Aritst_Song_itemOffset + Artist_postPerPage;
  const ArtistSearchPosts =  (SearchResult.search_artist_song_product.slice(Aritst_Song_itemOffset, endOffset2));
  const Artist_PageCount = (Math.ceil(SearchResult.search_artist_song_product.length / Artist_postPerPage)); // 전체 나타내는 페이지네이션 갯수 



  const handlePageClick = (event) => {
    const newOffset = (event.selected * Song_postPerPage) % items.length;
    setItemOffset(newOffset);
  };

  const handleArtist_Song_PageClick = (event) => {
    const newOffset = (event.selected * Artist_postPerPage) % items.length;
    setAritst_Song_ItemOffset(newOffset);
  };


  return (
    <Grid>

      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "26rem 0",
          }}
        >
          <CircularProgress color="secondary" size="5rem" />
        </div>
      ) : (   
    <Grid width="100%" >
    <Grid display ="flex" margin = "0 auto" width="1840px" height="1120px" paddingT="90px" >
        <div class="SearchResult_main_card">
          <Text size="30px" marginT="10px" row display="inline-block" textalign="center"  >Search Result for <span>{infoText}</span></Text>
            <Grid marginT="10px" width="1840px" display="inline-block">
            <Text size="36px" marginT="10px" row   width="1840px" bold textalign="left" marginL="30px">SONG</Text>        
            <div  className = 'SearchResult_main_song_card2'>
            {songSearchPosts && songSearchPosts.map((item) => (
                    <div id="cardHover" className = 'SearchResult_card' 
                      onClick={() => history.push(`/artist_product/${item.id}`)}>

                          {/* <div className="SearchResult_main_song_image_grid" > */}
                           <img src={item.data.Elbum_url}  alt="" className="SearchResult_main_song_card_image" />          
                          {/* </div> */}
                          <div  className = 'SearchResult_main_song_text'>{item.data.songName}
                              <span>/ {item.data.artistName}</span>
                          </div>
                    </div>
                )
                )}
               </div>

              <div className = 'SearchResult_main_ReactPaginate'>
               <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={Song_PageCount}
                  previousLabel="<"
                  renderOnZeroPageCount={null}
                />
                </div>

            </Grid>
            <Grid marginT="0px" width="1840px" display="inline-block">
            <Text size="36px" marginT="px" row   width="1840px" bold textalign="left" marginL="30px">Aritst Related Song</Text>        
            <div  className = 'SearchResult_main_Artist_song_card'>
            {ArtistSearchPosts && ArtistSearchPosts.map((item) => (
                    <div id="cardHover" className= 'SearchResult_card'
                     onClick={() => history.push(`/artist_product/${item.id}`)}>
                                                   
                        <div className="SearchResult_main_song_image_grid" >
                           <img src={item.data.Elbum_url}  alt="" className="SearchResult_main_song_card_image" />          
                           </div>
                            <div  className = 'SearchResult_main_song_text'>{item.data.songName}
                              <span>/ {item.data.artistName}</span>
                            </div>
                       </div>
                )
                )}
                </div>

                <div className = 'SearchResult_main_ReactPaginate'>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handleArtist_Song_PageClick}
                  pageRangeDisplayed={5}
                  pageCount={Artist_PageCount}
                  previousLabel="<"
                  renderOnZeroPageCount={null}
                />
                 </div>
            </Grid>

            <Grid marginT="0px" width="1840px" display="inline-block">
            <Text size="36px" marginT="px" row   width="1840px" bold textalign="left" marginL="30px">Aritst</Text>        
            <div  className = 'SearchResult_main_Artist_song_card'>
            {ArtistSearchPosts && ArtistSearchPosts.map((item) => (
                    <div id="cardHover"  className= 'SearchResult_card'>                   
                        <div className="SearchResult_main_song_image_grid" >
                           <img src={item.data.Elbum_url}  alt="" className="SearchResult_main_song_card_image" />          
                           </div>
                            <div  className = 'SearchResult_main_song_text'>{item.data.songName}
                              <span>/ {item.data.artistName}</span>
                            </div>
                       </div>
                )
                )}
                </div>

                <div className = 'SearchResult_main_ReactPaginate'>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handleArtist_Song_PageClick}
                  pageRangeDisplayed={5}
                  pageCount={Artist_PageCount}
                  previousLabel="<"
                  renderOnZeroPageCount={null}
                />
                 </div>
            </Grid>



         </div>      
      </Grid>
    </Grid>
      )}
    </Grid>
  
  );
}

export default SearchResult;
