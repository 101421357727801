import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { dbService } from "../../shared/firebase";
import { useDispatch, useSelector } from "react-redux";
import { Avatar } from "@material-ui/core";
import { IconButton, Menu, MenuItem} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Button, Text, Input2 } from "../../elements/index";
import "./Artist_MainPage.css";
import { CircularProgress } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { actionCreators as ArtistMainPageactions } from "../../redux/modules/Artist_MainPage";
import AddIcon from "@material-ui/icons/Add";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { Link, withRouter } from "react-router-dom";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import { ArtistMainSlider  , AlertModalContainer} from "../../components/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye as farEye } from "@fortawesome/free-regular-svg-icons";
import firebase from "firebase";

// import Artistmainslider from "../../components/Slider/artist_main_slider";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: -10,
    position: "absolute",
    marginRight: 10,
  },
  favoirte_button: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    margin: 0,
  },

}));

const StyledRating = withStyles({
  iconFilled: {
    color: "#ff6d75",
  },
  iconHover: {
    color: "#ff3d47",
  },
})(Rating);

function Artist_Main() {
  const Artist_MainPage = useSelector(
    (state) => state.Artist_MainPage
  );
  

  const history = useHistory();
  const { Artist } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const user = useSelector((state) => state.user);
  const [alert, setAlert] = useState({
    type: 'error',
    text: 'This is a alert message',
    show: false
  })
  const [LoginModalopen, setLoginModalopen] = useState(false);



  const classes = useStyles();

  useEffect(() => {
    dbService
      .collection("user_Public")
      .doc(Artist)
      .get()
      .then((snapshot) => {
        const fetchArtist = snapshot.data();
        const fetchid = snapshot.id;
        const fetch_artistuser_subscribe = snapshot.data()?.artistuser_subscribe
      
          dispatch(ArtistMainPageactions.set_ArtistMainPage_Artist(fetchArtist, fetchid, fetch_artistuser_subscribe ))
          
        if (!fetchid) {
          return;
        }
        dbService
          .collection("Song_All")
          .where("uid", "==", fetchid)
          .orderBy("update", "desc")
          .get()
          .then((snapshot) => {
            const update_song = snapshot.docs.map((doc) => ({
              id: doc.id,
              data: doc.data(),
              edit: songEdit(doc.data()?.songEdit),

            }));
            console.log(update_song)
            dispatch(ArtistMainPageactions.set_ArtistMainPage_Song(update_song))
            setIsLoading(false)
            if (!update_song) {
              return;
            }
           
          });
      });
  }, [
    history,
    dispatch,
    Artist,

  ]);

  useEffect(() => {
    console.log( Artist_MainPage, "data");
    // console.log(artist_main_song_update, "data");
  }, [Artist_MainPage]);


  const songEdit = (songEdit) => {
    if (songEdit === undefined) {
      return "원곡";
    } else if (songEdit.NoEdit === true) {
      return "원곡";
    } else if (songEdit.SomeEdit === true) {
      return "편곡";
    } else if (songEdit.Edit === true) {
      return "작곡";
    }
  };

  
  const Language_song_Edit = (song) => {
    if (song.edit === "원곡") {
      return `${t("Chart.Original_song")}`;
    } else if (song.edit === "편곡") {
      return `${t("Chart.Arrangement_song")}`;
    } else if (song.edit === "작곡") {
      return `${t("Chart.Composition_song")}`;
    }
  };

  const openOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const language_Currency_Amount = (song) => {
    if (i18n.language === 'kor') {
      return song.data.KOR;
    } else if (i18n.language === 'en') {
      return song.data.USD;
    } else if (i18n.language === 'ja') {
      return song.data.JPY;
    } else {
      return song.data.USD;
    }
  };

  const ratingvalue = (value) => {
    var newvalue = value.toFixed(2);
    return newvalue;
  };


  const youtubeAddressCheck = () => {

    if(Artist_MainPage?.Artist.artistuser_youTube === null || Artist_MainPage?.Artist.artistuser_youTube === undefined || Artist_MainPage?.Artist.artistuser_youTube === ""){
      setAlert({
        type: 'error',
        text: '아티스트의 유튜브가 없습니다',
        show: true
      })
      return;
    }
   else{
          window.open(
            `${Artist_MainPage?.Artist.artistuser_youTube}`,
            "Popup",
            "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30"
          )
        }
  };

 const closeAlertModal = () => {
    setAlert({
      type: '',
      text: '',
      show: false
    })
   };


  const Add_to_Basket = (id, song) => {
    if(user.is_login === false || user.user === null){
      // setLoginModalopen(true)
    }

    console.log(id)
    var doc =  dbService.collection('user_Public').doc(user.uid).collection('product_basket').doc(id);
    doc.get()
    .then((docSnapshot) => {
      
      if (docSnapshot.exists) {
          setAlert({
          type: 'warning',
          text: '이미 있는 곡입니다.',
          show: true
        })
        return;
      } else {
        doc.set(song)
        setAlert({
          type: 'success',
          text: '장바구니에 추가되었습니다.',
          show: true
        })
      }
  });
    setAnchorEl(false)
  };


  const gotoArtist_Product = async (id) => {

    var db = dbService.collection("Song_All").doc(id);
    return dbService
      .runTransaction((transaction) => {
        // This code may get re-run multiple times if there are conflicts.
        return transaction.get(db).then((sfDoc) => {
          if (!sfDoc.exists) {
            throw "Document does not exist!";
          }
          var newLookUP = sfDoc.data().lookup + 1;
          transaction.update(db, { lookup: newLookUP });
        });
      })
      .then((    
      ) => {
        history.push(`/Artist_product/${id}`)
      })
      .catch((error) => {});
  };


  const artist_subscribe_Active = async () => {
    if (user.is_login === false || user.user === null) {
      setLoginModalopen(true);
      return;
    }
   

    const artistuid = {
      artist_uid: Artist_MainPage?.Artist.uid,
    };

    var doc = dbService.collection("user_Public").doc(user?.uid);
    doc.get().then((docSnapshot) => {
      const filterUID = docSnapshot
        .data()
        ?.Artist_subscribe_uid.filter(
          (item) => item.artist_uid === Artist_MainPage?.Artist.uid
        );
      const artist_uid = filterUID.map((item) => item.artist_uid);
      if (artist_uid == `${Artist_MainPage?.Artist.uid}`) {
        setAlert({
          type: "warning",
          text: "이미 구독버튼을 눌렀습니다",
          show: true,
        });
        return;
      } else {


        doc.update({
          Artist_subscribe_uid:
            firebase.firestore.FieldValue.arrayUnion(artistuid),
        });

        setAlert({
          type: "success",
          text: "구독했습니다",
          show: true,
        });
      }

      var db = dbService.collection("user_Public").doc(Artist_MainPage?.Artist.uid);
      const newSubscribe_value =  Artist_MainPage?.ArtistSubScribe + 1;
      return dbService
        .runTransaction((transaction) => {
          // This code may get re-run multiple times if there are conflicts.
          return transaction.get(db).then((sfDoc) => {
            if (!sfDoc.exists) {
              throw "Document does not exist!";
            }
            transaction.update(db, {
              artistuser_subscribe: newSubscribe_value,
            });
            // setsubscribe(newPopulation);
          });
        })
        .then(() => {
          dispatch(
            ArtistMainPageactions.set_ArtistMainPage_Artist_subscribe(
              newSubscribe_value
            )
          );
          //누가구독했는지?
        })
        .catch((error) => {
          console.log("Transaction failed: ", error);
        });
    });

    // 이유저가 아티스트를 구독중이라면 diabled 계속
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "26rem 0",
          }}
        >
          <CircularProgress color="secondary" size="5rem" />
        </div>
      ) : (
        <Grid >
          <Grid    display="flex" width="90%" margin="auto" paddingT="30px">
            <Grid
                  width="75%"
                  column
                  marginB="90px"
                  display="flex"
                  paddingL="30px"
                  paddingR="30px"
                >
                { Artist_MainPage.Song && 
                <ArtistMainSlider key={Artist_MainPage.Song?.length} data={Artist_MainPage.Song} />}
                  

                <table className="Artist_MainPage_table">

                      <colgroup>
                          <col width="6%" />
                          <col width="38%" /> 
                          <col width="15%" />
                          <col width="11%" />
                          <col width="7%" />
                          <col width="7%" />
                          <col width="7%" />
                          <col width="7%" />
                          <col width="12%" />
                      </colgroup>

                  <thead className="Artist_MainPage_table_thead">
                      <tr>
                       <th className="Hardisest_tableHead_index">{t('Chart.Ranking')}</th>
                        <th className="Hardisest_tableHead_songname">
                        {t('Chart.Song_name')} / {t('Chart.Artist_name')}
                        </th>
                        <th className="Hardisest_tableHead_genres_edit">
                        {t('Chart.Genre')} / {t('Chart.Composition')}
                        </th>
                        <th className="Hardisest_tableHead_price">{t('Chart.Price')}</th>
                        <th className="Hardisest_tableHead_view">View</th>

                        <th className="Hardisest_tableHead_download">{t('Chart.Download')}</th>
                        <th className="Hardisest_tableHead_favorite">{t('Chart.Like')}</th>

                        <th className="Hardisest_tableHead_damgi">{t('Chart.Add')}</th>
                        <th className="Hardisest_tableHead_rating">{t('Chart.Rating')}</th>
                      </tr>
                    </thead>
                    {Artist_MainPage.Song?.map((song, index) =>
                      index < 11 ? (
                        <tbody className="Artist_MainPage_tbody_table"
                            onClick={() =>
                              gotoArtist_Product(song.id)
                                  }
                        >
                          <tr className="Artist_MainPage_tbody_tr">
                            <td className="Artist_MainPage_tbody_index">{index + 1}</td>

                            <td
                              className="Artist_MainPage_tbody_artist"
                            >
                              <img
                                src={song.data.Elbum_url}
                                alt=""
                                className="Artist_MainPage_tbody_image"
                              />
                          
                            <span className="Artist_MainPage_tbody_songName">
                                {song.data.songName}
                                <span className="Artist_MainPage_song_artistname">
                                  {song.data.artistName}
                                </span>
                              </span>
                            </td>

                           
                            <td className="Artist_MainPage_tbody_genres_edit">
                              {song.data.genres} / {Language_song_Edit(song)}
                            </td>
                            <td className="Artist_MainPage_tbody_price">
                              {t('Payment.Currency')} {language_Currency_Amount(song)}
                            </td>

                            <td className="Artist_MainPage_tbody_view">
                              {song.data.lookup}
                              <FontAwesomeIcon icon={farEye} className="Hardisest_farEye__Icon" />
                            </td>

                            <td className="Artist_MainPage_tbody_upgrade">
                              {song.data.update}
                              <ArrowDownwardIcon className="ArrowDownward__Icon" />
                            </td>

                            <td className="Artist_MainPage_tbody_favorite">
                              {song.data.faviorite}
                              <FavoriteBorderIcon className="ArrowDownward__Icon" />
                            </td>



                            <td className="Artist_MainPage_tbody_damgi">
                             
                            <ul class="Hardisest_basket_menu">
                                <li>
      
                                  <IconButton
                                            aria-haspopup="true"
                                            onClick={openOptions}
                                            className="Hardisest_basket_menu_icon"
                                          >
                                            <AddIcon className="Hardisest_tbody_add_Icon" />
                                </IconButton>
                                  <div class="Hardisest_basket_menu_depth">
                                    <ul>
                                      <li><span   onClick={() => Add_to_Basket(song.id , song)}>{t('Chart.Shopping_Basket')}</span></li>
                                  
                                    </ul>
                                  </div>
                                </li>
                              </ul>
                             
                           </td>

                           <td className="Artist_MainPage_tbody_rating_star">
                              <span className="Artist_MainPage_tbody_rating_star_icon">
                                <StyledRating
                                  precision={0.25}
                                  value={song.data.rating}
                                  readOnly
                                />
                              </span>
                              <span className="Artist_MainPage_tbody_rating">
                              {ratingvalue(song.data.rating)} / 5
                            </span>
                            </td>  


                        

                           
                          </tr>
                        </tbody>
                      ) : null
                    )}


                  
                </table>
              </Grid>

              <Grid width="25%" height="950px" position="relative" display="flex" paddingL="20px" paddingR="0px" column marginT="80px" >
                <Grid row position="relative" display="flex" paddingL="8%"  marginT="30px" width="100%" alignItem="left" justify="left" marginR="auto">
                 
                    <Avatar
                          className="Artist_info_artist_img"
                          src={Artist_MainPage.Artist?.photoURL}
                    >                     
                    </Avatar>
                  
                  <Grid column display="flex">
                    <Text bold size="27px" display="flex" marginL="30px">{Artist_MainPage.Artist?.displayName}</Text>
                    <Text color="#797979" size="15px" display="flex" marginL="30px" marginT="px">{Artist_MainPage.Artist?.email}</Text>
                  </Grid>
                </Grid>

                <Grid display="flex" margin="0 auto" justify="space-between" width="90%" paddingT="30px">
                  <Grid column display="flex" position="relative">
                      <Text size="18px"  color="#00000085" textAlign="center">
                        {Artist_MainPage?.ArtistSubScribe}
                      </Text>
                      <Text textAlign="center" size="21px" bold color="#00000085">Subscribe</Text>
                    </Grid>


                    <Grid column display="flex" position="relative">
                      <Text textAlign="center" size="18px"  color="#00000085">
                        {Artist_MainPage.Artist?.artistuser_update}
                      </Text>
                      <Text textAlign="center" size="21px" bold color="#00000085">Download</Text>
                    </Grid>

                    <Grid column display="flex" position="relative">
                      <Text textAlign="center" size="18px"  color="#00000085">
                      {Artist_MainPage.Artist?.artistuser_song}
                      </Text>
                      <Text textAlign="center" size="21px" bold color="#00000085">Songs</Text>
                    </Grid>

              

                </Grid>

                <Grid display="flex" margin="0 auto" justify="center" width="90%" paddingT="20px">
                    <Button
                      width="47%"
                      MainPage 
                      backgroundcolor="#ff6d75"
                      color="#fff"
                      borderradius="12px"
                      height="36px"
                      display="block"
                      _onClick={() =>
                        artist_subscribe_Active()
                      }
                      
                    >
                      구독하기
                    </Button>

                    <Button
                         MainPage 
                         backgroundcolor="#ff6d75"
                         color="#fff"
                         borderradius="12px"
                         height="36px"
                        width="47%"
                        marginL="6%"
                        display="block"
                      _onClick={() =>
                        youtubeAddressCheck()
                      }
                    >
                      YouTube
                    </Button>
                </Grid>

                <Grid display="flex" margin="0 auto" width="90%" paddingT="40px" >
                  <div class="artist__discrpction">
                    {Artist_MainPage.Artist?.artistuser_description}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <AlertModalContainer show={alert.show} close={closeAlertModal} type={alert.type}  text={alert.text} />

        </Grid>
      )}
    </>
  );
}

export default Artist_Main;
