import React, { useState, useEffect, useRef } from "react";
import CloseIcon from "@material-ui/icons/Close";
import "./AlertModalContainer.css";
import { useHistory } from "react-router-dom";

import styled from "styled-components";
import { dbService, authService , facebookprovider , googleprovider , setUser } from "../../shared/firebase";

import { useDispatch , useSelector  } from "react-redux";
import { Grid, Input2, Text, Button } from "../../elements/index";
import { Color } from "../../shared/DesignSys";
import firebase from "firebase";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import Alert from 'react-popup-alert'


function AlertModalContainer({ show, close ,type ,text }) {
  const history = useHistory();
 
  const [alert, setAlert] = useState({
    type: 'error',
    text: 'This is a alert message',
    show: false
  })
  const [typeColor, settypeColor] = useState(
  
  )
    
  useEffect(() => {
    if(type === 'success'){
      settypeColor({backgroundColor:"rgb(125, 219, 147)" , })
    } else if (type === 'error'){
      settypeColor({backgroundColor:"rgb(224, 134, 143)" , })
    } else if (type === 'warning'){
      settypeColor({backgroundColor:"rgb(246, 209, 97)" , })
    }
  }, [type]);


 

   

  return (
    <>
      {show ? (
        <div className="AlertModalContainer_wrapper">
          <div className="AlertModalContainer_background_white" style={typeColor}>
            {/* <div className="ModalContainer_content"> */}
            {/* <div className="AlertModalContainer_background_white" style={{backgroundColor:""}}> */}

            <Alert
              header={'Alert'}
              btnText={'Close'}
              text={text}
              type={type}
              show={show}
              onClosePress={close}
              pressCloseOnOutsideClick={true}
              showBorderBottom={false}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{alignItems:"center" ,   width:"200px" , height:"35px" , display:"block" , borderRadius:"20px" , justifyContent:"center" , textAlign:"center" , color:"#fff" , paddingTop:"4px" , margin:"auto"}}

              // buttonStyles={{backgroundColor:"#000"}}
            />


            {/* </div> */}
          </div>
        </div>
      ) : null}
    </>
  );
}


export default AlertModalContainer;
