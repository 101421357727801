import React, { useState, useEffect } from "react";
import { Select, MenuItem,} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import "./ArtistModalContainer.css";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {useHistory} from "react-router-dom";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Input2, Text, Button } from "../../elements/index";
import { Color } from "../../shared/DesignSys";
import { dbService,  Update_User  , Artist_amount} from "../../shared/firebase";
import { actionCreators as userActions } from "../../redux/modules/user";


const useStyles = makeStyles({
  select: {
    paddingTop: "8px",
    "&:before": {
      borderBottom: "1px solid #aaaaaa65",
    },
    "& .MuiInputBase-input": {
      color: "rgb(66, 66, 66)",
      textAlign: "left",
      fontSize: "21px",
      paddingLeft: "4px",
    },
    "&:after": {
      borderBottom: "1px solid #aaaaaa65",
    },
  },
});

function ArtistModalContainer({ show, close }) {
  const classes = useStyles();
  const histroy = useHistory();
  const dispatch = useDispatch();
  const [IsModal, setIsModal] = useState(true);
  const [closed, setclosed] = useState(show);
 
  const [agreeCheck, setagreeCheck] = useState(false); // 동의하기
  const [agreedisabled, setagreedisabled] = useState(true); // 버튼

  //아티스트 내용
  const [selectedCountry, setselectedCountry] = useState("");
  const [contact_email, setcontact_email] = useState("");
  const [youtube, setyoutube] = useState("");

  const gotoclose = () => {setclosed(close); setIsModal(true);};
  const gotoArtist_Info = () => {setIsModal(false);};
  const selectCountryHandler = (value) => setselectedCountry(value);

  const user = useSelector((state) => state.user);


  countries.registerLocale(enLocale);
  const countryObj = countries.getNames("en", { select: "official" });
  const countryArr = Object.entries(countryObj).map(([key, value]) => {
    return {
      label: value,
      value: key,
    };
  });


  useEffect(() => {
    setIsModal(true);
    setselectedCountry("");
  }, []);

  useEffect(() => {
    console.log(user)
  }, [user]);

  const Submit_ArtistModalContainer = async (event) => {

    const data = {
      artistuser_country: selectedCountry,
      artistuser_contact_email: contact_email,
      artistuser_youTube: youtube,
      artistuser_subscribe: 0,
      artistuser_song: 0,
      artistuser_update: 0,
      artistuser_description:"",
      role: "Artist",
    };
    const data_amount = {
      KOR:0, 
      JPY:0, 
      USD:0,
      EUR:0,
      CNY:0,
      Song_purchaseProduct:[],

    };

    if (data.country === "" || data.contact_email === "") {
      alert("필수사항을 입력해주세요");
    } else {
      Update_User(user.uid , data)


      dbService
      .collection("Artist_amount")
      .doc(user.uid)
      .get()
      .then((userFromDB) => {
        if (userFromDB.exists) {
          return;
      } else {
        Artist_amount(user.uid , data_amount)
      }
      })

      await dispatch(userActions.Artist_userdb(data));
      // histroy.push("/upload");
    }
  };


 
  return (
    <>
      {show ? (
        <div className="ArtistModalContainer_wrapper">
          <div className="ArtistModalContainer_background_white">
            <div className="ArtistModalContainer_content">
              <div className="ArtistModalContainer_title">
                {IsModal && (
                    <Text h2 color="#ba4148" size="25px">    
                      수익창출
                    </Text>             
                )}
                {!IsModal && (
                    <Text h2 color="#ba4148" size="25px"> 
                      아티스트 정보
                    </Text>
                )}
                <CloseIcon onClick={gotoclose} />
              </div>
              <div className="ArtistModalContainer_modalContents">
                {IsModal && (
                  <>
                    <textarea
                      className="ArtistModelContainer_textarea"
                      readonly=""
                    >
                      MyMusicSheet Terms of Service for Contents Provider These
                      Terms are entered into and made by and between
                      MyMusicSheet(MMS) and the CONTENTS PROVIDER (each a
                      "Party" and together the "Parties") as follows concerning
                      the CONTENTS PROVIDER’s provision of contents for MMS’s
                      digital music business. Article 1 (Purpose) The purpose of
                      this Terms is to set out matters such as the Parties’
                      respective rights/obligations and relevant business
                      procedure, etc. concerning the CONTENTS PROVIDER’s
                      provision of source contents and granting of the right to
                      use them to MMS for the music business using sheet music
                      contents platform carried out by MMS alone or jointly with
                      others. Article 2 (Definitions) As used herein, the
                      following terms used in this Terms shall have the meaning
                      stated below. Terms not defined shall be interpreted in
                      accordance with the relevant laws and generally accepted
                      practices. 1 “Contents”: Contents which is made by the
                      CONTENTS PROVIDER or whose copyright is owned by the
                      CONTENTS PROVIDER and all materials or information that is
                      produced or processed electronically to enhance their
                      efficacy in preservation and use. 2 “Service”: An act
                      designed to make it possible for users to use the contents
                      that the CONTENTS PROVIDER provides to MMS through an
                      information/communication network such as wired/wireless
                      Internet or a means similar to it within a MMS-run
                      web/mobile site and program, using transmission for
                      inter-change. Its details shall follow what is stipulated
                      in the ancillary document attached hereto. 3 “Users”:
                      People using the Service by paying the price including
                      those paying a discounted price or using the Service free
                      as offered through sales promotion. 4 “Right to use”:
                      MMS’s right to use the CONTENTS PROVIDER-provided contents
                      through reproduction, transmission, and publication within
                      the Service per this Terms. 5 “Fee for using contents”:
                      The amount that MMS pays to the CONTENTS PROVIDER out of
                      sales occurring through use of the CONTENTS
                      PROVIDER-provided contents and relevant copyrights. 6
                      “Service fee”: Fee that users should pay to MMS as a price
                      for using the contents in the Service. 7 “Sales”: The
                      total amount of Service fee paid by users. 8 “Net Profit”:
                      Profits which shall be calculated in the amount with taxes
                      and dues related to the payment handling fee and Copyright
                      royalty excluded from sales made using sheet music in
                      service. 9 “Business day”: Days in which normal business
                      activities are carried out other than periodic holidays
                      like Saturdays/Sundays, holidays stipulated by the
                      Regulations on Public Offices’ Holidays, and temporary
                      public holidays. Article 3 (CONTENTS PROVIDER’s rights and
                      obligations) 1 The CONTENTS PROVIDER warrants that it is
                      the rightful party for copyrights concerning contents
                      owned by it and it has legitimate right to allow others to
                      use the copyrights and that it shall be responsible for
                      all matters related to the said rights. The CONTENTS
                      PROVIDER shall settle all legal disputes under the
                      Civil/Criminal Act concerning contents that may occur/have
                      occurred after/before the signing of this Terms with its
                      expense and responsibility and defend and hold MMS
                      harmless from such legal disputes. 2 Where the CONTENTS
                      PROVIDER granted the exclusive right for using part of
                      contents owned by it to a third party, the CONTENTS
                      PROVIDER may exclude it from the scope of use contemplated
                      in this Terms. 3 The CONTENTS PROVIDER states and warrants
                      that the CONTENTS PROVIDER owns or holds the licenses,
                      rights, consent, and approvals to sell the CONTENTS
                      PROVIDER’s content in MMS and the CONTENTS PROVIDER hereby
                      certifies, states and warrants that the CONTENTS PROVIDER
                      has licensed to MMS any copyright or other proprietary
                      rights which the CONTENTS PROVIDER has in respect of the
                      content to sell through MMS. If the content is original,
                      the CONTENTS PROVIDER guarantees that the CONTENTS
                      PROVIDER is the complete and sole copyright holder for
                      that content. If content is copyrighted work by a third
                      party, the CONTENTS PROVIDER guarantees that the CONTENTS
                      PROVIDER is the only one who has created the work. In the
                      event of any damages caused MMS or a third party due to
                      any other facts, the CONTENTS PROVIDER shall be
                      responsible for all damages resulting. Except where MMS is
                      responsible under the law, the CONTENTS PROVIDER is solely
                      responsible for the CONTENTS PROVIDER’s content to MMS. 4
                      The CONTENTS PROVIDER shall verify the scope of the
                      licenses obtained by the Copyright Associations if the
                      CONTENTS PROVIDER uses the intellectual property of any
                      third party's works. Music works for which the CONTENTS
                      PROVIDER can use intellectual property through MMS are
                      those for the administrative works of each copyright
                      management organization contracted for the comprehensive
                      use of MMS and those for the administrative works of
                      worldwide copyright management organizations that have a
                      mutual management contract with the Copyright
                      Associations. The licenses for each work are valid for the
                      content sold within the territory of the Copyright
                      Associations that has a comprehensive use contract with
                      MMS. Article 4 (MMS’s rights and obligations) 1 MMS should
                      pay the CONTENTS PROVIDER of the music content 75% of the
                      Net Profit. The Net Profit refers to the total value of
                      the Sales revenue excluding all associated fees such as
                      settlement fees, tax processing fees and copyright fees.
                      The CONTENTS PROVIDER of the music content reserves the
                      sole right to determine the price for the contents and
                      sheet music. 2 MMS may use the image of the album jacket
                      and the artists for PR of the CONTENTS PROVIDER-provided
                      contents or images produced by MMS or received from a
                      third party. 3 MMS shall take care not to infringe on the
                      CONTENTS PROVIDER’s rights through unauthorized
                      use/reproduction/distribution of image rights and
                      neighboring copyright concerning the CONTENTS
                      PROVIDER-provided contents. In the event of such
                      infringement, MMS shall take a step to stop it immediately
                      and be responsible for compensation of the loss and/or
                      damages incurred by the CONTENTS PROVIDER. 4 As the key
                      actor of the Service, MMS shall have the obligation and
                      responsibilities to do what is required including payment
                      of the price for part of the contents for which a third
                      party owns copyright. 5 In the event of interruption in
                      the Service or a problem in price payment due to a
                      technical glitch, MMS shall immediately inform the
                      CONTENTS PROVIDER of the cause of the problem, how soon
                      the situation will be restored, and the name of the
                      employee in charge of the restoration work and do its best
                      for earliest restoration. Article 5 (Payment of fee for
                      using contents) 1 With regard to the fee for using the
                      CONTENTS PROVIDER-provided contents, MMS shall pay it per
                      the details calculated by MMS’s system. 2 MMS shall submit
                      the details of its use of contents for the CONTENTS
                      PROVIDER’s calculation of the fee by the 10th (or the
                      following business day if the said day falls on a public
                      holiday) of the following month. 3 Where the accumulated
                      amount of the fee that MMS should pay to the CONTENTS
                      PROVIDER come to USD 30 or less (not including VAT), the
                      payment invoice shall be carried forward to the following
                      month. 4 Where the CONTENTS PROVIDER does not consent to
                      the details submitted by MMS as stated in the foregoing 2,
                      it shall inform MMS of it, along with the relevant basis,
                      within 7 (seven) business days of its receipt of MMS’s
                      details. In such a case, the Parties shall do their best
                      to settle it the soonest possible through mutual
                      consultation. 5 MMS shall provide the CONTENTS PROVIDER
                      with materials concerning the Service-related statistics
                      and other payment-related settlement including the
                      percentage of users of the Service paying the fee upon the
                      CONTENTS PROVIDER’s request. 6 Where an error is found in
                      the fee payment, the Party shall inform the other Party of
                      it immediately and the Parties shall follow a procedure
                      for re-settlement of the case. 7 MMS shall pay the fee for
                      using of contents by remitting it to the CONTENTS
                      PROVIDER-designated PayPal account, by the end (or the
                      following business day if the said day falls on a public
                      holiday) of the month, in which it submits the details of
                      the fee for using contents in the foregoing 2. 8 To be
                      eligible to receive the payment of proceeds from MMS under
                      these terms, the CONTENTS PROVIDER must register an active
                      PayPal account, that is approved by MMS, with the
                      associated MMS account. The CONTENTS PROVIDER agrees that
                      MMS reserves the right to withhold all revenue earned
                      during the affected period of when the sheet music was
                      sold until payment account is completed to register. 9 The
                      CONTENTS PROVIDER of the Services will have the option of
                      having its payment through the bank account only if the
                      CONTENTS PROVIDER fulfills the conditions both of: i)
                      residing and adhering to the jurisdiction of the Republic
                      of Korea or Japan ii) not being able to create an approved
                      PayPal account in a country other than the Republic of
                      Korea Article 6 (Disputes following DMCA) 1 MMS and the
                      CONTENTS PROVIDER shall follow Digital Millennium
                      Copyright Act (DMCA) by notice and procedure for copyright
                      infringement. 2 MMS may establish reasonable procedures to
                      resolve other right owner claims, or otherwise, and the
                      CONTENTS POVIDER will cooperate with MMS to resolve such
                      disputes. 3 If a third party provides MMS with a claim of
                      ownership of any material contained within contents, then
                      the content may be blocked from the service and payments
                      accruing to the CONTENTS PROVIDER pursuant to this Terms
                      may be prorated, suspended or cancelled, and if the
                      CONTENTS PROVIDER disputes the third party claim, the
                      CONTENTS PROVIDER will participate in a procedure to
                      resolve the dispute. 4 The CONTENTS PROVIDER understands
                      and acknowledges that, unless there is a special
                      agreement, the original author has the moral right for
                      each work, including the right to produce derivative works
                      and right to maintain the identity of the content. The
                      CONTENTS PROVIDER agrees to cooperate fully with copyright
                      protection measures, such as requests for the deletion of
                      the contents provided by the copyright holders, if the
                      CONTENTS PROVIDER’ contents is derivative works. Article 7
                      (Mutual cooperation) 1 The Parties shall cooperate with
                      each other to provide uninterrupted Service and
                      enhancement of users’ rights/interests through provision
                      of good-quality contents. 2 The CONTENTS PROVIDER shall
                      cooperate for MMS’s PR and marketing effort made for sales
                      promotion of the contents. 3 MMS shall do its best for
                      cooperation with third parties including affiliated
                      businesses directly/indirectly related to operation of the
                      Service. MAPIA shall consult with the CONTENTS PROVIDER in
                      making decisions on important matters that may have an
                      impact on this Terms. Article 8 (Agreement period and
                      extension) This Agreement shall remain valid for one year
                      from the day it is signed by the Parties. This Agreement
                      shall be automatically extended for another year unless
                      either Party informs the other Party of its intention not
                      to at least one month in advance of the current Agreement
                      period. Article 9 (Termination) This Terms may be
                      terminated even during the Terms period if one of the
                      following is the case: 1 The Parties’ consent to such in
                      writing; 2 The breaching Party’s failure to take a
                      remedial step within 2 (two) weeks of the other Party’s
                      request for such a step in writing after its default on a
                      contractual clause or delay in fulfilling its contractual
                      obligation; 3 Failure of either Party to honor its
                      commercial check or note issued to a third party or the
                      authorities’ ban to use it in transactions; 4 Either Party
                      being subject to the court’s decision on (provisional)
                      seizure of property or (provisional) disposition or forced
                      execution 5 Either Party’s application for a bankruptcy or
                      reorganization procedure 6 Either Party being unable to
                      carry out normal business activities due to a reason or
                      carry out this Terms due to a situation beyond its
                      reasonable control Article 10 (Confidentiality) Neither
                      party shall disclose any business secret of the other
                      Party that it has come to obtain while carrying out this
                      Terms to a third party without obtaining the other party’s
                      prior consent. The breaching Party shall take
                      responsibilities under the Civil and Criminal Acts.
                      Article 11 (Force majeure) The failure or delay of either
                      Party to perform any obligation under this Terms solely by
                      reason of act of God, riot, war, terror, uprising or the
                      Government (including a local government)’s act or other
                      causes beyond its reasonable control shall not be deemed
                      to be a breach of this Terms. Article 12 (Interpretation
                      and court of competent jurisdiction) 1 Matters not
                      stipulated in this Terms, or disputes over interpretation
                      of a provision in this Terms shall be settled in a
                      mutually amicable way, taking into the relevant laws and
                      generally accepted commercial practices. 2 Where the
                      amicable settlement stated in the foregoing 1 is not
                      reached, the case shall be brought before the Seoul
                      Central District Court in Republic of Korea for its
                      decision.
                    </textarea>
                  </>
                )}

                {IsModal ? (
                  <>
                    <Grid display="flex" justify="space-between" marginT="12px">
                      <Input2
                        check
                        checked={agreeCheck}
                        _onClick={() => {
                          if (agreeCheck) {
                            setagreeCheck(false);
                            setagreedisabled(true);
                          } else {
                            setagreeCheck(true);
                            setagreedisabled(false);
                          }
                        }}
                        desc="동의하기"
                      />
                    </Grid>
                    <Grid display="flex" marginT="12px" justify="center">
                      <Button
                        noflex
                        width="400px"
                        height="60px"
                        marginT="6px"
                        disabled ={agreedisabled}
                        _onClick={gotoArtist_Info}
                      >
                        Agree
                      </Button>     
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid column display="flex">
                      <Text textalign="left" size="15px" marginT="8px">
                        Country*
                      </Text>
                      <Select
                        style={{ width: "400px" }}
                        value={selectedCountry}
                        onChange={(e) => selectCountryHandler(e.target.value)}
                        className={classes.select}
                      >
                        {!!countryArr?.length &&
                          countryArr.map(({ label, value }) => (
                            <MenuItem key={value} value={value}>
                              {label}
                            </MenuItem>
                          ))}
                      </Select>
                    </Grid>

                    <Grid marginT="9px">
                      <Text textalign="left" size="15px">
                        Contact email*
                      </Text>   
                      <ContactBox>
                        <ContactInput
                          type="email"
                          placeholder="EMAIL"
                          onChange={(e) => {
                            setcontact_email(e.target.value);
                          }}
                          value={contact_email}
                        />
                      </ContactBox>
                    </Grid>

                    <Grid marginT="9px">
                      <Text textalign="left" size="15px">
                        Youtube*
                      </Text>
                      <ContactBox>
                        <ContactInput
                          type="text"
                          placeholder="Youtube Address"
                          onChange={(e) => {
                            setyoutube(e.target.value);
                          }}
                          value={youtube}
                        />
                      </ContactBox>
                      <Button
                        noflex
                        width="400px"
                        height="60px"
                        marginT="18px"
                        _onClick={Submit_ArtistModalContainer}
                      >
                        ARTIST JOIN
                      </Button>
                    </Grid>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

const ContactBox = styled.div`
  border: 1px solid ${Color.Light_3};
  ${"" /* border-radius: 12px;  */}
  border-top: none;
  border-left: none;
  border-right: none;
  width: 100%;
  height: 45px;
  max-height: 45px;
  margin: 0.3rem 0;
  padding: 0px;
  box-sizing: border-box;
  overflow: hidden;
  align-items: center;
  transition: border 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    box-shadow 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &:focus-within {
    border: 1px solid ${Color.Primary};
    box-shadow: 0 0 0 3px ${Color.Primary}33;
  }
`;
const ContactInput = styled.input`
  height: 100%;
  border: none;
  background-color: transparent;
  box-sizing: border-box;
  letter-spacing: 0.5px;
  overflow: hidden;
  color: rgb(66, 66, 66);
  width: 90%;
  outline: none;
  padding-left: 4px;
  padding-right: 10px;
  padding-bottom: 2px;
  font-size: 21px;
  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
    font-size: 12px;
    user-select: none;
  }
`;

export default ArtistModalContainer;
