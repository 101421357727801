import React, { useState, useEffect, useRef } from "react";
import CloseIcon from "@material-ui/icons/Close";
import "./AvatarModalContainer.css";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Input2, Text, Button } from "../../elements/index";
import firebase from "firebase";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import Avatar from "react-avatar-edit";
import { actionCreators as userActions } from "../../redux/modules/user";
import { dbService  , storageService} from "../../shared/firebase";
import styled from "styled-components";
import { Color } from "../../shared/DesignSys";



function AvatarModalContainer({ show, close }) {
  const dispatch = useDispatch();
  const [closed, setclosed] = useState(show);
  const user = useSelector((state) => state.user);
  const [src, setSrc] = useState("");
  const [preview, setPreview] = useState(user?.photoURL);
  const [open, setOpen] = useState(true);
  const [PhotoFile, setPhotoFile] = useState();
  const [IsPhotoEdit, setIsPhotoEdit] = useState(true);
  const [displayName, setdisplayName] = useState(user?.displayName);


  const onBeforeFileLoad = (elem) => {
    if (elem.target.files[0].size > 7168000) {
      alert("File is too big!");
      elem.target.value = "";
    } else {
      
      console.log(elem.target.files[0]);
    }
  };

  useEffect(() => {
    if(displayName === ""){
      setdisplayName(user?.displayName)
    }

}, [user?.displayName, displayName ]);



 
  const convertURLtoFile = async (dataurl) => {
    if (dataurl === "") {
      return;
    }
    var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), 
    n = bstr.length, 
    u8arr = new Uint8Array(n);
    
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    const filename = dataurl.split("/").pop(); // url 구조에 맞게 수정할 것
     var file = new File([u8arr], filename, {type:mime});
 
     const AutoId = () => {
      const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    
      let autoId = ''
    
      for (let i = 0; i < 20; i++) {
        autoId += CHARS.charAt(
          Math.floor(Math.random() * CHARS.length)
        )
      }
      return autoId
    }
    var autoid = AutoId()

      var uploadPhotoFile = storageService
      .ref(`songs/User/Photo/${autoid + file.name}`)
      .put(file);
  
      const id = dbService.collection("user_Public").doc(user?.uid);
      await uploadPhoto(uploadPhotoFile , id , filename , autoid);

  };





  const onClose = () => {
    setPreview(user?.photoURL);
  };

  const onCrop = (preview) => {
    setPreview(preview);
  };


  

  const uploadPhoto = (uploadPhotoFile, id , filename , autoid) => {
    uploadPhotoFile.on(
      "state_change",
      (snapshot) => {
      },
      (error) => {
      },
      () => {
        storageService
          .ref("songs/User/Photo")
          .child(autoid + filename)
          .getDownloadURL()
          .then((url) => {
            id.update({ photoURL: url });
            dispatch(userActions.Edit_photoAvatar(url))
            console.log(url)
          });
      }
    );
  };


  const uploaddisplayName = () => {

    dbService.collection("user_Public").doc(user?.uid).update({
      displayName : displayName,  
  })
  dispatch(userActions.Edit_displayname(displayName)) 
  };

  const  saveAvatar = () => {
    convertURLtoFile(preview)
    setOpen(close);

  };

  const  savedisplayName = () => {
    uploaddisplayName();
    setOpen(close);

  };

  const gotoEMAIL = () => {setIsPhotoEdit(false);};
  const gotoPHOTO = () => {setIsPhotoEdit(true);}


  return (
    <>
      {show ? (
        <div className="AvatarModalContainer_wrapper">
          <div className="AvatarModalContainer_background_white">
            <div className="AvatarModalContainer_content">
              {/* 맨윗줄  */}
              <div className="AvatarModalContainer_content_Signup_content">
                <div className="AvatarModalContainer_content_Signup_text">
                  <Text
                    h2
                    color={IsPhotoEdit ? "#ba4148" : "#999999"}
                    onClick={gotoPHOTO}
                  >
                    PHOTO
                  </Text>
                  <Text
                    h2
                    color={IsPhotoEdit ? "#999999" : "#ba4148"}
                    marginL="18px"
                    onClick={gotoEMAIL}
                  >
                    NickName
                  </Text>


                </div>
                <CloseIcon
                  onClick={close}
                  className="AvatarModalContainer_content_Signup_close"
                />
              </div>

            {IsPhotoEdit ? (
              <div className="AvatarModalContainer_content_input_content">
                            <div className="AvatarModalContainer_avatar">          
                              <Avatar
                                width={400}
                                height={200}
                                onCrop={onCrop}
                                onClose={onClose}
                                onBeforeFileLoad={onBeforeFileLoad}
                                src={src}
                              />
                            <div className="AvatarModalContainer_avatar_Preview">          
                              <img src={preview} alt="Preview" className="AvatarModalContainer_PHOTO_PREVIEW"/>
                                </div>
                            </div>
                            <Grid column dispay="flex" marginT="8px">
                              <Button noflex main width="400px" height="52px" marginT="6px" _onClick={saveAvatar}>
                                UPDATE
                              </Button>
                            </Grid>
                          </div>
            ):(
              <div className="AvatarModalContainer_content_input_content">
              <SignupBoxDisabled>
                        <SignupInputDisabled
                          type="text"
                          value={displayName}
                          disabled={true}
                          name="displayName"
                        />
                      </SignupBoxDisabled>

              <SignupBox>
                        <SignupInput
                          type="text"
                          placeholder="NICKNAME"
                          onChange={(e) => {
                            setdisplayName(e.target.value);
                          }}
                          // onBlur={checkNickname}
                          name="displayName"
                        />
                      </SignupBox>
                      <Button noflex main width="400px" height="52px" marginT="6px" _onClick={savedisplayName}>
                                UPDATE
                     </Button>
                      </div>
                  )}
                              


              
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

const SignupBox = styled.div`
  border: 1px solid ${Color.Light_3};
 ${'' /* border-radius: 12px;  */}
  border-top: none;
  border-left: none;
  border-right: none;
  width: 100%;
  height: 45px;
  max-height: 45px;
  margin: 0.2rem 0;
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
  align-items: center;
  
  transition: border 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    box-shadow 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &:focus-within {
    border: 1px solid ${Color.Primary};
    box-shadow: 0 0 0 3px ${Color.Primary}33;
  }
`;
const SignupInput = styled.input`
  height: 100%;
  border: none;
  background-color: transparent;
  box-sizing: border-box;
  letter-spacing: 0.5px;
  overflow: hidden;
  color: rgb(71, 71, 71);
  width: 90%;
  outline: none;
  padding-left: 5px;
  padding-right: 10px;
  padding-bottom: 5px;
  font-size: 21px;
  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
    font-size: 12px;
    user-select: none;
  }
`;

const SignupBoxDisabled = styled.div`
  border: 1px solid ${Color.Light_3};
 ${'' /* border-radius: 12px;  */}
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: rgba(221, 221, 221, 0.315);

  width: 100%;
  height: 45px;
  max-height: 45px;
  margin: 0.2rem 0;
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
  align-items: center;
  
  transition: border 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    box-shadow 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &:focus-within {
    border: 1px solid ${Color.Primary};
    box-shadow: 0 0 0 3px ${Color.Primary}33;
  }
`;
const SignupInputDisabled = styled.input`
  height: 100%;
  border: none;
  box-sizing: border-box;
  letter-spacing: 0.5px;
  overflow: hidden;
  background-color: transparent;
  color: rgb(71, 71, 71);
  width: 90%;
  outline: none;
  padding-left: 5px;
  padding-right: 10px;
  padding-bottom: 5px;
  font-size: 21px;
  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
    font-size: 12px;
    user-select: none;
  }
`;


export default AvatarModalContainer;
