import React, { useRef, useMemo, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import { Footer } from "../../components/index";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { Avatar } from "@material-ui/core";
import { Grid, Button, Text, Input2 } from "../../elements/index";
import { Pagination } from "../../components/index";
import "./Notice.css";
import { dbService, storageService } from "../../shared/firebase";
import { actionCreators as NoticeActions } from "../../redux/modules/Notice";
import ReactPaginate from "react-paginate";
import { CircularProgress } from "@material-ui/core";

const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
// 글쓰기버튼 관리자만보이게

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  return isDesktop ? children : null;
};

const Notice = (props) => {
  const histroy = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const Notice = useSelector((state) => state.Notice);
  const [itemOffset, setItemOffset] = useState(0);
  const [Notice_PerPage, setNotice_PerPage] = useState(10);
  const [LookUp, setLookUp] = useState();
  const [SearchPosts, setSearchPosts] = useState([]);
  const [Song_PageCount, setSong_PageCount] = useState("");
  const user = useSelector((state) => state.user);

  useEffect(() => {
    dbService
      .collection("Notice")
      .orderBy("notice_createdAt", "desc")
      .get()
      .then((snapshot) => {
        const Noticedata = snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
          time: time(doc.data().notice_createdAt?.toDate().toISOString()),
        }));
        console.log(snapshot);
        dispatch(NoticeActions.set_Notice_Data(Noticedata));
        // setIsLoading(false);
      })
      .then(() => {});
  }, [dispatch, histroy]);

  // const endOffset = itemOffset + Notice_PerPage;
  // const songSearchPosts =  (Notice.noticeData.slice(itemOffset, endOffset));
  // const Song_PageCount = (Math.ceil(Notice.noticeData.length / Notice_PerPage));

  useEffect(() => {
    const endOffset = itemOffset + Notice_PerPage;
    if (Notice.noticeData === undefined) {
      return;
    }
    const songSearchPosts = Notice.noticeData.slice(itemOffset, endOffset);
    setSearchPosts(songSearchPosts);
    const Song_PageCount = Math.ceil(Notice.noticeData.length / Notice_PerPage);
    setSong_PageCount(Song_PageCount);
    setIsLoading(false);
  }, [dispatch, histroy, Notice, itemOffset, Notice_PerPage]);

  const time = (data) => {
    var split1 = (data || "").split("T");
    var replace = split1[0].replace(/\-/g, " ");
    var replace2 = replace.replace(" ", ".");
    var date = replace2.replace(" ", ".");
    return date;
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * Notice_PerPage) % items.length;
    setItemOffset(newOffset);
  };

  const timeControl = (data) => {
    const str2 = data.substring(0, data.length - 1);
    var regexFor = /￦./gi; // c 문자 전체
    var str3 = str2.replace(regexFor, "/");
    return str3;
    // console.log(data.toLocaleDateString())
  };

  const gotoNoticeSearch = async (id) => {
    var db = dbService.collection("Notice").doc(id);
    return dbService
      .runTransaction((transaction) => {
        // This code may get re-run multiple times if there are conflicts.
        return transaction.get(db).then((sfDoc) => {
          if (!sfDoc.exists) {
            throw "Document does not exist!";
          }
          var newLookUP = sfDoc.data().notice_LookUP + 1;
          setLookUp(newLookUP);
          transaction.update(db, { notice_LookUP: newLookUP });
        });
      })
      .then(() => {
        // dispatch(NoticeActions.set_Notice_LookUP(LookUp)) //바로바로 업데이트되게해야하는데
        histroy.push(`/Home/NoticeSearch/${id}`);
        // setrating_disabled(true)
      })
      .catch((error) => {});
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "26rem 0",
          }}
        >
          <CircularProgress color="secondary" size="5rem" />
        </div>
      ) : (
        <Desktop>
          <Grid position="relative" width="100%" marginT="64px" display="flex" paddingB="50px">
            <Grid display="flex" margin="auto" width="1200px">
              <Grid marginT="30px">
                <Grid row display="flex" justify="space-between" padding="10px">
                  <Text
                    h2
                    textAlign="left"
                    width="300px"
                    alignItem="center"
                    marginT="10px"
                  >
                    공지사항
                  </Text>
                  {user.user?.role === "Administrator" ? (
                    <Button
                      main
                      noflex
                      width="100px"
                      height="30px"
                      display="inline-block"
                      marginT="15px"
                      _onClick={() => histroy.push("/Home/NoticeWrite")}
                    >
                      글쓰기
                    </Button>
                  ) : null}
                </Grid>

                <table className="notice-table">
                  {/* <colgroup>
                    <col width="5%" />
                    <col width="10%" />
                    <col width="50%" />
                    <col width="15%" />
                    <col width="10%" />
                    <col width="10%" />
                  </colgroup>
                  <thead className="Notice_table_thead">
                    <tr>
                      <th>번호</th>
                      <th>구분</th>
                      <th>제목</th>
                      <th>작성자</th>
                      <th>작성일시</th>
                      <th>조회수</th>
                    </tr>
                  </thead> */}

                  {SearchPosts &&
                    SearchPosts.map((post, index) => (
                      <>
                        <Grid
                          width="1200px"
                          bdr="1px solid #4b4b4b59"
                          borderRadius="20px"
                          padding="20px"
                          marginT="15px"
                          display="block"
                        >
                          <Grid display="block" row>
                            <Text
                              color="#202020"
                              bold
                              size="18px"
                              textAlign="left"
                              flexdirection="row"
                            >
                              <span>{timeControl(post.data.notice_createdAt_date)} </span>
                              <span style={{marginLeft:"4px"}}> {post.data.notice_Type} </span>
                            </Text>
                          </Grid>

                          <Grid
                            bdrBottom="1px solid #4b4b4b59"
                            margin="auto"
                            marginT="10px"
                            justify="center"
                          ></Grid>

                          <Grid display="block" row marginT="10px">
                            <Text
                              color="#696969"
                              size="14px"
                              textAlign="left"
                              flexdirection="row"
                  
                            >
                              <span>작성자: {post.data.notice_Writer}</span>&nbsp;
                              <span style={{marginLeft:"10px" }}>작성일자: {timeControl(post.data.notice_createdAt_date)} </span>
                            </Text>
                          </Grid>

                          <Grid
                            bdrBottom="1px solid #4b4b4b59"
                            margin="auto"
                            marginT="10px"
                            justify="center"
                          ></Grid>

                          <Text
                            color="#363636"
                            display="block"
                            size="15px"
                            marginT="15px"
                            lineHeight="24px"
                          >
                             <div
                              className="notice_search_content"
                              dangerouslySetInnerHTML={{
                                __html: post.data.notice_Desc,
                              }}
                            ></div>
              
                          </Text>
                        </Grid>

                        {/* <tbody
                          className="Notice_table_tbody"
                          onClick={() => gotoNoticeSearch(post.id)}
                        >
                          <tr className="Notice_tr_table" key={index + 1}>
                            <td>{index + 1}</td>
                            <td>{post.data.notice_Type}</td>
                            <td className="text-left">
                              {post.data.notice_Title}
                            </td>
                            <td>{post.data.notice_Writer}</td>
                            <td>
                              {timeControl(post.data.notice_createdAt_date)}
                            </td>

                            <td>{post.data.notice_LookUP}</td>
                      
                          </tr>
                        </tbody> */}
                      </>
                    ))}

                  {/* </div> */}
                </table>
                <div className="notice_table_ReactPaginate">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={10}
                    pageCount={Song_PageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Footer />
        </Desktop>
      )}
    </>
  );
};

export default Notice;
