import React, { useRef, useMemo, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import { Editor } from "../../components/index";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { Avatar } from "@material-ui/core";
import { Grid, Button, Text, Input2 } from "../../elements/index";
import { Pagination } from "../../components/index";
import firebase from "firebase";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/Input";
import "./NoticeWrite.css"
import { dbService, storageService } from "../../shared/firebase";
import Select3 from 'react-select';

// import "./Notice.css"


// {
//   "_id": 1,
//   "Id": "bigexecution",
//   "userName": "홍*동",
//   "title": "제목입니다.",
//   "desc": "<p>내용입니다.</p>",
//   "readCount": 0,
//   "fileList": [],
//   "date": "2020-09-23 17:54:05.025"
// }

// {
//   "_id": "5f6445c37548c868c8effa72",
//   "noticeId": "1",
//   "Id": "bigexecution"
// }
const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  return isDesktop ? children : null;
};

const Upload_Tabs = [
  { value: '공지사항', text: '공지사항' },
  { value: '업데이트', text: '업데이트' }
]
const options = [

  { value: '공지사항', label: '공지사항' },
  { value: '업데이트', label: '업데이트' }


];

const NoticeWrite = (props) => {
  const [Edit_title , setEdit_title] = useState();
  const [Upload_Type , setUpload_Type] = useState([]);
  const histroy = useHistory();

   const [posts, setPosts] = useState([]);
   const [currentPage, setCurrentPage] = useState(1);
   const [postsPerPage] = useState(10);
   const [desc, setDesc] = useState('');
   function onEditorChange(value) {
       setDesc(value)
   }
   const user = useSelector((state) => state.user);

   const [selectedOption , setselectedOption] = useState();


//    useEffect(() => {
//     const fetchPosts = async () => {
//        let res = await ajax('/api/notice/noticeList');
//        setPosts(res.data);
//     }

//     fetchPosts();
//  }, []);

  const onClickSearch = ()  => {
    var date = new Date();
 

    const data = {
      notice_createdAt_date : date.toLocaleDateString('ko-KR'),
      notice_createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      notice_Desc:desc,
      notice_Title:Edit_title,
      notice_Type:selectedOption.value,
      notice_LookUP:0,
      notice_Writer: "Administrator"
    };

    const Notice = dbService.collection("Notice").doc()
    const HistoryPage = Notice.set(data);

    if(HistoryPage){
      histroy.push("/Home/Notice")
    }

  // if (title.trim() == '') {
  //     alert('제목을 입력해주세요'); return;
  // }

  // if (desc.trim() == '') {
  //     alert('내용을 입력해주세요'); return;
  // }

  // await uploadReferenece.current.upload().then(function (result) {

  //     const files = result;


  //     ajax('/api/notice/saveNotice', { title: title, desc: desc, type: type, files: files }, (res) => {
  //         if (res.data && res.data.ok === 1) {
  //             alert('저장 완료');
  //             setId(res.data.insertedId);
  //             var linkToClick = document.getElementById('notice_Detail_Link');
  //             linkToClick.click();
  //         } else {
  //             alert('공지사항을 저장하는 도중 오류가 발생하였습니다.')
  //         }
  //     }, null, true); 

  // }).catch(function (err) {

  // });

}


  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  // <Route path="/Home/NoticeWrite/:noticeID" exact component={NoticeWrite} />

    const handleChange = (e) => {
      // event handler
      console.log(e.target.value);
    };
 

  return (
    <>
      <Desktop>   
      <Grid position="relative"  width= "100%"   marginT= "64px" height="auto"  display= "flex">
        <Grid display= "flex" margin="auto" width="1200px" height="800px" >
         <Grid marginT="60px">
          <Grid row display="flex" marginB="10px">     
            <Text h2 textAlign="left" width="180px" marginT="8px">공지사항 작성</Text>
            {/* <Select
                 value={Upload_Type}
                 onChange={(e) => {setUpload_Type(e.target.value)}}
                 InputProps={QuoteListStyle2}
                 variant="outlined"
                // classes="NoticeWrite_select"
                 style={QuoteListStyle}
                       >
                {Upload_Tabs.map((doc) => (
                                  <option value={doc}>{doc.value}</option>
                                ))}
             </Select> */}
             {/* <Select2 onChange={handleChange}>
              {Upload_Tabs.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  defaultValue={props.defaultValue === option.value}
                >
                  {option.value}
                </option>
              ))}
            </Select2> */}
            <Select3
          value={selectedOption}
          onChange={setselectedOption}
          options={options}
          className="NoticeWrite_select"
        />

            </Grid>
           


        
            <Input2  value={Edit_title} type="text" plcholder="제목"  marginB="40px" marginT="10px" _onChange={(e) => {setEdit_title(e.target.value)}} />

      
            <Editor value={desc} onChange={onEditorChange} />
            <Button Edit  noflex width="100px" _onClick={onClickSearch}>저장</Button>
            <Button Edit  noflex width="100px" display="inline-block">목록으로</Button>

         </Grid>
        </Grid>
      </Grid>
    </Desktop>
    </>
  );
};

const QuoteListStyle = {
  color: "#303030",
  // borderBottom: "1px solid #DADADA",
  "&:hover:not($disabled):not($focused):not($error) $underline": {
    borderBottom: "2px solid #EAEAEA"
  },
  width: "140px",
  // marginTop: "1rem",
  borderRadius:"12px",
  height: "50px",
  // margin: 'dense',
};


const QuoteListStyle2 = {
  padding: "0px 0px",
  "&: MuiOutlinedInput-input":{
    padding: "0px 0px"
  }
};


export default NoticeWrite;


const Select2 = styled.select`
	margin: 0;
	min-width: 0;
	display: block;
	width: 140px;
	padding: 8px 8px;
	font-size: inherit;
	line-height: inherit;
	border: 1px solid #EAEAEA;
	border-radius: 12px;
	color: inherit;
	background-color: transparent;
  transition: border 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275), box-shadow 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

	&:focus-within {
    ${'' /* border: 1px solid #ba4148; */}
    box-shadow: 0 0 0 3px #ba4148;
    border:none;
	}
`;
const IconSVG = styled.svg`
	margin-left: -28px;
	align-self: center;
	width: 24px;
	height: 24px;
`;