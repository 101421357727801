import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../MainPage_Slider/MainPage_Slider.css";
import Color, { Palette } from "color-thief-react";
import { useMediaQuery } from "react-responsive";


const settings = {
  dots: false,
  infinite: true,
  speed: 1500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplaySpeed: 8000,
  autoplay: true,
  pauseOnHover: false,
  pauseOnFocus: false,
  fade: true,
  arrows: false,
};
const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1367 });
  return isDesktop ? children : null;
};

const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 916, maxWidth: 1366 });
  return isTablet ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 915 });
  return isMobile ? children : null;
};

const MainPage_Slider = (data) => {
  const [slider2, setslider] = useState();

  const Loading = () => <span>Loading...</span>;
  return (
    <>
    <Desktop>
    <div className="Homeslider">
      <Slider ref={(slider) => setslider(slider)} {...settings}>
        {data.data.map((song, index) => (
          <>
            {/* <Palette src={song.data.Elbum_url} crossOrigin="anonymous" format="hex" colorCount={5}>
            {({ data, loading }) => {
              if (loading) return <Loading />;
              return (
                <div>
                  Palette:
                  <ul>
                    {data.map((color, index) => (
                      <li key={index} style={{ color: color }}>
                        <strong>{color}</strong>
                      </li>
                    ))}
                  </ul>
                </div>
              );
            }}
          </Palette> */}

            <div class="Hardisestslider-width">
              <div className="Homeslider-image_opacity1">
                <img
                  src={song.data.Elbum_url}
                  alt=""
                  className="Homeslider__image25"
                  data-slick='{"zIndex": 1}'
                />
              </div>

              <div className="Homeslider-image_opacity0">
                <Palette
                  src={song.data.Elbum_url}
                  crossOrigin="anonymous"
                  format="hex"
                  colorCount={4}
                >
                  {({ data, loading }) => {
                    if (loading) return <Loading />;
                    return (
                      <div>
                        <h2
                          className="Homeslider_text2"
                          style={{ color: `${data[1]}` }}
                        >
                          {song.data.artistName}
                        </h2>
                        <h2
                          className="Homeslider_text"
                          style={{ color: `${data[0]}` }}
                        >
                          {song.data.songName}
                        </h2>
                      </div>
                    );
                  }}
                </Palette>
                <img
                  src={song.data.Elbum_url}
                  alt=""
                  className="Homeslider__image24"
                  data-slick='{"zIndex": 10}'
                />
              </div>
            </div>
          </>
        ))}
      </Slider>
    </div>
    </Desktop>


    <Tablet>
    <div className="Homeslider">
      <Slider ref={(slider) => setslider(slider)} {...settings}>
        {data.data.map((song, index) => (
          <>
            <div class="Hardisestslider-width">
              <div className="Homeslider-image_opacity1">
                <img
                  src={song.data.Elbum_url}
                  alt=""
                  className="Homeslider__image25"
                  data-slick='{"zIndex": 1}'
                />
              </div>

              <div className="Homeslider-image_opacity0">
                <Palette
                  src={song.data.Elbum_url}
                  crossOrigin="anonymous"
                  format="hex"
                  colorCount={4}
                >
                  {({ data, loading }) => {
                    if (loading) return <Loading />;
                    return (
                      <div>
                        <h2
                          className="Homeslider_text2"
                          style={{ color: `${data[1]}` }}
                        >
                          {song.data.artistName}
                        </h2>
                        <h2
                          className="Homeslider_text"
                          style={{ color: `${data[0]}` }}
                        >
                          {song.data.songName}
                        </h2>
                      </div>
                    );
                  }}
                </Palette>
                <img
                  src={song.data.Elbum_url}
                  alt=""
                  className="Homeslider__image24"
                  data-slick='{"zIndex": 10}'
                />
              </div>
            </div>
          </>
        ))}
      </Slider>
    </div>
    </Tablet>

    <Mobile>
    <div className="Homeslider">
      <Slider ref={(slider) => setslider(slider)} {...settings}>
        {data.data.map((song, index) => (
          <>
            <div class="Hardisestslider-width">
              <div className="Homeslider-image_opacity1">
                <img
                  src={song.data.Elbum_url}
                  alt=""
                  className="Homeslider__image25"
                  data-slick='{"zIndex": 1}'
                />
              </div>

              <div className="Homeslider-image_opacity0">
                <Palette
                  src={song.data.Elbum_url}
                  crossOrigin="anonymous"
                  format="hex"
                  colorCount={4}
                >
                  {({ data, loading }) => {
                    if (loading) return <Loading />;
                    return (
                      <div>
                        <h2
                          className="Homeslider_text2"
                          style={{ color: `${data[1]}` }}
                        >
                          {song.data.artistName}
                        </h2>
                        <h2
                          className="Homeslider_text"
                          style={{ color: `${data[0]}` }}
                        >
                          {song.data.songName}
                        </h2>
                      </div>
                    );
                  }}
                </Palette>
                <img
                  src={song.data.Elbum_url}
                  alt=""
                  className="Homeslider__image24"
                  data-slick='{"zIndex": 10}'
                />
              </div>
            </div>
          </>
        ))}
      </Slider>
    </div>
    </Mobile>

    </>
  );
};

export default MainPage_Slider;
