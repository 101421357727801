import { createAction, handleActions } from "redux-actions";
import axios from "axios";
import { setLocal, deleteLocal } from "../../shared/Local";



 const ADDTOCHECKOUT = "ADDTOCHECKOUT";
 const DELETENAME2 = "DELETENAME2";

const initialState = {
  checkout: [],
};

// 회원 가입
const set_checkout = (checkout) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: ADDTOCHECKOUT,
      checkout : checkout,
    });  
  };
};

const delete_dataName = (checkout) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: DELETENAME2,
      checkout : checkout,
    });  
  };
};



const CheckOut = (state = initialState, action) => {
  switch (action.type) {
  
      case ADDTOCHECKOUT:
        return {
          ...state,
          checkout : action.checkout,
        }
        case DELETENAME2:
          return {
            ...state,
            checkout : action.checkout,
          }

    default:
      return state
  }
};

export default CheckOut;


const actionCreators = {
  set_checkout,
  delete_dataName,
}

export { actionCreators };
