import { createAction, handleActions } from "redux-actions";
import axios from "axios";
import { setLocal, deleteLocal } from "../../shared/Local";

const POP_CHART = "POP_CHART";
const J_POP_CHART = "J_POP_CHART";
const K_POP_CHART = "K_POP_CHART";
const CLASSIC_CHART = "CLASSIC_CHART";
const ANI_CHART = "ANI_CHART";
const OST_CHART = "OST_CHART";
const ROCK_METAL_CHART = "ROCK_METAL_CHART";
const DANCE_ELEC_CHART = "DANCE_ELEC_CHART";
const MEDLEY_CHART = "MEDLEY_CHART";
const ETC_CHART = "ETC_CHART";

const initialState = {
  Pop_Chart: "",
  JPop_Chart: "",
  KPop_Chart: "",
  Classic_Chart: "",
  Ani_Chart: "",
  OST_Chart: "",
  Rock_Metal_Chart: "",
  Dance_Chart: "",
  Medley_Chart: "",
  ETC_Chart: "",
};

const set_Pop_Chart = (song) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: POP_CHART,
      Pop_Chart: song,
    });
  };
};

const set_KPop_Chart = (song) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: K_POP_CHART,
      KPop_Chart: song,
    });
  };
};

const set_JPop_Chart = (song) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: J_POP_CHART,
      JPop_Chart: song,
    });
  };
};

const set_Classic_Chart = (song) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: CLASSIC_CHART,
      Classic_Chart: song,
    });
  };
};

const set_ANI_Chart = (song) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: ANI_CHART,
      Ani_Chart: song,
    });
  };
};

const set_OST_Chart = (song) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: OST_CHART,
      OST_Chart: song,
    });
  };
};

const set_ROCK_METAL_Chart = (song) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: ROCK_METAL_CHART,
      Rock_Metal_Chart: song,
    });
  };
};

const set_DANCE_ELEC_Chart = (song) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: DANCE_ELEC_CHART,
      Dance_Chart: song,
    });
  };
};

const set_MEDLEY_Chart = (song) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: MEDLEY_CHART,
      Medley_Chart: song,
    });
  };
};

const set_ETC_Chart = (song) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: ETC_CHART,
      ETC_Chart: song,
    });
  };
};

const Genre = (state = initialState, action) => {
  switch (action.type) {
    case POP_CHART:
      return {
        ...state,
        Pop_Chart: action.Pop_Chart,
      };

    case K_POP_CHART:
      return {
        ...state,
        KPop_Chart: action.KPop_Chart,
      };
    case J_POP_CHART:
      return {
        ...state,
        JPop_Chart: action.JPop_Chart,
      };
    case CLASSIC_CHART:
      return {
        ...state,
        Classic_Chart: action.Classic_Chart,
      };
    case ANI_CHART:
      return {
        ...state,
        Ani_Chart: action.Ani_Chart,
      };
    case OST_CHART:
      return {
        ...state,
        OST_Chart: action.OST_Chart,
      };
    case ROCK_METAL_CHART:
      return {
        ...state,
        Rock_Metal_Chart: action.Rock_Metal_Chart,
      };
    case DANCE_ELEC_CHART:
      return {
        ...state,
        Dance_Chart: action.Dance_Chart,
      };

    case MEDLEY_CHART:
      return {
        ...state,
        Medley_Chart: action.Medley_Chart,
      };

    case ETC_CHART:
      return {
        ...state,
        ETC_Chart: action.ETC_Chart,
      };

 
    default:
      return state;
  }
};

export default Genre;

const actionCreators = {
  set_Pop_Chart,
  set_KPop_Chart,
  set_JPop_Chart ,
  set_Classic_Chart,
  set_ANI_Chart,
  set_OST_Chart,
  set_ROCK_METAL_Chart,
  set_DANCE_ELEC_Chart,
  set_MEDLEY_Chart,
  set_ETC_Chart,
};

export { actionCreators };
