import React, { useRef, useMemo, useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import { Footer , Editor } from "../../components/index";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { Avatar } from "@material-ui/core";
import { Grid, Button, Text, Input2 } from "../../elements/index";
import "./NoticeSearch.css";
import { dbService, storageService } from "../../shared/firebase";
import { actionCreators as NoticeActions } from "../../redux/modules/Notice";
import { CircularProgress } from "@material-ui/core";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  return isDesktop ? children : null;
};

const NoticeSearch = (props) => {
  const history = useHistory();
  const { content } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const Notice = useSelector((state) => state.Notice);
  const [NoticeEdit, setNoticeEdit] = useState(false);
  const [Edit_title , setEdit_title] = useState(Notice.noticeContentData.notice_Title);
  const [desc, setDesc] = useState(Notice.noticeContentData.notice_Desc);


   function onEditorChange(value) {
       setDesc(value)
   }

  useEffect(() => {
    dbService
      .collection("Notice")
      .doc(content)
      .get()
      .then((snapshot) => {
        const fetchid = snapshot.id;
        const fetchNotice = snapshot.data();
        const fethchNoticeTIME = time(
          snapshot.data()?.notice_createdAt.toDate().toISOString()
        );
        dispatch(
          NoticeActions.set_Notice_Content(
            fetchid,
            fetchNotice,
            fethchNoticeTIME
          )
        );
        
        setIsLoading(false);
      });
  }, [content, history, dispatch ]);
  



  useEffect(() => {
 

      
  }, [Notice]);


  const time = (data) => {
    var split1 = (data || "").split("T");
    var replace = split1[0].replace(/\-/g, " ");
    var replace2 = replace.replace(" ", ".");
    var date = replace2.replace(" ", ".");
    return date;
  };


  const onClickSave = (id)  => {

    const Notice = dbService.collection("Notice").doc(id).update({
      notice_Desc:desc,
      notice_Title:Edit_title,

    })
    // const HistoryPage = Notice.update(data)
    // const HistoryPage = Notice.set(data);

    if(Notice){
      history.push("/Home/Notice")
    }
}
  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "26rem 0",
          }}
        >
          <CircularProgress color="secondary" size="5rem" />
        </div>
      ) : (
        <Desktop>
          <Grid
            position="relative"
            width="100%"
            marginT="64px"
            height="auto"
            display="flex"
          >
            <Grid display="flex" margin="auto" width="1200px" 
            // height="800px"
            >
              <Grid marginT="180px" display="flex" column>
                {/* <Grid row display="flex" marginB="10px">      */}
                {/* <Text h2 textAlign="left" width="180px" marginT="8px"></Text> */}

                {/* </Grid> */}
                <Grid
                  width="1200px"
                  bdr="1px solid #4b4b4b59"
                  borderRadius="20px"
                  padding="30px 40px"
                >
                  <Text
                    color="#202020"
                    bold
                    size="24px"
                    textAlign="left"
                    flexdirection="row"
                  >
                    {Notice.noticeContentData.notice_Type}
                  </Text>
                  <Grid
                    bdrBottom="1px solid #4b4b4b59"
                    margin="auto"
                    marginT="10px"
                    marginB="10px"
                    justify="center"
                  ></Grid>

                  
                  {NoticeEdit === true ? (
                 <Input2   value={Edit_title} type="text"  plcholder="제목"  marginB="40px" marginT="15px" _onChange={(e) => {setEdit_title(e.target.value)}} />

              ):(
                <Text
                    color="#202020"
                    size="18px"
                    textAlign="left"
                    flexdirection="row"
                    marginT="6px"
                    // bold
                  >
                    제 목 : {Notice.noticeContentData.notice_Title}
                  </Text>
              )}

                  <Grid
                    bdrBottom="1px solid #4b4b4b59"
                    margin="auto"
                    marginT="10px"
                    justify="center"
                  ></Grid>

                  <Text
                    color="#202020"
                    size="15px"
                    textAlign="left"
                    flexdirection="row"
                    marginT="6px"
                  >
                    작성자 : {Notice.noticeContentData.notice_Writer} &nbsp;
                    &nbsp; 작성일 : {Notice.noticeTime} &nbsp; &nbsp; 조회수 :{" "}
                    {Notice.noticeContentData.notice_LookUP}
                  </Text>
                  <Grid
                    bdrBottom="1px solid #4b4b4b59"
                    margin="auto"
                    marginT="10px"
                    justify="center"
                  ></Grid>

              {NoticeEdit === true ? (
                <Editor value={desc} onChange={onEditorChange} />

              ):(
                <div
                    className="notice_search_content"
                    dangerouslySetInnerHTML={{
                      __html: Notice.noticeContentData.notice_Desc,
                    }}
                  ></div>
              
              )}

   
                </Grid>

                <Grid display="flex" justify="center" marginT="10px" marginB="150px">
                  <Button
                    main
                    noflex
                    width="100px"
                    height="40px"
                    display="inline-block"
                    justify="center"
               
                    _onClick={() => history.push("/Home/Notice")}
                  >
                    목록
                  </Button>


                  {NoticeEdit === true ? (
                   
                  <Button
                    main
                    noflex
                    width="100px"
                    height="40px"
                    display="inline-block"
                    justify="center"
                    marginL="5px"
                    _onClick={() => onClickSave(Notice.noticeContentID)}
                  >
                    저장
                  </Button>

              ):(
                <>
                <Button
                    main
                    noflex
                    width="100px"
                    height="40px"
                    display="inline-block"
                    justify="center"
                    marginL="5px"
                    _onClick={() => setNoticeEdit(true)}
                  >
                    수정
                  </Button>
                
                  <Button
                    main
                    noflex
                    width="100px"
                    height="40px"
                    display="inline-block"
                    justify="center"
                    marginL="5px"
                    // _onClick={() => setNoticeEdit(true)}
                  >
                    삭제
                  </Button>
                
                
                </>
              )}
                 

                  
                 
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Footer />
        </Desktop>
      )}
    </>
  );
};

export default NoticeSearch;
