import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { dbService } from "../../shared/firebase";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import "./ProductBasket.css";
import { actionCreators as ProductBasketActions } from "../../redux/modules/ProductBasket";
import { actionCreators as CheckOutActions } from "../../redux/modules/CheckOut";

import { Grid, Button, Text, Input2 } from "../../elements/index";
import { CircularProgress } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";

import {
  useTranslation,
  withTranslation,
  Trans,
  I18nContext,
} from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import i18next from "i18next";


function ProductBasket() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const user = useSelector((state) => state.user);
  const ProductBasket = useSelector((state) => state.ProductBasket);

  const [CheckList, setCheckList] = useState([]);
  const [Selectdata, setSelectdata] = useState([]);

  const [fist_ID_List, setfist_ID_List] = useState([]);
  const [fist_data_List, setfist_data_List] = useState([]);

  const [sumamount, setsumamount] = useState([]);


  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (user) {
      dbService
        .collection("user_Public")
        .doc(user.uid)
        .collection("product_basket")
        .get()
        .then((snapshot) => {
          const product_basket = snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data()?.data,
          }));
          // console.log(snapshot.data())
          // console.log(product_basket + "ooo");
          dispatch(ProductBasketActions.set_Product_Basket(product_basket));
          setIsLoading(false);
        });
    }
  }, [user, dispatch, history]);


  // useEffect(() => {
  //   console.log(ProductBasket.basket + "Ssss");
  // }, [ProductBasket]);



  useEffect(() => {
    var Price_mapping = Selectdata.map((i) => i.data.Number2);
    // console.log(Price_mapping)
    var Price_reducing =[]
    var Price_setting  =[]
    if (i18n.language === 'kor') {
        Price_reducing = Price_mapping.reduce((sum, cur) => sum + cur, 0);
        Price_setting = Price_reducing;
      } else if (i18n.language === 'en') {
        Price_reducing = Price_mapping.reduce((sum, cur) => sum + cur, 0);
        Price_setting = Price_reducing.toFixed(1);
      } else if (i18n.language === 'ja') {
        Price_reducing = Price_mapping.reduce((sum, cur) => sum + cur, 0);
        Price_setting = Price_reducing;
      }
    setsumamount(Price_setting);
  }, [Selectdata, i18n , sumamount]);



  useEffect(() => {
    let ids = [];
    let entire = [];

    i18n.on("languageChanged", (lng) => {
      ProductBasket.basket.map((item, i) => {
        var orgprice = 0;
        var realprice = orgprice;
        if (i18n.language === "kor") {
          orgprice = `${item.data.KOR}`;
          if(orgprice.indexOf(",") === 1 ){
            realprice = orgprice.replace(/,/, "");
         } else {
            realprice = orgprice;
          };

        } else if (i18n.language === "en") {
          orgprice = item.data.USD;
          realprice = orgprice;
        } else if (i18n.language === "ja") {
          orgprice = item.data.JPY;
          realprice = orgprice;
        }

        var NumberPrice = Number(realprice);

        var org = { Number2: NumberPrice };

        ids[i] = { id: item.id, update: NumberPrice };
        entire[i] = { id: item.id, data: Object.assign(item.data, org) };

        setsumamount();

      });
    });

    ProductBasket.basket.map((item, i) => {
      var orgprice = 0;
      var realprice = orgprice;
      if (i18n.language === "kor") {
       
        orgprice = `${item.data.KOR}`;
        console.log(orgprice.indexOf(","))

        if(orgprice.indexOf(",") === 1 ){
          realprice = orgprice.replace(/,/, "");
       } else {
          realprice = orgprice;
        };
        console.log( realprice )
      } else if (i18n.language === "en") {
        orgprice = item.data.USD;
        realprice = orgprice;
      } else if (i18n.language === "ja") {
        orgprice = item.data.JPY;
        realprice = orgprice;
      }

      var NumberPrice = Number(realprice);
      var org = { Number2: NumberPrice };

      ids[i] = { id: item.id, update: NumberPrice };
      entire[i] = { id: item.id, data: Object.assign(item.data, org) };
    });


    setfist_ID_List(ids);
    setfist_data_List(entire);


    setIsLoading(false);
  }, [i18n, ProductBasket.basket]);





  const onChangeAll = (e) => {
    setCheckList(e.target.checked ? fist_ID_List.map((i) => i.id) : []);
    setSelectdata(e.target.checked ? fist_data_List : []);
  };


  const onChangeEach = (e, data) => {
    
    var orgprice2 = [];
    var realprice = orgprice2;
    if (i18n.language === "kor") {
  
      orgprice2 = `${data.data.KOR}`;
        if(orgprice2.indexOf(",") === 1){
          realprice = orgprice2.replace(/,/, "");
       } else {
          realprice = orgprice2;
        };

    } else if (i18n.language === "en") {
      orgprice2 = data.data.USD;
      realprice = orgprice2;
    } else if (i18n.language === "ja") {
      orgprice2 = data.data.JPY;
      realprice = orgprice2;
    }

    var NumberPrice = Number(realprice);
    var org = { Number2: NumberPrice};

    if (e.target.checked) {
      setCheckList([...CheckList, data.id]);
      setSelectdata((state, i) => [
        ...state,
        (state[data.id] = {
          id: data.id,
          data: Object.assign(data.data, org),
        }),
      ]);
      // console.log(CheckList)
    } else {
      // console.log(CheckList)
      setCheckList(CheckList.filter((checkedId) => checkedId !== data.id));
      setSelectdata(Selectdata.filter((state) => state.id !== data.id));
    }
  };

  const removeFromBasket = async (data) => {
    dispatch(ProductBasketActions.remove_Product_Basket(data));
    dbService
      .collection("user_Public")
      .doc(user.uid)
      .collection("product_basket")
      .doc(data.id)
      .delete();

      setCheckList(CheckList.filter((checkedId) => checkedId !== data.id));
      setSelectdata(Selectdata.filter((state) => state.id !== data.id));
  };


  const Change_Language_currncy = (item) => {
    if (i18n.language === "kor") {
      return item.data.KOR;
    } else if (i18n.language === "en") {
      return item.data.USD;
    } else if (i18n.language === "ja") {
      return item.data.JPY;
    } else {
      return item.data.KOR;
    }
  };

  const gotoCheckout = () => {

    if(CheckList.length === 0){
      alert(`물품이 하나도 없습니다`)
      return;
    }


    dbService.collection('user_Public')
    .doc(user.uid)
    .collection('Purchase_Product')
    .get()
    .then((docSnapshot) => {

    
      const song_UID2 = docSnapshot.docs.map((doc) => ({id: doc.id , data : doc.data()}) );
      const song_UID3 = song_UID2.map((item) => item.id)

      var eaqulId = song_UID3.filter(x=> CheckList.includes(x))

      // console.log(song_UID3.filter(x=> CheckList.includes(x)));
      // console.log(Selectdata.filter((state) => state.id !== eaqulId.map((item) => item)))

        // 곡여러개 뱉음
      if(Array.isArray(eaqulId) && eaqulId.length !== 0){
        var equal = Selectdata.filter((state) => state.id !== eaqulId.map((item) => item))
        var equalSongName = equal.map((item) => item.data.songName)
       
          // console.log(equalSongName)
        alert(`${equalSongName} 는 현재 구매한물품입니다`)
        return;
     } else {

      dispatch(CheckOutActions.set_checkout(Selectdata));
      history.push("/Payment/Checkout");
      };
      

      
  });


  };


  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "26rem 0",
          }}
        >
          <CircularProgress color="secondary" size="5rem" />
        </div>
      ) : (
       
        <Grid display= "flex" margin="auto" width="85%" column >
            <Grid display= "flex" margin="auto"  width="80%" justify="center" marginT="60px">
                <Grid borderRadius="50px" width="50px" height="50px" bg="#ba4148"  >
                    {/* <Grid borderRadius="50px" width="50px" height="50px" bdr="3px solid #fff" display="block"  justify="center"  margin="4px auto"> */}
                      <Text color="#fff" textAlign="center" size="24px" bold padding="8px 0" justify="center" >1</Text>
                  {/* </Grid> */}
                </Grid>
                
                <Text color="rgb(70, 70, 70)" textAlign="center" size="18px" bold  justify="center" lineHeight="24px" display="block" padding="15px 0" marginL="10px" marginR="10px">장바구니</Text>
                 
                  <ArrowForwardIos className="ArrowForwardIos_icon"/>
                  <ArrowForwardIos className="ArrowForwardIos_icon"/>

                <Grid borderRadius="50px" width="50px" height="50px" bg="rgb(211, 211, 211)" marginL="10px">
                      <Text color="#fff" textAlign="center" size="24px" bold padding="8px 0" justify="center" >2</Text>
                </Grid>
               
                <Text color="rgb(70, 70, 70)" textAlign="center" size="18px" bold  justify="center" lineHeight="24px" display="block" padding="15px 0" marginL="10px" marginR="10px">결제</Text>
                 
                  <ArrowForwardIos className="ArrowForwardIos_icon"/>  
                  <ArrowForwardIos className="ArrowForwardIos_icon"/>
               
                <Grid borderRadius="50px" width="50px" height="50px" bg="rgb(211, 211, 211)"  marginL="10px">
                      <Text color="#fff" textAlign="center" size="24px" bold padding="8px 0" justify="center" >3</Text>
                </Grid>
               
                <Text color="rgb(70, 70, 70)" textAlign="center" size="18px" bold  justify="center" lineHeight="24px" display="block" padding="15px 0" marginL="10px">결제완료</Text>


            </Grid>

        <Grid  width="100%"  display= "flex" margin="auto"  marginT="60px">
          <Grid display="flex" column width="70%"   marginL="auto" marginB="auto" marginR="auto">
            <Grid  paddingB="50px" width="90%" margin="auto">
                <Text h2  width="100%">장바구니</Text>

              <table className="Payment_checkout__left_table">
                       <colgroup>
                          <col width="10%" />
                          <col width="50%" /> 
                          <col width="20%" />
                          <col width="20%" />
                      </colgroup>
              
                <thead className="Payment_checkout__left_thead">
                  <tr>
                    <th className="Payment_checkout__left_content_allcheckbox">
                     
                        <Input2
                            // marginL="50px"
                            check
                            checked={CheckList.length === fist_ID_List.length}
                            _onClick={onChangeAll}
                            //   desc="아이디 저장"
                          />
                    </th>

                    <th className="Payment_checkout__left_content_product">
                      상품
                    </th>
                    <th className="Payment_checkout__left_content_price">
                      가격
                    </th>
                    <th className="Payment_checkout__left_content_price">
                      삭제
                    </th>
                  </tr>
                </thead>

                {ProductBasket.basket.map((item) => (
                    <tbody className="Payment_checkout__left_content_tbody">
                      <tr className="Header_song_tr">
                        <td className="Payment_checkout__left_content_tbody_checkbox">
                       
                          <Input2
                          // marginL="50px"
                          justify="center"
                          margin="auto"
                          width="100%"
                            check
                            checked={CheckList.includes(item.id) ? true : false}
                            _onClick={(e) => onChangeEach(e, item)}
                            //   desc="아이디 저장"
                          />
                       
                        </td>
                        <td
                              className="Payment_checkout__left_content_song_artist"
                            >
                              <img
                                  src={item.data.Elbum_url}
                                  alt=""
                                  className="Payment_checkout__left_content_tbody_image"
                                />
                          
                            <span className="Payment_checkout__left_content_tbody_songName">
                                 {item.data.songName}
                                <span className="Payment_checkout__left_content_tbody_artistname">
                                {item.data.artistName}
                                </span>
                              </span>
                            </td>



                        


                        <td className="Payment_checkout__left_content_tbody_price">
                          {t("Payment.Currency")} {Change_Language_currncy(item)}
                        </td>

                   

                        <td className="Payment_checkout__left_content_tbody_closeIcon">
                          <IconButton
                            className="closeIcon"
                            onClick={() => removeFromBasket(item)}
                          >
                            <CloseIcon className="close__Icon" />
                          </IconButton>
                        </td>
                      </tr>
                    </tbody>
                 
                ))}

                <tfoot className="Payment_checkout__left_content_tbody_total">
                  <tr>
                    <td className="Payment_checkout__left_content_tbody_total_text">
                      Total
                    </td>

                    <td className="Payment_checkout__left_content_tbody_total_price">
                      {""}
                    </td>

                    <td className="Payment_checkout__left_content_tbody_total_price">
                      {t("Payment.Currency")}  {sumamount}
                        
                    </td>
                  </tr>
                </tfoot>
              </table>
            </Grid>
          </Grid>

          <Grid width="30%"  >
            <Grid>
              <div className="Payment_checkout__right_content_card">
                <div className="Payment_checkout__right_content_card_total">
                  <span>TOTAL</span>
                </div>

                <div className="Payment_checkout__right_content_card_total_price">
                  <span>{t("Payment.Currency")} </span>
                  <span>
                    {sumamount}
                    {/* {sumamount} */}
                  </span>
                </div>

                <Button main _onClick={gotoCheckout} backgroundcolor="#ba4148" height="60px" width="80%" margin= "20px auto">구입하기</Button>
              
              </div>
            </Grid>
          </Grid>
        </Grid>
        </Grid>
      )}
    </>
  );
}

export default ProductBasket;
