import React, { useEffect, useState } from "react";
import Color, { Palette } from "color-thief-react";
import { useMediaQuery } from "react-responsive";
import { Grid, Button, Text } from "../../elements/index";
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Rating from "@material-ui/lab/Rating";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { dbService } from "../../shared/firebase";
import { useDispatch, useSelector } from "react-redux";
import { AlertModalContainer} from "../../components/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye as farEye } from "@fortawesome/free-regular-svg-icons";


const StyledRating = withStyles({
    iconFilled: {
      color: "#ff6d75",
    },
    iconHover: {
      color: "#ff3d47",
    },
  })(Rating);

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1367 });
  return isDesktop ? children : null;
};

const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 916, maxWidth: 1366 });
  return isTablet ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 915 });
  return isMobile ? children : null;
};

const ChartTable = ({tableData , tableTitle}) => {
  const [slider2, setslider] = useState();
  const Loading = () => <span>Loading...</span>;
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const user = useSelector((state) => state.user);

  const [alert, setAlert] = useState({
    type: 'error',
    text: 'This is a alert message',
    show: false
  })

  const openOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const Language_song_Edit = (song) => {
    if(song.edit === "원곡"){
      return `${t('Chart.Original_song')}`;
    } else if (song.edit === "편곡"){
      return  `${t('Chart.Arrangement_song')}`;
    } else if (song.edit === "작곡"){
      return  `${t('Chart.Composition_song')}`;
    }
  };

  const language_Currency_Amount = (song) => {
    if (i18n.language === 'kor') {
      return song.data.KOR;
    } else if (i18n.language === 'en') {
      return song.data.USD;
    } else if (i18n.language === 'ja') {
      return song.data.JPY;
    } else {
      return song.data.USD;
    }
  };

  const Add_to_Basket = (id, song) => {
    if(user.is_login === false || user.user === null){
      // setLoginModalopen(true)
    }

    console.log(id)
    var doc =  dbService.collection('user_Public').doc(user.uid).collection('product_basket').doc(id);
    doc.get()
    .then((docSnapshot) => {
      
      if (docSnapshot.exists) {
          setAlert({
          type: 'warning',
          text: '이미 있는 곡입니다.',
          show: true
        })
        return;
      } else {
        doc.set(song)
        setAlert({
          type: 'success',
          text: '장바구니에 추가되었습니다.',
          show: true
        })
      }
  });
    setAnchorEl(false)
  };

  const ratingvalue = (value) => {
    var newvalue = value.toFixed(2)
    return newvalue;
  };
  const closeAlertModal = () => {
    setAlert({
      type: '',
      text: '',
      show: false
    })
   };

   const gotoArtist_Product = async (id) => {

    var db = dbService.collection("Song_All").doc(id);
    return dbService
      .runTransaction((transaction) => {
        // This code may get re-run multiple times if there are conflicts.
        return transaction.get(db).then((sfDoc) => {
          if (!sfDoc.exists) {
            throw "Document does not exist!";
          }
          var newLookUP = sfDoc.data().lookup + 1;
          transaction.update(db, { lookup: newLookUP });
        });
      })
      .then((    
      ) => {
        history.push(`/Artist_product/${id}`)
      })
      .catch((error) => {});
  };



  return (
    <>
  <Desktop>

              <Text h1 textalign="left" padding="10px" marginT="20px" width="80%" margin="auto">{tableTitle}</Text>
              <table className="Hardisest_table">

                <colgroup>
                    <col width="6%" />
                    <col width="38%" /> 
                    <col width="15%" />
                    <col width="11%" />
                    <col width="7%" />
                    <col width="7%" />
                    <col width="7%" />
                    <col width="7%" />
                    <col width="12%" />
                </colgroup>

                <thead className="Hardisest_table_thead">
                      <tr>
                        <th className="Hardisest_tableHead_index">{t('Chart.Ranking')}</th>
                        <th className="Hardisest_tableHead_songname">
                        {t('Chart.Song_name')} / {t('Chart.Artist_name')}
                        </th>
                        <th className="Hardisest_tableHead_genres_edit">
                        {t('Chart.Genre')} / {t('Chart.Composition')}
                        </th>
                        <th className="Hardisest_tableHead_price">{t('Chart.Price')}</th>
                        <th className="Hardisest_tableHead_view">View</th>

                        <th className="Hardisest_tableHead_download">{t('Chart.Download')}</th>
                        <th className="Hardisest_tableHead_favorite">{t('Chart.Like')}</th>

                        <th className="Hardisest_tableHead_damgi">{t('Chart.Add')}</th>
                        <th className="Hardisest_tableHead_rating">{t('Chart.Rating')}</th>
                      </tr>
                    </thead>
                    {tableData?.map((song, index) =>
                      index < 100 ? ( 
                        <tbody className="Hardisest_tbody_table"
                         onClick={() =>
                          gotoArtist_Product(song.id)
                              }
                   
                        >
                          <tr className="Hardisest_tbody_tr">
                            <td className="Hardisest_tbody_index">{index + 1}</td>

                            <td
                              className="Hardisest_tbody_song_artist"
                            >
                              <img
                                src={song.data.Elbum_url}
                                alt=""
                                className="Hardisest_tbody_image"
                              />
                          
                            <span className="Hardisest_song_songName">
                                {song.data.songName}
                                <span className="Hardisest_song_artistname">
                                  {song.data.artistName}
                                </span>
                              </span>
                            </td>

                            <td className="Hardisest_tbody_genres_edit">
                              {song.data.genres} / {Language_song_Edit(song)}
                            </td>

                            <td className="Hardisest_tbody_price">
                              {t('Payment.Currency')} {language_Currency_Amount(song)}
                            </td>

                            <td className="Hardisest_tbody_view">
                              {song.data.lookup}
                              <FontAwesomeIcon icon={farEye} className="Hardisest_farEye__Icon" />
                            </td>

                            <td className="Hardisest_tbody_upgrade">
                              {song.data.update}
                              <ArrowDownwardIcon className="ArrowDownward__Icon" />
                            </td>

                            <td className="Hardisest_tbody_favorite">
                              {song.data.faviorite}
                              <FavoriteBorderIcon className="ArrowDownward__Icon" />
                            </td>

                            <td className="Hardisest_tbody_damgi">
                              <ul class="Hardisest_basket_menu">
                                <li>
      
                                  <IconButton
                                            aria-haspopup="true"
                                            onClick={openOptions}
                                            className="Hardisest_basket_menu_icon"
                                          >
                                            <AddIcon className="Hardisest_tbody_add_Icon" />
                                </IconButton>
                                  <div class="Hardisest_basket_menu_depth">
                                    <ul>
                                      <li><span   onClick={() => Add_to_Basket(song.id , song)}>{t('Chart.Shopping_Basket')}</span></li>
                                  
                                    </ul>
                                  </div>
                                </li>
                              </ul>       
                            </td>

                            <td className="Hardisest_tbody_rating_star">
                              <span className="Hardisest_tbody_rating_star_icon">
                                <StyledRating
                                  precision={0.25}
                                  value={song.data.rating}
                                  readOnly
                                />
                              </span>
                              <span className="Hardisest_tbody_rating">
                              {ratingvalue(song.data.rating)} / 5
                            </span>
                            </td>  
                          </tr>
                        </tbody>
                      ) : null
                    )}

                 </table>
                 <AlertModalContainer show={alert.show} close={closeAlertModal} type={alert.type}  text={alert.text} />
            </Desktop>

         <Tablet>
             <Text h1 textalign="left" marginT="20px" width="90%" margin="auto">{tableTitle}</Text>

            <table className="Hardisest_table">

            <colgroup>
                   <col width="6%" />
                    <col width="38%" /> 
                    <col width="12%" />
                    <col width="10%" />
                    <col width="8%" />
                    <col width="8%" />
                    <col width="8%" />
                    <col width="8%" />
                    <col width="11%" />
            </colgroup>

            <thead className="Hardisest_table_thead">
            <tr>
                <th className="Hardisest_tableHead_index">{t('Chart.Ranking')}</th>
                <th className="Hardisest_tableHead_songname">
                {t('Chart.Song_name')} / {t('Chart.Artist_name')}
                </th>
                <th className="Hardisest_tableHead_genres_edit">
                {t('Chart.Genre')} / {t('Chart.Composition')}
                </th>
                <th className="Hardisest_tableHead_price">{t('Chart.Price')}</th>
                <th className="Hardisest_tableHead_view">View</th>

                <th className="Hardisest_tableHead_download">{t('Chart.Download')}</th>
                <th className="Hardisest_tableHead_favorite">{t('Chart.Like')}</th>

                <th className="Hardisest_tableHead_damgi">{t('Chart.Add')}</th>
                <th className="Hardisest_tableHead_rating">{t('Chart.Rating')}</th>
            </tr>
            </thead>
            {tableData.map((song, index) =>
            index < 100 ? ( 
                <tbody className="Hardisest_tbody_table"
                 onClick={() =>
                          gotoArtist_Product(song.id)
                              }
                >
                <tr className="Hardisest_tbody_tr">
                    <td className="Hardisest_tbody_index">{index + 1}</td>

                    <td
                    className="Hardisest_tbody_song_artist"
                    >
                    <img
                        src={song.data.Elbum_url}
                        alt=""
                        className="Hardisest_tbody_image"
                    />
                
                    <span className="Hardisest_song_songName">
                        {song.data.songName}
                        <span className="Hardisest_song_artistname">
                        - {song.data.artistName}
                        </span>
                    </span>
                    </td>

                    <td className="Hardisest_tbody_genres_edit">
                    {song.data.genres} / {Language_song_Edit(song)}
                    </td>

                    <td className="Hardisest_tbody_price">
                    {t('Payment.Currency')} {language_Currency_Amount(song)}
                    </td>

                    <td className="Hardisest_tbody_view">
                              {song.data.lookup}
                              <FontAwesomeIcon icon={farEye} className="Hardisest_farEye__Icon" />
                            </td>

                    <td className="Hardisest_tbody_upgrade">
                    {song.data.update}
                    <ArrowDownwardIcon className="ArrowDownward__Icon" />
                    </td>

                    <td className="Hardisest_tbody_favorite">
                    {song.data.update}
                    <FavoriteBorderIcon className="ArrowDownward__Icon" />
                    </td>

                    <td className="Hardisest_tbody_damgi">
                    <ul class="Hardisest_basket_menu">
                        <li>

                        <IconButton
                                    aria-haspopup="true"
                                    onClick={openOptions}
                                    className="Hardisest_basket_menu_icon"
                                >
                                    <AddIcon className="Hardisest_tbody_add_Icon" />
                        </IconButton>
                        <div class="Hardisest_basket_menu_depth">
                            <ul>
                            <li><span   onClick={() => Add_to_Basket(song.id , song)}>{t('Chart.Shopping_Basket')}</span></li>
                        
                            </ul>
                        </div>
                        </li>
                    </ul>       
                    </td>
                
                    <td className="Hardisest_tbody_rating_star">
                    <span className="Hardisest_tbody_rating_star_icon">
                        <StyledRating
                        precision={0.25}
                        value={song.data.rating}
                        readOnly
                        />
                    </span>
                    <span className="Hardisest_tbody_rating">
                    {ratingvalue(song.data.rating)} / 5
                    </span>
                    </td>  
                </tr>
                </tbody>
            ) : null
            )}

            </table>
        <AlertModalContainer show={alert.show} close={closeAlertModal} type={alert.type}  text={alert.text} />
    </Tablet>

    <Mobile>
             <Text h1 textalign="left" marginT="20px" width="90%" margin="auto">{tableTitle}</Text>

             <table className="Hardisest_table">

                    <colgroup>   
                      <col width="9%" />
                      <col width="35%" /> 
                      <col width="20%" />
                      <col width="12%" />
                      <col width="12%" />
                      <col width="12%" />
                    </colgroup>

                      <thead className="Hardisest_table_thead">
                      <tr>
                        <th className="Hardisest_tableHead_index">{t('Mobile.Ranking')}</th>
                        <th className="Hardisest_tableHead_songname">
                        {t('Mobile.Song_name')} / {t('Mobile.Artist_name')}
                        </th>
                        <th className="Hardisest_tableHead_genres_edit">
                        {t('Chart.Genre')} / {t('Chart.Composition')}
                        </th>
                        <th className="Hardisest_tableHead_price">{t('Chart.Price')}</th>
                        <th className="Hardisest_tableHead_download">{t('Chart.Download')}</th>

                        <th className="Hardisest_tableHead_damgi">{t('Chart.Add')}</th>
                        {/* <th className="Hardisest_tableHead_rating">{t('Chart.Rating')}</th> */}
                      </tr>
                    </thead>
                    {tableData.map((song, index) =>
                      index < 100 ? ( 
                        <tbody className="Hardisest_tbody_table"
                           onClick={() =>
                          gotoArtist_Product(song.id)
                              }
                        >
                          <tr className="Hardisest_tbody_tr">
                            <td className="Hardisest_tbody_index">{index + 1}</td>

                            <td
                              className="Hardisest_tbody_song_artist"
                            >
                              <img
                                src={song.data.Elbum_url}
                                alt=""
                                className="Hardisest_tbody_image"
                              />
                          
                            <span className="Hardisest_song_songName">
                                {song.data.songName}
                                <span className="Hardisest_song_artistname">
                                  - {song.data.artistName}
                                </span>
                              </span>
                            </td>

                            <td className="Hardisest_tbody_genres_edit">
                              {song.data.genres} / {Language_song_Edit(song)}
                            </td>

                            <td className="Hardisest_tbody_price">
                              {t('Payment.Currency')} {language_Currency_Amount(song)}
                            </td>

                            <td className="Hardisest_tbody_upgrade">
                              {song.data.update}
                              <ArrowDownwardIcon className="ArrowDownward__Icon" />
                            </td>


                            <td className="Hardisest_tbody_damgi">
                              <ul class="Hardisest_basket_menu">
                                <li>
      
                                  <IconButton
                                            aria-haspopup="true"
                                            onClick={openOptions}
                                            className="Hardisest_basket_menu_icon"
                                          >
                                            <AddIcon className="Hardisest_tbody_add_Icon" />
                                </IconButton>
                                  <div class="Hardisest_basket_menu_depth">
                                    <ul>
                                      <li><span   onClick={() => Add_to_Basket(song.id , song)}>{t('Chart.Shopping_Basket')}</span></li>
                                  
                                    </ul>
                                  </div>
                                </li>
                              </ul>       
                            </td>
                          </tr>
                        </tbody>
                      ) : null
                    )}
              
                </table>
        <AlertModalContainer show={alert.show} close={closeAlertModal} type={alert.type}  text={alert.text} />
    </Mobile>

    </>
  );
};

export default ChartTable;
