import React, { useState, useEffect, useRef } from "react";
import CloseIcon from "@material-ui/icons/Close";
import "./ModalContainer.css";
import { useHistory } from "react-router-dom";

import styled from "styled-components";
import { dbService, authService , facebookprovider , googleprovider , setUser } from "../../shared/firebase";
import facebook from "../../images/facebook.png";
import kakao from "../../images/kakao.png";
import google from "../../images/google.png";
import naver from "../../images/naver.png";
import { useDispatch , useSelector  } from "react-redux";
import { Grid, Input2, Text, Button } from "../../elements/index";
import { Color } from "../../shared/DesignSys";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash as farEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { actionCreators as userActions } from "../../redux/modules/user";
import { faEye as farEye } from "@fortawesome/free-regular-svg-icons";
// import { initializeApp } from 'firebase-admin/app';
import firebase from "firebase";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import "cors";


function ModalContainer({ show, close }) {
  const history = useHistory();
  const [isAddSong, setIsAddSong] = useState(true);
  const [isLogin, setIsLogin] = useState(false);
  const dispatch = useDispatch();
  const [closed, setclosed] = useState(show);
  const [open, setOpen] = useState(true);
  const [saveId, setId] = useState(false); // 아이디 저장
  const [agreedisabled, setagreedisabled] = useState(true); // 버튼

  const [signup_check_1, setsignup_check_1] = useState(false); // 아이디 저장
  const [signup_check_2, setsignup_check_2] = useState(false); // 아이디 저장
  /////////////////////////////////////
  const [email, setEmail] = useState("");
  const [pw, setPw] = useState("");
  const [pwCheck, setPwCheck] = useState("");
  const [displayName, setdisplayName] = useState("");


  const [Message, setMessage] = useState({
    _email_wrng: "",
    _pw_wrng: "",
    _newpw_wrng:"",
    _displaywrng: "",
    open: false,
  });
 
  const [warning, setWarning] = useState({ _email_wrng: false, _pw_wrng: false, _newpw_wrng: false, _displaywrng: false });
  const { _email_wrng, _pw_wrng, _newpw_wrng, _displaywrng } = warning;


  const [loginMessage, setloginMessage] = useState({
    _login_email_wrng: "",
    _login_pw_wrng: "",
  });
  const [loginwarning, setloginWarning] = useState({ _login_email_wrng: false, _login_pw_wrng: false, });
  const { _login_email_wrng, _login_pw_wrng, } = loginwarning;


  // 비밀번호 인풋 내용 state
  const [show3, setShow3] = useState(false); // show3 = 비밀번호 인풋에 작성한 비밀번호
  const [show4, setShow4] = useState(false); // show4 = 비밀번호 확인 인풋에 작성한 비밀번호
  const {Kakao} = window;

  function unlinkApp() {
    Kakao.API.request({
      url: '/v1/user/unlink',
      success: function(res) {
        alert('success: ' + JSON.stringify(res))
      },
      fail: function(err) {
        alert('fail: ' + JSON.stringify(err))
      },
    })
  }

  


  const kakaoLogin = () => {
    window.Kakao.Auth.login({
      success: function (authObj) {
        getMyKakaoInfo(authObj);
      },
      fail: function (err) {
        console.log(JSON.stringify(err));
      },
    });
  };


  const getMyKakaoInfo = (authObj) => {
    const result = JSON.stringify(authObj);
    const auth_code = JSON.parse(result);
    //카카오 토큰 셋팅
    window.Kakao.Auth.setAccessToken(auth_code.access_token);
    window.Kakao.API.request({
      url: "/v2/user/me",
      success: function (response) {
        const body = response;
        const userId = `kakao_${body.id}`;
        let nickname = null;
        let profileImage = null;
        if (body.properties) {
          nickname = body.properties.nickname;
          profileImage = body.properties.profile_image;
        }
        let accountEmail = null;
        if (body.kakao_account) {
          accountEmail = body.kakao_account.email;
          console.log("Email", accountEmail);
        }
        updateOrCreateUser(userId, accountEmail, nickname, profileImage);
      },
      fail: function (error) {
        alert(error);
      },
    });
  };

  const updateOrCreateUser = (userId, email, displayName, photoURL) => {
    console.log("updating or creating a firebase user");
    // adminInstance
    //   .auth()
    //   .createCustomToken(userId, { provider: "KAKAO" })
    //   .then((token) => {
    //     firebaseInstance
    //       .auth()
    //       .signInWithCustomToken(token)
    //       .then((result) => {
    //         var user = firebaseInstance.auth().currentUser;
    //         user.updateEmail(email);
    //         user.updateProfile({
    //           displayName: displayName,
    //           photoURL: photoURL,
    //         });
    //       })
    //       .catch((err) => {
    //         console.log("이미 가입된 아이임");
    //       });
    //   })
    //   .catch((err) => {
    //     console.log("이미 가입된 아이임");
    //   });
  };

 
  function kakaoLogout() {
    if (!Kakao.Auth.getAccessToken()) {
      alert('Not logged in.')
      return
    }
    Kakao.Auth.logout(function() {
      alert('logout ok\naccess token -> ' + Kakao.Auth.getAccessToken())
    })
  }



  useEffect(() => {
    var email = localStorage.getItem("saved_id");
    console.log(email)
    if(email ===  'null'){
      setEmail('');
    } else{
      setEmail(email);
    }
  }, []);


  useEffect(() => {
    if (signup_check_1 === true && signup_check_2 === true){
      setagreedisabled(false)
    } else{
      setagreedisabled(true)
    }
    

  }, [signup_check_1, signup_check_2]);


  useEffect(() => {
    if(close){
      setMessage({
        _email_wrng: "",
        _pw_wrng: "",
        _newpw_wrng:"",
        _displaywrng: "",
      });
      setloginMessage({
        _login_email_wrng: "",
        _login_pw_wrng: "",
      });

      setsignup_check_1(false)
      setsignup_check_2(false)
      setWarning({ _email_wrng: false, _pw_wrng: false, _newpw_wrng: false, _displaywrng: false });
      setloginWarning({  _login_email_wrng: false, _login_pw_wrng: false, });

      setEmail("")
      setPw("")
      setPwCheck("")
      setdisplayName("")
    }

    // if(agreedisabled === true){
    //   setMessage({
    //     _email_wrng: "",
    //     _pw_wrng: "",
    //     _newpw_wrng:"",
    //     _displaywrng: "",
    //   });
    // }   
  }, [close]);


  
    const SocialSignUp = (provider) => {
      return authService
      .signInWithPopup(provider)
  
      .then((res) => { 
        return res.user;
      })
      .catch((err) => {
        if(err){
          history.push("/")
        }
      })
      }

    const handlesignup = async(provider) => {
      if(agreedisabled === true &&  isAddSong === false){
        return;
      }      
      const res = await SocialSignUp(provider)
      if(!res){
        return;
      }

    
      const data = {
        email: res.email,
        role: "Public",
        uid: res.uid,
        displayName : res.displayName,
        photoURL : `https://ui-avatars.com/api/?name=${res.displayName}`,
        authProvider : `${provider.providerId === "google.com" ? ("google"): ("facebook")}`,
        user_artist_subscribe : 0,
        Artist_subscribe_uid:[],
        Artist_rating:[],
        Favorite_Song:[],
      };

        dispatch(userActions.userdb(data));
        dbService
        .collection("user_Public")
        .doc(res.uid)
        .get()
        .then((userFromDB) => {
          if (userFromDB.exists) {
            return;
        } else {
          setUser(res.uid , data); 
         
        }
        })
         setOpen(close);
    }



  const Local_login_SignUP = async (event) => {
    event.preventDefault();
    const data = {
      email: email,
      role: "Public",
      authProvider: "local",
      uid: "",
      user_artist_subscribe : 0,
      Artist_subscribe_uid:[],
      Artist_rating:[],
      Favorite_Song:[],
    };

    try {
      if (isAddSong) {
        const userAuth = await authService
          .signInWithEmailAndPassword(email, pw)
          .catch((error) => {
            switch (error.code) {
              case "auth/wrong-password":
                setloginWarning({ _login_email_wrng: false, _login_pw_wrng: true, });
                setloginMessage({
                  _login_pw_wrng: "비밀번호가 잘못되었습니다",
                });
                break;
              default:
                setloginWarning({ _login_email_wrng: true, _login_pw_wrng: false, });
                setloginMessage({
                  _login_email_wrng: "정보를 다시 넣어주세요",
                });
             
                break;
            }
          });
        
          if (saveId === true){
            localStorage.setItem("saved_id", email);
          } else{
            localStorage.removeItem("saved_id", email);
            localStorage.setItem("saved_id", "null");
            setEmail('')
          }
        if (userAuth) {
          setOpen(close);
        } 

      } else {     


        if(agreedisabled === true){
          return;
        }       
        else if (email === "") {
          setWarning({ _email_wrng: true, _pw_wrng: false ,_newpw_wrng:false , _displaywrng : false });
          setMessage({_email_wrng: "이메일을 입력해주세요."})
            return;
          }
        else if (pw === "") {
            setWarning({ _email_wrng: false, _pw_wrng: true ,_newpw_wrng:false , _displaywrng :false });
            setMessage({ _pw_wrng: "비밀번호를 입력해주세요."})
              return;
            }
        else if (pwCheck === "") {
              setWarning({ _email_wrng: false, _pw_wrng: false ,_newpw_wrng:true , _displaywrng :false });
              setMessage({ _newpw_wrng: "비밀번호확인을 입력해주세요."})
                return;
              }
        else if (displayName === "") {
          setWarning({ _email_wrng: false, _pw_wrng: false ,_newpw_wrng:false , _displaywrng :true });
          setMessage({_displaywrng: "닉네임을 입력해주세요."})
            return;
          }
        else if (pw !== pwCheck) {
            setWarning({ _email_wrng: false, _pw_wrng: false ,_newpw_wrng:true ,_displaywrng :false });
            setMessage({_newpw_wrng: "비밀번호 확인을 다시 확인해주세요."})
            return;
          }

        data.displayName = displayName;
        data.photoURL = `https://ui-avatars.com/api/?name=${displayName}`;

      
      

        const userAuth = await authService
          .createUserWithEmailAndPassword(email, pw)
          .catch((error) => {
            switch (error.code) {
              case "auth/email-already-in-use":
                setWarning({ _email_wrng: true, _pw_wrng: false });
                setMessage({_email_wrng: "이미 등록된 이메일입니다."})
                console.log(`Email address  already in use.`);
                break;
              case "auth/invalid-email":
                //유효하지 않은
                setWarning({ _email_wrng: true, _pw_wrng: false });
                setMessage({_email_wrng: "유효하지 않은 이메일입니다."})
                console.log(`Email address  is invalid.`);
                break;
              case "auth/weak-password":
                setWarning({ _email_wrng: false, _pw_wrng: true });
                setMessage({_pw_wrng: "새로운 비밀번호를 입력해주세요."})
                console.log(
                  "Password is not strong enough. Add additional characters including special characters and numbers."
                );
                break;
              default:
                setWarning({ _id_wrng: true, _pw_wrng: true });

                console.log(error.message);
                break;
            }
          });

        userAuth.user.updateProfile({
          displayName: data.displayName,
          photoURL: data.photoURL,
        });

        data.uid = userAuth.user.uid;
        await setUser(data.uid , data)
        if (userAuth) {
          setOpen(close);
        }
        dispatch(userActions.userdb(data));
   
      }
    } catch (error) {
    }
  };


  // 비밀번호 인풋 내용 보기
  const changeEye = () => { setShow3(show3 ? false : true);  };
  // 비밀번호 확인 인풋 내용 보기
  const changeEye2 = () => {setShow4(show4 ? false : true);};
  const gotoSignup = () => {setIsAddSong(false);};
  const gotoLogin = () => {setIsAddSong(true);}
  

   

  return (
    <>
      {show ? (
        <div className="ModalContainer_wrapper">
          <div className="ModalContainer_background_white">
            <div className="ModalContainer_content">

               {/* 맨윗줄  */}
              <div className="ModalContainer_content_Signup_content">
                <div className="ModalContainer_content_Signup_text">
                  <Text
                    h2
                    color={isAddSong ? "#ba4148" : "#999999"}
                    onClick={gotoLogin}
                  >
                    로그인
                  </Text>
                  <Text
                    h2
                    color={isAddSong ? "#999999" : "#ba4148"}
                    marginL="18px"
                    onClick={gotoSignup}
                  >
                    회원가입
                  </Text>
                </div>
                <CloseIcon onClick={close}  className="ModalContainer_content_Signup_close" />
              </div>


              <div className="ModalContainer_content_input_content">
       

                
                  {isAddSong && (
                    <>
                    <SignupBox>
                    <SignupInput
                      name="user_email"
                      type="email"
                      placeholder="EMAIL"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      value={email}
                      // onFocus={() => {
                      //   setMessageEmail("");
                      // }}
                    />
                  </SignupBox>

                  {_login_email_wrng && (
                        <Text subBody color={Color.Primary} marginL="10px">
                       {loginMessage._login_email_wrng}
                      </Text>
                      )}  

                  <SignupBox>
                    <PwdInput
                      type={show3 ? "text" : "password"}
                      placeholder="PASSWORD"
                      onChange={(e) => {
                        setPw(e.target.value);
                      }}
                      name="password"
                      // onFocus={doubleCheckPw}
                      // onBlur={doubleCheckPw}
                    />
                    <IconEyeSpan onClick={changeEye}>{show3 ? <FontAwesomeIcon icon={farEyeSlash} /> : <FontAwesomeIcon icon={farEye} />}</IconEyeSpan>
                  </SignupBox>

                  {_login_pw_wrng && (
                        <Text subBody color={Color.Primary} marginL="10px">
                       {loginMessage._login_pw_wrng}
                      </Text>
                      )}  


                  </>
                  )}
                 
           
                  {!isAddSong && (
                    <>

                    <SignupBox>
                    <SignupInput
                      name="user_email"
                      type="email"
                      placeholder="EMAIL"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      value={email}
                      // onFocus={() => {
                      //   setMessageEmail("");
                      // }}
                    />
                  </SignupBox>
                      {_email_wrng && (
                        <Text subBody color={Color.Primary} marginL="10px">
                       {Message._email_wrng}
                      </Text>
                      )}    

                  <SignupBox>
                    <PwdInput
                      type={show3 ? "text" : "password"}
                      placeholder="PASSWORD"
                      onChange={(e) => {
                        setPw(e.target.value);
                      }}
                      name="password"
                      // onFocus={doubleCheckPw}
                      // onBlur={doubleCheckPw}
                    />
                    <IconEyeSpan onClick={changeEye}>{show3 ? <FontAwesomeIcon icon={farEyeSlash} /> : <FontAwesomeIcon icon={farEye} />}</IconEyeSpan>
                  </SignupBox>

                  {_pw_wrng && (
                        <Text subBody color={Color.Primary} marginL="10px">
                       {Message._pw_wrng}
                      </Text>
                      )}    

                      <SignupBox>
                        <PwdInput
                          type={show4 ? "text" : "password"}
                          placeholder="PASSWORD CHECK"
                          onChange={(e) => {
                            setPwCheck(e.target.value);
                          }}
                          name="password_check"
                          // onFocus={doubleCheckPw}
                          // onBlur={doubleCheckPw}
                        />
                        <IconEyeSpan onClick={changeEye2}>{show4 ? <FontAwesomeIcon icon={farEyeSlash} /> : <FontAwesomeIcon icon={farEye} />}</IconEyeSpan>
                      </SignupBox>

                      {_newpw_wrng && (
                        <Text subBody color={Color.Primary} marginL="10px">
                       {Message._newpw_wrng}
                      </Text>
                      )}  

                      <SignupBox>
                        <SignupInput
                          type="text"
                          placeholder="NICKNAME"
                          onChange={(e) => {
                            setdisplayName(e.target.value);
                          }}
                          // onBlur={checkNickname}
                          name="displayName"
                        />
                      </SignupBox>
                      {_displaywrng && (
                        <Text subBody color={Color.Primary} marginL="10px">
                       {Message._displaywrng}
                      </Text>
                      )} 

                     
                    </>
                  )}

                  {isAddSong ? (
                    <>
                      <div className="ModalContainer_content_input_checkbox">
                        <Input2
                          check
                          checked={saveId}
                          _onClick={() => {
                            if (saveId) {
                              setId(false);
                            } else {
                              setId(true);
                            }
                          }}
                          desc="아이디 저장"
                        />
                      </div>

                      <Grid column dispay ="flex" marginT="8px">
                       
                          <Button
                            noflex
                            main
                            width="400px"
                            height="52px"
                          
                            marginT="6px"
                            _onClick={Local_login_SignUP}
                          >
                            로그인
                          </Button>
                        {/* <Grid row dispay ="flex">
                          <Button
                            noflex
                            social
                            width="197px"
                            height="32px"
                            marginT="10px"
                            _onClick={unlinkApp}
                            
                          >
                            <img className="ModalContainer_SocialBox_Logo" src={kakao} />
                            카카오
                          </Button>

                          <Button
                            noflex
                            social
                            width="197px"
                            height="32px"
                            marginT="10px"
                            marginL="6px"
                          >
                            <img className="ModalContainer_SocialBox_Logo" src={naver} />
                            네이버
                          </Button>
                        </Grid> */}

                        <Grid column dispay ="flex">
                          <Button
                            noflex
                            social
                            width="400px"
                            height="40px"
                            marginT="6px"
                            _onClick={() => handlesignup(googleprovider)}
                          >
                            <img className="ModalContainer_SocialBox_Logo" src={google} />
                            구글
                          </Button>

                          <Button
                            noflex
                            social
                            width="400px"
                            height="40px"
                            marginT="6px"
                            // marginL="6px"
                            _onClick={() => handlesignup(facebookprovider)}
                          >
                            <img className="ModalContainer_SocialBox_Logo" src={facebook} />
                            페이스북
                          </Button>
                        </Grid>

                        {/* <div className="ModalContainer_autoLogin">아이디/비밀번호 찾기</div> */}
                      </Grid>
                    </>
                  ) : (
                    <>
                      <div className="ModalContainer_info_checkbox_signup">
                        <Grid row display="flex" marginT="14px" justify="space-between">
                          <Text
                            flexdirection="row"
                            width="250px"
                            marginL="14px"
                            color="rgb(66, 66, 66)"
                            size="15px"
                            bold
                            onClick={() => history.push("/Support/Policy")}
                          >
                            Pianissimo 이용약관
                            {/* <span class="ModalContainer_info_checkbox_signup_emphasis">
                              이용약관
                            </span> */}
                          </Text>

                          <Input2
                            check
                            checked={signup_check_1}
                            float="right"
                            marginR="6px"
                            _onClick={() => {
                              if (signup_check_1) {
                                setsignup_check_1(false);
                              } else {
                                setsignup_check_1(true);
                              }
                            }}
                            desc="동의(필수)"
                          />
                        </Grid>

                        <Grid row display="flex" marginT="14px" justify="space-between">
                          <Text
                            flexdirection="row"
                            width="250px"
                            marginL="14px"
                            color="rgb(66, 66, 66)"
                            size="15px"
                            bold
                            onClick={() => history.push("/Support/Privacy")}

                          >
                            User Agreement
                            {/* <span class="ModalContainer_info_checkbox_signup_emphasis">
                              Agreement
                            </span> */}
                          </Text>

                          <Input2
                            check
                            checked={signup_check_2}
                            float="right"
                            marginR="6px"
                            _onClick={() => {
                              if (signup_check_2) {
                                setsignup_check_2(false);
                              } else {
                                setsignup_check_2(true);
                              }
                            }}
                            desc="동의(필수)"
                          />
                        </Grid>

                        
                      </div>
                              {/*회원가입 소셜박스  */}
                      <Grid row display="flex" marginT="8px">
                        <Grid column display="flex" width="115px">
                          {/* <Button
                            noflex
                            social
                            width="120px"
                            height="32px"
                            marginL="6px"
                            marginT="10px"
                            _onClick={() => kakaoLogin()}

                          >
                            <img className="ModalContainer_SocialBox_Logo" src={kakao} />
                            카카오
                          </Button> */}

                          <Button
                            noflex
                            social
                            width="240px"
                            height="36px"
                            marginL="6px"
                            marginT="6px"
                            disabled ={agreedisabled}
                            _onClick={() => handlesignup(googleprovider)}
                           
                          >
                            <img className="ModalContainer_SocialBox_Logo" src={google} />
                            구글
                          </Button>
                          <Button
                            noflex
                            social
                            width="240px"
                            height="36px"
                            marginL="6px"
                            marginT="6px"
                            disabled ={agreedisabled}
                            _onClick={() => handlesignup(facebookprovider)}
                            
                          >
                            <img className="ModalContainer_SocialBox_Logo" src={facebook} />
                            페이스북
                          </Button>
                        </Grid>

                        {/* <Grid column display="flex" width="115px" marginL="10px">
                          <Button
                            noflex
                            social
                            width="120px"
                            height="32px"
                            marginL="3px"
                            marginT="10px"
                           
                           
                         
                          >
                            <img className="ModalContainer_SocialBox_Logo" src={naver} />
                            네이버
                          </Button>

                          
                        </Grid> */}

                        <Button
                            noflex
                            main
                            width="150px"
                            height="80px"
                            marginL="140px"
                            marginT="6px"
                            disabled ={agreedisabled}
                            _onClick={Local_login_SignUP}
                          >
                            회원가입
                          </Button>
                      
                     {/* {_displaywrng === true ? (
                      <Button
                            noflex
                            main
                            width="150px"
                            height="80px"
                            marginL="140px"
                            marginT="6px"
                            disabled ={agreedisabled}
                          >
                            회원가입
                          </Button>

                      ):(
                        <Button
                            noflex
                            main
                            width="150px"
                            height="80px"
                            marginL="140px"
                            marginT="6px"
                            disabled ={agreedisabled}
                            _onClick={Local_login_SignUP}
                          >
                            회원가입
                          </Button>
                      )} 
                       */}
                      
                      

                      </Grid>
                    </>
                  )}
              
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

const SignupBox = styled.div`
  border: 1px solid ${Color.Light_3};
 ${'' /* border-radius: 12px;  */}
  border-top: none;
  border-left: none;
  border-right: none;
  width: 100%;
  height: 45px;
  max-height: 45px;
  margin: 0.2rem 0;
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
  align-items: center;
  transition: border 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    box-shadow 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &:focus-within {
    border: 1px solid ${Color.Primary};
    box-shadow: 0 0 0 3px ${Color.Primary}33;
  }
`;
const SignupInput = styled.input`
  height: 100%;
  border: none;
  background-color: transparent;
  box-sizing: border-box;
  letter-spacing: 0.5px;
  overflow: hidden;
  color: rgb(71, 71, 71);
  width: 90%;
  outline: none;
  padding-left: 5px;
  padding-right: 10px;
  padding-bottom: 5px;
  font-size: 21px;
  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
    font-size: 12px;
    user-select: none;
  }
`;

const PwdInput = styled.input`
  height: 100%;
  border: none;
  background-color: transparent;
  box-sizing: border-box;
  letter-spacing: 0.5px;
  overflow: hidden;
  color: rgb(71, 71, 71);
  width: 80%;
  outline: none;
  padding-left: 5px;
  padding-right: 10px;
  padding-bottom: 5px;
  font-size: 21px;
  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
    font-size: 12px;
    user-select: none;
  }
`;


const IconEyeSpan = styled.span`
  width: 26px;
  float: right;
  text-align: center;
  padding: 3px 0 0;
  font-size: 14px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
`;

export default ModalContainer;
