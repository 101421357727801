import React, { useEffect, useState, useMemo,} from 'react';
import { dbService } from '../../shared/firebase';
import { useDispatch, useSelector } from 'react-redux';
import '../MyHome/MyHome.css';
import { useHistory,  Link } from 'react-router-dom';
import { Grid, Button,  Text } from "../../elements/index";
import styled from "styled-components";
import { Color } from "../../shared/DesignSys";
import Select from "react-select";
// import AvatarUploader from "../../../src";
import Avatar from 'react-avatar-edit'
import { CameraOutlined } from '@ant-design/icons';
import { AvatarModalContainer , PublicSideBar , ArtistSideBar } from "../../components/index";
import { actionCreators as PurchaseProduct_Actions } from "../../redux/modules/PurchaseProduct";
import { actionCreators as ProductBasketActions } from "../../redux/modules/ProductBasket";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";

const MyHome = () => {
  const dispatch = useDispatch();
  const [paypal_disable, setpaypal_disable] = useState(false);
  const [bank_disable, setbank_disable] = useState(false);
  const [bankaccount_disable, setbankaccount_disable] = useState(false);
  const [description_disable, setdescription_disable] = useState(false);
  const [youtube_disable, setyoutube_disable] = useState(false);

  const [description_value, setdescription_value] = useState();
  const [paypaladress_value, setpaypaladress_value] = useState();
  const [bankadress_value, setbankadress_value] = useState();
  const [bankselected_value, setbankselected_value] = useState();
  const [youtube_value, setyoutube_value] = useState();
  const [open, setOpen] = useState(false);
  const PurchaseProduct = useSelector((state) => state.PurchaseProduct);
  const ProductBasket = useSelector((state) => state.ProductBasket);
  const [Artist_Amount, setArtist_Amount] = useState();

  const user = useSelector((state) => state.user);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const MainCT = [
    { value: "카카오뱅크", label: "카카오뱅크" },
    { value: "농협", label: "농협" },
    { value: "신한은행", label: "신한은행" },
    { value: "IBK기업", label: "IBK기업" },
    { value: "하나은행", label: "하나은행" },
    { value: "우리은행", label: "우리은행" },
    { value: "국민은행", label: "국민은행" },
    { value: "대구은행", label: "대구은행" },
    { value: "부산은행", label: "부산은행" },
    { value: "광주은행", label: "광주은행" },
    { value: "새마을금고", label: "새마을금고" },
    { value: "경남은행", label: "경남은행" },
    { value: "전북은행", label: "전북은행"},
    { value: "제주은행", label: "제주은행" },
    { value: "우체국", label: "우체국" },
    { value: "씨티은행", label: "씨티은행" },
    { value: "케이뱅크", label: "케이뱅크" },
    { value: "토스은행", label: "토스은행" },
    // { value: "케이뱅크", label: "3D" },
  ];



  useEffect(() => {
    dbService
    .collection("user_Public")
    .doc(user?.uid)
    .collection("Purchase_Product")
    .get()
    .then((snapshot) => {
      const product_basket = snapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
        edit: songEdit(doc.data()?.songEdit),
        // time: time(doc.data()?.PurchaseAt.toDate().toISOString()),
      }));
      dispatch(PurchaseProduct_Actions.set_RecentSong(
        product_basket,
        ));
        
       dbService
        .collection("Artist_amount")
        .doc(user?.uid)
        .get()
        .then((snapshot) => {
          const data = {
            data: snapshot.data(),
            id: snapshot.id,
          }
          dispatch(PurchaseProduct_Actions.set_Artist_Amount(
            data,
            ));
            // setArtist_Amount(data)
        });
    });

  }, [user?.uid , dispatch]);

  const time = (data) => {
    var split1 = (data || "").split("T");
    var replace = split1[0].replace(/\-/g, " ");
    var replace2 = replace.replace(" ", ".");
    var date = replace2.replace(" ", ".");
    return date;
  };

  
  const songEdit = (songEdit) => {
    if (songEdit === undefined) {
      return "원곡";
    } else if (songEdit.NoEdit === true) {
      return "원곡";
    } else if (songEdit.SomeEdit === true) {
      return "편곡";
    } else if (songEdit.Edit === true) {
      return "작곡";
    }
  };




  const correctionbankmail = () => {
    setbankaccount_disable(false)
    setbank_disable(false)
  };

  const customStyles = useMemo(
    () => ({
   
      // 컨트롤 박스
      control: (provided, state) => ({
        ...provided,
        borderRadius: "12px",
        alignItems: "center",
        color: Color.Primary,
        backgroundColor: "transparent",
        borderColor:"#EAEAEA",
        // border: "#EAEAEA",
        boxShadow: "none",
        cursor: "pointer",
        width:"100%",
        height: "45px",
        paddingLeft:"10px"
      }),
      indicatorSeparator: (provided, state) => ({
        ...provided,
        display: "none",
      }),
      placeholder: (provided, state) => ({
        ...provided,
        alignItems: "center",
        fontSize: "14px",
        color: Color.Dark_4,
      }),

      // 메뉴 드롭 다운 바탕 박스
      menu: (provided, state) => ({
        ...provided,
        fontWeight: state.isSelected ? "500" : "400",
        border: `0.5px solid ${Color.Light_2}`,
        boxShadow: `0 2px 10px ${Color.Light_4}`,
        backgroundColor: "white",
        borderTopLeftRadius: "0",
        borderTopRightRadius: "0",
        borderBottomLeftRadius: "15px",
        borderBottomRightRadius: "15px",
        width:"100%"
      }),
      // 메뉴 드롭 다운 내 바탕 박스
      menuList: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? Color.Primary : "transparent",
        fontSize: "14px",
        margin: "10px 0 5px 0",
      }),
      // 메뉴 드롭 다운 내 값1, 값2 ...
      option: (provided, state) => ({
        ...provided,
        cursor: "pointer",
        fontSize: "14px",
        color: state.isFocused ? Color.Primary : Color.Dark_4,
        backgroundColor: state.isSelected ? "transparent" : state.isFocused ? "transparent" : "transparent",
        // border: state.isSelected ? `5px solid blue` : state.isFocused ? `5px solid green` : `0.5px solid red`,
      }),
    //   multiValueRemove: (provided, state) => ({
    //     ...provided,
    //     border: "none",
    //   }),
     
    }),
    []
  );

  const save_youtube_submit = async () => {
    const data = {
      artistuser_youTube : youtube_value,
    };
    const firebase = dbService.collection('user_Public').doc(user.uid);
    return dbService
      .runTransaction((transaction) => {
        // This code may get re-run multiple times if there are conflicts.
        return transaction.get(firebase).then((sfDoc) => {
          if (!sfDoc.exists) {
            throw 'Document does not exist!';
          }
          transaction.update(firebase, data);
        });
      })
      .then(() => {
        setyoutube_disable(true);
      })
      .catch((error) => {
        console.log('Transaction failed: ', error);
      });
  };

  const save_bank_submit = async () => {
    const data = {
      artistuser_bank : bankselected_value,
      artistuser_bankAddress : bankadress_value,
    };
    const firebase = dbService.collection('user_Public').doc(user.uid);
    return dbService
      .runTransaction((transaction) => {
        // This code may get re-run multiple times if there are conflicts.
        return transaction.get(firebase).then((sfDoc) => {
          if (!sfDoc.exists) {
            throw 'Document does not exist!';
          }
          transaction.update(firebase, data);
        });
      })
      .then(() => {
        setbankaccount_disable(true);
        setbank_disable(true);
      })
      .catch((error) => {
        console.log('Transaction failed: ', error);
      });
  };


  const save_paypal_submit = async () => {
    const data = {
      artistuser_PaypalAddress: paypaladress_value,
    };
    const firebase = dbService.collection('user_Public').doc(user.uid);
    return dbService
      .runTransaction((transaction) => {
        // This code may get re-run multiple times if there are conflicts.
        return transaction.get(firebase).then((sfDoc) => {
          if (!sfDoc.exists) {
            throw 'Document does not exist!';
          }
          transaction.update(firebase, data);
        });
      })
      .then(() => {
        setpaypal_disable(true);
      })
      .catch((error) => {
        console.log('Transaction failed: ', error);
      });
  };


  const save_textarea_submit = async () => {
    const data = {
      artistuser_description: description_value,
    };
    const firebase = dbService.collection('user_Public').doc(user.uid);
    return dbService
      .runTransaction((transaction) => {
        // This code may get re-run multiple times if there are conflicts.
        return transaction.get(firebase).then((sfDoc) => {
          if (!sfDoc.exists) {
            throw 'Document does not exist!';
          }
          transaction.update(firebase, data);
        });
      })
      .then(() => {
        setdescription_disable(true);
      })
      .catch((error) => {
        console.log('Transaction failed: ', error);
      });
  };

  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };
  
  const Language_song_Edit = (song) => {
    if (song.edit === "원곡") {
      return `${t("Chart.Original_song")}`;
    } else if (song.edit === "편곡") {
      return `${t("Chart.Arrangement_song")}`;
    } else if (song.edit === "작곡") {
      return `${t("Chart.Composition_song")}`;
    }
  };

  //TODO firebase에서 데이터가져와야함 
  // const Change_Language_artistAmount = () => {
  //   console.log(PurchaseProduct)
  //   if (i18n.language === "kor") {
  //     return PurchaseProduct.Artist_Amount.data?.KOR;
  //   } else if (i18n.language === "en") {
  //     return PurchaseProduct.Artist_Amount.data?.USD;
  //   } else if (i18n.language === "ja") {
  //     return PurchaseProduct.Artist_Amount.data?.JPY;
  //   } else {
  //     // 오류
  //     return PurchaseProduct.Artist_Amount.data?.USD;
  //   }
  // };

  return (
    <Grid width="100%" height="100%">
      <Grid display ="flex" margin = "0 auto" width="100%"  paddingT="20px" paddingB="20px">
        {user.user?.role === 'Public' ? (
          <>
              <PublicSideBar />
    <Grid column display="flex" position="relative" width="86%" margin="15px auto">
        <div class="MyHome_main_card">
            <img
              className="card-img-wrapper"
              src={user?.photoURL}
            />
             

          <Grid margin="auto" column width="30%" paddingL="40px" marginT="auto">
            <Text bold color = "#434343" size= "28px"  textalign="left" marginT ="0px">{user?.displayName}</Text>
            <Text color = "#797979" size= "15px"  textalign="left" marginT ="8px">{user.user?.role} </Text>
            <Text color = "#797979" size= "15px"  textalign="left" marginT ="3px"> {user.user?.email}</Text>
            <div class="MyHome_PHOTO_EDIT_BUTTON">
               <CameraOutlined  onClick={() => {openModal()}}/>
               </div>
               <AvatarModalContainer show={open} close={closeModal} />
        
          </Grid>
         
      
            <Grid row width="60%"  justify="space-around" display="flex" margin="auto" padding="30px"   marginR="20px">
              <Grid position="relative" >
          {/* TODO 구독하면 올리기*/}
                <Text size="32px" bold color ="#434343">{user.user?.user_artist_subscribe}</Text>
                <Text size="16px" bold color="#00000085" letterspacing="0.5px">Artist Subscribe</Text>
              </Grid>

              <Grid position="relative" >
                <Text size="32px" bold color ="#434343">{PurchaseProduct.Recent_PurchaseProdcut_Song?.length}</Text>
                <Text size="16px" bold color="#00000085" letterspacing="0.5px">Download Song</Text>
              </Grid>

              <Grid position="relative" >
              {/* TODO 새로회원가입하면 업데이트가 안되요  로그아웃하면 0으로*/}
                <Text size="32px" bold color ="#434343">{ProductBasket.basket?.length}</Text>
                <Text size="16px" bold color="#00000085" letterspacing="0.5px">Shopping Basket</Text>
              </Grid>
            </Grid>
      
        </div>
         <div class="upload_public_card_bottom">
             <div class="upload_public_card_bottom_paypal">
             <Grid paddingL="30px" paddingR="30px">
                 <Text bold textalign="left" display="block" size="21px"  marginT="21px" color="#434343">Recent Purchase Songs</Text>
                <Grid width="100%" bdrBottom="1px solid #EAEAEA" marginT="16px" display="flex"></Grid>
              </Grid>
             {/* <Text textalign="left" display="block" size="27px" bold marginT="18px" marginL="30px" color ="#434343" >Recent Purchase Songs</Text> */}
            <Grid display="flex" marginB="auto" width="100%"  paddingL="30px" paddingR="30px">
             <table className="MyHome_table">
                    <colgroup>
                      <col width="5%"  />
                      <col width="45%" />
                      <col width="15%" />
                      <col width="10%" />
                    </colgroup>

                    <thead className="MyHome_table_thead">
                      <tr className="MyHome_table_thead_tr">
                        <th className="MyHome_tableHead_index" style={{color: "#434343"}}>
                          {t("Chart.Ranking")}
                        </th>
                        <th className="MyHome_tableHead_songname"  style={{color: "#434343"}}>
                          {t("Chart.Song_name")} / {t("Chart.Artist_name")}
                        </th>
                        <th className="MyHome_tableHead_genres_edit"  style={{color: "#434343"}}>
                          {t("Chart.Genre")} / {t("Chart.Composition")}
                        </th>
                        {/* <th className="MyHome_tableHead_download">
                          {t("Chart.Download")}
                        </th>
                        <th className="MyHome_tableHead_favorite">
                          {t("Chart.Like")}
                        </th> */}

                        <th className="MyHome_tableHead_rating"  style={{color: "#434343"}}>
                        구입일자
                        </th>
                      </tr>
                    </thead>
           
             {PurchaseProduct.Recent_PurchaseProdcut_Song?.map((song, index) =>
                      index < 5 ? (
                        <tbody className="MyHome_tbody_table">
                          <tr className="MyHome_tbody_tr">
                            <td className="MyHome_tbody_index">
                              {index + 1}
                            </td>

                            <td
                              className="MyHome_tbody_artist"
                              onClick={() =>
                                history.push(`/artist_product/${song.id}`)
                              }
                            >
                              {/* <Link to={`/artist_product/${song.id}`}> */}
                              <img
                                src={song.data.Elbum_url}
                                alt=""
                                className="MyHome_tbody_image"
                              />

                              <span className="MyHome_songName">
                                {song.data.songName}
                                <span className="MyHome_artistname">
                                  - {song.data.artistName}
                                </span>
                              </span>
                            </td>

                            <td className="MyHome_tbody_genres_edit">
                              {song.data.genres} / {Language_song_Edit(song)}
                            </td>
                            {/* <td className="MyHome_tbody_upgrade">
                              {song.data.update}
                              <div className="MyHome_tbody_download_Icon">
                                <ArrowDownwardIcon className="MyHome__Icon" />
                              </div>
                            </td>

                            <td className="MyHome_tbody_favorite">
                              {song.data.faviorite}
                              <div className="MyHome_tbody_favorite_Icon">
                                <FavoriteBorderIcon className="MyHome__Icon" />
                              </div>
                            </td> */}

                          

                            <td className="MyHome_tbody_date">
                              {song.time}
                            </td>
                          </tr>
                        </tbody>
                      ) : null
                      )}
            </table>
              <table className="MyHome_table_right">
                    {/* <div className="Artist_song_chart"> */}
                    <colgroup>
                    <col width="5%" />
                      <col width="45%" />
                      <col width="15%" />
                      <col width="10%" />
                      {/* <col width="10%" />
                      <col width="15%" /> */}
                    </colgroup>

                    <thead className="MyHome_table_thead">
                      <tr className="MyHome_table_thead_tr">
                        <th className="MyHome_tableHead_index"  style={{color: "#434343"}}>
                          {t("Chart.Ranking")}
                        </th>
                        <th className="MyHome_tableHead_songname"  style={{color: "#434343"}}>
                          {t("Chart.Song_name")} / {t("Chart.Artist_name")}
                        </th>
                        <th className="MyHome_tableHead_genres_edit"  style={{color: "#434343"}} >
                          {t("Chart.Genre")} / {t("Chart.Composition")}
                        </th>
                        {/* <th className="MyHome_tableHead_download">
                          {t("Chart.Download")}
                        </th>
                        <th className="MyHome_tableHead_favorite">
                          {t("Chart.Like")}
                        </th> */}

                        <th className="MyHome_tableHead_rating"  style={{color: "#434343"}}>
                          구입일자
                        </th>
                      </tr>
                    </thead>
           
             {PurchaseProduct.Recent_PurchaseProdcut_Song?.map((song, index) =>
                      index < 9 && index > 4 ? (
                        <tbody className="MyHome_tbody_table">
                          <tr className="MyHome_tbody_tr">
                            <td className="MyHome_tbody_index">
                              {index + 1}
                            </td>

                            <td
                              className="MyHome_tbody_artist"
                              onClick={() =>
                                history.push(`/artist_product/${song.id}`)
                              }
                            >
                              {/* <Link to={`/artist_product/${song.id}`}> */}
                              <img
                                src={song.data.Elbum_url}
                                alt=""
                                className="MyHome_tbody_image"
                              />

                              <span className="MyHome_songName">
                                {song.data.songName}
                                <span className="MyHome_artistname">
                                  - {song.data.artistName}
                                </span>
                              </span>
                            </td>

                            <td className="MyHome_tbody_genres_edit">
                              {song.data.genres} / {Language_song_Edit(song)}
                            </td>
                            {/* <td className="MyHome_tbody_price">
                              {t("Payment.Currency")}{" "}
                              {language_Currency_Amount(song)}
                            </td> */}

                            {/* <td className="MyHome_tbody_upgrade">
                              {song.data.update}
                              <div className="MyHome_tbody_right_download_Icon">
                                <ArrowDownwardIcon className="MyHome__Icon" />
                              </div>
                            </td>

                            <td className="MyHome_tbody_favorite">
                              {song.data.faviorite}
                              <div className="MyHome_tbody_right_favorite_Icon">
                                <FavoriteBorderIcon className="MyHome__Icon" />
                              </div>
                            </td> */}

                          

                            <td className="MyHome_tbody_date">
                              {song.time}
                            </td>
                          </tr>
                        </tbody>
                      ) : null
                      )}
              </table>
            </Grid>
             </div>
        </div>
      </Grid>
    </>
        ) : user.user?.role === 'Artist' || user.user?.role === "Administrator" ? (
          <>
          <ArtistSideBar/>
       <Grid width="86%" margin="15px auto">
         <div class="MyHome_main_card">
            <img
              className="card-img-wrapper"
              src={user?.photoURL}
            />
         
          <Grid margin="auto" column width="30%" paddingL="40px" marginT="auto">
            <Text bold color = "#434343" size= "28px"  textalign="left" marginT ="0px">{user?.displayName}</Text>
            <Text color = "#797979" size= "15px"  textalign="left" marginT ="8px">{user.user?.role} </Text>
            <Text color = "#797979" size= "15px"  textalign="left" marginT ="3px"> {user.user?.email}</Text>
          </Grid>
          {/* bdr="1px solid #EAEAEA" borderRadius="20px" */}
          <Grid row width="60%"  justify="space-around" display="flex" margin="auto" padding="30px"   marginR="20px">
            {/* <Grid position="relative" bdrTop="2px solid #ff9b9b" padding ="15px" marginT="15px" display="flex" justify="space-around"> */}
              <Grid position="relative"  >
                <Text size="32px" bold color ="#545454">{user.user?.artistuser_song}</Text>
                <Text size="16px" bold color="#00000085" letterspacing="0.5px">Upload Song</Text>
              </Grid>
              <Grid position="relative" display="block">
                <Text size="32px" bold color ="#545454">{user.user?.artistuser_subscribe}</Text>
                <Text size="16px" bold color="#00000085" letterspacing="0.5px">Subscribe</Text>
             </Grid>
             <Grid position="relative" display="block">
                 <Text size="32px" bold color ="#545454">{user.user?.artistuser_update}</Text>
                 <Text size="16px" bold color="#00000085" letterspacing="0.5px">Download</Text>
             </Grid>
            {/* </Grid> */}
          </Grid>
        </div>


        <div class="MyHome_bottom_card">
          <div class="MyHome_bottom_paypal_card">
            <Grid  width="100%" marginT="14px" paddingL="20px" paddingR="20px">
                <Text bold textalign="left" display="block" size="21px"  marginT="21px" color="#434343">Accout address & Amount</Text>
                 
              <Grid marginT="8px" >
              <Grid bdrBottom="1px solid #EAEAEA"></Grid>
              { user.user?.artistuser_country == "KR" ? (
                  <>
                  <Text textalign="left" display="block" size="15px"  marginT="21px" marginB="0.8rem" color="#797979" >Bank account</Text>
                    
                    <SubSelectbox>
                      <Select placeholder="은행종류" onChange={(e) => {setbankselected_value(e.value)}} isDisabled={bank_disable}  value={MainCT.find((obj) => obj.value === MainCT)}  options={MainCT}  styles={customStyles}/>
                    </SubSelectbox>
                    
                    <SignupBox>
                        <SignupInput
                          type="number"
                          placeholder="Bank Address"
                          onChange={(e) => {
                            setbankadress_value(e.target.value);
                          }}
                          value={bankadress_value}
                          disabled = {bankaccount_disable}
                        />
                      </SignupBox>

                    
                    <Grid display="flex" row marginT="10px">
                      <div className="MyHome_bottom_button_enrollment">
                      <Button _onClick={correctionbankmail} token size="15px"  backgroundcolor="#9e9e9e" width="60px" height="30px" color="#fff" marginR="3px">수정</Button>
                      <Button _onClick={save_bank_submit} token size="15px"  backgroundcolor="#ba4148" width="60px" height="30px" color="#fff" marginR="3px">저장</Button>
                      </div>
                    </Grid>

          <Text textalign="left" display="block" size="15px"  marginT="36px" marginB="0.3rem" color="#797979" >Amount</Text>
           <Grid bdrBottom="1px solid #EAEAEA" marginT="4px"></Grid>
              <Grid display="flex" column >
                <Grid justify="space-between" display="flex" width="100%">
                 {/* <Text  display="flex" size="32px" bold marginT="21px" color="#434343">{Change_Language_artistAmount()}</Text> */}
                 <Text  display="flex" size="32px" bold marginT="21px" color="#434343">{t("Payment.Currency")}</Text> 
                </Grid>
                  <Grid display="flex" row marginT="14px">
                    <Grid margin="auto">
                    <Button _onClick={save_textarea_submit}  size="15px"  backgroundcolor="#ba4148" width="100%" height="40px" color="#fff" marginT="10px">정산하기</Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Text textalign="left" display="block" size="14px" bold marginT="14px" color="#ba4148" >1. 매달25일날 지급</Text>

                  </>
              ):(
                  <>
                  <Text textalign="left" display="block" size="15px"  marginT="21px"  color="#797979" >Paypal Account</Text>
                <SignupBox>
                    <SignupInput
                      type="email"
                      placeholder="Paypal Address"
                      onChange={(e) => {
                        setpaypaladress_value(e.target.value)
                      }}
                      value={paypaladress_value}
                      disabled = {paypal_disable}
                    />
                  </SignupBox>
                <Grid display="flex" row marginT="10px">
                  <div className="MyHome_bottom_button_enrollment">
                  <Button _onClick={() => setpaypal_disable(false)} token size="15px"  backgroundcolor="#9e9e9e" width="60px" height="30px" color="#fff" marginR="3px">수정</Button>
                  <Button _onClick={save_paypal_submit} token size="15px"  backgroundcolor="#ba4148" width="60px" height="30px" color="#fff" marginR="3px">저장</Button>
                  </div>
                </Grid>
              <Text textalign="left" display="block" size="15px"  marginT="36px" marginB="0.3rem" color="#797979" >Amount</Text>
                    <Grid bdrBottom="1px solid #EAEAEA" marginT="4px"></Grid>
                        <Grid display="flex" column >
                          <Grid justify="space-between" display="flex" width="100%">
                          {/* <Text  display="flex" size="32px" bold marginT="21px" color="#434343">{Change_Language_artistAmount()}</Text> */}
                          <Text  display="flex" size="32px" bold marginT="21px" color="#434343">{t("Payment.Currency")}</Text> 
                          </Grid>
                            <Grid display="flex" row marginT="14px">
                              <Grid margin="auto">
                              <Button _onClick={save_textarea_submit}  size="15px"  backgroundcolor="#ba4148" width="100%" height="40px" color="#fff" marginT="10px">정산하기</Button>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Text textalign="left" display="block" size="14px" bold marginT="14px" color="#ba4148" >1. 매달25일날 지급</Text>

                  </>

              )}
            
              </Grid>
            </Grid>
          </div>


          <div class="MyHome_bottom_discription_card">
            <div class="MyHome_bottom_discription_card_content">
            <Text bold textalign="left" display="block" size="21px"  marginT="21px" color="#434343">Discription & Youtube</Text>
              <Grid display="flex" column marginT="8px">
              <Grid bdrBottom="1px solid #EAEAEA"></Grid>

              {/* <Text textalign="left" display="block" size="15px"  marginT="8px" marginB="0.4rem" color="#797979" >Description</Text> */}

                {/* <form> */}
                <Text textalign="left" display="block" size="15px"  marginT="21px" marginB="0.2rem" color="#797979" >Discription</Text>

                <textarea
                  class="MyHome_bottom_discription_card_entiretext"
                  rows="12"
                  disabled={description_disable}
                  name="description"
                  value={description_value}
                  onChange={(e) => {
                    setdescription_value(e.target.value);
                      }}
                >  
                  {description_value}
                </textarea>
                <Grid display="flex" row marginT="10px">
                  <div className="MyHome_bottom_button_enrollment">
                  <Button _onClick={() => setdescription_disable(false)} token size="15px"  backgroundcolor="#9e9e9e" width="60px" height="30px" color="#fff" marginR="3px">수정</Button>
                  <Button _onClick={save_textarea_submit} token size="15px"  backgroundcolor="#ba4148" width="60px" height="30px" color="#fff" marginR="3px">저장</Button>

                  </div>
                </Grid>

                <Text textalign="left" display="block" size="15px"  marginT="21px" marginB="0.2rem" color="#797979" >Youtube</Text>
                <YoutubeBox>
                    <YoutubeInput
                      type="text"
                      placeholder="Youtube Address"
                      onChange={(e) => {
                        setyoutube_value(e.target.value);
                      }}
                      disabled={youtube_disable}
                      value={youtube_value}
                    />
                  </YoutubeBox>
                <Grid display="flex" row marginT="10px">
                <Text textalign="center" display="block" size="14px" bold marginT="4px" color="#ba4148" >나의 아티스트 페이지에서 확인가능합니다</Text>

                  <div className="MyHome_bottom_button_enrollment">

                  <Button _onClick={() => setyoutube_disable(false)} token size="15px"  backgroundcolor="#9e9e9e" width="60px" height="30px" color="#fff" marginR="3px">수정</Button>
                  <Button _onClick={save_youtube_submit}  token size="15px"  backgroundcolor="#ba4148" width="60px" height="30px" color="#fff" marginR="3px">저장</Button>
                  </div>
                </Grid>
                {/* <Text textalign="center" display="block" size="14px" bold marginT="14px" color="#ba4148" >나의 아티스트 페이지에서 확인가능합니다</Text> */}

              </Grid>
            </div>
          </div>
        </div>

      
      </Grid>
       </>
        ):(
          history.push("/")
        
    )
     
        }
    </Grid>
 </Grid>
  );
};

const YoutubeBox = styled.div`
  border: 1px solid ${Color.Light_3};
  border-radius: 12px; 
  margin-top:8px;
  width: 100%;
  height: 45px;
  max-height: 45px;
  ${'' /* margin: 0.8rem auto; */}
  padding-left:10px;
  ${'' /* padding-top:10px */}
  ${'' /* padding: 5px; */}
  box-sizing: border-box;
  overflow: hidden;
  align-items: center;
  transition: border 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    box-shadow 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &:focus-within {
    border: 1px solid ${Color.Primary};
    box-shadow: 0 0 0 3px ${Color.Primary}33;
  }
`;

const YoutubeInput = styled.input`
  height: 100%;
  border: none;
  background-color: transparent;
  box-sizing: border-box;
  letter-spacing: 0.5px;
  overflow: hidden;
  color: rgb(71, 71, 71);
  width: 100%;
  outline: none;
  padding-bottom: 5px;
  font-size: 18px;
  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
    font-size: 15px;
    user-select: none;
  }
`;


const SignupBox = styled.div`
  border: 1px solid ${Color.Light_3};
  border-radius: 12px; 
  width: 100%;
  height: 45px;
  max-height: 45px;
  margin: 0.8rem 0;
  ${'' /* padding: 5px; */}
  box-sizing: border-box;
  overflow: hidden;
  align-items: center;
  transition: border 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    box-shadow 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &:focus-within {
    border: 1px solid ${Color.Primary};
    box-shadow: 0 0 0 3px ${Color.Primary}33;
  }
`;
const SignupInput = styled.input`
  height: 100%;
  border: none;
  background-color: transparent;
  box-sizing: border-box;
  letter-spacing: 0.5px;
  overflow: hidden;
  color: rgb(71, 71, 71);
  width: 90%;
  outline: none;
  padding-bottom: 5px;
  font-size: 18px;
  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
    font-size: 15px;
    user-select: none;
  }
`;

const SubSelectbox = styled.div`
  ${'' /* width: 160px; */}
  align-items: center;
  cursor: default;

  @media only screen and (max-width: 767px) {
    width: 200px;
    align-items: center;
  }
`;
export default MyHome;

