import React, { useRef, useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import "./product_upload.css";
import { Link } from "react-router-dom";
import { dbService, storageService } from "../../shared/firebase";
import firebase from "firebase";
import { Grid, Button, Text, Input2 } from "../../elements/index";
import { ArtistSideBar  ,  ProductUploadEditor } from "../../components/index";
import styled from "styled-components";
import { Color } from "../../shared/DesignSys";
import SearchIcon from "@material-ui/icons/Search";
import {
  createNamesArrayWithCaptitalizedWords,
  capitalizeAllWords,
  createartistNamesArrayWithCaptitalizedWords,
} from "../../shared/common";
import {
  FormControl,
  InputLabel,
  TextField,
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import { makeStyles} from "@material-ui/styles";
import { pdfjs, Document, Page, PageProps } from "react-pdf";
import { Upload_SongAll_firebase , Upload_SongCopyRight , ArtistUser_Song_Plus } from "../../shared/firebase";


import NumberFormat from "react-number-format";
import Chip from "@material-ui/core/Chip";
import OutlinedInput from "@material-ui/core/Input";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;



//태그(플레이리스트로 찾기) 곡명 따로 분리시켜놓을것
const useStyles = makeStyles((theme) => ({


  artistinput_example: {
    marginLeft: 20,
    marginTop: 10,
    minWidth: 180,
    position: "relative",
    width: 35+"%",
    // left: 500,
    borderColor: "orange",
    overflow: "visible",
  },
  hardest_example: {
    marginLeft: 20,
    marginTop: 3,
    minWidth: 180,
    position: "relative",
    width:  35+"%",
    // left: 500,
    borderColor: "orange",
    overflow: "visible",
  },

  genreinput_example: {
    marginTop: 0,
    width:  65+"%",
    position: "relative",
    borderColor: "orange",
  },


  menuDropBar: {
    "& ul": {
      backgroundColor: "#fff",
      padding: "10px",
    },
    "& li": {
      fontSize: 12,
    },
  },
  Discription: {
    width: 70+"%",
    marginTop: 40,
    // marginLeft: 140,
  },
  PricsStyle:{marginTop:20,},
  textFieldRoot: {
    "&$focusedLabel": {
      color: "cyan",
    },
    color: "#000",
    "&:hover": {
      color: "#000",
    },
    "&:hover $textFieldNotchedOutline": {
      borderColor: "#000",
    },
    "&$textFieldFocused $textFieldNotchedOutline": {
      borderColor: "#000",
    },
  },
  textFieldFocused: {},
  textFieldNotchedOutline: {},
}));


const useOutlinedInputStyles = makeStyles((theme) => ({
  underline: {
    fontSize: "15px",
    "&:before": {
      borderColor: "#EAEAEA",
    },
    "&:after": {
      borderColor: "#ff9532",
    },
  },
}));
const useOutlinedInputStyles2 = makeStyles((theme) => ({
  underline: {
    fontSize: "18px",
    paddingLeft: "10px",
    color: "rgb(71, 71, 71)",
    "&:before": {
      borderColor: "#EAEAEA",
    },
    "&:after": {
      borderColor: "#ff9532",
    },
  },
}));



const Product_upload = () => {
  const outlinedInputClasses = useOutlinedInputStyles();
  const outlinedInputClasses2 = useOutlinedInputStyles2();
  const All_Genres = [
    "Pop",
    "K-Pop",
    "J-Pop",
    "Classic",
    "Ani",
    "OST",
    "Rock/Metal",
    "Dance/Elec",
    // "Medley",
    "Etc",
  ];
  const hardest100 = ["Very Hard", "Hard", "Normal", "Easy", "Very Easy"];
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const formRef = useRef(null);
  const [midifile, setmidiFile] = useState(null);
  const [sheetfile, setsheetFile] = useState(null);

  //////////////////////편곡 유무체크 /////////////////////
  const [songedit, setsongedit] = useState({
    NoEdit: false,
    SomeEdit: false,
    Edit: false,
  });
  //////////////////////spotify /////////////////////
  const [spotifyaccessToken, setspotifyaccessToken] = useState();
  const [spotifytrack, setspotifytrack] = useState();
  const [spotifysongName, setspotifysongName] = useState('');
  const [spotifyartist, setspotifyartist] = useState([]);
  const [spotifygenre, setspotifygenre] = useState("");
  const [query, setquery] = useState("");
  const [hardest, sethardest] = useState("");
  //////////////////////Label /////////////////////
  const [artistSelect, setartistSelect] = useState();
  const [SongLabel, setSongLabel] = useState();
  const [artistLabel, setartistLabel] = useState("아티스트를 입력하거나 검색해주세요." );
  const [SongNameLabel, setSongNameLabel] = useState(
    "검색최적화를 위해 (한국어 / 영어)로 작성해주세요."
  );

  //////////////////////preview /////////////////////
  const [previewSongName, setpreviewSongName] =
    useState("곡 명 (한국어 / 영어)");
  const [spotifyimage, setspotifyimage] = useState({
    url: "https://prexoo.com/images/no-music-cover.png",
    file: "",
    name: "No File",
  });
  //////////////////////search /////////////////////
  const [ArtistFirst, setArtistFirst] = useState([]);
  const [SongFirst, setSongFirst] = useState([]);

  const [midifileinfo, setmidifileinfo] = useState({ name: "No File" });
  const [sheetfileinfo, setsheetfileinfo] = useState({
    url: "",
    name: "No File",
  });
  const [youtubeinfo, setyoutubeinfo] = useState("");
  //////////////////////pdf /////////////////////
  const [numPages, setNumPages] = useState(null);
  const [arrpage, setarrpage] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [secondpageNumber, setsecondPageNumber] = useState();
  const [pageWidth, setpageWidth] = useState(240);
 //////////////////////Discription /////////////////////
  const [Discription, setDiscription] = useState("");
//////////////////////price /////////////////////
  const [price, setprice] = useState();


  //환율
  const [selectedCountry, setselectedCountry] = useState("");

  const [currencyWon, setcurrencyWon] = useState("");
  const [currencyUSD, setcurrencyUSD] = useState("");
  const [currencyJPY, setcurrencyJPY] = useState("");
  const [currencyEUR, setcurrencyEUR] = useState("");
  const [currencyCNY, setcurrencyCNY] = useState("");

  const [currencyformat, setcurrencyformat] = useState("");

  const [songedit_disabled, setsongedit_disabled] = useState({
    NoEdit: false,
    SomeEdit: false,
    Edit: false,
  });
  const [midi_filedisabled, set_midi_filedisabled] = useState(false);
  const [sheet_filedisabled, set_sheet_filedisabled] = useState(false);


  const countryObj = ["$", "￦", "¥"];
  // const countryObj = ["$", "￦", "¥", "€", "元"];
  const [discrpction_EDIT_content, setdiscrpction_EDIT_content] = useState("");


  useEffect(() => {
    const GATEWAY_URL =
      "https://t2xklefjcd.execute-api.us-east-1.amazonaws.com/prod/access-token";
    fetch(GATEWAY_URL, {
      method: "GET",
      mode: "cors",
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setspotifyaccessToken(json.done.json.access_token);
      });
  }, []);

  const search = async () => {
    const BASE_URL = "https://api.spotify.com/v1/search?";
    let FETCH_URL = `${BASE_URL}q=${query}&type=artist`;
    // console.log(query);
    const myOptions = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + spotifyaccessToken,
      },
      mode: "cors",
      cache: "default",
    };

    await fetch(FETCH_URL, myOptions)
      .then((response) => {
        if (response.status === 401) {
          console.log("ok");
          return response.json();
        } else {
          return response.json();
        }
      })

      .then((json) => {
        if (json.hasOwnProperty("error")) {
          return;
        } else {
          const artist = json.artists.items;
          if (artist.length <= 0) {
            return;
          } else {
            const songs = artist.map((doc) => [
              { value: `${doc.name}` },
              { id: `${doc.id}` },
              { genres: `${doc.genres}` },
            ]);
            const edit = [{ value: "직접입력" }];
            songs.unshift(edit);
            console.log(songs);
            // arr.unshift('d');
            setspotifyartist(songs);
            setartistSelect(songs[1][0].value);
            setArtistFirst("");
            setSongLabel("");
            setSongFirst("");
            setspotifysongName("");
            setSongNameLabel(
              "검색최적화를 위해 (한국어 / 영어)로 작성해주세요."
            );
            setartistLabel("아티스트를 입력하거나 검색해주세요.");
          }
        }
      });
  };
  const searchSong = async () => {
    const BASE_URL = "https://api.spotify.com/v1/search?";
    let FETCH_URL = `${BASE_URL}q=${spotifysongName}&type=track&limit=10`;
    // let FETCH_URL = `${BASE_URL}q=${"Bummerland"}&type=track&q=${"AJR"}&type=artist`;
    // let FETCH_URL = `${BASE_URL}q=artist:+AJR+track:+Bummerland&type=track`;
    const myOptions = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + spotifyaccessToken,
      },
      mode: "cors",
      cache: "default",
    };

    await fetch(FETCH_URL, myOptions)
      .then((response) => {
        if (response.status === 401) {
          console.log("ok");
          return response.json();
        } else {
          return response.json();
        }
      })

      .then((json) => {
        if (json.hasOwnProperty("error")) {
          return;
        } else {
          console.log(json.tracks.items)
          const song = json.tracks.items;

          const deleteundefine = song.filter(function (item) {
            return (
              item.name !== null &&
              item.name !== undefined &&
              item.name !== "" &&
              item.length !== 0
            );
          });

          const tracks = deleteundefine.map((doc) => [
            { name: `${doc.name}` },
            { id: `${doc.id}` },
            { album: doc.album.images[0] },
            { artist: `${doc.artists.id}` },

          ]);
     
          const edit = [
            { name: "직접입력" },
            { id: "" },
            { album: { url: "" } },
            { artist: ""}
          ];
          tracks.unshift(edit);
          console.log(tracks)
          if (tracks.length === 1) {
            return;
          } else {
            setspotifytrack(tracks);
            // setSongLabel(songs[1][0].name);
          }
          
        }
      });
  };

  const search_Spotify_Song = async (name) => {
    var urlname = encodeURI(name);
    //url이 리턴되면 query 된걸로 찾는다
    const BASE_URL = "https://api.spotify.com/v1/search?";
    let FETCH_URL = `${BASE_URL}q=${urlname}&type=track&limit=50`;
    const myOptions = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + spotifyaccessToken,
      },
      mode: "cors",
      cache: "default",
    };

    await fetch(FETCH_URL, myOptions)
      .then((response) => {
        if (response.status === 401) {
          console.log("ok");
          return response.json();
        } else {
          return response.json();
        }
      })

      .then((json) => {
        if (json.hasOwnProperty("error")) {
          return;
        } else {
          const artist = json.tracks.items;

          const deleteundefine = artist.filter(function (item) {
            return (
              item.name !== null &&
              item.name !== undefined &&
              item.name !== "" &&
              item.length !== 0
            );
          });

          const songs = deleteundefine.map((doc) => [
            { name: `${doc.name}` },
            { id: `${doc.id}` },
            { album: doc.album.images[0] },
          ]);
          //  3HqSLMAZ3g3d5poNaI7GOU

          const edit = [
            { name: "직접입력" },
            { id: "" },
            { album: { url: "" } },
          ];
          songs.unshift(edit);

          if (songs.length === 1) {
            return;
          } else {
            setspotifytrack(songs);
            setSongLabel(songs[1][0].name);
          }
        }
      });
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    for (let j = 0; j < numPages; j++) {
      arrpage.push(j);
    }
  }

  function onDocumentNoData() {
    return;
  }

  const onEditorChange  = (value) =>{
    setdiscrpction_EDIT_content(value)
}

  const handlePriceChange = (event) => {
    setprice(event.target.value);
    var orgprice = event.target.value;
    if (selectedCountry === "￦") {
      setcurrencyformat("#,#00");
      var realprice = orgprice.replace(/,/, "");
      var USD = realprice / 1200;
      var USD_real = USD.toFixed(1);

      var EUR = realprice / 1400;
      var EUR_real = EUR.toFixed(1);

      var CNY = realprice / 185;
      var CNY_real = CNY.toFixed(1);

      var JPY4 = realprice / 10;
      var JPY_real4 = Math.ceil(JPY4 / 10) * 10;

      setcurrencyWon(event.target.value);
      setcurrencyUSD(USD_real);
      setcurrencyJPY(JPY_real4);
      setcurrencyEUR(EUR_real);
      setcurrencyCNY(CNY_real);
    } else if (selectedCountry === "$") {
      setcurrencyformat("#.#");


        if(orgprice === "0. " || orgprice === "0.0"){
            console.log("ddd")
        }
      var KOR = orgprice * 1200;
      var KOR_real = Math.ceil(KOR / 100) * 100;

      var JPY = orgprice * 120;
      var JPY_real = Math.ceil(JPY / 10) * 10;

      var EUR2 = orgprice * 0.88;
      var EUR_real2 = EUR2.toFixed(1);

      var CNY2 = orgprice * 6.55;
      var CNY_real2 = CNY2.toFixed(1);

      setcurrencyWon(KOR_real);
      setcurrencyUSD(event.target.value);
      setcurrencyJPY(JPY_real);
      setcurrencyEUR(EUR_real2);
      setcurrencyCNY(CNY_real2);
    } else if (selectedCountry === "¥") {
      setcurrencyformat("##0");

      var KOR2 = orgprice * 10;
      var KOR_real2 = Math.ceil(KOR2 / 100) * 100;

      var USD2 = orgprice / 120;
      var USD_real2 = USD2.toFixed(1);

      var EUR3 = orgprice / 132;
      var EUR_real3 = EUR3.toFixed(1);

      var CNY3 = orgprice / 17.8;
      var CNY_real3 = CNY3.toFixed(1);

      setcurrencyWon(KOR_real2);
      setcurrencyUSD(USD_real2);
      setcurrencyJPY(event.target.value);
      setcurrencyEUR(EUR_real3);
      setcurrencyCNY(CNY_real3);
    } else if (selectedCountry === "€") {
      setcurrencyformat("#.#");

      var KOR3 = orgprice * 1380;
      var KOR_real3 = Math.ceil(KOR3 / 100) * 100;

      var USD3 = orgprice * 1.14;
      var USD_real3 = USD3.toFixed(1);

      var JPY2 = orgprice * 131;
      var JPY_real2 = Math.ceil(JPY2 / 10) * 10;

      var CNY4 = orgprice * 7.4;
      var CNY_real4 = CNY4.toFixed(1);

      setcurrencyWon(KOR_real3);
      setcurrencyUSD(USD_real3);
      setcurrencyJPY(JPY_real2);
      setcurrencyEUR(event.target.value);
      setcurrencyCNY(CNY_real4);
    } else if (selectedCountry === "元") {
      setcurrencyformat("##.#");

      var KOR4 = orgprice * 185;
      var KOR_real4 = Math.ceil(KOR4 / 100) * 100;

      var USD4 = orgprice / 6.5;
      var USD_real4 = USD4.toFixed(1);

      var JPY3 = orgprice * 17.8;
      var JPY_real3 = Math.ceil(JPY3 / 10) * 10;

      var EUR4 = orgprice / 7.4;
      var EUR_real4 = EUR4.toFixed(1);

      setcurrencyWon(KOR_real4);
      setcurrencyUSD(USD_real4);
      setcurrencyJPY(JPY_real3);
      setcurrencyEUR(EUR_real4);
      setcurrencyCNY(event.target.value);
    }
  };

  const handlemidifile = (e) => {
    if (e.target.files.length !== 0) {
      setmidiFile(e.target.files[0]);
      setmidifileinfo({ name: e.target.files[0].name });
      set_midi_filedisabled(true);
    }
  };

  const handlsheetfile = (e) => {
    if (e.target.files.length !== 0) {
      setsheetFile(e.target.files[0]);
      setsheetfileinfo({
        url: window.URL.createObjectURL(e.target.files[0]),
        name: e.target.files[0].name,
      });
      set_sheet_filedisabled(true);
    }
  };

  const handleImageFile = (event) => {
    if (event.target.files.length !== 0) {
      setspotifyimage({
        url: URL.createObjectURL(event.target.files[0]),
        name: event.target.files[0].name,
        file: event.target.files[0],
      });
    }
  };

  const handleArtistChange = (event) => {
    if (event.target.value[0].value === "직접입력") {
      setquery("");
      setartistSelect("");
      setArtistFirst(event.target.value);
      setartistLabel("아티스트를 입력하거나 검색해주세요.");
    } else {
      setquery(event.target.value[0].value);
      search_Spotify_Song(event.target.value[0].value);
      setartistSelect("");
      setArtistFirst(event.target.value);
      setSongFirst("");
      setartistLabel("");


    }
  };




  const handletrackChange = (event) => {

    if (event.target.value[0].name === "직접입력") {
      setSongLabel("");
      setspotifysongName("");
      setpreviewSongName("곡 명 (한국어 / 영어)");
      setSongFirst(event.target.value);
      setSongNameLabel("검색최적화를 위해 (한국어 / 영어)로 작성해주세요.");
      setspotifyimage({
        url: "https://prexoo.com/images/no-music-cover.png",
        file: "",
        name: "No File",
      });
    } else {
      const imageFile = convertURLtoFile(event.target.value[2].album.url);
      const imageName = imagename(event.target.value[2].album);
      imageFile.then(function (result) {
        setspotifyimage({
          url: event.target.value[2].album.url,
          file: result,
          name: imageName,
        });
      });

      setspotifysongName(event.target.value[0].name);
      setSongFirst(event.target.value);
      setSongLabel("");
      setpreviewSongName(event.target.value[0].name);
      setSongNameLabel("");


    }
  };

  // ab67616d0000b27393aba72cc0ca72fa7ca17aff.jpg
  // https://i.scdn.co/image/ab67616d0000b27393aba72cc0ca72fa7ca17aff
  const convertURLtoFile = async (url) => {
    if (url === "") {
      return;
    }
    const response = await fetch(url);
    const data = await response.blob();
    const filename = url.split("/").pop(); // url 구조에 맞게 수정할 것
    const filename2 = filename + ".jpg";
    const metadata = { type: "image/jpg" };
    return new File([data], filename2, metadata);
  };

  const imagename = (album) => {
    if (album.url === "") {
      return;
    }
    const filename = album.url.split("/").pop(); // url 구조에 맞게 수정할 것
    const filename2 = filename + ".jpg";
    return filename2;
  };


  const clearFormFields = () => {

    // set_song_data(null)

    formRef.current.sheetfile.value = "";
    formRef.current.elbumImageFile.value = "";
    // formRef.current.subImageFile.value = ''
    formRef.current.midifile.value = "";
    formRef.current.songName.value = "";
    formRef.current.YoutubeUrl.value = "";
    formRef.current.price.value = "";
    // formRef.current.country.value = ''
    // formRef.current.songedit.value = ''
    formRef.current.songEnglishName.value = "";
  };


  const handleAddSongForm = () => {

    const AutoId = () => {
      const CHARS = 'abcdefghijklmnopqrstuvwxyz0123456789'
    
      let autoId = ''
    
      for (let i = 0; i < 24; i++) {
        autoId += CHARS.charAt(
          Math.floor(Math.random() * CHARS.length)
        )
      }
      return autoId
    }

    var autoid = AutoId()
    var dd = (price.replace(/^\s*/, ""));

    console.log(currencyWon);
    console.log(currencyUSD);
    console.log(currencyJPY);
    console.log(currencyEUR);
    console.log(currencyCNY);


    var orgprice = currencyWon;
    var realprice = 0; 
    if (orgprice.indexOf(",") === 1) {
      realprice = orgprice.replace(/,/, "");
    } else {
      realprice = orgprice;
    }

    if( isNaN(realprice) || isNaN(currencyUSD) || isNaN(currencyJPY) || isNaN(currencyEUR) || isNaN(currencyCNY)){
      alert("가격을 제대로 입력해주세요");
      return;
    }




    else if (songedit.Edit == false && songedit.NoEdit == false && songedit.SomeEdit == false) {
        alert("원곡 유무를 선택해주세요");
        return;
    }
    if (midifile && sheetfile) {
      if (midifile.type !== "audio/mid" && "audio/midi") {
        alert("midi 파일이 아닙디다");
        return;
      }
    }
    if (query === '') {
      alert("아티스트명을 입력해주세요");
      return;
  }
  if (spotifysongName === '') {
    alert("곡이름을 입력해주세요");
    return;
  }
  if (spotifygenre === '' || hardest === "") {
    alert("장르 혹은 난이도를 넣어주세요");
    return;
  }

  if (midifile === null|| sheetfile === null || spotifyimage.file === '') {
    alert("midi파일 ,악보파일 혹은 앨범파일을 넣어주세요");
    return;
  }


  if (currencyWon === ''|| currencyUSD === '' || currencyJPY === '' || currencyEUR === '') {
    alert("가격을 입력해주세요");
    return;
  }

    // event.preventDefault();
    const songname = capitalizeAllWords(spotifysongName);
    const songnames = createNamesArrayWithCaptitalizedWords(songname);
    const artistname = capitalizeAllWords(query);
    const Artistnames = createartistNamesArrayWithCaptitalizedWords(artistname);

    const data = {
      songEdit: songedit,
      songnames,
      songName: songname,
      Artistnames,
      artistName: artistname,
      genres: spotifygenre,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      youtubeUrl: youtubeinfo,
      Discription: discrpction_EDIT_content,
      Elbum_url: spotifyimage.url,
      uid: user.user?.uid,
      photoURL: user.user?.photoURL,
      displayName: user.user?.displayName,
      hardest: hardest,
      update: 0,
      rating: 2,
      faviorite: 0,
      lookup: 0,
      KOR: currencyWon,
      USD: currencyUSD,
      JPY: currencyJPY,
      EUR: currencyEUR,
      CNY: currencyCNY,

      // uploadArtist: user.artist_id,
    };

    const CopyRight_data = {
      songName: songname,
      artistName: artistname,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      Elbum_url: spotifyimage.url,
      photoURL: user.user?.photoURL,
      KOR: 0,
      USD: 0,
      JPY: 0,
      EUR: 0,
      CNY: 0,
      CopyRight_uid:"",
      User_uid: user.user?.uid,
    };


      (async () => {
        const uploadMidi = storageService
          .ref(`songs/midi/${autoid + midifile.name}`)
          .put(midifile);
        const uploadSheet = storageService
          .ref(`songs/sheet/${autoid + sheetfile.name }`)
          .put(sheetfile);
        const uploadElbumImage = storageService
          .ref(`songs/Elbum/${autoid + spotifyimage.name}`)
          .put(spotifyimage.file);
          


        try {
          const id = dbService.collection("Song_All").doc(autoid);
          const CopyRight = dbService.collection("Song_CopyRight").doc(autoid);

          await Upload_SongAll_firebase(midifile,sheetfile, spotifyimage,  uploadMidi, uploadSheet, uploadElbumImage, data, id , autoid );
          await Upload_SongCopyRight(spotifyimage, uploadElbumImage, CopyRight_data, CopyRight , autoid);
        
          await id.set(data);
          await CopyRight.set(CopyRight_data);
          await ArtistUser_Song_Plus(user);  
         
        } catch (e) {
          throw e.message;
        }
      })();
  
  };

  return (
    <div class="product_upload_dashboard">
      <div class="product_upload_side_container">
        <ArtistSideBar/>

        <Grid width="86%">

     
          {/* <form
            ref={formRef}
            onSubmit={handleAddSongForm}
            className="admin__form"
            autoComplete="off"
          > */}
            <div class="card_Product_upload">
              <Grid paddingL="30px" paddingR="30px">
                <Text bold  textalign="left" display="block" size="21px"  marginT="24px" color="#434343">Song Upload</Text>
                <Grid width="100%" bdrBottom="1px solid #EAEAEA" marginT="16px" display="flex"></Grid>
              </Grid>
              {/* <div class="mainCircle">UpLoad</div> */}
              <Grid paddingL="20px" paddingR="20px" paddingB="20px">
                <Grid  position="relative" width="100%" margin="40px auto" display="flex" paddingT="0px"  row>
                  <Grid width="65%" >
                      <div>
                        <div class="boxIn_first">
                          <div class="t_name">
                            {" "}
                            원곡 유무 <font color="red">*</font>
                          </div>
                          <Grid width="100%" display="flex">
                          <Input2
                            check_product
                            checked={songedit.NoEdit}
                            _onClick={() => {
                              setsongedit({ NoEdit: !songedit.NoEdit ,SomeEdit:false , Edit: false  });
                              setsongedit_disabled({
                                SomeEdit: !songedit_disabled.SomeEdit,
                                Edit: !songedit_disabled.Edit,
                              });
                            }}
                            disabled={songedit_disabled.NoEdit}
                            desc2="원곡"
                            marginT="15px"
                            height="30px"
                          />
                          <Input2
                            check_product
                            checked={songedit.SomeEdit}
                            _onClick={() => {
                              setsongedit({ SomeEdit: !songedit.SomeEdit ,NoEdit: false, Edit: false });
                              setsongedit_disabled({
                                NoEdit: !songedit_disabled.NoEdit,
                                Edit: !songedit_disabled.Edit,
                              });
                            }}
                            disabled={songedit_disabled.SomeEdit}
                            desc2="편곡"
                            marginL="32px"
                            marginT="15px"
                            height="30px"

                          />

                          <Input2
                            check_product
                            checked={songedit.Edit}
                            _onClick={() => {
                              setsongedit({ Edit: !songedit.Edit ,SomeEdit: false ,NoEdit: false,});
                              setsongedit_disabled({
                                SomeEdit: !songedit_disabled.SomeEdit,
                                NoEdit: !songedit_disabled.NoEdit,
                              });
                            }}
                            disabled={songedit_disabled.Edit}
                            desc2="작곡"
                            marginL="32px"
                            marginT="15px"
                            height="30px"
                          />
                          </Grid>
                        </div>
                      </div>

                      <div>
                        <td class="boxIn">
                          <div class="t_name">
                            아티스트 명<font color="red">*</font>
                          </div>
                          <div class="t_value">
                            <SearchBox>
                              <SearchInput
                                type="text"
                                placeholder={artistLabel}
                                onChange={(e) => {
                                  setquery(e.target.value);
                                }}
                                // onBlur={checkNickname}
                                name="displayName"
                                // inputProps={query}
                                value={query}
                              />
                            </SearchBox>
                            <SearchIcon
                              className="product_upload_search"
                              onClick={() => search()}
                            />

              
                            <FormControl
                              className={classes.artistinput_example}
                            >
                              <InputLabel id="demo-simple-select-label">
                                {artistSelect}
                              </InputLabel>
                              <Select
                                labelId="demo-mutiple-name-label"
                                value={ArtistFirst}
                                onChange={handleArtistChange}
                                disabled={songedit.Edit}
                                input={
                                  <OutlinedInput
                                    disableUnderline={false}
                                    classes={outlinedInputClasses}
                                  />
                                }
                              >
                                {spotifyartist.map((doc) => (
                                  <option value={doc}>{doc[0].value}</option>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                        </td>
                      </div>

                      <div>
                        <td class="boxIn">
                          <td class="t_name">
                            곡 명 <font color="red">*</font>
                          </td>
                          <td class="t_value">
                            <SearchBox>
                              <SearchInput
                                type="text"
                                placeholder={SongNameLabel}
                                onChange={(e) => {
                                  setspotifysongName(e.target.value);
                                  setpreviewSongName(e.target.value);
                                }}
                                // onBlur={checkNickname}
                                name="songName"
                                inputProps={spotifysongName}
                                value={spotifysongName}
                              />
                            </SearchBox>
                            <SearchIcon
                              className="product_upload_search2"
                              onClick={() => searchSong()}
                            />

                            <FormControl
                              className={classes.artistinput_example}
                            >
                              <InputLabel id="demo-simple-select-label">
                                {SongLabel}
                              </InputLabel>
                              <Select
                                labelId="demo-mutiple-name-label"
                                value={SongFirst}
                                onChange={handletrackChange}
                                disabled={songedit.Edit}
                                input={
                                  <OutlinedInput
                                    disableUnderline={false}
                                    classes={outlinedInputClasses}
                                  />
                                }
                              >
                                {spotifytrack &&
                                  spotifytrack.map((doc) => (
                                    <option value={doc}>{doc[0].name}</option>
                                  ))}
                              </Select>
                            </FormControl>
                          </td>
                        </td>
                      </div>

                      <div>
                        <td class="boxIn">
                          <div class="t_name">
                            장 르 / 난이도<font color="red">*</font>
                          </div>
                          <td class="t_value">
                            <FormControl className={classes.genreinput_example}>
                              <InputLabel id="demo-simple-select-label">
                                {/* {"장르"} */}
                              </InputLabel>
                              <Select
                                labelId="demo-mutiple-name-label"
                                value={spotifygenre}
                                onChange={(e) =>
                                  setspotifygenre(e.target.value)
                                }
                                input={
                                  <OutlinedInput
                                    disableUnderline={false}
                                    classes={outlinedInputClasses2}
                                  />
                                }
                                MenuProps={{
                                  classes: { paper: classes.menuDropBar },
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  getContentAnchorEl: null,
                                }}
                              >
                                {All_Genres.map((doc) => (
                                  <option value={doc} className="genre_option">
                                    {doc}
                                  </option>
                                ))}
                              </Select>
                            </FormControl>

                            <FormControl
                              className={classes.hardest_example}
                            >
                              <InputLabel id="demo-simple-select-label">
                                {/* {hardest} */}
                              </InputLabel>
                              <Select
                                labelId="demo-mutiple-name-label"
                                value={hardest}
                                onChange={(event, values) => sethardest(event.target.value)}
                                input={
                                  <OutlinedInput
                                    disableUnderline={false}
                                    classes={outlinedInputClasses}
                                  />
                                }
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  getContentAnchorEl: null,
                                }}
                              >
                                {hardest100.map((doc) => (
                                  <option value={doc} className="genre_option">
                                    {doc}
                                  </option>
                                ))}
                              </Select>
                            </FormControl>
                          </td>
                        </td>
                      </div>


                  
                  </Grid>
                  <Grid display="flex" column width="35%"  marginL="0px" margin="auto">
                        {spotifyimage && (
                          <img
                            src={spotifyimage.url}
                            alt=""
                            class="spotifyimage_main"
                          />
                        )}

                        <div class="filebox_JPG">
                                  {spotifyimage.file !== "" ? (
                                    <label class="filebox2" for="ex_file3">
                                      jpg / jpeg Only
                                    </label>
                                  ) : (
                                    <label class="filebox3" for="ex_file3">
                                      jpg / jpeg Only
                                    </label>
                                  )}
                                  <input
                                    name="sheetfile"
                                    type="file"
                                    id="ex_file3"
                                    accept="image/*"
                                    onChange={handleImageFile}
                                    disabled={spotifyimage.file !== ""}
                                  />
                                </div>

                                <div class="t_Subvalue">
                                  <div className="fileNameInput">
                                    {spotifyimage.name}
                                  </div>

                                  {/* <td class="sub_tit_alt2">
                                    Jpg, PNG 파일만 업로드해주세요.
                                  </td> */}
                                </div>

                      </Grid>
                </Grid>
              </Grid>
            </div>

            <Grid display="flex" row marginB="0px">
              <Grid borderRadius="15px"   boxshadow="2px 8px 12px #1414140a, 0 1px 3px #14141405, 0 0 0 1px #00000005" marginT="30px" marginL="1%" display="inline-block"
                    textAlign="center" width="60%"  height="520px" >
                <Grid display="flex"  column margin="auto" width="100%"  >
                  <Grid paddingL="30px" paddingR="30px">
                    <Text bold  textalign="left" display="block" size="21px"  marginT="24px" color="#434343">File Upload</Text>
                    <Grid width="100%" bdrBottom="1px solid #EAEAEA" marginT="16px" display="flex"></Grid>
                  </Grid>
                
                 <Grid display= "flex"  row paddingL="30px" paddingR="30px" marginT="42px">
                  <Grid column display="flex" width="65%" margin="48px auto">
                    <Grid display="flex" row>
                         
                              <Text display="inline-block" fontfamily="Tahoma, sans-serif" width="30%" paddingT="10px" bold size="13px">
                                midi 업로드 <font color="red">*</font>
                              </Text>
                              <Grid width="70%">
                                <div class="filebox">
                                  {midi_filedisabled ? (
                                    <label class="filebox2" for="ex_file">
                                      midi / mid Only
                                    </label>
                                  ) : (
                                    <label class="filebox3" for="ex_file">
                                      midi / mid Only
                                    </label>
                                  )}
                                  <input
                                    name="midifile"
                                    type="file"
                                    id="ex_file"
                                    accept=".midi, .mid;"
                                    onChange={handlemidifile}
                                    disabled={midifile !== null}
                                  />
                                </div>
                             
                                <Grid display="flex" justify="left"  paddingL="5%" paddingR="5%">
                                  <Text marginT="12px" size="12px" color="#696969">
                                  {midifileinfo.name}
                                  </Text> 
                               </Grid>

                              </Grid>
                            </Grid>

                           


                         
                            <Grid display="flex" row marginT="45px">
                              <Text display="inline-block" fontfamily="Tahoma, sans-serif" width="30%" bold size="13px">
                                악보파일 업로드 <font color="red">*</font>
                              </Text>
                              <Grid column display="flex" width="70%">
                                <div class="filebox">
                                  {sheet_filedisabled ? (
                                    <label class="filebox2" for="ex_file2">
                                      Sheet Music Only
                                    </label>
                                  ) : (
                                    <label class="filebox3" for="ex_file2">
                                      Sheet Music Only
                                    </label>
                                  )}
                                  <input
                                    name="sheetfile"
                                    type="file"
                                    id="ex_file2"
                                    accept=".pdf;"
                                    onChange={handlsheetfile}
                                    disabled={sheetfile !== null}
                                  />
                                </div>

                                <Grid display="flex" justify="left"  paddingL="5%" paddingR="5%">
                                  <Text marginT="12px" size="12px" color="#696969">
                                  {sheetfileinfo.name}
                                  </Text> 
                               </Grid>

                              </Grid>
                            </Grid>


                          


                        
                            <Grid display="flex" row  marginT="45px">
                              <Text  display="inline-block" fontfamily="Tahoma, sans-serif" width="30%" bold size="13px">Youtube 주소 </Text>
                              <Grid column display="flex" width="70%">
                                <YoutubeBox>
                                  <YoutubeInput
                                    type="text"
                                    placeholder="Youtube영상 주소를 입력해주세요"
                                    onChange={(e) => {
                                      setyoutubeinfo(e.target.value);
                                    }}
                                    // onBlur={checkNickname}
                                    name="YoutubeUrl"
                                    inputProps={youtubeinfo}
                                    value={youtubeinfo}
                                  />
                                </YoutubeBox>
                              </Grid>
                            </Grid>
                        


                  </Grid>
                 {/* <Text class="songNameImage">{previewSongName}</Text>    */}
               
                <Grid width="35%" column>
                 {/* <div className="songNameImage">{previewSongName}</div>   */}

                <Grid marginT = "12px">
                 {sheetfileinfo.url === "" ? (
                      <Grid display="flex" row marginT="0px" marginL="0px">
                        <img
                          src={
                            "https://www.soundlaunch.com/cover_photos/default_cover.jpg"
                          }
                          alt=""
                          class="sheetfileinfo_main"
                        />
                    
                      </Grid>
                    ) : (
                      <Document
                        file={sheetfileinfo.url}
                        onLoadSuccess={onDocumentLoadSuccess}
                        className="container__wrapper"
                        noData={"No PDf"}
                        // onItemClick ={option}
                      >
                        <Grid display="flex" position="relative" row marginT="0px"  >
                          <Page pageNumber={pageNumber} width={pageWidth} />
                          {numPages < 2 ? (
                            <Page
                              noData={""}
                              onLoadSuccess={onDocumentNoData}
                            />
                          ) : (
                          null
                          )}
                        </Grid>
                      </Document>
                    )}
                    </Grid>
                </Grid>
                </Grid>
            





                </Grid>
              </Grid>

              <Grid
                width="37%"
                height="520px"
                marginT="30px"
                marginL="1%"
                textAlign="center"
                position="relative"
                display="flex"
                borderRadius="15px"
                boxshadow="2px 8px 12px #1414140a, 0 1px 3px #14141405"
              >
                <Grid width="100%" display="flex" column >
                <Grid paddingL="30px" paddingR="30px">
                    <Text bold  textalign="left" display="block" size="21px"  marginT="24px" color="#434343">Description / Price</Text>
                    <Grid width="100%" bdrBottom="1px solid #EAEAEA" marginT="16px" display="flex"></Grid>
                  </Grid>
                  <Grid position="relative" width="100%" display="flex" column paddingL="30px" paddingR="30px">
                    <Grid row display="flex" width="100%">
                      {/* <Text textAlign="center"  size="13px"  width="20%"  marginT="120px" color="#333" bold >                        
                        곡 설명<font color="red">*</font>
                      </Text> */}
                      <Grid width="100%">                 
                         <ProductUploadEditor value={discrpction_EDIT_content} onChange={onEditorChange} />
                      </Grid> 
                    </Grid>

                    <Grid row width="100%" display="flex" marginT="42px">
                      {/* <Text  textAlign="center" size="13px" width="150px" marginT="40px" color="#333"  bold>
                        가격<font color="red">*</font>
                      </Text> */}

                      <Select
                        style={{
                          width: "120px",
                          height: "60px",
                          marginBottom: "10px",
                        }}
                        variant="outlined"
                        value={selectedCountry}
                        onChange={(e) => setselectedCountry(e.target.value)}
                        className={classes.PricsStyle}
                        InputProps={{
                          disableUnderline: true,
                          classes: {
                            input: classes.bootstrapInput,
                          },
                        }}
                      >
                        {countryObj &&
                          countryObj.map((doc) => (
                            <option value={doc} key={doc}>
                              {doc}
                            </option>
                          ))}
                      </Select>

                      <NumberFormat
                        name="numberformat"
                        value={price}
                        onChange={handlePriceChange}
                        thousandSeparator={true}
                        className="some"
                        inputmode="numeric"
                        // format="#,#00"
                        format={currencyformat}
                      />
                    </Grid>

                    <Grid  height="40px"    row  display="flex" paddingL="120px"  >
                      <Grid display="flex" width="80px">
                        <Text size="15px" color="rgb(151, 151, 151)">
                          ￦
                        </Text>
                        <Text  size="15px" color="rgb(151, 151, 151)"  marginL="5px">
                          {currencyWon}
                        </Text>
                      </Grid>

                      <Grid display="flex" marginL="12px"  width="80px">
                        <Text size="15px" color="rgb(151, 151, 151)">
                          $
                        </Text>
                        <Text  size="15px" color="rgb(151, 151, 151)"  marginL="5px">
                          {currencyUSD}
                        </Text>
                      </Grid>

                      <Grid display="flex" marginL="12px"  width="80px">
                        <Text size="15px" color="rgb(151, 151, 151)">
                          ¥
                        </Text>
                        <Text  size="15px" color="rgb(151, 151, 151)"  marginL="5px">
                          {currencyJPY}
                        </Text>
                      </Grid>

                      {/* <Grid display="flex" marginL="26px">
                        <Text size="15px" color="rgb(151, 151, 151)">
                          €
                        </Text>
                        <Text  size="15px" color="rgb(151, 151, 151)"  marginL="5px">
                          {currencyEUR}
                        </Text>
                      </Grid>

                      <Grid display="flex" marginL="26px">
                        <Text size="15px" color="rgb(151, 151, 151)">
                          元
                        </Text>
                        <Text  size="15px" color="rgb(151, 151, 151)"  marginL="5px">
                          {currencyCNY}
                        </Text>
                      </Grid> */}
                    </Grid>

                    <Button
                      orange
                      backgroundcolor="#ba4148"
                      borderradius="15px"
                      margin="auto"
                      width="100%"
                      height="54px"
                      _onClick={handleAddSongForm}
                    >
                      UPLOAD
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          {/* </form> */}
        </Grid>
      </div>
    </div>
  );
};

const SearchBox = styled.div`
  ${"" /* border: 1px solid ${Color.Light_3}; */}
  border: 1px solid ${Color.Light_3};

  ${"" /* border-radius: 12px;  */}
  border-top: none;
  border-left: none;
  border-right: none;
  width: 65%;
  height: 45px;
  max-height: 45px;
  margin: 0.8rem 0;
  padding: 0px;
  padding-top: 5px;
  line-height: -0px;
  ${"" /* margin-bottom: 335px; */}
  box-sizing: border-box;
  overflow: hidden;
  align-items: center;
  transition: border 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    box-shadow 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &:focus-within {
    border: 1px solid ${Color.Primary};
    box-shadow: 0 0 0 3px ${Color.Primary}33;
  }
`;

const SearchInput = styled.input`
  height: 100%;
  border: none;
  background-color: transparent;
  box-sizing: border-box;
  letter-spacing: 0.5px;
  overflow: hidden;
  color: rgb(71, 71, 71);
  width: 90%;
  outline: none;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  font-size: 18px;
  line-height: -30px;
  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
    font-size: 12px;
    user-select: none;
  }
`;

const YoutubeBox = styled.div`
  ${"" /* border: 1px solid ${Color.Light_3}; */}
  border: 1px solid ${Color.Light_3};

  ${"" /* border-radius: 12px;  */}
  border-top: none;
  border-left: none;
  border-right: none;
  width: 90%;
  ${'' /* height: 45px; */}
  ${'' /* max-height: 45px; */}
  justify:center;
  margin:auto;
  ${'' /* margin: 0.2rem 0; */}
  padding: 0px;
  ${'' /* padding-top: 5px; */}
  line-height: -0px;
  ${"" /* margin-bottom: 335px; */}
  box-sizing: border-box;
  overflow: hidden;
  align-items: center;
  transition: border 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    box-shadow 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &:focus-within {
    border: 1px solid ${Color.Primary};
    box-shadow: 0 0 0 3px ${Color.Primary}33;
  }
`;

const YoutubeInput = styled.input`
  height: 100%;
  border: none;
  background-color: transparent;
  box-sizing: border-box;
  letter-spacing: 0.5px;
  overflow: hidden;
  color: rgb(71, 71, 71);
  width: 90%;
  outline: none;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  font-size: 18px;
  line-height: -30px;
  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
    font-size: 12px;
    user-select: none;
  }
`;

export default Product_upload;
