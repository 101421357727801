import { createAction, handleActions } from "redux-actions";
import axios from "axios";
import { setLocal, deleteLocal } from "../../shared/Local";

 const BOARD_MANAGEMENT_SONG = "BOARD_MANAGEMENT_SONG";
 const BOARD_MANAGEMENT_REMOVESONG = "BOARD_MANAGEMENT_REMOVESONG";

const initialState = {
  Song: "",
};

const set_BOARD_MANAGEMENT_Song = (song) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: BOARD_MANAGEMENT_SONG,
      Song : song,
    });  
  };
};

const remove_Song = (song) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: BOARD_MANAGEMENT_REMOVESONG,
      Song : song,
    });  
  };
};




const BoardManagement = (state = initialState, action) => {
  switch (action.type) {
  
      case BOARD_MANAGEMENT_SONG:
        return {
          ...state,
          Song : action.Song,
        }

        case BOARD_MANAGEMENT_REMOVESONG:     
        const index = state.Song.findIndex(
          (SongItem) => SongItem.id=== action.Song.id
          
        );

          // console.log(state.basket.findIndex((basketItem) =>  basketItem.id=== action.basket.id))

        let newSong = [...state.Song];
   
        if (index >= 0) {
          newSong.splice(index, 1);
  
        } else {
          console.warn(
            `Cant remove product (id: ${action.Song.id}) as its not in basket!`
          )
        }
       return {
            ...state,
            Song: newSong 
          }


    default:
      return state
  }
};

export default BoardManagement;


const actionCreators = {
  set_BOARD_MANAGEMENT_Song,
  remove_Song,
};

export { actionCreators };
