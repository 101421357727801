import React, { useRef, useMemo, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import { ModalContainer } from "../../components/index";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { Avatar } from "@material-ui/core";
import { actionCreators as SearchResultActions } from "../../redux/modules/SearchResult";
import { Grid, Button, Text, Input2 } from "../../elements/index";
import "./Footer.css";


const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1367 });
  return isDesktop ? children : null;
};

const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 916, maxWidth: 1366 });
  return isTablet ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 915 });
  return isMobile ? children : null;
};


const Footer = (props) => {
  const dispatch = useDispatch();
  const [currentLocation, setCurrentLocation] = useState("/");
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const { showHeader } = props;
  const [keyword, setKeyword] = useState("");
  const [mainct, setMainct] = useState("");
  const [subct, setSubct] = useState("");
  const user = useSelector((state) => state.user);
	const { t, i18n } = useTranslation();
  const isDesktop = useMediaQuery({ minWidth: 1024 });


  const SearchProduct = () => {
    dispatch(SearchResultActions.setSearch_Keyword(keyword));
    history.push("/Search/Result");
    setKeyword("");
  };

  const SearchProduct2 = () => {
  
  };


  return (
    <>
      <Desktop>
      <DesktopFooterWrap>
         <Grid justify="center" width="70%" display="flex" column margin="auto">
            <Grid display='flex'  width="100%"  marginT="5px" marginB="12px" bdrBottom="1px solid rgba(255, 255, 255, 0.534)" paddingB="15px">
              <Text lineHeight="28px" width="90px" color="#fff" size="18px" bold texttransform="uppercase">Legal</Text>
              <Text  TextClick onClick={()=>  {history.push("/Support/Policy")}}  hoverColor="rgb(255, 127, 127)"  lineHeight="28px" color="#fff" size="12px"  >이용약관</Text>
              <Text  TextClick onClick={()=>  {history.push("/Support/Privacy")}} lineHeight="28px"  hoverColor="rgb(255, 127, 127)" color="#fff" size="12px" marginL="30px">개인정보보호정책</Text>
              <Text  TextClick onClick={()=>  {history.push("/Support/copyright")}} lineHeight="28px" whoverColor="rgb(255, 127, 127)" color="#fff" size="12px" marginL="30px">Copyright</Text>
              <Text  TextClick onClick={()=>  {history.push("/ArtistJoin")}} lineHeight="28px"  hoverColor="rgb(255, 127, 127)"  color="#fff" size="12px"  marginL="30px">아티스트 가입</Text>
              <Text  TextClick onClick={()=>  {history.push("/Support/copyright")}} lineHeight="28px" hoverColor="rgb(255, 127, 127)" color="#fff" size="12px" marginL="30px">Email문의</Text>
            </Grid>

            <Grid display="flex" width="100%">
            <Grid display='flex' column width="70%"  marginT="5px" marginL="0px">
              <Text lineHeight="28px"  marginR="10px" textAlign="left" width="150px" color="#fff" size="18px" bold texttransform="uppercase">about us</Text>

              <Text  lineHeight="28px"  marginT="5px"  color="#fff" size="12px"  >어뮤니티 &nbsp; 대표자 : 김동현 &nbsp; 주소 : 충민로 5 c동 505호</Text>
              <Text  lineHeight="28px"  marginT="5px"  color="#fff" size="12px"  >사업자등록번호 : 626-40-00870  &nbsp; 통신판매업신고 : 제 2021-서울송파-0000호</Text>
              </Grid>

              <Grid display='flex' column width="30%"  marginT="5px">
                <Text  width="150px" color="#fff" size="18px" bold texttransform="uppercase">로고</Text>
            </Grid>

            </Grid>
   
         </Grid>
        </DesktopFooterWrap>
      </Desktop>

      <Tablet>
      <TabletFooterWrap>
         <Grid justify="center" width="90%" display="flex" column margin="auto">
            <Grid display='flex'  width="100%"  marginT="5px" marginB="12px" bdrBottom="1px solid rgba(255, 255, 255, 0.534)" paddingB="15px">
              <Text lineHeight="28px" width="90px" color="#fff" size="18px" bold texttransform="uppercase">Legal</Text>
              <Text  TextClick onClick={()=>  {history.push("/Support/Policy")}}  hoverColor="rgb(255, 127, 127)"  lineHeight="28px" color="#fff" size="12px"  >이용약관</Text>
              <Text  TextClick onClick={()=>  {history.push("/Support/Privacy")}} lineHeight="28px"  hoverColor="rgb(255, 127, 127)" color="#fff" size="12px" marginL="30px">개인정보보호정책</Text>
              <Text  TextClick onClick={()=>  {history.push("/Support/copyright")}} lineHeight="28px" whoverColor="rgb(255, 127, 127)" color="#fff" size="12px" marginL="30px">Copyright</Text>
              <Text  TextClick onClick={()=>  {history.push("/ArtistJoin")}} lineHeight="28px"  hoverColor="rgb(255, 127, 127)"  color="#fff" size="12px"  marginL="30px">아티스트 가입</Text>
              <Text  TextClick onClick={()=>  {history.push("/Support/copyright")}} lineHeight="28px" hoverColor="rgb(255, 127, 127)" color="#fff" size="12px" marginL="30px">Email문의</Text>
            </Grid>

            <Grid display="flex" width="100%">
            <Grid display='flex' column width="70%"  marginT="5px" marginL="0px">
              <Text lineHeight="28px"  marginR="10px" textAlign="left" width="150px" color="#fff" size="18px" bold texttransform="uppercase">about us</Text>

              <Text  lineHeight="28px"  marginT="5px"  color="#fff" size="12px"  >어뮤니티 &nbsp; 대표자 : 김동현</Text>
              <Text  lineHeight="28px"  marginT="5px"  color="#fff" size="12px"  >사업자등록번호 : 626-40-00870  &nbsp; 통신판매업신고 : 제 2021-서울송파-0000호</Text>
              </Grid>

              <Grid display='flex' column width="30%"  marginT="5px">
                <Text  width="150px" color="#fff" size="18px" bold texttransform="uppercase">로고</Text>
            </Grid>

            </Grid>
   
         </Grid>
        </TabletFooterWrap>
      </Tablet>

      <Mobile>
      <MobileFooterWrap>
         <Grid justify="center" width="90%" display="flex" column margin="auto">
            <Grid display='flex'  width="100%"  marginT="5px"  paddingB="5px">
              <Text lineHeight="21px" width="70px" color="#fff" size="15px" bold texttransform="uppercase">Legal</Text>
              <Text  TextClick onClick={()=>  {history.push("/Support/Policy")}}  hoverColor="rgb(255, 127, 127)"  lineHeight="21px" color="#fff" size="11px"  >이용약관</Text>
              <Text  TextClick onClick={()=>  {history.push("/Support/Privacy")}} lineHeight="21px"  hoverColor="rgb(255, 127, 127)" color="#fff" size="11px" marginL="5%">개인정보보호정책</Text>
              <Text  TextClick onClick={()=>  {history.push("/Support/copyright")}} lineHeight="21px" whoverColor="rgb(255, 127, 127)" color="#fff" size="11px" marginL="5%">Copyright</Text>
            </Grid>
            <Grid display='flex'  width="100%"  marginT="5px" marginB="5px" bdrBottom="1px solid rgba(255, 255, 255, 0.534)" paddingB="10px">
              <Text  TextClick onClick={()=>  {history.push("/ArtistJoin")}} lineHeight="21px"  hoverColor="rgb(255, 127, 127)"  color="#fff" size="11px"  marginL="70px">아티스트 가입</Text>
              <Text  TextClick onClick={()=>  {history.push("/Support/copyright")}} lineHeight="21px" hoverColor="rgb(255, 127, 127)" color="#fff" size="11px" marginL="5%">Email문의</Text>
            </Grid>

            <Grid display="flex" width="100%">
            <Grid display='flex' column width="70%"  marginT="5px" marginL="0px">
              <Text lineHeight="28px"  marginR="10px" textAlign="left" width="150px" color="#fff" size="15px" bold texttransform="uppercase">about us</Text>
              <Text  lineHeight="28px"  marginT="5px"  color="#fff" size="11px"  >어뮤니티 &nbsp; 대표자 : 김동현</Text>
              <Text  lineHeight="28px"  marginT="5px"  color="#fff" size="11px"  >사업자등록번호 : 626-40-00870  &nbsp; 통신판매업신고 : 제 2021-서울송파-0000호</Text>
              </Grid>

              <Grid display='flex' column width="30%"  marginT="5px">
                <Text  width="150px" color="#fff" size="15px" bold texttransform="uppercase">logo</Text>
            </Grid>

            </Grid>
   
         </Grid>
        </MobileFooterWrap>
      </Mobile>

    </>
  );
};



	

const DesktopFooterWrap = styled.footer`
  @media only screen and (min-width: 1367px) {
    ${'' /* max-width: 100%; */}
    width: 100%;
    left: 0;
    right: 0;
    ${'' /* height: 150px; */}
    bottom: 0;
    z-index: 10;
    background: rgb(95, 95, 95);
    position: relative;
    ${(props) => (props.display === false ? "display : none;" : "display : flex;")}
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 16px 16px 16px;
    box-sizing: border-box;
    margin:auto;
    display:flex;
  }
`;

const TabletFooterWrap = styled.footer`
  @media only screen and (max-width: 1366px) and (min-width:916px){
    ${'' /* max-width: 100%; */}
    width: 100%;
    left: 0;
    right: 0;
    ${'' /* height: 150px; */}
    bottom: 0;
    z-index: 10;
    background: rgb(95, 95, 95);
    position: relative;
    ${(props) => (props.display === false ? "display : none;" : "display : flex;")}
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 16px 16px 16px;
    box-sizing: border-box;
    margin:auto;
    display:flex;
  }
`;

const MobileFooterWrap = styled.footer`
  @media only screen and (max-width: 915px){
    ${'' /* max-width: 100%; */}
    width: 100%;
    left: 0;
    right: 0;
    ${'' /* height: 150px; */}
    bottom: 0;
    z-index: 10;
    background: rgb(95, 95, 95);
    position: relative;
    ${(props) => (props.display === false ? "display : none;" : "display : flex;")}
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 16px 16px 16px;
    box-sizing: border-box;
    margin:auto;
    display:flex;
  }
`;

export default Footer;
