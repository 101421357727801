import "./App.css";
import { useSelector, useDispatch  } from "react-redux";
import { Link, useParams } from "react-router-dom";

import styled from "styled-components";
import {
  Header,
  MyHome,
  ArtistJoin,
  ProductUpload,
  MainPage,
  HardisestNormal,
  HardisestEasy,
  HardisestVeryEasy,
  HardisestHARD,
  HardisestVeryHard,
  GlobalHot100,
  GlobalNew100, 
  ArtistProduct,
  PopChart,
  KPopChart,
  JPopChart,
  ClassicChart,
  AniChart,
  OSTChart,
  ROCKMETALChart,
  DANCEELECChart,
  MEDLEYChart,
  ETCChart,
  ProductBasket,
  CheckOut,
  CheckoutResult,
  PurchaseProduct,
  SearchResult,
  ArtistMainPage,
  Policy,
  Privacy,
  copyright,
  Notice,
  NoticeWrite,
  NoticeSearch,
  BoardManagement,
  Question,
  QuestionWrite,
  QuestionSearch,
  ArtistProductEDIT,
  CalculateAmount,
  PaymentHistory,
  CopyrightADMIN,
} from "../pages/index";
import React, { useEffect , useState } from "react";
import { authService, dbService } from "./firebase";
import { actionCreators as userActions } from "../redux/modules/user";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch, Redirect } from "react-router-dom";
import { Footer } from "../components/index";

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { Artistid  , id} = useParams();
  const [usersong, setusersong] = useState([]);
  const [token, setToken] =useState('');

  useEffect(() => {
    const listener = authService.onAuthStateChanged((authUser) => {
      if (authUser) {

            authUser.getIdToken().then((token) => {
              // console.log(token)
              setToken(token)
            })

        dbService
          .collection("user_Public")
          .doc(authUser.uid)
          .get()
          .then((userFromDB) => {
            if (userFromDB.exists) {
              const data = {
                email: userFromDB.data()?.email,
                role: userFromDB.data()?.role,
                uid: userFromDB.data()?.uid,
                Artist_subscribe_uid: userFromDB.data()?.Artist_subscribe_uid,
                displayName: userFromDB.data()?.displayName,
                photoURL: userFromDB.data()?.photoURL,
                user_artist_subscribe: userFromDB.data()?.user_artist_subscribe,
                user_download_song: userFromDB.data()?.user_download_song,
                user_shopping_basket: userFromDB.data()?.user_shopping_basket,
                artistuser_country: userFromDB.data()?.artistuser_country,
                artistuser_contact_email: userFromDB.data()?.artistuser_contact_email,   
                artistuser_subscribe: userFromDB.data()?.artistuser_subscribe,
                artistuser_song: userFromDB.data()?.artistuser_song,
                artistuser_update: userFromDB.data()?.artistuser_update,
                artistuser_amount: userFromDB.data()?.artistuser_amount,
                artistuser_youTube: userFromDB.data()?.artistuser_youTube,
                artistuser_bank: userFromDB.data()?.artistuser_bank,
                artistuser_bankAddress:
                  userFromDB.data()?.artistuser_bankAddress,
                artistuser_PaypalAddress:
                  userFromDB.data()?.artistuser_PaypalAddress,
                artistuser_description:
                  userFromDB.data()?.artistuser_description,
              };

              dispatch(userActions.userdb(data));

            }

       

          });
      } else {
        dispatch(userActions.no_userdb());
      }
    });
    return () => listener();
  }, [dispatch]);


    //////////배치바꾸기 
    // 잘리는거해결
    //  .firebaserc
  return (
    <Wrap>
      <Header
      // showHeader={header_display
      />
      <Switch>
      {/* <Route path="/Copyright_ADMIN" exact component={CopyrightADMIN}>
        
             <CopyrightADMIN  token={token} />
      </Route> */}
  
      
        <Route path="/" exact component={MainPage} />
        
        <Route path="/MyHome" exact component={MyHome} >
            {user?.user === null ? <Redirect to="/" /> : <MyHome/>}
        </Route>

        <Route path="/MyHome/ProductUpload" exact component={ProductUpload}>
            {user.user?.role === 'Artist' || user.user?.role === "Administrator"  ? <ProductUpload/> : <Redirect to="/" />}
        </Route>

        <Route path="/MyHome/BoardManagement/:board" exact component={BoardManagement}>
            {user.user?.role === 'Artist' || user.user?.role === "Administrator"  ? <BoardManagement/> : <Redirect to="/" />}
        </Route>

        <Route path="/MyHome/CalculateAmount/:CalculateArtistid" exact component={CalculateAmount}>
            {user.user?.role === 'Artist' || user.user?.role === "Administrator"   ? <CalculateAmount/> : <Redirect to="/" />}
        </Route>

        <Route path="/MyHome/PaymentHistory/:PaymentHistory" exact component={PaymentHistory}>
            {user.user?.role === 'Artist' || user.user?.role === "Administrator" || user.user?.role === "Public"  ? <PaymentHistory/> : <Redirect to="/" />}
        </Route>


        
        <Route path="/Song/PlayChart/Normal" exact component={HardisestNormal}/> 
        <Route path="/Song/PlayChart/Easy" exact component={HardisestEasy}/> 
        <Route path="/Song/PlayChart/VeryEasy" exact component={HardisestVeryEasy}/> 
        <Route path="/Song/PlayChart/HARD" exact component={HardisestHARD}/> 
        <Route path="/Song/PlayChart/VeryHard" exact component={HardisestVeryHard}/> 
        <Route path="/Song/PlayChart/GlobalHot100" exact component={GlobalHot100}/> 
        <Route path="/Song/PlayChart/GlobalNew100" exact component={GlobalNew100}/>       
        <Route path="/Song/Genre/Pop" exact component={PopChart} />
        <Route path="/Song/Genre/KPop" exact component={KPopChart} />
        <Route path="/Song/Genre/JPop" exact component={JPopChart} />
        <Route path="/Song/Genre/Classic" exact component={ClassicChart} />
        <Route path="/Song/Genre/Ani" exact component={AniChart} />
        <Route path="/Song/Genre/OST" exact component={OSTChart} />
        <Route path="/Song/Genre/ROCKMETAL" exact component={ROCKMETALChart} />
        <Route path="/Song/Genre/DANCEELEC" exact component={DANCEELECChart} />
        <Route path="/Song/Genre/MEDLEY" exact component={MEDLEYChart} />
        <Route path="/Song/Genre/ETC" exact component={ETCChart} />
        <Route path="/Checkout/ProductBasket" exact component={ProductBasket} />
        <Route path="/Payment/Checkout" exact component={CheckOut} />
        <Route path="/Payment/CheckoutResult" exact component={CheckoutResult} />
        <Route path="/Product/PurchaseProduct" exact component={PurchaseProduct} />
        <Route path="/Search/Result" exact component={SearchResult} />

        <Route path="/Artist/main/:Artist" exact component={ArtistMainPage}>
            {user.user?.role === 'Artist' || user.user?.role === "Administrator"  ? <ArtistMainPage/> : <Redirect to="/" />}
        </Route>

        <Route path="/MyHome/PaymentHistory/:PaymentHistory" exact component={PaymentHistory}>
            {user.user?.role === 'Artist' || user.user?.role === "Administrator"  ? <PaymentHistory/> : <Redirect to="/" />}
        </Route>

        <Route path="/Support/Policy" exact component={Policy} />
        <Route path="/Support/Privacy" exact component={Privacy} />
        <Route path="/Support/copyright" exact component={copyright} />
        <Route path="/Home/Notice" exact component={Notice} />

        <Route path="/Home/NoticeWrite" exact component={NoticeWrite}>
            {user.user?.role !== "Administrator"  ?   <Redirect to="/" />: <NoticeWrite/>}
        </Route>
      
        <Route path="/Home/NoticeSearch/:content" exact component={NoticeSearch} />
        <Route path="/Home/Question" exact component={Question} />
        <Route path="/Home/QuestionWrite" exact component={QuestionWrite} />
        <Route path="/Home/QuestionSearch/:Questioncontent" exact component={QuestionSearch} />

        <Route path="/ArtistJoin" exact component={ArtistJoin}>
          {user.user?.role === "Artist"  || user.user?.role === "Administrator" ? <Redirect to="/" /> : <ArtistJoin />}
        </Route>

        <Route path="/Artist_product/:id" exact component={ArtistProduct} />
        <Route path="/Artist_productEDIT/:Artistid/:Productid" exact component={ArtistProductEDIT}>

        </Route>
       
    
      </Switch>
      {/* <Footer/> */}
      
    </Wrap>
  );
}

const Wrap = styled.div`
  margin: 0 auto;
  max-width: 100%;
  position: relative;
  /* border: 10px solid red; */
`;

export default App;
