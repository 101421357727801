import React from "react";
import styled from "styled-components";

// import { Text } from "elements/";
import { Color } from "../shared/DesignSys";

const Button = (props) => {
  // disabled : 비활성화 모드
  const {
    main,
    sub,
    social,
    waiting,
    _onClick,
    text,
    children,
    width,
    height,
    margin,
    marginB,
    marginT,
    marginL,
    marginR,
    padding,
    paddingB,
    paddingT,
    paddingL,
    paddingR,
    disabled,
    needLogin,
    noflex,
    token,
    size,
    color,
    justify,
    backgroundcolor,
    bordercolor,
    float,
    orange,
    MainPage,
    Edit,
    display,
    borderradius,
    texttransform,
  } = props;
  const styles = {
    onClick: _onClick,
    text: text,
    width: width,
    height: height,
    margin: margin,
    marginB: marginB,
    marginT: marginT,
    marginL: marginL,
    marginR: marginR,
    padding: padding,
    paddingB : paddingB,
    paddingT : paddingT,
    paddingL : paddingL,
    paddingR : paddingR,
    justify: justify,
    noflex: noflex,
    display: display,
    size: size,
    color:color,
    backgroundcolor:backgroundcolor,
    bordercolor:bordercolor,
    float:float,
    borderradius:borderradius,
    texttransform:texttransform,
  };

  if (disabled) {
    return (
      <BtnDisabled color={Color.Light_4} contrast={Color.Light_1} {...styles}>
        {text ? text : children}
      </BtnDisabled>
    );
  }

  if (needLogin) {
    return (
      <BtnNotLogin
        color={Color.Secondary_2}
        contrast={Color.Secondary_1}
        {...styles}
      >
        {text ? text : children}
      </BtnNotLogin>
    );
  }

  return (
    <>
      {sub ? (
        <Btn color="white" contrast={Color.Primary} {...styles}>
          {text ? text : children}
        </Btn>
      ) : main ? (
        <Btn color={Color.Primary} contrast="white" {...styles}>
          {text ? text : children}
        </Btn>
      ) : waiting ? (
        <BtnGradient color={Color.Primary} contrast="white" {...styles}>
          {text ? text : children}
        </BtnGradient>
      ) : social ? (
        <SocialBtn color={"#fff"} contrast="white" {...styles}>
          {text ? text : children}
        </SocialBtn>
      ) : token ? (
        <TokenBtn  {...styles}>
          {text ? text : children}
        </TokenBtn>
      ): orange ? (
        <OrangeBtn contrast="#fff" {...styles}>
          {text ? text : children}
        </OrangeBtn>
      ): MainPage ? (
        <RoundWhiteBtn contrast="#000000" {...styles}>
          {text ? text : children}
        </RoundWhiteBtn>
      ): Edit ? (
        <EditBtn contrast="#000000" {...styles}>
          {text ? text : children}
        </EditBtn>
      ):(
        <Btn color={Color.Primary} contrast="white" {...styles}>
          {text ? text : children}
        </Btn>
      )}
    </>
  );
};

Button.defaultProps = {
  children: "I'm Button!",
  _onClick: () => {},
  margin: false,
  marginB: false,
  marginT: false,
  marginL: false,
  width: "max-content",
  height: "45px",
  size: false,
};


const TokenBtn = styled.button`
  user-select: none;
  outline: none;

  background-color: ${(props) => props.backgroundcolor};
  border:  ${(props) => (props.border ? props.border : props.backgroundcolor)};
  ${'' /* border-color:   ${(props) => (props.bordercolor ? props.bordercolor : "")}; */}
  
  border-radius: 6px;
  margin-bottom: ${(props) => props.marginB};
  margin-top: ${(props) => props.marginT};
  margin-left: ${(props) => props.marginL};
  margin-right: ${(props) => props.marginR};
  color: ${(props) => props.color};
  font-size: ${(props) => (props.size ? props.size : "17px")};
  font-weight: 400;

  ${(props) => (props.noflex ? "" : "flex-grow: 1;")};

  height: ${(props) => props.height};
  width: ${(props) => props.width};
  box-sizing: border-box;

  margin: ${(props) => props.margin};

  cursor: pointer;
  transition: color 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    background-color 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    border 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    box-shadow 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  &:hover {
    background-color: ${(props) => props.backgroundcolor};
    color: ${(props) => props.color};
    border: 1px solid ${Color.Primary};
    box-shadow: 0 0 0 3px ${Color.Primary}33;
    svg {
      color: ${(props) => props.color};
    }
  }

  &:active {
    box-shadow: 0 0 0 3px ${Color.Primary}33;
  }
  svg {
    color: ${(props) => props.contrast};
  }

  @media only screen and (max-width: 767px) {
    &:hover {
      background-color: ${(props) => props.color};
      color: ${(props) => props.contrast};
      border: 1px solid ${Color.Primary};
      box-shadow: 0 0 0 3px ${Color.Primary}00;
      svg {
        color: ${(props) => props.color};
      }
    }

    &:active {
      background-color: ${(props) => props.contrast};
      color: ${(props) => props.color};
      border: 1px solid ${Color.Primary};
      box-shadow: 0 0 0 3px ${Color.Primary}33;
      svg {
        color: ${(props) => props.color};
      }
    }
  }
`;




const Btn = styled.button`
  user-select: none;
  outline: none;

  background-color:  ${Color.Primary};
  border: 1px solid ${Color.Primary};
  border-radius: 12px;
  margin-bottom: ${(props) => props.marginB};
  margin-top: ${(props) => props.marginT};
  margin-left: ${(props) => props.marginL};
  color: ${(props) => props.contrast};
  font-size: ${(props) => (props.size ? props.size : "17px")};
  font-weight: 700;
  float: ${(props) => props.float};

  ${(props) => (props.noflex ? "" : "flex-grow: 1;")};

  height: ${(props) => props.height};
  width: ${(props) => props.width};
  box-sizing: border-box;

  margin: ${(props) => props.margin};

  cursor: pointer;
  transition: color 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    background-color 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    border 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    box-shadow 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  &:hover {
    background-color: ${(props) => props.contrast};
    color: ${(props) => props.color};
    border: 1px solid ${Color.Primary};
    box-shadow: 0 0 0 3px ${Color.Primary}33;
    svg {
      color: ${(props) => props.color};
    }
  }

  &:active {
    box-shadow: 0 0 0 3px ${Color.Primary}33;
  }
  svg {
    color: ${(props) => props.contrast};
  }

  @media only screen and (max-width: 767px) {
    &:hover {
      background-color: ${(props) => props.color};
      color: ${(props) => props.contrast};
      border: 1px solid ${Color.Primary};
      box-shadow: 0 0 0 3px ${Color.Primary}00;
      svg {
        color: ${(props) => props.color};
      }
    }

    &:active {
      background-color: ${(props) => props.contrast};
      color: ${(props) => props.color};
      border: 1px solid ${Color.Primary};
      box-shadow: 0 0 0 3px ${Color.Primary}33;
      svg {
        color: ${(props) => props.color};
      }
    }
  }
`;

const SocialBtn = styled.button`
  user-select: none;
  outline: none;
  margin-bottom: ${(props) => props.marginB};
  margin-top: ${(props) => props.marginT};
  margin-left: ${(props) => props.marginL};
  background-color: ${(props) => Color.White_1};
  border: 1px solid rgba(78, 78, 78, 0.171);
  border-radius: 3px;

  ${"" /* color: ${(props) => props.contrast}; */}
  color: rgb(63, 63, 63);
  font-size: ${(props) => (props.size ? props.size : "14px")};
  font-weight: 400;

  ${(props) => (props.noflex ? "" : "flex-grow: 1;")};

  height: ${(props) => props.height};
  width: ${(props) => props.width};
  box-sizing: border-box;

  margin: ${(props) => props.margin};

  cursor: pointer;
  transition: color 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    background-color 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    border 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    box-shadow 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  &:hover {
    background-color: ${(props) => Color.contrast};
    color: rgb(63, 63, 63);
    border: 1px solid ${Color.Primary};
    box-shadow: 0 0 0 3px ${Color.Primary}33;
    svg {
      color: ${(props) => props.color};
    }
  }

  &:active {
    box-shadow: 0 0 0 3px ${Color.Primary}33;
  }
  svg {
    color: ${(props) => props.contrast};
  }

  @media only screen and (max-width: 767px) {
    &:hover {
      background-color: ${(props) => props.color};
      color: ${(props) => props.contrast};
      border: 1px solid ${Color.Primary};
      box-shadow: 0 0 0 3px ${Color.Primary}00;
      svg {
        color: ${(props) => props.color};
      }
    }

    &:active {
      background-color: ${(props) => props.contrast};
      color: ${(props) => props.color};
      border: 1px solid ${Color.Primary};
      box-shadow: 0 0 0 3px ${Color.Primary}33;
      svg {
        color: ${(props) => props.color};
      }
    }
  }
`;

const BtnGradient = styled.button`
  user-select: none;
  outline: none;

  /* background: -moz-linear-gradient(-45deg, ${Color.Primary} 0%, ${Color.Secondary_1} 100%); FF3.6-15 */
  /* background: -webkit-linear-gradient(-45deg, ${Color.Primary} 0%, ${Color.Secondary_1} 100%); Chrome10-25,Safari5.1-6 */
  /* background: linear-gradient(135deg, ${Color.Primary} 0%, ${Color.Secondary_1} 100%); W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  background-color: ${Color.Primary}99;
  border-radius: 12px;

  border: 0;
  color: ${(props) => props.contrast};
  font-size: ${(props) => (props.size ? props.size : "17px")};
  font-weight: 700;

  ${(props) => (props.noflex ? "" : "flex-grow: 1;")};

  height: ${(props) => props.height};
  width: ${(props) => props.width};
  box-sizing: border-box;

  margin: ${(props) => props.margin};

  cursor: pointer;
  transition: color 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    background-color 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    border 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    box-shadow 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  &:hover {
    background-color: ${(props) => props.contrast};
    box-shadow: 0 0 0 3px ${Color.Primary}33;
    svg {
      color: ${(props) => props.color};
    }
  }

  &:active {
    box-shadow: 0 0 0 3px ${Color.Primary}33;
  }
  svg {
    color: ${(props) => props.contrast};
  }

  @media only screen and (max-width: 767px) {
    &:hover {
      background-color: ${(props) => props.color};
      color: ${(props) => props.contrast};
      box-shadow: 0 0 0 3px ${Color.Primary}00;
      svg {
        color: ${(props) => props.color};
      }
    }

    &:active {
      background-color: ${(props) => props.contrast};
      color: ${(props) => props.color};
      box-shadow: 0 0 0 3px ${Color.Primary}33;
      svg {
        color: ${(props) => props.color};
      }
    }
  }
`;

const BtnDisabled = styled.button`
  user-select: none;
  outline: none;

  background-color: ${(props) => props.color};
  border: 1px solid ${Color.Light_4};
  border-radius: 12px;

  color: ${(props) => props.contrast};
  font-size: 17px;
  font-weight: 700;
  margin-bottom: ${(props) => props.marginB};
  margin-top: ${(props) => props.marginT};
  margin-left: ${(props) => props.marginL};
  ${(props) => (props.noflex ? "" : "flex-grow: 1;")};

  height: ${(props) => (props.height ? props.height : "45px")};
  width: ${(props) => props.width};
  box-sizing: border-box;

  margin: ${(props) => props.margin};

  cursor: default;

  svg {
    color: ${(props) => props.contrast};
  }
`;

const BtnNotLogin = styled.button`
  user-select: none;
  outline: none;

  background-color: ${(props) => props.color};
  border: 1px solid ${Color.Light_4};
  border-radius: 12px;

  color: ${(props) => props.contrast};
  font-size: 17px;
  font-weight: 700;

  ${(props) => (props.noflex ? "" : "flex-grow: 1;")};

  height: ${(props) => (props.height ? props.height : "45px")};
  width: ${(props) => props.width};
  box-sizing: border-box;

  margin: ${(props) => props.margin};

  cursor: default;

  svg {
    color: ${(props) => props.contrast};
  }
`;

const OrangeBtn = styled.button`
  user-select: none;
  outline: none;

  background-color:  ${(props) => props.backgroundcolor}};
  border: 1px solid ${(props) => props.backgroundcolor};
  border-radius: ${(props) => props.borderradius};

  margin-bottom: ${(props) => props.marginB};
  margin-top: ${(props) => props.marginT};
  margin-left: ${(props) => props.marginL};
  color: ${(props) => props.contrast};
  font-size: ${(props) => (props.size ? props.size : "17px")};
  font-weight: 700;
  float: ${(props) => props.float};

  ${(props) => (props.noflex ? "" : "flex-grow: 1;")};

  height: ${(props) => props.height};
  width: ${(props) => props.width};
  box-sizing: border-box;

  margin: ${(props) => props.margin};

  cursor: pointer;
  transition: color 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    background-color 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    border 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    box-shadow 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  &:hover {
    background-color: ${(props) => "#fff"};
    color: ${(props) => "#fff"};
    border: 1px solid ${Color.Primary};
    box-shadow: 0 0 0 3px ${Color.Primary}33;
    svg {
      color: ${(props) => props.color};
    }
  }

  &:active {
    box-shadow: 0 0 0 3px ${Color.Primary}33;
  }
  svg {
    color: ${(props) => props.contrast};
  }

  @media only screen and (max-width: 767px) {
    &:hover {
      background-color: ${(props) => props.color};
      color: ${(props) => props.contrast};
      border: 1px solid ${Color.Primary};
      box-shadow: 0 0 0 3px ${Color.Primary}00;
      svg {
        color: ${(props) => props.color};
      }
    }

    &:active {
      background-color: ${(props) => props.contrast};
      color: ${(props) => props.color};
      border: 1px solid ${Color.Primary};
      box-shadow: 0 0 0 3px ${Color.Primary}33;
      svg {
        color: ${(props) => props.color};
      }
    }
  }
`;
const RoundWhiteBtn = styled.button`
  user-select: none;
  outline: none;
  padding: ${(props) => props.padding};
  padding-bottom: ${(props) => props.paddingB};
  padding-top: ${(props) => props.paddingT};
  padding-left: ${(props) => props.paddingL};
  padding-right: ${(props) => props.paddingR};
  background-color:  ${(props) => props.backgroundcolor}};
  border: 1px solid ${(props) => props.backgroundcolor};
  border-radius: ${(props) => props.borderradius};
  text-transform: ${(props) => props.texttransform};

  justify-content: ${(props) => (props.justify ? props.justify : "")};

  margin-bottom: ${(props) => props.marginB};
  margin-top: ${(props) => props.marginT};
  margin-left: ${(props) => props.marginL};
  color: ${(props) => props.color};
  font-size: ${(props) => (props.size ? props.size : "17px")};
  font-weight: 700;
  float: ${(props) => props.float};

  ${(props) => (props.noflex ? "" : "flex-grow: 1;")};

  height: ${(props) => props.height};
  width: ${(props) => props.width};
  box-sizing: border-box;

  margin: ${(props) => props.margin};

  cursor: pointer;
  transition: color 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    background-color 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    border 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    box-shadow 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  &:hover {
    background-color: ${(props) => "rgb(243, 190, 190)"};
    color: ${(props) => "#fff"};
    border: 1px solid ${(props) => "rgb(243, 190, 190)"};
    box-shadow: 0 0 0 3px ${Color.Primary}33;
    svg {
      color: ${(props) => props.color};
    }
  }

  &:active {
    box-shadow: 0 0 0 3px ${Color.Primary}33;
  }
  svg {
    color: ${(props) => props.contrast};
  }

  @media only screen and (max-width: 767px) {
    &:hover {
      background-color: ${(props) => props.color};
      color: ${(props) => props.contrast};
      border: 1px solid ${Color.Primary};
      box-shadow: 0 0 0 3px ${Color.Primary}00;
      svg {
        color: ${(props) => props.color};
      }
    }

    &:active {
      background-color: ${(props) => props.contrast};
      color: ${(props) => props.color};
      border: 1px solid ${Color.Primary};
      box-shadow: 0 0 0 3px ${Color.Primary}33;
      svg {
        color: ${(props) => props.color};
      }
    }
  }
`;


const EditBtn = styled.button`
  user-select: none;
  outline: none;
  background-color:  ${(props) => props.backgroundcolor}};
  border: 1px solid ${(props) => props.backgroundcolor};
  border-radius: ${(props) => props.borderradius};
  text-transform: ${(props) => props.texttransform};
  display: ${(props) => props.display};
  margin-bottom: ${(props) => props.marginB};
  margin-top: ${(props) => props.marginT};
  margin-left: ${(props) => props.marginL};
  color: ${(props) => props.color};
  font-size: ${(props) => (props.size ? props.size : "17px")};
  font-weight: 700;
  float: ${(props) => props.float};

  ${(props) => (props.noflex ? "" : "flex-grow: 1;")};

  height: ${(props) => props.height};
  width: ${(props) => props.width};
  box-sizing: border-box;

  margin: ${(props) => props.margin};

  cursor: pointer;
  transition: color 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    background-color 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    border 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    box-shadow 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  &:hover {
    background-color: ${(props) => "rgb(243, 190, 190)"};
    color: ${(props) => "#fff"};
    border: 1px solid ${(props) => "rgb(243, 190, 190)"};
    box-shadow: 0 0 0 3px ${Color.Primary}33;
    svg {
      color: ${(props) => props.color};
    }
  }

  &:active {
    box-shadow: 0 0 0 3px ${Color.Primary}33;
  }
  svg {
    color: ${(props) => props.contrast};
  }

  @media only screen and (max-width: 767px) {
    &:hover {
      background-color: ${(props) => props.color};
      color: ${(props) => props.contrast};
      border: 1px solid ${Color.Primary};
      box-shadow: 0 0 0 3px ${Color.Primary}00;
      svg {
        color: ${(props) => props.color};
      }
    }

    &:active {
      background-color: ${(props) => props.contrast};
      color: ${(props) => props.color};
      border: 1px solid ${Color.Primary};
      box-shadow: 0 0 0 3px ${Color.Primary}33;
      svg {
        color: ${(props) => props.color};
      }
    }
  }
`;
export default Button;
