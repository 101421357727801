//패스워드 체크 정규식  https://beagle-dev.tistory.com/114
export const pwMacth = (pw) => {
  const _reg = /^.*(?=^.{8,15}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/;
  const __reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
  const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,15}/;
  return reg.test(pw) && pw.search(/\s/) === -1 ? true : false;
};

//이메일 체크 정규식
export const emailCheck = (email) => {
  let _reg = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
  return _reg.test(email);
};

//닉네임 체크 정규식  https://rubberduck-debug.tistory.com/82
export const nicknameCheck = (nickname) => {
  const _reg = /^[\w\Wㄱ-ㅎㅏ-ㅣ가-힣]{2,10}$/;
  const reg = /^[a-z0-9_-]{2,10}$/;
  return reg.test(nickname);
};

//가격 콤마 정규식(input)
export const input_priceComma = (price) => {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",");
};

// https://blog.naver.com/crazyb/220743863861
export const comma = (str) => { 
    str = String(str); 
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,'); 
};

// https://blog.naver.com/crazyb/220743863861
export const uncomma = (str) => { 
    str = String(str); 
    return str.replace(/[^\d]+/g, ''); 
};



export function getRandomWelcomeText(usename) {
  const welcomeTexts = [
    `Hey ${usename}, continue listening to your Music`,
    `Hey ${usename}, welcome back`,
  ];
  const randomIndex = Math.floor(Math.random() * welcomeTexts.length);
  return welcomeTexts[randomIndex];
}

export function capitalize(string) {
  let word = string.toLowerCase();
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function createNamesArray(name) {
  let names = [];
  let previous = "";

  for (let char of name) {
    names.push(`${previous}${char}`);
    previous += char;
  }
  return names;
}

export function capitalizeAllWords(name) {

  if(name === ""){
    return
  }
  let words = name.split(" ");
  let capitalizedword = "";

  for (let word of words) {
    let capWord = capitalize(word);
    capitalizedword += ` ${capWord}`;
  }

  capitalizedword = capitalizedword.trim();
  return capitalizedword;
}

export function createNamesArrayWithCaptitalizedWords(name) {
  let capitalizedword = capitalizeAllWords(name);
  let names = createNamesArray(capitalizedword);
  return names;
}


export function createartistNamesArrayWithCaptitalizedWords(name) {
  let capitalizedword = capitalizeAllWords(name);
  let artistnames = createNamesArray(capitalizedword);
  return artistnames;
}




const playlistImages = [
  "https://prexoo.com/images/no-music-cover.png",
  "https://www.soundlaunch.com/cover_photos/default_cover.jpg",
  "https://d1csarkz8obe9u.cloudfront.net/posterpreviews/abstract-music-cd-cover-art-design-template-e43d2f90de58fd720f97f7e8068bb4f8_screen.jpg?ts=1570368591",
  "https://upload.wikimedia.org/wikipedia/en/c/c9/Zedd-True-Colors.png",
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnJL-5T0rDIVexjW4ndJko_z_YnBtFmTRauQ&usqp=CAU",
];

export function getRandomPlaylistImage() {
  const randomIndex = Math.floor(Math.random() * playlistImages.length);
  return playlistImages[randomIndex];
}


