import { createAction, handleActions } from "redux-actions";
import axios from "axios";
import { setLocal, deleteLocal } from "../../shared/Local";

const SET_ARTIST_SONG_PRODUCT_SEARCH = "SET_ARTIST_SONG_PRODUCT_SEARCH"; // 검색 페이지
const SET_SONG_PRODUCT_SEARCH = "SET_SONG_PRODUCT_SEARCH"; // 검색 페이지
const CLEAR_SEARCH_PAGE = "CLEAR_SEARCH_PAGE"; // 새롭게 검색할때 초기화
const SET_KEYWORD = "SET_KEYWORD"; // 검색어 설정

const initialState = {
  search_song_product: [],
  search_artist_song_product: [],
  search_page: 0,
  keyword: "",
};

const setSearch_Keyword = (keyword) => {
  return async function (dispatch) {
    try {
      dispatch({
        type: CLEAR_SEARCH_PAGE,
        search_song_product: [],
        search_artist_song_product:[],
      });
      dispatch({
        type: SET_KEYWORD,
        keyword: keyword,
      });
    } catch (err) {
      console.log(err, "getProductSearch");
    }
  };
};

const setSearch_Song_Product = (product) => {
  return async function (dispatch) {
    try {
      dispatch({
        type: SET_SONG_PRODUCT_SEARCH,
        search_song_product: product,
      });
    } catch (err) {
      console.log(err, "getProductSearch");
    }
  };
};

const setSearch_Artist_relatedSong_Product = (product) => {
  return async function (dispatch) {
    try {
      dispatch({
        type: SET_ARTIST_SONG_PRODUCT_SEARCH,
        search_artist_song_product: product,
      });
    } catch (err) {
      console.log(err, "getProductSearch");
    }
  };
};

const SearchResult = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_SEARCH_PAGE:
      return {
        ...state,
        search_song_product: action.search_song_product,
        search_artist_song_product: action.search_artist_song_product

      };

    case SET_KEYWORD:
      return {
        ...state,
        keyword: action.keyword,
      };

    case SET_SONG_PRODUCT_SEARCH:
      return {
        ...state,
        search_song_product: action.search_song_product,
      };

    case SET_ARTIST_SONG_PRODUCT_SEARCH:
      return {
        ...state,
        search_artist_song_product: action.search_artist_song_product,
      };

    default:
      return state;
  }
};

export default SearchResult;

const actionCreators = {
  setSearch_Song_Product,
  setSearch_Artist_relatedSong_Product,
  setSearch_Keyword,
};

export { actionCreators };
