import { createAction, handleActions } from "redux-actions";
import axios from "axios";
import { setLocal, deleteLocal } from "../../shared/Local";


 const SETTOBASKET = "SETTOBASKET";
 const ADDTOBASKET = "ADDTOBASKET";
 const EMPTYBASKET = "EMPTYBASKET";
 const REMOVEFROMBASKET = "REMOVEFROMBASKET";

const initialState = {
  basket: "",

};

// 회원 가입
const set_Product_Basket = (basket) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: SETTOBASKET,
      basket : basket,
    });  
  };
};

const remove_Product_Basket = (basket) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: REMOVEFROMBASKET,
      basket : basket,
    });  
  };
};


const ProductBasket = (state = initialState, action) => {
  switch (action.type) {
  
      case SETTOBASKET:
        return {
          ...state,
          basket : action.basket,
        }

        case REMOVEFROMBASKET:     
          const index = state.basket.findIndex(
            (basketItem) => basketItem.id=== action.basket.id
            
          );

            // console.log(state.basket.findIndex((basketItem) =>  basketItem.id=== action.basket.id))

          let newBasket = [...state.basket];
     
          if (index >= 0) {
            newBasket.splice(index, 1);
    
          } else {
            console.warn(
              `Cant remove product (id: ${action.basket.id}) as its not in basket!`
            )
          }
    
          return {
            ...state,
            basket: newBasket
          }
    default:
      return state
  }
};

export default ProductBasket;


const actionCreators = {
  set_Product_Basket,
  remove_Product_Basket,
};

export { actionCreators };
