import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
// import serviceAccount from "../shared/sdsddddd-23663-firebase-adminsdk-qb8dw-2b7338874c.json";
// import * as admin from "firebase-admin";
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  // apiKey: "AIzaSyDe7-UKJEUvdUkuZ9xWX_Vs_1SJGDstcgY",
  // authDomain: "sdsddddd-23663.firebaseapp.com",
  // projectId: "sdsddddd-23663",
  // storageBucket: "sdsddddd-23663.appspot.com",
  // messagingSenderId: "484871293739",
  // appId: "1:484871293739:web:c7965eff1c4e0a7fd1d36e",
  // measurementId: "G-8PJFPSW1BE"
  apiKey: "AIzaSyBPU1PwYHpjXpQOi3KnLp3XALCjORs_tuA",
  authDomain: "pianissimo.app",
  databaseURL: "https://pianissimo-5287f-default-rtdb.firebaseio.com",
  projectId: "pianissimo-5287f",
  storageBucket: "pianissimo-5287f.appspot.com",
  messagingSenderId: "135262607891",
  appId: "1:135262607891:web:ab150f8529abb7ed86fde6"
  // measurementId: "${config.measurementId}"
};

 firebase.initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const firebaseInstance = firebase;
export const authService = firebase.auth();
export const dbService = firebase.firestore();
export const storageService = firebase.storage();
export const firebasefunction = firebase.functions();

export const facebookprovider = new firebase.auth.FacebookAuthProvider()
export const googleprovider = new firebase.auth.GoogleAuthProvider()

// admin.initializeApp({
//     credential: admin.credential.cert(serviceAccount),
//     databaseURL: "https://sdsddddd-23663-default-rtdb.firebaseio.com"
//   });

  
  // export const firebaseInstant = admin.initializeApp({
  //   credential: admin.credential.cert(serviceAccount),
  //   databaseURL: "https://sdsddddd-23663-default-rtdb.firebaseio.com"
  // });
// export const adminInstance = admin;




googleprovider.addScope('email')
// googleprovider.addScope('https://www.googleapis.com/auth/contacts.readonly');
facebookprovider.addScope('email')
// provider.addScope('profile');
export const setUser = async (uid, data) => {
  const user = await dbService.collection("user_Public").doc(uid).set(data);
  return user;
};


export const Update_User = async (uid, data) => {
  const user = await dbService.collection("user_Public").doc(uid);
  return await user.update(data)
};


export const Artist_amount = async (uid, data) => {
  const amount = await dbService.collection("Artist_amount").doc(uid);
  return await amount.set(data)
};


export const getUser = (userUid) => {
  const doc = dbService.collection("admin_users")
  .doc(userUid)
  .get()
  .then((www) => {
    if (www.exists) {
        return dbService.collection("admin_users").doc(userUid).get();
    }
    else{
         return dbService.collection("users").doc(userUid).get();
    }}
    )
  return doc;
};



export const getdoc = (userUid) => {
  return dbService.collection("admin_users").doc(userUid);
};


export const Upload_SongAll_firebase =  async (midifile,sheetfile, spotifyimage, uploadMidi, uploadSheet, uploadElbumImage, data, id , autoid)  => {
    await uploadMidi.on(
      "state_change",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
      },
      () => {
        storageService
          .ref("songs/midi")
          .child(autoid + midifile.name)
          .getDownloadURL()
          .then((url) => {
            id.update({ Midi_url: url });
          });
      }
    );
    
    await uploadSheet.on(
      "state_change",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
      },
      () => {
        storageService
          .ref("songs/sheet")
          .child(autoid + sheetfile.name)
          .getDownloadURL()
          .then((url) => {
            data.Sheet_url = url; // adding the recived Url
            id.update({ Sheet_url: url });
          });
      }
    );

    await uploadElbumImage.on(
      "state_change",
      (snapshot) => {
        // Progress function
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
      },
      () => {
        storageService
          .ref("songs/Elbum")
          .child(autoid + spotifyimage.name)
          .getDownloadURL()
          .then((url) => {
            data.Elbum_url = url; // adding the recived Url
            id.update({ Elbum_url: url });
            setTimeout(() => {
               alert("업로드 완료")
            }, 3000);
          });
      }
    );
  };

  export const Upload_SongCopyRight = async (spotifyimage , uploadElbumImage,  CopyRight_data, CopyRight, autoid) => {
    uploadElbumImage.on(
      "state_change",
      (snapshot) => {
      },
      (error) => {
        alert(error.message);
        console.log(error.message);
      },
      () => {
        storageService
          .ref("songs/Elbum")
          .child(autoid + spotifyimage.name)
          .getDownloadURL()
          .then((url) => {
            CopyRight_data.Elbum_url = url; // adding the recived Url
            CopyRight.update({ Elbum_url: url });
          });
      }
    );
  };


  export const ArtistUser_Song_Plus = async (user) => {
    var download = dbService.collection("user_Public").doc(user.user?.uid);

    return dbService
      .runTransaction((transaction) => {
        // This code may get re-run multiple times if there are conflicts.
        return transaction.get(download).then((sfDoc) => {
          if (!sfDoc.exists) {
            throw "Document does not exist!";
          }
          var newPopulation = sfDoc.data().artistuser_song + 1;
          transaction.update(download, { artistuser_song: newPopulation });
        });
      })
      .then(() => {})
      .catch((error) => {
        // console.log("Transaction failed: ", error);
      });
  };