import React, { useEffect, useState } from "react";
import "./Pagination.css";
import styled from 'styled-components';


const PageUl = styled.ul`
  ${'' /* float:left; */}
  list-style: none;
  text-align:center;
  border-radius:3px;
  color:"#ba4148";
  ${'' /* padding:1px; */}
  ${'' /* border-top:3px solid #186EAD; */}
  ${'' /* border-bottom:3px solid #186EAD; */}
  ${'' /* background-color: rgba( 0, 0, 0, 0.4 ); */}
`;

const PageSpan = styled.span`
  color:"#ba4148";
  &:hover{
    cursor:pointer;
    color:"#ba4148";
    ${'' /* background-color:#263A6C; */}
    
  }

  &:hover::after,
  &:focus::after{
    border-radius:100%;
    color:"#ba4148";
    background-color:#263A6C;
  }
`;


const Pagination = ({postPerPage, totalPosts , paginate }) => {


    useEffect(() => {
        console.log(totalPosts)
        console.log(postPerPage)

        console.log(pageNumbers)
      }, []);
    

const pageNumbers = [];


for (let i=1; i<= Math.ceil(totalPosts / postPerPage); i++){
    pageNumbers.push(i)
}

    return (
        <div className="PurchaseProduct_pagination">
        <nav >
          <PageUl className="pagination">
            {pageNumbers.map(number => (
              <li key={number} className="page-item">
                <PageSpan onClick={() => paginate(number)} className="page-link" 
                // style={currentPage == number ? {color: '#17a2b8'} : null} 
                >
                  {number}
                </PageSpan>
              </li>
            ))}
          </PageUl>
        </nav>
      </div>


    )

}
export default  Pagination;