import React, { useRef, useMemo, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import { Footer } from "../../components/index";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { actionCreators as SearchResultActions } from "../../redux/modules/SearchResult";
import { Grid, Button, Text, Input2 } from "../../elements/index";
import "./Policy.css";


const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  return isDesktop ? children : null;
};

const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
  return isTablet ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

const Privacy = (props) => {
  const dispatch = useDispatch();
  const [currentLocation, setCurrentLocation] = useState("/");
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const { showHeader } = props;
  const [keyword, setKeyword] = useState("");
  const [mainct, setMainct] = useState("");
  const [subct, setSubct] = useState("");
  const user = useSelector((state) => state.user);
	const { t, i18n } = useTranslation();
  const isDesktop = useMediaQuery({ minWidth: 1024 });


  const SearchProduct = () => {
    dispatch(SearchResultActions.setSearch_Keyword(keyword));
    history.push("/Search/Result");
    setKeyword("");
  };

  const SearchProduct2 = () => {
  
  };


  return (
    <>
      <Desktop>

      <div className="wrap">
        <div div className="menuBar">
                <ul className="tabs">
                <li onClick={()=>  {history.push("/Support/Policy")}}>이용약관</li>
                <li onClick={()=>  {history.push("/Support/Privacy")}}>개인정보보호정책</li>
                <li onClick={()=>  {history.push("/Support/copyright")}}>Copyright</li>
                </ul>
            </div>

            <Grid width="1200px" bg="#fff" display="flex" column margin="auto">
            <Text h2  padding="20px"  size="32px" color="#202020" textAlign="left" >개인정보보호정책</Text>

            <Grid
              width="1200px"
              bdr="1px solid #4b4b4b59"
              borderRadius="20px"
              padding="20px"
            >
            
         
              <Text
                color="#363636"
                display="block"
                size="15px"
                marginT="5px"
                lineHeight="24px"
              > 
              "PIANISSIMO"('WWW.PIANISSIMO.COM'이하 'WWW.PIANISSIMO.COM')은(는) 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.
            ○ 이 개인정보처리방침은 2021년 12월 1부터 적용됩니다.`
              </Text>
            </Grid>

            <Grid
              width="1200px"
              bdr="1px solid #4b4b4b59"
              borderRadius="20px"
              padding="20px"
              marginT="15px"
            >
              <Text
                color="#202020"
                bold
                size="18px"
                textAlign="left"
                flexdirection="row"
              >
                {" "}
                개인정보의 처리 목적
              </Text>
              <Grid
                bdrBottom="1px solid #4b4b4b59"
                margin="auto"
                marginT="10px"
                justify="center"
              ></Grid>

              <Text
                color="#363636"
                display="block"
                size="15px"
                marginT="15px"
                lineHeight="24px"
              >
               "PIANISSIMO"('WWW.PIANISSIMO.APP'이하 PIANISSIMO)은(는) 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
              </Text>
              <Grid
                // width="1200px"
                display="flex"
                bg="#dbdbdba1"
                height="200px"   
                 marginT="15px"
                column
              >
                <Text
                  lineHeight="21px"
                  display="block"
                  size="12px"
                  marginT="10px"
                  marginL="20px"
                >
                    1. 홈페이지 회원가입 및 관리<br/>
                    회원 가입의사 확인, 회원자격 유지·관리, 서비스 부정이용 방지 목적으로 개인정보를 처리합니다.
                    <br/>
                    2. 민원사무 처리<br/>
                    민원인의 신원 확인, 민원사항 확인 목적으로 개인정보를 처리합니다.
                    <br/>
                    3. 재화 또는 서비스 제공<br/>
                    서비스 제공, 콘텐츠 제공, 요금결제·정산을 목적으로 개인정보를 처리합니다.
                    <br/>
                    4. 마케팅 및 광고에의 활용<br/>
                    신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 등을 목적으로 개인정보를 처리합니다.
                    <br/>
                </Text>
              </Grid>
            </Grid>



            <Grid
              width="1200px"
              bdr="1px solid #4b4b4b59"
              borderRadius="20px"
              padding="20px"
              marginT="15px"
            >
              <Text
                color="#202020"
                bold
                size="18px"
                textAlign="left"
                flexdirection="row"
              >
                {" "}
                개인정보의 처리 및 보유 기간
              </Text>
              <Grid
                bdrBottom="1px solid #4b4b4b59"
                margin="auto"
                marginT="10px"
                justify="center"
              ></Grid>

              <Text
                color="#363636"
                display="block"
                size="15px"
                marginT="15px"
                lineHeight="24px"
              >
           ① "PIANISSIMO"은(는) 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.
           <br/>
           ② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.              
          </Text>
              <Grid
                // width="1200px"
                display="flex"
                bg="#dbdbdba1"
                height="130px"   
                 marginT="15px"
                column
              >
                <Text
                  lineHeight="21px"
                  display="block"
                  size="12px"
                  marginT="10px"
                  marginL="20px"
                >
              홈페이지 회원가입 및 관리 와 관련한 개인정보는 수집.이용에 관한 동의일로부터 사이트 폐쇄 시까지 위 이용목적을 위하여 보유.이용됩니다. <br/>
              -보유근거 : 계약 또는 청약철회 등에 관한 기록 <br/>
              -신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년 (전자상거래등에서의 소비자보호에 관한 법률) <br/>
              -소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래등에서의 소비자보호에 관한 법률) <br/>
              -대금결제 및 재화 등의 공급에 관한 기록 : 5년 (전자상거래등에서의 소비자보호에 관한 법률) <br/> 
                </Text>
              </Grid>
            </Grid>

            <Grid
              width="1200px"
              bdr="1px solid #4b4b4b59"
              borderRadius="20px"
              padding="20px"
              marginT="15px"
            >
              <Text
                color="#202020"
                bold
                size="18px"
                textAlign="left"
                flexdirection="row"
              >
                {" "}
                개인정보의 제3자 제공
              </Text>
              <Grid
                bdrBottom="1px solid #4b4b4b59"
                margin="auto"
                marginT="10px"
                justify="center"
              ></Grid>

              <Text
                color="#363636"
                display="block"
                size="15px"
                marginT="15px"
                lineHeight="24px"
              >
          ① "PIANISSIMO"은(는) 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.<br/>
          ② "PIANISSIMO"은(는) 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.           
          </Text>
              <Grid
                // width="1200px"
                display="flex"
                bg="#dbdbdba1"
                height="150px"   
                 marginT="15px"
                column
              >
                <Text
                  lineHeight="21px"
                  display="block"
                  size="12px"
                  marginT="10px"
                  marginL="20px"
                >
             수집항목<br/> 
          - 필수 수집항목: 로그인ID, 비밀번호, 비밀번호확인, 이름, 이메일, 주 음악/장르 설정<br/> 
          - 결제수단(신용카드 정보, 계좌번호 정보), 증빙수단(사업자등록번호, 사업자 상호, 대표자, 사업장 소재지, 전화번호,이메일, 현금영수증 카드번호, 휴대전화번호)<br/> 
          - 자동 수집항목: IP Adress, 접속기록, 쿠키 등<br/> 
            개인정보 수집방법<br/> 
          - 회원가입,상품주문(콘텐츠)<br/> 
                </Text>
              </Grid>
            </Grid>

            <Grid
              width="1200px"
              bdr="1px solid #4b4b4b59"
              borderRadius="20px"
              padding="20px"
              marginT="15px"
            >
              <Text
                color="#202020"
                bold
                size="18px"
                textAlign="left"
                flexdirection="row"
              >
                {" "}
                개인정보의 파기
              </Text>
              <Grid
                bdrBottom="1px solid #4b4b4b59"
                margin="auto"
                marginT="10px"
                justify="center"
              ></Grid>

              <Text
                color="#363636"
                display="block"
                size="15px"
                marginT="15px"
                lineHeight="24px"
              >
           ① "PIANISSIMO" 은(는) 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.<br/>
           ② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.         
          </Text>
              <Grid
                // width="1200px"
                display="flex"
                bg="#dbdbdba1"
                height="150px"   
                 marginT="15px"
                column
              >
                <Text
                  lineHeight="21px"
                  display="block"
                  size="12px"
                  marginT="10px"
                  marginL="20px"
                >
           파기절차<br/>
          -회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 어떤 용도로도 열람 또는 이용할 수 없도록 파기됩니다.
          별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되어지는 이외의 다른 목적으로 이용되지 않습니다.<br/>
          <br/>
           파기방법<br/>
          - 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.<br/>
                </Text>
              </Grid>
            </Grid>

            <Grid
              width="1200px"
              bdr="1px solid #4b4b4b59"
              borderRadius="20px"
              padding="20px"
              marginT="15px"
            >
              <Text
                color="#202020"
                bold
                size="18px"
                textAlign="left"
                flexdirection="row"
              >
                {" "}
                개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항
              </Text>
              <Grid
                bdrBottom="1px solid #4b4b4b59"
                margin="auto"
                marginT="10px"
                justify="center"
              ></Grid>

              <Text
                color="#363636"
                display="block"
                size="15px"
                marginT="15px"
                lineHeight="24px"
              >
          ①  "PIANISSIMO" 은(는) 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.<br/>
          ② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.     
          </Text>
              <Grid
                // width="1200px"
                display="flex"
                bg="#dbdbdba1"
                height="90px"   
                 marginT="15px"
                column
              >
                <Text
                  lineHeight="21px"
                  display="block"
                  size="12px"
                  marginT="10px"
                  marginL="20px"
                >
          가. 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.<br/>
          나. 쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구`{'>'}`인터넷 옵션 `{'>'}`개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.<br/>
          다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.<br/>
                </Text>
              </Grid>
            </Grid>

            <Grid
              width="1200px"
              bdr="1px solid #4b4b4b59"
              borderRadius="20px"
              padding="20px"
              marginT="15px"
            >
              <Text
                color="#202020"
                bold
                size="18px"
                textAlign="left"
                flexdirection="row"
              >
                {" "}
                개인정보에 관한 민원서비스
              </Text>
              <Grid
                bdrBottom="1px solid #4b4b4b59"
                margin="auto"
                marginT="10px"
                justify="center"
              ></Grid>

              <Text
                color="#363636"
                display="block"
                size="15px"
                marginT="15px"
                lineHeight="24px"
              >
           ① PIANISSIMO 은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
          </Text>
              <Grid
                // width="1200px"
                display="flex"
                bg="#dbdbdba1"
                height="90px"   
                 marginT="15px"
                column
              >
                <Text
                  lineHeight="21px"
                  display="block"
                  size="12px"
                  marginT="10px"
                  marginL="20px"
                >
            ▶ 개인정보 보호책임자<br/>
          성명 :김동현<br/>
          연락처 :, rlacjs1021@naver.com,<br/>
                </Text>
              </Grid>
            </Grid>

            <Grid
              width="1200px"
              bdr="1px solid #4b4b4b59"
              borderRadius="20px"
              padding="20px"
              marginT="15px"
            >
              <Text
                color="#202020"
                bold
                size="18px"
                textAlign="left"
                flexdirection="row"
              >
                {" "}
                권익침해 구제방법
              </Text>
              <Grid
                bdrBottom="1px solid #4b4b4b59"
                margin="auto"
                marginT="10px"
                justify="center"
              ></Grid>

              <Text
                color="#363636"
                display="block"
                size="15px"
                marginT="15px"
                lineHeight="24px"
              >
          정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
          </Text>
              <Grid
                // width="1200px"
                display="flex"
                bg="#dbdbdba1"
                height="170px"   
                 marginT="15px"
                column
              >
                <Text
                  lineHeight="21px"
                  display="block"
                  size="12px"
                  marginT="10px"
                  marginL="20px"
                >
          1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)<br/>
          2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)<br/>
          3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)<br/>
          4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)<br/>

          「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.<br/>

          ※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.
                </Text>
              </Grid>
            </Grid>

          </Grid>
          </div> 

          <Footer/>
       
      </Desktop>
    </>
  );
};



	



export default Privacy;
