import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { dbService } from "../../shared/firebase";
import "./Artist_Product.css";
import {
  SheetPDF,
  MidiPiano,
  AlertModalContainer,
} from "../../components/index";
import YouTubeIcon from "@material-ui/icons/YouTube";
import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined";
import StraightenIcon from "@material-ui/icons/Straighten";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { Player } from "../../components/midi/Midi_Player";
import { Grid, Button, Text, Input2 } from "../../elements/index";
import Color, { Palette } from "color-thief-react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import YouTube from "react-youtube";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { actionCreators as ArtistProductActions } from "../../redux/modules/Artist_Product";
import { actionCreators as UserActions } from "../../redux/modules/user";

import LanguageDetector from "i18next-browser-languagedetector";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import { ModalContainer } from "../../components/index";
import { actionCreators as QuestionActions } from "../../redux/modules/Question";
import firebase from "firebase";
import { useMediaQuery } from "react-responsive";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1367 });
  return isDesktop ? children : null;
};

const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 916, maxWidth: 1366 });
  return isTablet ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 915 });
  return isMobile ? children : null;
};

const MIN_WIDTH2 = 1040;

var getYouTubeID = require("get-youtube-id");
var ui, player;

const StyledRating = withStyles({
  iconFilled: {
    color: "#ff6d75",
  },
  iconHover: {
    color: "#ff3d47",
  },
})(Rating);

const StyledRatingPlayer = withStyles({
  iconFilled: {
    color: "#5A5656",
  },
  iconHover: {
    color: "#ff3d47",
  },
})(Rating);

function Artist_Product() {
  const history = useHistory();
  const { id } = useParams();
  // const [artist, setArtist] = useState(null);
  const [songs, setSongs] = useState();

  ///////////////////////////setting//////////////////////////////////
  const [midimode, setmidimode] = useState(false);
  const [sheetmode, setsheetmode] = useState(true);
  const [youtubemode, setyoutubemode] = useState(false);
  const user = useSelector((state) => state.user);
  const ArtistProduct = useSelector((state) => state.ArtistProduct);
  const [releasetime, setreleasetime] = useState();

  ///////////////////////////redux/////////////////////////////////
  // const current_song = useSelector((state) => state.current_song);
  ///////////////////////////rating//////////////////////////////////
  const [hover, setHover] = useState(-1);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [LoginModalopen, setLoginModalopen] = useState(false);
  ///////////////////////////youtube//////////////////////////////////
  const [youtubeVideo, setyoutubeVideo] = useState();
  const [rating_disabled, setrating_disabled] = useState(false);
  const [subscribe_disabled, setsubscribe_disabled] = useState(false);

  // const { basket } = useSelector((state) => state.Basket);

  const [width, setWidth] = useState(
    // Math.max(MIN_WIDTH2, Math.floor(window.innerWidth - 440))
    window.innerWidth * 0.8 * 0.7
  );

  const [height100, setheight100] = useState(Math.floor(window.innerHeight));
  const [height25, setheight25] = useState(
    Math.floor(window.innerHeight) * 0.24
  );
  const [height75, setheight75] = useState(
    // Math.floor(window.innerHeight) * 0.75
    Math.floor(window.innerHeight * 0.7)
  );

  const [height755, setheight755] = useState(
    Math.floor(window.innerHeight) * 0.6
    // Math.floor(window.innerHeight * 0.8)
  );
  const [heightMobile, setheightMobile] = useState(
    Math.floor(window.screen.height - 300)
  );

  const [height65, setheight65] = useState(
    Math.floor(window.innerHeight * 0.65)
  );

  const [Mobilewidth, setMobilewidth] = useState(window.innerWidth);

  const [Currency_Amount, setCurrency_Amount] = useState();
  const [youtube, setyoutube] = useState();

  const [alert, setAlert] = useState({
    type: "error",
    text: "This is a alert message",
    show: false,
  });

  const { t, i18n } = useTranslation();

  const [locale, setLocale] = useState(i18n.language);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const Loading = () => <span>Loading...</span>;
  const updateDimensions = () => {
    setWidth(window.innerWidth * 0.8 * 0.7);
    setheight100(Math.floor(window.innerHeight));
    setheight75(Math.floor(window.innerHeight) * 0.7);
    setheight755(Math.floor(window.innerHeight) * 0.6);
    setheight25(Math.floor(window.innerHeight) * 0.25);
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    history.listen((location) => {
      if (location.pathname !== `/Artist_product/${id}` && player) {
        player.stop();
      }
    });
  }, [history, id]);

  useEffect(() => {
    dbService
      .collection("Song_All")
      .doc(id)
      .get()
      .then((snapshot) => {
        const fetchSong = snapshot.data();
        const fetchid = snapshot.id;
        dispatch(
          ArtistProductActions.set_Artist_Product_data(
            fetchSong,
            fetchid,
            songEdit(snapshot.data()?.songEdit),
            snapshot.data()?.rating,
            snapshot.data()?.update,
            snapshot.data()?.faviorite,
            time(snapshot.data()?.createdAt.toDate().toISOString()),
            // youtube_EmbedID(snapshot.data()?.youtubeUrl)
            youtube_EmbedID(snapshot.data()?.youtubeUrl)
          )
        );

        if (!fetchSong) {
          return;
        }

        dbService
          .collection("user_Public")
          .doc(fetchSong?.uid)
          .get()
          .then((snapshot) => {
            const fetchArtist = snapshot.data();
            dispatch(
              ArtistProductActions.set_Artist_Product_artist_data(
                snapshot.data()?.artistuser_subscribe,
                fetchArtist
              )
            );
            setIsLoading(false);
          });
      });
  }, [id, history, dispatch]);

  useEffect(() => {
    const address = "https://www.youtube.com/watch?v=wTv6aZ0pRkM";
    var split1 = (address || "").split("=");
    var embedID = split1[1];
    console.log(embedID);
    // wTv6aZ0pRkM
    setmidimode(true);
    init();
    setyoutubeVideo(embedID);
  }, []);

  useEffect(() => {
    if (i18n.language === "kor") {
      setCurrency_Amount(songs?.data.KOR);
    } else if (i18n.language === "en") {
      setCurrency_Amount(songs?.data.USD);
    } else if (i18n.language === "ja") {
      setCurrency_Amount(songs?.data.JPY);
    }

    // i18n.on("languageChanged", (lng) => {
    //   if (lng === "kor") {
    //     setCurrency_Amount(songs?.data.KOR);
    //   } else if (lng === "en") {
    //     setCurrency_Amount(songs?.data.USD);
    //   } else if (lng === "ja") {
    //     setCurrency_Amount(songs?.data.JPY);
    //   }
    // });
  }, [songs, i18n]);

  const youtube_EmbedID = (data) => {
    if (data === undefined || data === "" || data === null) {
      return;
    }
    const split1 = (data || "").split("=");
    var embedID = split1[1];
    if (embedID.includes("&t")) {
      const split2 = embedID.split("&t");
      return split2[0];
    }

    return embedID;
  };

  const time = (data) => {
    var split1 = (data || "").split("T");
    var replace = split1[0].replace(/\-/g, " ");
    var replace2 = replace.replace(" ", ".");
    var date = replace2.replace(" ", ".");
    setreleasetime(date);
    return date;
  };

  const songEdit = (songEdit) => {
    if (songEdit === undefined) {
      return "원곡";
    } else if (songEdit.NoEdit === true) {
      return "원곡";
    } else if (songEdit.SomeEdit === true) {
      return "편곡";
    } else if (songEdit.Edit === true) {
      return "작곡";
    }
  };

  async function midi_playground() {
    setmidimode(true);
    setsheetmode(false);
    setyoutubemode(false);
    // dispatch(MAIN_PLAYER_PAUSE())
    await init();
  }

  const goToArtistMaingPage = () => {
    history.push(`/Artist/main/${ArtistProduct?.data.uid}`);
  };

  const sheet_playground = () => {
    setmidimode(false);
    setsheetmode(true);
    setyoutubemode(false);

    if (player) {
      player.stop();
    }
  };

  const youtube_playground = () => {
    setmidimode(false);
    setsheetmode(false);
    setyoutubemode(true);
    if (player) {
      player.stop();
    }
  };

  const Add_favorite_Song = async () => {
    if (user.is_login === false || user.user === null) {
      setLoginModalopen(true);
    }
    // if(subscribe_disabled){
    //   return;
    // }
    const artistuid = {
      song_uid: ArtistProduct?.id,
      Favorite_Song_Check: true,
    };

    var doc = dbService.collection("user_Public").doc(user?.uid);
    doc.get().then((docSnapshot) => {
      const filterUID = docSnapshot
        .data()
        ?.Favorite_Song.filter((item) => item.song_uid === ArtistProduct?.id);
      const artist_uid = filterUID.map((item) => item.song_uid);
      // ddddd.indexOf(ArtistProduct?.id)

      if (artist_uid == `${ArtistProduct?.id}`) {
        setAlert({
          type: "warning",
          text: "이미좋아요",
          show: true,
        });
        return;
      } else {
        doc.update({
          Favorite_Song: firebase.firestore.FieldValue.arrayUnion(artistuid),
        });
      }
      var db = dbService.collection("Song_All").doc(ArtistProduct?.id);
      const newFavorite_value = ArtistProduct?.faviorite + 1;

      return dbService
        .runTransaction((transaction) => {
          // This code may get re-run multiple times if there are conflicts.
          return transaction.get(db).then((sfDoc) => {
            if (!sfDoc.exists) {
              throw "Document does not exist!";
            }
            transaction.update(db, { faviorite: newFavorite_value });
            // setsubscribe(newPopulation);
          });
        })
        .then(() => {
          dispatch(ArtistProductActions.set_New_Favorite(newFavorite_value));
          //누가구독했는지?
        })
        .catch((error) => {
          console.log("Transaction failed: ", error);
        });
    });
  };

  const add_to_basket_page = async (id, song) => {
    if (user.is_login === false || user.user === null) {
      setLoginModalopen(true);
    }
    const songdata = { data: song };
    var doc = dbService
      .collection("user_Public")
      .doc(user.uid)
      .collection("product_basket")
      .doc(id);
    doc.get().then((docSnapshot) => {
      if (docSnapshot.exists) {
        setAlert({
          type: "warning",
          text: "이미 있는 곡입니다.",
          show: true,
        });
        return;
      } else {
        doc.set(songdata);
        setAlert({
          type: "success",
          text: "장바구니에 추가되었습니다.",
          show: true,
        });
      }
    });
  };

  const go_to_basket_page = async (id, song) => {
    if (user.is_login === false || user.user === null) {
      setLoginModalopen(true);
    }
    const songdata = { data: song };
    var doc = dbService
      .collection("user_Public")
      .doc(user.uid)
      .collection("product_basket")
      .doc(id);
    doc.get().then((docSnapshot) => {
      if (docSnapshot.exists) {
        setAlert({
          type: "warning",
          text: "장바구니에  이미 담겨있는 곡입니다.",
          show: true,
        });
        return;
      } else {
        doc.set(songdata);
        history.push("/Checkout/ProductBasket");
      }
    });
  };

  const closeModal = () => {
    setLoginModalopen(false);
  };

  const goTo_Question = async () => {
    if (user.is_login === false || user.user === null) {
      setLoginModalopen(true);
      return;
    }
    dispatch(QuestionActions.set_Question_SongData(ArtistProduct?.data));
    history.push("/Home/QuestionWrite");
  };

  const artist_subscribe_Active = async () => {
    if (user.is_login === false || user.user === null) {
      setLoginModalopen(true);
      return;
    }
    if (subscribe_disabled) {
      return;
    }

    const artistuid = {
      artist_uid: ArtistProduct?.artistuser_data.uid,
    };

    var doc = dbService.collection("user_Public").doc(user?.uid);
    doc.get().then((docSnapshot) => {
      const filterUID = docSnapshot
        .data()
        ?.Artist_subscribe_uid.filter(
          (item) => item.artist_uid === ArtistProduct?.artistuser_data.uid
        );
      const artist_uid = filterUID.map((item) => item.artist_uid);
      if (artist_uid == `${ArtistProduct?.artistuser_data.uid}`) {
        setAlert({
          type: "warning",
          text: "이미 구독버튼을 눌렀습니다",
          show: true,
        });
        return;
      } else {
        doc.update({
          Artist_subscribe_uid:
            firebase.firestore.FieldValue.arrayUnion(artistuid),
        });
      }

      var db = dbService.collection("user_Public").doc(ArtistProduct?.data.uid);
      const newSubscribe_value = ArtistProduct?.artistuser_subscribe + 1;
      return dbService
        .runTransaction((transaction) => {
          // This code may get re-run multiple times if there are conflicts.
          return transaction.get(db).then((sfDoc) => {
            if (!sfDoc.exists) {
              throw "Document does not exist!";
            }
            transaction.update(db, {
              artistuser_subscribe: newSubscribe_value,
            });
            // setsubscribe(newPopulation);
          });
        })
        .then(() => {
          dispatch(
            ArtistProductActions.set_New_artist_subscribe_Value(
              newSubscribe_value
            )
          );
          user_post_subscribe_Active();
          //누가구독했는지?
        })
        .catch((error) => {
          console.log("Transaction failed: ", error);
        });
    });

    // 이유저가 아티스트를 구독중이라면 diabled 계속
  };

  const user_post_subscribe_Active = async () => {
    var db = dbService.collection("user_Public").doc(user?.uid);
    const user_Subscribe_value = user.user?.user_artist_subscribe + 1;
    return dbService
      .runTransaction((transaction) => {
        return transaction.get(db).then((sfDoc) => {
          if (!sfDoc.exists) {
            throw "Document does not exist!";
          }
          transaction.update(db, {
            user_artist_subscribe: user_Subscribe_value,
          });
        });
      })
      .then(() => {
        dispatch(UserActions.set_user_artist_subscribe(user_Subscribe_value));
        setsubscribe_disabled(true);
        //새로운 구독 컬렉션만들어야함
      })
      .catch((error) => {
        console.log("Transaction failed: ", error);
      });
  };

  const setratingvalue = async (newValue) => {
    if (user.is_login === false || user.user === null) {
      setLoginModalopen(true);
      return;
    }
    if (rating_disabled) {
      return;
    }

    const artistuid = {
      artist_uid: ArtistProduct?.id,
      rating_Check: true,
    };

    var doc = dbService.collection("user_Public").doc(user?.uid);
    doc.get().then((docSnapshot) => {
      const filterUID = docSnapshot
        .data()
        ?.Artist_rating.filter((item) => item.artist_uid === ArtistProduct?.id);
      const artist_uid = filterUID.map((item) => item.artist_uid);
      // ddddd.indexOf(ArtistProduct?.id)

      if (artist_uid == `${ArtistProduct?.id}`) {
        // alert("이미 별점을 메겻당.");
        return;
      } else {
        doc.update({
          Artist_rating: firebase.firestore.FieldValue.arrayUnion(artistuid),
        });
      }
    });

    var ratingValue = [];
    ratingValue.push(ArtistProduct?.ratingvalue, newValue);
    var ratingValue_answer =
      ratingValue.reduce((sum, el) => sum + el, 0) / ratingValue.length;

    var db = dbService.collection("Song_All").doc(id);
    return dbService
      .runTransaction((transaction) => {
        // This code may get re-run multiple times if there are conflicts.
        return transaction.get(db).then((sfDoc) => {
          if (!sfDoc.exists) {
            throw "Document does not exist!";
          }

          transaction.update(db, { rating: ratingValue_answer });
        });
      })
      .then(() => {
        dispatch(ArtistProductActions.set_New_RatingValue(ratingValue_answer));
        setrating_disabled(true);
      })
      .catch((error) => {});
  };

  const closeAlertModal = () => {
    setAlert({
      type: "",
      text: "",
      show: false,
    });
  };

  const language_Currency_Amount = (song) => {
    if (i18n.language === "kor") {
      return song.data.KOR;
    } else if (i18n.language === "en") {
      return song.data.USD;
    } else if (i18n.language === "ja") {
      return song.data.JPY;
    } else {
      return song.data.USD;
    }
  };

  async function init() {
    player = new Player();
  }

  return (
    <>
      <Desktop>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "26rem 0",
            }}
          >
            <CircularProgress color="secondary" size="5rem" />
          </div>
        ) : (
          <Grid display="flex" position="relative" width="100%">
            {/* <Grid position="relative" width="100%" overflow="hidden"> */}
            <Grid marginT="40px">
              <Grid
                display="flex"
                position="relative"
                width="100%"
                bg="#ffffff"
                margin="auto"
              >
                <Grid
                  width="80%"
                  display="flex"
                  position="relative"
                  bg="#ffffff"
                  justify="center"
                  margin="auto"
                  row
              
                >
                  <Grid
                    width="70%"
                    display="flex"
                    position="relative"
                    bg="#ffffff"
                    column
                    overflowx="hidden"

                  >
                    {midimode && (
                      <div id="sheet" class="contain2">
                        <div class="Artist_Product_midiPiano">
                          <MidiPiano
                            key={ArtistProduct?.id}
                            id={ArtistProduct?.id}
                            data={ArtistProduct?.Midi_url}
                            name={ArtistProduct.data?.songName}
                            player={player}
                          />
                        </div>
                      </div>
                    )}

                    <div id="pdf" class="contain3">
                      <div
                        class="Artist_Product_sheet_PDF"
                        style={{
                          width: 400 + "px",
                          marginTop: 600 + "px",
                          borderRadius: 10 + "px",
                          marginBottom: 20 + "px",
                          display:"block"
                        }}
                      >
                        <SheetPDF
                          key={ArtistProduct?.id}
                          id={ArtistProduct?.id}
                          data={ArtistProduct?.Sheet_url}
                        />
                      </div>

                      <div
                        class="Artist_Product_score_youtube"
                        style={{  marginTop: 600 + "px", marginBottom: 20 + "px" ,marginLeft: 3 + "%" , paddingRight: 20 + "px", width: 100 + "%"}}
                      >
                        <div class="Artist_Product_score"    style={{ width: 100 + "%" , height: 280 + "px"}}
                        ></div>
                        <div class="Artist_Product_score"  style={{ width: 100 + "%" , height: 280 + "px", marginTop: 40 +"px" }}>



                        </div>
                      </div>
                    </div>

                    <Grid display="flex" position="relative" column paddingR = "20px">
                      
                        <div
                          class="Artist_Product_Bottom_menu_mainbox"
                          style={{height: 300 + "px", width: 100 + "%" }}
                        >


                          <Grid
                            display="flex"
                            paddingB="30px"
                            column
                            backgroundColor="#fff"
                            bdrBottom="1px solid #7979795e"

                          >
                            <Grid
                              display="flex"
                              row
                            >
                              <Text
                                display="flex"
                                color="#7c7c7c"
                                size="20px"
                                lineHeight="32px"
                              >
                                {releasetime}
                              </Text>
                              <Text
                                display="flex"
                                color="#7c7c7c"
                                size="20px"
                                marginL="5px"
                                lineHeight="32px"
                              >
                                Release
                              </Text>
                            </Grid>

                            <div
                              className="Artist_Product_Bottom_menu_discrpction"
                              dangerouslySetInnerHTML={{
                                __html: ArtistProduct.Discription,
                              }}
                              style={{ width: "94%" }}
                            ></div>
                            {/* {songs?.data.Description} */}
                          </Grid>


                          <Grid
                            width="100%"
                            row
                            display="flex"
                            paddingT="20px"
                            paddingB="20px"
                            bdrBottom="1px solid #7979795e"
                          >
                            <Grid row display="flex"  width="60%">
                              
                                <Avatar
                                  src={ArtistProduct.artistuser_data?.photoURL}
                                  className="card-img-wrapper_artist"
                                  onClick={goToArtistMaingPage}
                                >
                                  {" "}
                                </Avatar>
                          
                              <Grid display="flex" column paddingL="5px" justify="center" margin="auto">
                                <span
                                  class="card-title_artist"
                                  onClick={goToArtistMaingPage}
                                >
                                  {ArtistProduct.artistuser_data?.displayName}
                                </span>

                                <div className="favorite__group">
                                  <div className="favorite__move">
                                    <FavoriteBorderIcon className="favorite__Icon" />
                                  </div>
                                  <h2 className="favorite__number">
                                    {ArtistProduct?.artistuser_subscribe}
                                  </h2>
                                </div>
                              </Grid>
                            </Grid> 


                            <Grid
                            width="40%"
                            display="flex"
                            column
                            position="relative"
                            align ="end"
                            paddingR="10px"
                          >
                           

                            <Grid
                              display="flex"
                              row
                         
                              // margin="auto"
                              // marginT="20px"
                              width="120px"
                            >
                              <Button
                                MainPage
                                backgroundcolor="#BA4147"
                                color="#fff"
                                borderradius="12px"
                               
                                _onClick={artist_subscribe_Active}
                                disabled={subscribe_disabled}
                                // backgroundColor: "#ff3d47",
                                // onClick={subscribeActive}
                              >
                                구독하기
                              </Button>


                              {/* <Button
                                MainPage
                                marginL="10px"
                                backgroundcolor="#ff6d75"
                                color="#fff"
                                borderradius="12px"
                                width="80px"
                                _onClick={goTo_Question}
                                // disabled={subscribe_disabled}
                                // backgroundColor: "#ff3d47",
                                // onClick={subscribeActive}
                              >
                                문의하기
                              </Button> */}


                            </Grid>
                          </Grid>


                          </Grid>



                         
                        </div>
                      
                    </Grid>
                  </Grid>

                  <div
                    class="Artist_Product_right_panel2"
                    style={{ width: 30 + "%", height: 100 + "%" }}
                  >
                    <div
                      class="Artist_Product_right_panel"
                      // style={{ height: height755 }}
                      style={{height : 550 + "px"}}
                    >
                       <div class="Artist_Product_right_panel_artistAlbum">
                       <img
                          src={ArtistProduct.data?.Elbum_url}
                          alt=""
                          className="Artist_Product_right_panel_artistAlbum_image"
                        />
                        <div class="Artist_Product_right_panel_songName">  
                        <FavoriteBorderIcon className="Artist_Product_right_panel_favorite__Icon" />
                        </div> 
                      </div>  
                      <div class="Artist_Product_right_panel_songName2">
                      {ArtistProduct.data?.songName}
                      </div> 
                      <div class="Artist_Product_right_panel_grid">
                        <Grid className="one" column>
                        <Text
                              textalign="left"
                              color="#888888"
                              size="12px"
                            >
                              장르
                            </Text>
                        <Text
                              textalign="left"
                              color="#000000"
                              size="15px"
                              fontfamily="Arial, Helvetica, sans-serif"
                              marginT="2px"
                            >
                              {ArtistProduct.data?.genres}
                            </Text>
                        </Grid>
                        <Grid className="one" column>
                        <Text
                              textalign="left"
                              color="#888888"
                              size="12px"
                            >
                              원곡 아티스트 명
                            </Text>
                        <Text
                              textalign="left"
                              color="#000000"
                              size="15px"
                              fontfamily="Arial, Helvetica, sans-serif"
                              marginT="2px"
                            >
                              {ArtistProduct.data?.artistName}
                            </Text>
                        </Grid>
                        <Grid className="one" column>
                        <Text
                              textalign="left"
                              color="#888888"
                              size="12px"
                            >
                              다운로드 
                            </Text>
                        <Text
                              textalign="left"
                              color="#000000"
                              size="15px"
                              fontfamily="Arial, Helvetica, sans-serif"
                              marginT="2px"
                            >
                               {ArtistProduct.data?.update}
                            </Text>
                        </Grid>
                        <Grid className="one" column>
                        <Text
                              textalign="left"
                              color="#888888"
                              size="12px"
                            >
                              난이도
                            </Text>
                        <Text
                              textalign="left"
                              color="#000000"
                              size="15px"
                              fontfamily="Arial, Helvetica, sans-serif"
                              marginT="2px"
                            >
                               {ArtistProduct.data?.hardest}
                            </Text>
                        </Grid>
                        <Grid className="one" column>
                        <Text
                              textalign="left"
                              color="#888888"
                              size="12px"
                            >
                              좋아요
                            </Text>
                        <Text
                              textalign="left"
                              color="#000000"
                              size="15px"
                              fontfamily="Arial, Helvetica, sans-serif"
                              marginT="2px"
                            >
                              {ArtistProduct?.faviorite}
                            </Text>
                        </Grid>
                        <Grid className="one" column>
                        <Text
                              textalign="left"
                              color="#888888"
                              size="12px"
                            >
                              편곡유무
                            </Text>
                        <Text
                              textalign="left"
                              color="#000000"
                              size="15px"
                              fontfamily="Arial, Helvetica, sans-serif"
                              marginT="2px"
                            >
                                {ArtistProduct?.edit}
                            </Text>
                        </Grid>
                        <Grid className="one" column>
                        <Text
                              textalign="left"
                              color="#888888"
                              size="12px"
                            >
                              PDF 페이지수
                            </Text>
                        <Text
                              textalign="left"
                              color="#000000"
                              size="15px"
                              fontfamily="Arial, Helvetica, sans-serif"
                              marginT="2px"
                            >
                              2
                            </Text>
                        </Grid>
                        <Grid className="one" column>
                        <Text
                              textalign="left"
                              color="#888888"
                              size="12px"
                            >
                              평점
                            </Text>
                            <StyledRatingPlayer
                                name="hover-feedback"
                                readOnly
                                value={ArtistProduct?.ratingvalue}
                                precision={0.5}
                                // precision={0.5}
                                // onChange={(event, newValue) => {
                                //   setValue(songs?.ratingValue);
                                // }}
                                // onChangeActive={(event, newHover) => {
                                //   setHover(newHover);
                                // }}
                                size="medium"
                              />
                        </Grid>
                        
                      </div> 
                      {/* <div className="ratingsize">
                              <Grid marginT="0px">
                                <StyledRating
                                  name="hover-feedback"
                                  value={ArtistProduct?.ratingvalue}
                                  precision={0.25}
                                  onChange={(event, newValue) => {
                                    setratingvalue(newValue);
                                  }}
                                  onChangeActive={(event, newHover) => {
                                    setHover(newHover);
                                  }}
                                  size="large"
                                  disabled={rating_disabled}
                                />
                              </Grid>
                            </div>           */}
                    </div>

                    <div
                      class="Artist_Product_right_panel"
                      style={{height : 200 + "px" , marginTop : 30 + "px" }}
                    >
                    <Grid
                          column
                          display="flex"
                          borderRadius="15px"
                        >
                          <Text
                            textalign="right"
                            color="#000000"
                            bold
                            size="36px"
                            display="inline-block"
                            // border-bottom: 1px solid #97979754;
                            paddingT="0px"
                            justify="center"
                          >
                            {t("Payment.Currency")}{" "}
                            {language_Currency_Amount(ArtistProduct)}
                          </Text>
 
                          <Grid  bdrBottom ="1px solid #4b4b4b59" width="100%" paddingT="10px"></Grid>
                        </Grid>

                       
                          <Grid display="flex" row paddingT="30px">
                            <Button
                              MainPage
                              width="50px"
                              height="50px"
                              backgroundcolor="#ba4148"
                              color="#fff"
                              borderradius="12px"
                              display="flex"
                              _onClick={() =>
                                add_to_basket_page(
                                  ArtistProduct.id,
                                  ArtistProduct.data
                                )
                              }
                            >
                              장바구니
                            </Button>

                            <Button
                              MainPage
                              width="100%"
                              marginL ="5px"
                              height="50px"
                              backgroundcolor="#ba4148"
                              color="#fff"
                              borderradius="12px"
                              display="flex"
                              _onClick={() =>
                                go_to_basket_page(
                                  ArtistProduct.id,
                                  ArtistProduct.data
                                )
                              }
                            >
                              결제하기
                            </Button>
                          </Grid>


                    </div>


                  </div>
                </Grid>
              </Grid>

              {/* {sheetmode && (
                <div id="pdf" class="contain3">
                  <div
                    class="Artist_Product_sheet_PDF"
                    style={{ width: width, height: height75 }}
                  >
                    <SheetPDF
                      key={ArtistProduct?.id}
                      id={ArtistProduct?.id}
                      data={ArtistProduct?.Sheet_url}
                    />
                  </div>
                </div>
              )} */}

              {/* {youtubemode && (
                <div id="youtube" class="contain4">
                  <div class="youtube_main">
                    <div className="video-responsive">
                      <iframe
                        width={width}
                        height={height75}
                        autoplay={true}
                        src={`https://www.youtube.com/embed/${ArtistProduct?.youtube_EmbedID}?autoplay=1`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                      />
                    </div>
                  </div>
                </div>
              )} */}

              <ModalContainer show={LoginModalopen} close={closeModal} />
            </Grid>
          </Grid>
        )}
        <AlertModalContainer
          show={alert.show}
          close={closeAlertModal}
          type={alert.type}
          text={alert.text}
        />
      </Desktop>

      <Mobile>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "26rem 0",
            }}
          >
            <CircularProgress color="secondary" size="5rem" />
          </div>
        ) : (
          <Grid position="relative" width="100%" overflow="hidden">
            <Grid display="flex" column position="relative">
              {midimode && (
                <div id="sheet" class="contain2">
                  <div class="Artist_Product_midiPiano">
                    <MidiPiano
                      key={ArtistProduct?.id}
                      id={ArtistProduct?.id}
                      data={ArtistProduct?.Midi_url}
                      name={ArtistProduct.data?.songName}
                      player={player}
                    />
                  </div>
                </div>
              )}

              {sheetmode && (
                <div id="pdf" class="contain3">
                  <div
                    class="Artist_Product_sheet_PDF"
                    style={{ width: "100%" }}
                  >
                    <SheetPDF
                      key={ArtistProduct?.id}
                      id={ArtistProduct?.id}
                      data={ArtistProduct?.Sheet_url}
                    />
                  </div>
                </div>
              )}

              {/* {youtubemode && (
          <div id="youtube" class="contain4">
            <div class="youtube_main" > 
                <div className="video-responsive">
                <iframe
                  width={width}
                  height={height75 }
                  autoplay={true}
                  src={`https://www.youtube.com/embed/${ArtistProduct?.youtube_EmbedID}?autoplay=1`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              </div>
            </div>
          </div>
        )} */}

              {midimode == true ? (
                <div
                  class="Artist_Product_Bottom_menu"
                  style={{
                    marginTop: heightMobile,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Grid
                    width="100%"
                    display="flex"
                    paddingB="20px"
                    paddingT="20px"
                    bdrTop="1px dashed #7979795e"
                    bdrBottom="1px dashed #7979795e"
                  >
                    <Grid
                      width="30%"
                      column
                      paddingT="2%"
                      paddingB="2%"
                      paddingL="2%"
                      margin="auto"
                    >
                      <Grid row display="flex" margin="auto">
                        <a onClick={goToArtistMaingPage}>
                          <Avatar
                            src={ArtistProduct.artistuser_data?.photoURL}
                            className="card-img-wrapper_artist"
                          >
                            {" "}
                          </Avatar>
                        </a>
                        <Grid display="flex" column paddingL="5px">
                          <span
                            class="card-title_artist"
                            onClick={goToArtistMaingPage}
                          >
                            {ArtistProduct.artistuser_data?.displayName}
                          </span>

                          <div className="favorite__group">
                            <div className="favorite__move">
                              <FavoriteBorderIcon className="favorite__Icon" />
                            </div>
                            <h2 className="favorite__number">
                              {ArtistProduct?.artistuser_subscribe}
                            </h2>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      width="52%"
                      height="100%"
                      display="flex"
                      column
                      backgroundColor="#fff"
                    >
                      <Grid display="flex" row marginT="10px" paddingL="20px">
                        <Text
                          display="flex"
                          color="#7c7c7c"
                          size="20px"
                          marginL="0px"
                          lineHeight="32px"
                          textalign="left"
                        >
                          {releasetime}
                        </Text>
                        <Text
                          display="flex"
                          color="#7c7c7c"
                          size="20px"
                          marginL="5px"
                          lineHeight="32px"
                          textalign="left"
                        >
                          Release
                        </Text>
                      </Grid>
                      <span
                        class="Artist_Product_Bottom_menu_discrpction"
                        style={{ width: "96%" }}
                      >
                        ssssssssssss21421sss241ssssssssss21421sss241ssssssssss21421sss241ssssssssss21421sss241421sss241421sss233ㅏㅏ41421kㅏㅏkkkㅏㅏㅏㅏㅏㅏssss241421sssssssssssssssssss241421sssssssssssssssssss241421sssssssssssssssssss241421sssssssssssssssssss241421s421sssssssssssssssssss241421421sssssssssssssssssss241421421sssssssssssssssssss241421421sssssssssssssssssss241421421sssssssssssssssssss241421ssssssssssssssssss241421sssssssssssssssssss241421sssssssssssssssssss241421ssssssssssssssssssss241421sssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss
                      </span>
                      {/* {songs?.data.Description} */}
                    </Grid>

                    <Grid width="18%" display="flex" column position="relative">
                      <Grid
                        column
                        display="flex"
                        row
                        margin="auto"
                        paddingL="2px"
                        paddingR="5%"
                      >
                        <Button
                          MainPage
                          margin="0px auto"
                          backgroundcolor="#ff6d75"
                          color="#fff"
                          borderradius="3px"
                          width="100%"
                          _onClick={artist_subscribe_Active}
                          disabled={subscribe_disabled}
                          // backgroundColor: "#ff3d47",
                          // onClick={subscribeActive}
                        >
                          구독하기
                        </Button>
                        <Button
                          MainPage
                          backgroundcolor="#ff6d75"
                          color="#fff"
                          borderradius="3px"
                          width="100%"
                          marginT="10px"
                          _onClick={goTo_Question}
                          // disabled={subscribe_disabled}
                          // backgroundColor: "#ff3d47",
                          // onClick={subscribeActive}
                        >
                          문의하기
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              ) : (
                <Grid position="relative" display="flex" column width="100%">
                  <Grid
                    width="100%"
                    display="flex"
                    paddingB="20px"
                    paddingT="20px"
                    bdrTop="1px dashed #7979795e"
                    bdrBottom="1px dashed #7979795e"
                  >
                    <Grid
                      width="30%"
                      column
                      paddingT="2%"
                      paddingB="2%"
                      paddingL="2%"
                      margin="auto"
                    >
                      <Grid row display="flex" margin="auto">
                        <a onClick={goToArtistMaingPage}>
                          <Avatar
                            src={ArtistProduct.artistuser_data?.photoURL}
                            className="card-img-wrapper_artist"
                          >
                            {" "}
                          </Avatar>
                        </a>
                        <Grid display="flex" column paddingL="5px">
                          <span
                            class="card-title_artist"
                            onClick={goToArtistMaingPage}
                          >
                            {ArtistProduct.artistuser_data?.displayName}
                          </span>

                          <div className="favorite__group">
                            <div className="favorite__move">
                              <FavoriteBorderIcon className="favorite__Icon" />
                            </div>
                            <h2 className="favorite__number">
                              {ArtistProduct?.artistuser_subscribe}
                            </h2>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      width="52%"
                      height="100%"
                      display="flex"
                      column
                      backgroundColor="#fff"
                    >
                      <Grid display="flex" row marginT="10px" paddingL="20px">
                        <Text
                          display="flex"
                          color="#7c7c7c"
                          size="20px"
                          marginL="0px"
                          lineHeight="32px"
                          textalign="left"
                        >
                          {releasetime}
                        </Text>
                        <Text
                          display="flex"
                          color="#7c7c7c"
                          size="20px"
                          marginL="5px"
                          lineHeight="32px"
                          textalign="left"
                        >
                          Release
                        </Text>
                      </Grid>

                      <div
                        className="Artist_Product_Bottom_menu_discrpction"
                        dangerouslySetInnerHTML={{
                          __html: ArtistProduct.Discription,
                        }}
                        style={{ width: "96%" }}
                      ></div>
                      {/* {songs?.data.Description} */}
                    </Grid>

                    <Grid width="18%" display="flex" column position="relative">
                      <Grid
                        column
                        display="flex"
                        row
                        margin="auto"
                        paddingL="2px"
                        paddingR="5%"
                      >
                        <Button
                          MainPage
                          margin="0px auto"
                          backgroundcolor="#ff6d75"
                          color="#fff"
                          borderradius="3px"
                          width="100%"
                          _onClick={artist_subscribe_Active}
                          disabled={subscribe_disabled}
                          // backgroundColor: "#ff3d47",
                          // onClick={subscribeActive}
                        >
                          구독하기
                        </Button>
                        <Button
                          MainPage
                          backgroundcolor="#ff6d75"
                          color="#fff"
                          borderradius="3px"
                          width="100%"
                          marginT="10px"
                          _onClick={goTo_Question}
                          // disabled={subscribe_disabled}
                          // backgroundColor: "#ff3d47",
                          // onClick={subscribeActive}
                        >
                          문의하기
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              <Palette
                src={ArtistProduct.data?.Elbum_url}
                crossOrigin="anonymous"
                format="hex"
                colorCount={4}
              >
                {({ data, loading }) => {
                  if (loading) return <Loading />;
                  return (
                    <div
                      class="Artist_Product_Right_menu"
                      style={{
                        width: "100%",
                        backgroundColor: `${data[0]}`,
                      }}
                    >
                      <div
                        class="Artist_Product_Right_menu_song_name"
                        style={{ color: `${data[1]}` }}
                      >
                        {ArtistProduct.data?.songName}
                      </div>
                      <div
                        class="Artist_Product_Right_menu_artistName"
                        style={{ color: `${data[1]}` }}
                      >
                        <span> {ArtistProduct.data?.artistName}</span>
                      </div>
                      <Grid display="flex" row width="100%">
                        <Grid display="flex" column width="35%">
                          <Grid column width="100%" isflex margin="0px auto">
                            <img
                              src={ArtistProduct.data?.Elbum_url}
                              alt=""
                              className="product_song__image"
                            />
                            <Grid
                              align
                              row
                              width="100%"
                              paddingL="10%"
                              paddingR="10%"
                              justify="space-between"
                              display="flex"
                              margin="5px auto"
                            >
                              <IconButton
                                className="playIcon_list__optionsIcon"
                                onClick={sheet_playground}
                              >
                                <PictureAsPdfOutlinedIcon
                                  className="__Icon"
                                  style={{ color: `${data[2]}` }}
                                />
                              </IconButton>

                              <IconButton
                                className="playIcon_list__optionsIcon"
                                onClick={midi_playground}
                              >
                                <StraightenIcon
                                  className="PlayArrowIcon"
                                  style={{ color: `${data[2]}` }}
                                />
                              </IconButton>

                              <IconButton
                                className="playIcon_list__optionsIcon"
                                onClick={youtube_playground}
                              >
                                <YouTubeIcon
                                  className="__Icon"
                                  style={{ color: `${data[2]}` }}
                                />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid
                          display="flex"
                          width="65%"
                          margin="5px auto"
                          column
                        >
                          <Grid
                            row
                            display="flex"
                            width="100%"
                            paddingL="5%"
                            paddingR="5%"
                          >
                            <Grid marginL="10px" column>
                              <div
                                class="Artist_Product_SubBox_Info"
                                style={{ backgroundColor: `${data[1]}` }}
                              >
                                <Grid
                                  size="15px"
                                  paddingT="3px"
                                  width="40%"
                                  display="flex"
                                  margin="auto"
                                  align="center"
                                  justify="center"
                                >
                                  <ArrowDownwardIcon className="p_con_down_icon" />
                                  <Text
                                    textalign="center"
                                    color="#fff"
                                    size="15px"
                                    paddingT="0px"
                                    display="flex"
                                  >
                                    {ArtistProduct.data?.update}
                                  </Text>
                                </Grid>
                              </div>

                              <div
                                class="Artist_Product_SubBox_Info"
                                style={{ backgroundColor: `${data[1]}` }}
                              >
                                <Text
                                  margin="auto"
                                  textalign="center"
                                  color="#fff"
                                  size="15px"
                                  paddingT="3px"
                                  width="90%"
                                >
                                  {ArtistProduct.data?.hardest}
                                </Text>
                              </div>
                            </Grid>
                            <Grid marginL="10px" column>
                              <div
                                class="Artist_Product_SubBox_Info"
                                style={{ backgroundColor: `${data[1]}` }}
                              >
                                <Grid
                                  size="15px"
                                  paddingT="3px"
                                  width="90%"
                                  display="flex"
                                  margin="auto"
                                  align="center"
                                  justify="center"
                                >
                                  <FavoriteBorderIcon className="p_con_down_icon" />
                                  <Text
                                    textalign="center"
                                    color="#fff"
                                    size="15px"
                                    paddingT="0px"
                                    display="flex"
                                  >
                                    {ArtistProduct?.faviorite}
                                  </Text>
                                </Grid>
                              </div>

                              <div
                                class="Artist_Product_SubBox_Info"
                                style={{ backgroundColor: `${data[1]}` }}
                              >
                                <Text
                                  margin="auto"
                                  textalign="center"
                                  color="#fff"
                                  size="15px"
                                  paddingT="3px"
                                  width="90%"
                                >
                                  {ArtistProduct.data?.genres}
                                </Text>
                              </div>
                            </Grid>

                            <Grid marginL="10px" column>
                              <div
                                class="Artist_Product_SubBox_Info"
                                style={{ backgroundColor: `${data[1]}` }}
                              >
                                <Text
                                  margin="auto"
                                  textalign="center"
                                  color="#fff"
                                  size="15px"
                                  paddingT="3px"
                                  width="90%"
                                >
                                  {ArtistProduct.data?.displayName}
                                </Text>
                              </div>

                              <div
                                class="Artist_Product_SubBox_Info"
                                style={{ backgroundColor: `${data[1]}` }}
                              >
                                <Text
                                  margin="auto"
                                  textalign="center"
                                  color="#fff"
                                  size="15px"
                                  paddingT="3px"
                                  width="90%"
                                >
                                  {ArtistProduct?.edit}
                                </Text>
                              </div>
                            </Grid>
                          </Grid>

                          <Grid
                            display="flex"
                            width="100%"
                            margin="15px auto"
                            paddingL="5%"
                            paddingR="5%"
                          >
                            <Grid
                              display="flex"
                              width="40%"
                              bg={`${data[2]}`}
                              borderRadius="15px"
                            >
                              <Text
                                margin="auto"
                                textalign="center"
                                color="#fff"
                                bold
                                size="24px"
                                display="inline-block"
                                width="90%"
                                paddingT="0px"
                              >
                                {t("Payment.Currency")}{" "}
                                {language_Currency_Amount(ArtistProduct)}
                              </Text>
                            </Grid>

                            <Grid
                              display="flex"
                              width="60%"
                              margin="auto"
                              column
                              marginL="3%"
                            >
                              <Grid width="100%" display="flex">
                                <Button
                                  MainPage
                                  width="60%"
                                  height="50px"
                                  backgroundcolor={`${data[2]}`}
                                  color="#fff"
                                  borderradius="12px"
                                  display="flex"
                                  _onClick={() =>
                                    add_to_basket_page(
                                      ArtistProduct.id,
                                      ArtistProduct.data
                                    )
                                  }
                                >
                                  장바구니
                                </Button>

                                <Button
                                  MainPage
                                  marginL="5%"
                                  width="35%"
                                  height="50px"
                                  backgroundcolor={`${data[2]}`}
                                  color="#fff"
                                  borderradius="12px"
                                  // paddingL="20px"
                                  _onClick={Add_favorite_Song}
                                >
                                  좋아요
                                </Button>
                              </Grid>

                              <Grid width="100%" display="flex">
                                <Button
                                  MainPage
                                  width="100%"
                                  height="50px"
                                  backgroundcolor="#fff"
                                  color="#000"
                                  borderradius="12px"
                                  display="flex"
                                  marginT="5%"
                                  _onClick={() =>
                                    go_to_basket_page(
                                      ArtistProduct.id,
                                      ArtistProduct.data
                                    )
                                  }
                                >
                                  결제하기
                                </Button>
                              </Grid>

                              {/* <div class="Artist_Product_checkoutbox" onClick={gotoCheckout}>
        <span className="Artist_Product_checkoutbox_text">결제하기</span>
      </div> */}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  );
                }}
              </Palette>

              <ModalContainer show={LoginModalopen} close={closeModal} />
            </Grid>
          </Grid>
        )}
      </Mobile>
    </>
  );
}

export default Artist_Product;
