import React, { useEffect, useState , useRef} from "react";
import ReactWaterMark from "react-watermark-component";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { pdfjs, Document, Page, PageProps , } from "react-pdf";
import "./SheetPDF.css";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import RefreshIcon from "@material-ui/icons/Refresh";
import { IconButton, Button } from "@material-ui/core";
import { Grid, Text } from "../../elements/index";
import { useMediaQuery } from "react-responsive";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1367 });
  return isDesktop ? children : null;
};

const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 916, maxWidth: 1366 });
  return isTablet ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 915 });
  return isMobile ? children : null;
};


function SheetPDF({ id, data }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  // const [pageWidth, setpageWidth] = useState(600);
  const [pageWidth, setpageWidth] = useState(400);
  const [Mobile_pageWidth, setMobile_pageWidth] = useState(window.innerWidth * 0.7);
  const [arrpage, setarrpage] = useState([]);
  const [endpage, setendpage] = useState(10);
  const [pagegination, setpagegination] = useState(0);
  const [height75, setheight75] = useState(
    Math.floor(window.innerHeight) * 0.75
  );
  const canvasRef = useRef(null);
  const divRef = React.useRef<HTMLDivElement>(null);
  const ref = React.useRef()
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
    // setIsLoading(false);
    // canvas.filter = 'blur(4px)';
  }, []);


 
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setIsLoading(false);
    for (let j = 0; j < numPages; j++) {
      arrpage.push(j);
    }
  }


  function onDocumentLoadPrgress() {
    // setIsLoading(true);
    return <div
    style={{
      display: "flex",
      justifyContent: "center",
      margin: "auto",
    }}
  >
    <CircularProgress color="secondary" size="5rem" />
  </div>
  }


  function onclickPre() {
    if (pageNumber <= 1) {
      setPageNumber(1);
    } else {
      setPageNumber(pageNumber - 1);
    }
  }

  function onclickNext() {
    if (pageNumber >= numPages) {
      return;
    } else {
      setPageNumber(pageNumber + 1);
    }
  }

  function onclickIn() {
    setpageWidth(pageWidth + 100);
  }

  function onclickOut() {
    setpageWidth(pageWidth - 100);
  }
  function onclickInit() {
    setpageWidth(600);
  }

  const pagenagin = arrpage.map((item, i) => {
    return (
      <Button
        type="primary"
        size="small"
        onClick={() => clickPagination(i)}
        key={i}
      >
        {i + 1}
      </Button>
    );
  });

  const clickPagination = (i) => {
    let end = endpage * (i + 1);
    let start = end - endpage;
    let arr2 = [];
    setpagegination(i);
  };

  const text = "piannissmo";

  const options = {
    chunkWidth: 130,
    chunkHeight: 60,
    textAlign: "left",
    textBaseline: "bottom",
    globalAlpha: 15.17,
    font: "18px Microsoft Yahei",
    rotateAngle: -0.26,
    fillStyle: "#000",
  };

  return (
    <>
    <Desktop>
  
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "26rem 0",
            }}
          >
            <CircularProgress color="secondary" size="5rem" />
          </div>
        ) : (
      <>
      {/* <Grid position="relative"  display="flex">
        <div className="SheetPDF_all_button_wrapper">
          <Grid align="center" display="flex" margin="auto"  justify="center" width="400px" paddingL="140px">
         
              <IconButton className="pdf__optionsIcon" onClick={onclickPre}>
                <NavigateBeforeIcon className="pdf__Icon" />
              </IconButton>
              <Text size="11px" color="rgb(27, 27, 27)"> Page {pageNumber} of {numPages}</Text>
              <IconButton className="pdf__optionsIcon" onClick={onclickNext}>
                <NavigateNextIcon className="pdf__Icon" />
              </IconButton>
          </Grid>
          <div className="SheetPDF_right_button_wrapper" >
              <IconButton className="pdf__optionsIcon" onClick={onclickOut}>
                <ZoomOutIcon className="pdf__Icon2" />
              </IconButton>
           
              <IconButton className="pdf__optionsIcon" onClick={onclickInit}>
                <RefreshIcon className="pdf__Icon2" />
              </IconButton>
            
              <IconButton className="pdf__optionsIcon" onClick={onclickIn}>
                <ZoomInIcon className="pdf__Icon2" />
              </IconButton>
              </div>
        </div>
      </Grid> */}

      {/* <div className="SheetPDF_main_wrapper" style={{ height: height75 }}> */}
      <div className="SheetPDF_main_wrapper" style={{height: 600 + 'px' , }}>
        <Grid margin="auto" justify="center" display="block" >
          <Document
            file={data}
            onLoadSuccess={onDocumentLoadSuccess}
            className="container__wrapper"
            // onLoadProgress = { onDocumentLoadPrgress}
            loading = {onDocumentLoadPrgress}
            // onItemClick ={option}
          >
            {pageNumber >= 2 ? (
              <ReactWaterMark waterMarkText={text} options={options} children={{backgroundColor:"#fff" ,color:"#000" }}>
                <Page pageNumber={pageNumber} width={pageWidth} className="react-pdf"/>
              </ReactWaterMark>
            ) : (
              <Page pageNumber={pageNumber} width={pageWidth} canvasRef={ref} />
            )}
          </Document>
        </Grid>
      </div>
   
        
        </>
        )}
       
    </Desktop>

    <Mobile>
    <Grid>
        <div className="SheetPDF_all_button_wrapper">
          <Grid align="center" display="flex" margin="auto"  justify="center"  width="100%" >
         
              <IconButton className="pdf__optionsIcon" onClick={onclickPre}>
                <NavigateBeforeIcon className="pdf__Icon" />
              </IconButton>
              <Text size="11px" color="rgb(27, 27, 27)"> Page {pageNumber} of {numPages}</Text>
              <IconButton className="pdf__optionsIcon" onClick={onclickNext}>
                <NavigateNextIcon className="pdf__Icon" />
              </IconButton>

              {/* <div className="SheetPDF_right_button_wrapper" >
              <IconButton className="pdf__optionsIcon" onClick={onclickOut}>
                <ZoomOutIcon className="pdf__Icon2" />
              </IconButton>
           
              <IconButton className="pdf__optionsIcon" onClick={onclickInit}>
                <RefreshIcon className="pdf__Icon2" />
              </IconButton>
            
              <IconButton className="pdf__optionsIcon" onClick={onclickIn}>
                <ZoomInIcon className="pdf__Icon2" />
              </IconButton>
              </div> */}

          </Grid>
         
        </div>
   

      <div className="SheetPDF_main_wrapper">
        {/* <div className ='thumnail'>
                  {pagenagin}
                </div> */}
        <Grid margin="auto" justify="center" display="flex" >
          <Document
            file={data}
            onLoadSuccess={onDocumentLoadSuccess}
            className="container__wrapper"
            // onItemClick ={option}
          >
            {pageNumber >= 2 ? (
              <ReactWaterMark waterMarkText={text} options={options}>
                <Page pageNumber={pageNumber} width={Mobile_pageWidth} />
              </ReactWaterMark>
            ) : (
              <Page pageNumber={pageNumber} width={Mobile_pageWidth} />
            )}
          </Document>
        </Grid>
      </div>
    </Grid>
    </Mobile>

    </>
  );
}

export default SheetPDF;
