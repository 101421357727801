import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Genres_Slider.css";
import Color, { Palette } from "color-thief-react";
import { useMediaQuery } from "react-responsive";

const settings = {
  dots: false,
  infinite: true,
  speed: 1500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplaySpeed: 8000,
  autoplay: true,
  pauseOnHover: false,
  pauseOnFocus: false,
  fade: true,
};

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1367 });
  return isDesktop ? children : null;
};

const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 916, maxWidth: 1366 });
  return isTablet ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 915 });
  return isMobile ? children : null;
};


const GenresSlider = (data) => {
  const [slider2, setslider] = useState();
  const Loading = () => <span>Loading...</span>;

  useEffect(() => {
    // console.log(data)
  }, []);

  return (
    <>
    <Desktop>
    <div className="Genresslider">
      <Slider ref={(slider) => setslider(slider)} {...settings}>
        {data.data.map((song, index) => (
          <>
          <div>
            <div className="Genresslider-image_opacity1">
              <img
                src={song.data.Elbum_url}
                alt=""
                className="Genresslider__image25"
                data-slick='{"zIndex": 1}'
              />
            </div>

            <div className="Genresslider-image_opacity0">
              <Palette
                src={song.data.Elbum_url}
                crossOrigin="anonymous"
                format="hex"
                colorCount={6}
              >
                {({ data, loading }) => {
                  if (loading) return <Loading />;
                  return (
                    <div>
                      <h2
                        className="Genresslider_text2"
                        style={{ color: `${data[4]}` }}
                      >
                        {song.data.artistName}
                      </h2>
                      <h2
                        className="Genresslider_text"
                        style={{ color: `${data[2]}` }}
                      >
                        {song.data.songName}
                      </h2>
                      <div className="Genresslider__image24"    style={{ backgroundColor :  `${data[0]}`}}></div>
                    </div>

                  );
                }}
              </Palette>  
            </div>
          </div>
          </>
        ))}
      </Slider>
    </div>
    </Desktop>

    <Tablet>
    <div className="Genresslider">
      <Slider ref={(slider) => setslider(slider)} {...settings}>
        {data.data.map((song, index) => (
          <>
          <div>
            <div className="Genresslider-image_opacity1">
              <img
                src={song.data.Elbum_url}
                alt=""
                className="Genresslider__image25"
                data-slick='{"zIndex": 1}'
              />
            </div>

            <div className="Genresslider-image_opacity0">
              <Palette
                src={song.data.Elbum_url}
                crossOrigin="anonymous"
                format="hex"
                colorCount={6}
              >
                {({ data, loading }) => {
                  if (loading) return <Loading />;
                  return (
                    <div>
                      <h2
                        className="Genresslider_text2"
                        style={{ color: `${data[4]}` }}
                      >
                        {song.data.artistName}
                      </h2>
                      <h2
                        className="Genresslider_text"
                        style={{ color: `${data[2]}` }}
                      >
                        {song.data.songName}
                      </h2>
                      <div className="Genresslider__image24"   style={{ backgroundColor :  `${data[0]}`}}></div>
                    </div>

                  );
                }}
              </Palette>  
            </div>
          </div>
          </>
        ))}
      </Slider>
    </div>
    </Tablet>

    <Mobile>
    <div className="Genresslider">
      <Slider ref={(slider) => setslider(slider)} {...settings}>
        {data.data.map((song, index) => (
          <>
          <div>
            <div className="Genresslider-image_opacity1">
              <img
                src={song.data.Elbum_url}
                alt=""
                className="Genresslider__image25"
                data-slick='{"zIndex": 1}'
              />
            </div>

            <div className="Genresslider-image_opacity0">
              <Palette
                src={song.data.Elbum_url}
                crossOrigin="anonymous"
                format="hex"
                colorCount={6}
              >
                {({ data, loading }) => {
                  if (loading) return <Loading />;
                  return (
                    <div>
                      <h2
                        className="Genresslider_text2"
                        style={{ color: `${data[4]}` }}
                      >
                        {song.data.artistName}
                      </h2>
                      <h2
                        className="Genresslider_text"
                        style={{ color: `${data[2]}` }}
                      >
                        {song.data.songName}
                      </h2>
                      <div className="Genresslider__image24"   style={{ backgroundColor :  `${data[0]}`}}></div>
                    </div>

                  );
                }}
              </Palette>  
            </div>
          </div>
          </>
        ))}
      </Slider>
    </div>
    </Mobile>

    </>
  );
};

export default GenresSlider;
