import React, { useEffect, useState, useMemo,} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Artist_SideBar.css';
import { useHistory,  Link } from 'react-router-dom';
import { Grid, Button,  Text } from "../../../elements/index";
import styled from "styled-components";
import { BorderTop } from '@material-ui/icons';


const Artist_SideBar = () => {

  const user = useSelector((state) => state.user);
  const history = useHistory();






  return (
          <>
          <div id="MyHome_sidebar_wrapper" class="MyHome_sidebar">
                <Text color = "rgb(99, 99, 99)" size= "32px"  textalign="left" marginT="30px" lineHeight="50px" paddingL="30px">Artist</Text>
                <Text color = "rgb(99, 99, 99)" size= "32px"  textalign="left" marginT="4px" marginB="30px" lineHeight="14px" paddingL="30px">Dashboard</Text>

                <div id="navigation">
                  <ul class="MyHome_list">
                    <li><Link to={"/MyHome"}>HOME</Link></li>
                    <li><Link to={`/Artist/main/${user?.uid}`}>아티스트 페이지</Link></li>
                    <li><Link to={"/MyHome/ProductUpload"}>악보 업로드</Link></li>
                    <li><Link to={`/MyHome/BoardManagement/${user?.uid}`}>게시판관리</Link></li>
                    <li><Link to={`/MyHome/CalculateAmount/${user?.uid}`}>정산하기</Link></li>
                    <li style={{borderBottom : "1px solid #c2c2c2ad" , marginLeft : "10px" , marginRight: "10px"}}></li>

                    <li><Link to={"/Product/PurchaseProduct"}>결제 곡 다운로드</Link></li>
                    <li><Link to={`/MyHome/PaymentHistory/${user?.uid}`}>결제내역</Link></li>
                    <li><Link to={"/Checkout/ProductBasket"}>장바구니</Link></li>
                    {/* 구독한 아티스트 */}
                  </ul>
                </div>
              </div>
  
       </>
     
     

  );
};


export default Artist_SideBar;

