import { createAction, handleActions } from "redux-actions";
import axios from "axios";
import { setLocal, deleteLocal } from "../../shared/Local";

 const CALCULATE_AMOUNT_DATA = "NOTICE_DATA";

const initialState = {
  CalculateAmountData: "",
  sortedData_DATE:[],
  KOR : "",
  EUR : "",
  JPY : "",
  CNY : "",
  USD : "",
};

// 회원 가입
const set_CalculateAmount_Data = (CalculateAmountData ,sortedData_DATE) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type:  CALCULATE_AMOUNT_DATA,
      CalculateAmountData : CalculateAmountData.Song_purchaseProduct,
      sortedData_DATE: sortedData_DATE,
      KOR : CalculateAmountData.KOR,
      EUR : CalculateAmountData.EUR,
      JPY : CalculateAmountData.JPY,
      CNY : CalculateAmountData.CNY,
      USD : CalculateAmountData.USD,
    });  
  };
};






const CalculateAmount = (state = initialState, action) => {
  switch (action.type) {
  
      case CALCULATE_AMOUNT_DATA:
        return {
          ...state,
          CalculateAmountData : action.CalculateAmountData,
          sortedData_DATE: action.sortedData_DATE,
          KOR : action.KOR,
          EUR : action.EUR,
          JPY : action.JPY,
          CNY : action.CNY,
          USD : action.USD,
        }

        // case NOTICE_LOOKUP:
        //   const user_artistuser = Object.assign(state.noticeData, action.lookUp, );
        // console.log(action.lookUp)
        // console.log(user_artistuser)
        // return {
        //   ...state,
        // }

        
    default:
      return state
  }
};

export default CalculateAmount;


const actionCreators = {
  set_CalculateAmount_Data,
};

export { actionCreators };
