import { createAction, handleActions } from "redux-actions";
import axios from "axios";
import { setLocal, deleteLocal } from "../../shared/Local";

 const ARTISTMAINGPAGE_SONG = "ARTISTMAINGPAGE_SONG";
 const ARTISTMAINGPAGE_ARTIST = "ARTISTMAINGPAGE_ARTIST"; 
 const ARTISTMAINGPAGE_ARTIST_SUBSCIRBE = "ARTISTMAINGPAGE_ARTIST_SUBSCIRBE"; 


const initialState = {
  Song: "",
  Artist: "",
  ArtistID: "",
  ArtistSubScribe: "",
};

const set_ArtistMainPage_Song = (song) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: ARTISTMAINGPAGE_SONG,
      Song : song,
    });  
  };
};

const set_ArtistMainPage_Artist = (artist , artistID, artistSubScribe) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: ARTISTMAINGPAGE_ARTIST,
      Artist : artist,
      ArtistID : artistID,
      ArtistSubScribe : artistSubScribe,
    });  
  };
};

const set_ArtistMainPage_Artist_subscribe = (SubScribe) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: ARTISTMAINGPAGE_ARTIST_SUBSCIRBE, 
      ArtistSubScribe : SubScribe,
    });  
  };
};


const ArtistMainPage = (state = initialState, action) => {
  switch (action.type) {
  
      case ARTISTMAINGPAGE_SONG:
        return {
          ...state,
          Song : action.Song,
        }

        case ARTISTMAINGPAGE_ARTIST:
          return {
            ...state,
            Artist : action.Artist,
            ArtistID : action.ArtistID,
            ArtistSubScribe: action.ArtistSubScribe,

          }

          case ARTISTMAINGPAGE_ARTIST_SUBSCIRBE:
          return {
            ...state,
            ArtistSubScribe : action.ArtistSubScribe,
          }

    default:
      return state
  }
};

export default ArtistMainPage;


const actionCreators = {
  set_ArtistMainPage_Artist,
  set_ArtistMainPage_Song,
  set_ArtistMainPage_Artist_subscribe,
};

export { actionCreators };
