import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { dbService } from "../../shared/firebase";
import { useDispatch, useSelector } from "react-redux";
import { Avatar } from "@material-ui/core";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { Grid, Button, Text, Input2 } from "../../elements/index";
import "./PaymentHistory.css";
import { CircularProgress } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { actionCreators as paymentHistoryactions } from "../../redux/modules/PaymentHistory";
import { Link, withRouter } from "react-router-dom";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import { ArtistSideBar , PublicSideBar  } from "../../components/index";

function PaymentHistory() {
  const paymentHistory = useSelector((state) => state.PaymentHistory);

  const history = useHistory();
  const { PaymentHistory } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    dbService
      .collection("user_Public")
      .doc(user?.uid)
      .collection("paymentHistory")
      .orderBy("paid_at", "desc")
      .get()
      .then((snapshot) => {
        const paymentHistory = snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));
        // console.log(paymentHistory);
        dispatch(paymentHistoryactions.set_PAYMENTHISTORY_DATA(paymentHistory));
        setIsLoading(false);
      });
  }, [history, dispatch, user]);

  const checkDate = (paidAt) => {
    var paidDate = new Date(paidAt.toDate());
    return `${paidDate.getFullYear()}.${
      paidDate.getMonth() + 1
    }.${paidDate.getDate()}`;
  };

  return (
    <Grid width="100%">
      <>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "26rem 0",
            }}
          >
            <CircularProgress color="secondary" size="5rem" />
          </div>
        ) : (
          <>
            <Grid
              display="flex"
              margin="0 auto"
              width="100%"
              paddingT="20px"
              paddingB="10px"
            >
                {user.user?.role === 'Public' ? (
          <>
          <PublicSideBar />
         </>
            ) : (
              <>
              <ArtistSideBar/>
              </>
           )}

              <div class="paymentHistory_main_card">
                <div className="paymentHistory_main_content">
                  <Grid paddingL="30px" paddingR="30px">
                    <Text
                      bold
                      textalign="left"
                      display="block"
                      size="21px"
                      marginT="24px"
                      color="#434343"
                    >
                      Payment History
                    </Text>
                    <Grid
                      width="100%"
                      bdrBottom="1px solid #EAEAEA"
                      marginT="16px"
                      display="flex"
                    ></Grid>
                  </Grid>
                  {/* <div className="paymentHistory_main_Circle">
                      결제내역
                  </div> */}
                  <table className="paymentHistory_table">
                    {/* <div className="Artist_song_chart"> */}
                    <colgroup>
                      <col width="5%" />
                      <col width="29%" />
                      <col width="21%" />
                      <col width="15%" />
                      <col width="10%" />
                      <col width="10%" />
                      <col width="10%" />
                    </colgroup>

                    <thead className="paymentHistory_table_thead">
                      <tr className="paymentHistory_table_thead_tr">
                        <th className="paymentHistory_tableHead_index">번호</th>
                        <th className="paymentHistory_tableHead_merchant_uid">
                          상품번호
                        </th>
                        <th className="paymentHistory_tableHead_merchant_name">
                          상품명
                        </th>
                        <th className="paymentHistory_tableHead_pg">
                          결제수단
                        </th>
                        <th className="paymentHistory_tableHead_amount">
                          액수
                        </th>
                        <th className="paymentHistory_tableHead_purchaseDate">
                          구매일자
                        </th>
                        <th className="paymentHistory_tableHead_question">
                          문의하기
                          {/* 관리자문의로 보내기 */}
                        </th>
                      </tr>
                    </thead>
                    {paymentHistory.PaymentHistory?.map((data, index) =>
                      index < 11 ? (
                        <tbody className="paymentHistory_tbody_table">
                          <tr className="paymentHistory_tbody_tr">
                            <td className="paymentHistory_tbody_index">
                              {index + 1}
                            </td>

                            <td className="paymentHistory_tbody_merchant_uid">
                              {data.data.merchant_uid}
                            </td>

                            <td className="paymentHistory_tbody_merchant_name">
                              {data.data.name}
                            </td>
                            <td className="paymentHistory_tbody_pg">
                              {data.data.pg}
                            </td>
                            <td className="paymentHistory_tbody_amount">
                              {data.data.currency}
                              {data.data.amount}
                            </td>

                            <td className="paymentHistory_tbody_paid_at">
                              {checkDate(data.data.paid_at)}
                            </td>
                            <td className="paymentHistory_tbody_paid_at">
                              <Button>문의하기</Button>
                            </td>
                          </tr>
                        </tbody>
                      ) : null
                    )}
                    {/* </div> */}
                  </table>
                </div>
              </div>
            </Grid>
          </>
        )}
      </>
    </Grid>
  );
}

export default PaymentHistory;
