import { createAction, handleActions } from "redux-actions";
import { produce } from "immer";
import axios from "axios";

import { setLocal, deleteLocal } from "../../shared/Local";

// import { actionCreators as loadingActions } from "redux/modules/loading";

const SET_USER = "SET_USER";
const SET_ARTISTUSER = "SET_ARTISTUSER";
const NO_USER = "NO_USER";
const SET_USER_ARTIST_SUBSCRIBE = "SET_USER_ARTIST_SUBSCRIBE";
const SET_DOWNLOAD_SONG = "SET_DOWNLOAD_SONG";
const SET_SHOPPING_BASKET = "SET_SHOPPING_BASKET";

const LOGIN_CHECK = "LOGIN_CHECK";
const LOGIN_MSG = "LOGIN_MSG";

const LOGIN_USER = "LOGIN_USER";
const LOGOUT_USER = "LOGOUT_USER";
const EDIT_PHOTOAVATAR = "EDIT_PHOTOAVATAR";
const EDIT_DISPLAYNAME = "EDIT_DISPLAYNAME";

const logIn = createAction(LOGIN_USER, (user) => ({ user }));
const logOut = createAction(LOGOUT_USER, (user) => ({ user }));
const setUser = createAction(SET_USER, (user) => ({ user }));
const loginCheck = createAction(LOGIN_CHECK, (token) => ({ token }));
const loginMsg = createAction(LOGIN_MSG, (msg) => ({ msg }));

const initialState = {
  uid: null,
  user: null,
  photoURL: null,
  displayName: null,
  is_login: false,
  login_msg: false,
  user_artist_subscribe: null,
  // user_download_song: null,
  // user_shopping_basket: null,
};

// 회원 가입
const userdb = (user) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: SET_USER,
      user: user,
      uid: user.uid,
      displayName: user.displayName,
      user_artist_subscribe: user.user_artist_subscribe,
      // user_download_song: user.user_download_song,
      // user_shopping_basket: user.user_shopping_basket,
      photoURL: user.photoURL,
      // setUser(user)
    });
  };
};

const set_user_download_song = (user_download_song) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: SET_DOWNLOAD_SONG,
      user_download_song: user_download_song,
    });
  };
};

const set_user_shopping_basket = (user_shopping_basket) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: SET_SHOPPING_BASKET,
      user_shopping_basket: user_shopping_basket,
    });
  };
};

const Artist_userdb = (artistuser) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: SET_ARTISTUSER,
      artistuser: artistuser,
    });
  };
};

const no_userdb = (user) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: NO_USER,
      // setUser(user)
    });
  };
};

const Edit_photoAvatar = (photo) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: EDIT_PHOTOAVATAR,
      photoURL: photo,
    });
  };
};

const Edit_displayname = (displayname) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: EDIT_DISPLAYNAME,
      displayName: displayname,
    });
  };
};

const set_user_artist_subscribe = (user_artist_subscribe) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: SET_USER_ARTIST_SUBSCRIBE,
      user_artist_subscribe: user_artist_subscribe,
    });
  };
};

// 일반 로그인
const loginAPI = (email, pw, autoLogin, saveId) => {
  return function (dispatch, getState, { history }) {
    // dispatch(loadingActions.loading(true));
  };
};

// 소셜로그인
const socialLoginDB = (_id) => {
  return function (dispatch, getState, { history }) {};
};

// 로그인 상태 체크
const isLogin = () => {
  return function (dispatch, getState, { history }) {
    const token = localStorage.getItem("access_token");
    const nickname = localStorage.getItem("nickname");
    const uid = localStorage.getItem("uid");

    if (!token || !nickname) {
      return;
    }
    dispatch(
      setUser({
        user: nickname,
        uid: uid,
      })
    );
  };
};

// 로그아웃 localstorage 삭제
const isLogout = () => {
  return function (dispatch, getState, { history }) {
    localStorage.removeItem("access_token");
    localStorage.removeItem("nickname");
    localStorage.removeItem("uid");
    // dispatch(logOut());
    history.replace("/");
  };
};

// 아이디 저장
const saveIdLocalstorage = (id) => {
  return function () {
    localStorage.setItem("saved_id", id);
  };
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.user,
        uid: action.uid,
        photoURL: action.photoURL,
        displayName: action.displayName,
        user_artist_subscribe: action.user_artist_subscribe,
        // user_download_song: action.user_download_song,
        // user_shopping_basket: action.user_shopping_basket,
        is_login: true,
        // isLoggedIn: action.isLoggedIn
      };

    case SET_ARTISTUSER:
      const user_artistuser = Object.assign(state.user, action.artistuser);
      return {
        ...state,
        user: user_artistuser,
        is_login: true,
      };

    case LOGOUT_USER:
      return {
        ...state,
        user: action.userData,
        isLoggedIn: action.isLoggedIn,
      };

    case NO_USER:
      return {
        ...state,
        is_login: false,
        user: null,
        uid: null,
        photoURL: null,
        displayName: null,
        user_artist_subscribe: null,
        // user_download_song: null,
        // user_shopping_basket: null,
      };
    case SET_USER_ARTIST_SUBSCRIBE:
      return {
        ...state,
        user_artist_subscribe: action.user_artist_subscribe,
      };

    case EDIT_PHOTOAVATAR:
      return {
        ...state,
        photoURL: action.photoURL,
      };
    case EDIT_DISPLAYNAME:
      return {
        ...state,
        displayName: action.displayName,
      };
      // case SET_SHOPPING_BASKET:
      //   return {
      //     ...state,
      //     user_shopping_basket: action.user_shopping_basket,
      //   };
      //   case SET_DOWNLOAD_SONG:
      //     return {
      //       ...state,
      //       user_download_song: action.user_download_song,
      //     };

          
    default:
      return state;
  }
};

export default user;

const actionCreators = {
  loginAPI,
  loginCheck,
  isLogin,
  isLogout,
  loginMsg,
  saveIdLocalstorage,
  // loginByKakao,
  socialLoginDB,
  userdb,
  no_userdb,
  Artist_userdb,
  set_user_artist_subscribe,
  Edit_photoAvatar,
  Edit_displayname,
  set_user_download_song,
  set_user_shopping_basket,
};

export { actionCreators };
