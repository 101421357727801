import React, { useState, useEffect } from "react";
import "./MainPage.css";
import { useHistory, useParams } from "react-router-dom";
import { Link, withRouter } from "react-router-dom";
import { MainPageSlider , Footer , ModalContainer , AlertModalContainer  } from "../../components/index";
import { Grid, Button, Text, Input2 } from "../../elements/index";
import { useDispatch, useSelector } from "react-redux";
import { dbService } from "../../shared/firebase";
import { IconButton, Menu, MenuItem, } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import { actionCreators as userActions } from "../../redux/modules/MainPage";
import WhatisPianissimo from "../../images/what_is_pianissimo8.svg";
import { useMediaQuery } from "react-responsive";
import { TabletAndroid } from "@material-ui/icons";
import Alert from 'react-popup-alert'
import { Avatar } from "@material-ui/core";
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { faEyeSlash as farEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { faEye as farEye } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1367 });
  return isDesktop ? children : null;
};

const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 916, maxWidth: 1366 });
  return isTablet ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 915 });
  return isMobile ? children : null;
};


const useStyles = makeStyles((theme) => ({
  menu: {
    "& .MuiPaper-root": {
      // borderRadius: "100px",
      boxShadow: "0px 6px 5px 0px #7979793d;",
    },
  },

  // MuiPaper-elevation8:'none'
}));

const MainPage = () => {
  const MainPage = useSelector((state) => state.MainPage);
  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const user = useSelector((state) => state.user);
  const [Modalopen, setModalOpen] = useState(false);
  const open = Boolean(anchorEl);

  const [alert, setAlert] = useState({
    type: 'error',
    text: 'This is a alert message',
    show: false
  })

  const { t, i18n } = useTranslation();

  useEffect(() => {
    dbService
      .collection("user_Public")
      //  .where("genres")
      .orderBy("artistuser_subscribe", "desc")
      .limit(10)
      .get()
      .then((snapshot) => {
        const admin_Artist = snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));
        if (!admin_Artist) {
          return;
        }
        dispatch(userActions.set_Artist_10(admin_Artist));

        dbService
          .collection("Song_All")
          .orderBy("update", "desc")
          .limit(10)
          .get()
          .then((snapshot) => {
            const update_song = snapshot.docs.map((doc) => ({
              id: doc.id,
              data: doc.data(),
            }));
            dispatch(userActions.set_song_10(update_song));
            setIsLoading(false);
          });
      });
  }, [
    history,
    dispatch,
  ]);

  useEffect(() => {
    console.log(MainPage, "data");
  }, [MainPage]);

  const openOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const language_Currency_Amount = (song) => {
    if (i18n.language === 'kor') {
      return song.data.KOR;
    } else if (i18n.language === 'en') {
      return song.data.USD;
    } else if (i18n.language === 'ja') {
      return song.data.JPY;
    } else {
      return song.data.USD;
    }
  };

  const closeModal = () => {
   setModalOpen(false);
  };

  const closeAlertModal = () => {
    setAlert({
      type: '',
      text: '',
      show: false
    })
   };

   const handleClose = (id) => {
    setAnchorEl(null);
    console.log(id)
  };

  const Add_to_Basket = (id, song) => {

    console.log(id)//RKKcHJ3pQkLmL4lF6swm
    if(user?.uid === undefined || user?.uid === null || user?.uid === ""){  
     setModalOpen(true);
     return;
    }


    var doc =  dbService.collection('user_Public').doc(user.uid).collection('product_basket').doc(id);
    doc.get()
    .then((docSnapshot) => {
      
      if (docSnapshot.exists) {
        setAlert({
          type: 'warning',
          text: '이미 있는 곡입니다.',
          show: true
        })
        
        return;
      } else {
        doc.set(song)
        setAlert({
          type: 'success',
          text: '장바구니에 추가되었습니다.',
          show: true
        })
   
      }
  });
    setAnchorEl(false)
  };


  const gotoArtist_Product = async (id) => {

    var db = dbService.collection("Song_All").doc(id);
    return dbService
      .runTransaction((transaction) => {
        // This code may get re-run multiple times if there are conflicts.
        return transaction.get(db).then((sfDoc) => {
          if (!sfDoc.exists) {
            throw "Document does not exist!";
          }
          var newLookUP = sfDoc.data().lookup + 1;
          transaction.update(db, { lookup: newLookUP });
        });
      })
      .then((    
      ) => {
        history.push(`/Artist_product/${id}`)
      })
      .catch((error) => {});
  };



  return (
    <>
    <Desktop>

    {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "26rem 0",
          }}
        >
          <CircularProgress color="secondary" size="5rem" />
        </div>
      ) : (
        <Grid position="relative"  width= "100%"   margin-top= "64px" height="auto"  >
          {/* <Homeslider  key={Home_HOT_SONG_10_chart?.length} data={Home_HOT_SONG_10_chart} /> */}
          <MainPageSlider key={MainPage.song?.length} data={MainPage.song} />
          <Grid position= "relative"  padding= "20px 0" width= "70%"  margin= "auto" background-color= "#ffffff"   >
            <Grid overflow= "hidden" margin-bottom= "40px" display="flex"    margin= "auto">
              <Grid width= "20%" position= "relative"  marginT="20px">
                  <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1rem" lineHeight="1.5em" letterspacing=".3em" color="#8086a0" marginL="2px">Pianissimo</Text>
                  <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1.8rem" lineHeight="1.1em" color="#723434" > {t('Main_Page.Artist10')}</Text>


                  <table className="Header_artist_table">

                  <colgroup>
                      <col width="15%" />
                      <col width="25%" /> 
                      <col width="60%" />
                    </colgroup>


                {MainPage.artist?.map((song, index) =>
                  index < 10 ? (
                    <tbody className="Header_artist_SUBtable" onClick={() => history.push(`/Artist/main/${song.id}`)}>
                          <tr className="Header_song_tr" >
                            <td className="Header_artist_index">{index + 1}</td>
                            <td className="Header_artist_photoURL">
                             <Avatar
                              src={song?.data.photoURL}
                              className="Header_artist_avater"

                            />
                            </td>
                            <td className="Header_artist_displayName">{song.data.displayName}
                            </td>

                            
                            
                            </tr>
                            </tbody>
                    
                   
                  ) : null
                )}
                </table>

                  <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1rem" lineHeight="1.5em" letterspacing=".3em" color="#8086a0" marginL="2px" marginT="50px">Pianissimo</Text>
                  <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1.8rem" lineHeight="1.1em" color="#723434" >{t('Main_Page.What_is_Pianissimo')}</Text>
                <div class="Header_Pianissimo_description" >
                  <img src= {WhatisPianissimo} alt="" class="Header_Pianissimo_description_img"></img>
                  <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="12px" lineHeight="1.5em" letterspacing=".1em" paddingL="5px"  paddingR="5px" color="#8086a0" marginL="0px" textalign="center" width="100%">Pianissimo는 MIDI, 악보, 아티스트들의 YOUTUBE로 누구나 원하는 피아노곡을 연주 가능하게 하는 플랫폼입니다</Text>
                </div>
              </Grid>

              <div className="mainpage_top_song10">
                  <Text  fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1rem" lineHeight="1.5em" letterspacing=".3em" color="#8086a0" marginL="50px">Pianissimo</Text>
                  <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1.8rem" lineHeight="1.1em" color="#723434" marginL="50px">{t("Main_Page.Song10")}</Text>
               
                {/* <a href="shop/prd_hit_list.php" class="more">View All</a> */}

                <table className="Header_song_table">

                <colgroup>
                <col width="8%" />
                 <col width="42%" /> 
                 {/* 50 */}
                 <col width="16%" />
                 <col width="10%" />
                 <col width="9%" />
                 <col width="9%" />
                 <col width="9%" />
                 <col width="7%" />
                  </colgroup>



                    <thead className="Header_song_thead_tr">
                      <tr className="Header_song_thead_thead_tr">
                        <th className="Header_songhead_thead2">{t('Chart.Ranking')}</th>
                        <th className="Header_songhead_songname">
                        {t('Chart.Song_name')} / {t('Chart.Artist_name')}
                        </th>
                        <th className="Header_songhead_country">
                        {t('Chart.Genre')} / {t('Chart.Composition')}
                        </th>
                        <th className="Header_songhead_price">{t('Chart.Price')}</th>
                        <th className="Header_songhead_view">View</th>

                        <th className="Header_songhead_download">{t('Chart.Download')}</th>
                        <th className="Header_songhead_like">{t('Chart.Like')}</th>

                        {/* <td className= 'Header_songhead_listen'>{t('Main_Page.Listening')}</td>  */}
                        <th className="Header_songhead_damgi">{t('Chart.Add')}</th>
                      </tr>
                    </thead>

                    {MainPage.song?.map((song, index) =>
                      index < 10 ? (
                        <tbody className="Header_song_subtable"  
               
                                // history.push(`/Artist_product/${song.id}`)
                              >
                          <tr className="Header_song_tr">
                            <td className="Header_song_index"
                              onClick={() =>  gotoArtist_Product(song.id)}
                            >{index + 1}</td>



                            <td
                              className="Header_song_td"
                              onClick={() =>  gotoArtist_Product(song.id)}
                            >

                              <img
                                src={song.data.Elbum_url}
                                alt=""
                                className="Header_song__image"
                              />
                          
                            <span className="Header_song_songName">
                                {song.data.songName}
                                <span className="Header_song_artistname">
                                  - {song.data.artistName}
                                </span>
                              </span>

                             
                            </td>

                            <td className="Header_song_country"
                              onClick={() =>  gotoArtist_Product(song.id)}
                            >
                              {song.data.genres} / {song.data.hardest}
                            </td>
                            {/* <td className= 'Very_Hard_price'>{song.data.price}</td> */}

                            {/* <div className="Header_song_Icon">
                              <ArrowDownwardIcon className="ArrowDownward__Icon" />
                            </div> */}

                            <td className="Header_song_price"
                              onClick={() =>  gotoArtist_Product(song.id)}
                            >
                            {t('Payment.Currency')}  {language_Currency_Amount(song)}
                            </td>
                        
                            <td className="Header_song_view">
                              {song.data.lookup}
                              <FontAwesomeIcon icon={farEye} className="farEye__Icon" />
                            </td>

                            <td className="Header_song_upgrade">
                              {song.data.update}
                              <ArrowDownwardIcon className="ArrowDownward__Icon" />
                            </td>

                            <td className="Header_song_like">
                            {song.data.faviorite}
                            <FavoriteBorderIcon className="ArrowDownward__Icon" />

                            </td>


                            <td className="Header_song_AddIcon">
                              <ul class="MainPage_basket_menu">
                                  <li>
        
                                    <IconButton
                                              aria-haspopup="true"
                                              onClick={openOptions}
                                              className="MainPage_basket_menu_icon"
                                            >
                                              <AddIcon className="MainPage_tbody_add_Icon" />
                                  </IconButton>
                                    <div class="MainPage_basket_menu_depth">
                                      <ul>
                                        <li><span   onClick={() => Add_to_Basket(song.id , song)}>{t('Chart.Shopping_Basket')}</span></li>
                                    
                                      </ul>
                                    </div>
                                  </li>
                                </ul>   
                            </td>

                          </tr>
                        </tbody>
                      ) : null
                    )}
                </table>
              </div>
              {/* 미디어부분기록 */}
            </Grid>

            <Grid marginT="50px">
            <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1rem" lineHeight="1.5em" letterspacing=".3em" color="#8086a0" marginL="2px">Pianissimo</Text>
            <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1.8rem" lineHeight="1.1em" color="#723434" > {t("Main_Page.TOP_CHARTS")}</Text>
         
          
            <Grid width="100%" display="flex" row marginT="25px" paddingB="40px" margin="0 auto">
              <Grid >

                <div class="header_main_banner_hot100">
                <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1.7rem" lineHeight=".833em"  color="#fff" textalign="center" marginL="6px" width="250px" paddingT="20px">Hot 100 Songs</Text>
                <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="3.5rem" lineHeight=".693em"  color="#fff"  marginL="20px" width="250px" opacity=".2">Chart</Text>

                  <Grid display="flex" row marginL="12px" marginT="12px" >
                    {MainPage.song?.map((song, index) =>
                      index < 3 ? (
                        <Grid marginL="12px">
                          <img
                            src={song.data.Elbum_url}
                            alt=""
                            className="header_main_banner_song__image"
                          />
                        </Grid>
                      ) : null
                    )}
                  </Grid>
                  <Link to={"/Song/PlayChart/GlobalHot100"}>
                        <Button MainPage color="#723434" size="1.3rem" backgroundcolor="#fff" display="inline-block" height="40px" borderradius="20px" texttransform="uppercase" marginR="30px" width="220px" marginT="15px" marginL="20px" >{t("Main_Page.Full_Chart")}</Button>
                  </Link>

             
                </div>
              </Grid>

              <Grid marginL="20px">
                <div class="header_main_banner_NEW100">
                <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1.7rem" lineHeight=".833em"  color="#fff" textalign="center" marginL="6px" width="250px" paddingT="20px">NEW 100 Songs</Text>
                <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="3.5rem" lineHeight=".693em"  color="#fff"  marginL="20px" width="250px" opacity=".2">Chart</Text>
                  <Grid display="flex" marginL="12px" marginT="12px">
                    {MainPage.song?.map((song, index) =>
                      index > 3 && index < 7 ? (
                        <Grid marginL="12px" >
                          <img
                            src={song.data.Elbum_url}
                            alt=""
                            className="header_main_banner_song__image"
                          />
                        </Grid>
                      ) : null
                    )}
                  </Grid>

                  <Link to={"/Song/PlayChart/GlobalNew100"}>
                  <Button MainPage color="#723434" size="1.3rem" backgroundcolor="#fff" display="inline-block" height="40px" borderradius="20px" texttransform="uppercase" marginR="30px" width="220px" marginT="15px" marginL="20px" >{t("Main_Page.Full_Chart")}</Button>
                  </Link>

                </div>
              </Grid>

              <Grid marginL="20px">
                <div class="header_main_banner_ARTIST100">
                <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1.7rem" lineHeight=".833em"  color="#fff" textalign="left" marginL="26px" width="250px" paddingT="20px">ARTIST 100</Text>
                <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="3.5rem" lineHeight=".693em"  color="#fff"  marginL="20px" width="250px" opacity=".2">Chart</Text>
                  <Grid display="flex" marginL="12px" marginT="12px">
                    {MainPage.artist.map((song, index) =>
                      index < 3 ? (
                        <Grid marginL="12px" >
                          <img
                            src={song.data.photoURL}
                            alt=""
                            className="header_main_banner_artist__image"
                          />
                        </Grid>
                      ) : null
                    )}
                  </Grid>
                  <Link to={"/Song/PlayChart/GlobalHot100"}>
                  <Button MainPage color="#723434" size="1.3rem" backgroundcolor="#fff" display="inline-block" height="40px" borderradius="20px" texttransform="uppercase" marginR="30px" width="220px" marginT="15px" marginL="20px" >{t("Main_Page.Full_Chart")}</Button>
                  </Link>
                </div>
              </Grid>
            </Grid>
            </Grid>   
            <Grid display= "flex" marginT= "100px" width= "100%" marginB= "100px">
              <Grid>
              <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1rem" lineHeight="1.5em" letterspacing=".3em" color="#8086a0" marginL="2px">Pianissimo</Text>
              <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1.8rem" lineHeight="1.1em" color="#723434" >{t("Main_Page.DIFFICULTY_CHARTS")}</Text>
         

                <Grid width="750px" display="flex" height="300px" bg="rgba(182, 182, 182, 0.2)" marginT="30px">

                  <Grid margin="auto">
                    <div class="header_main_hardiest_hardiest100">
                    <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1.3rem" lineHeight=".833em"  color="#fff" textalign="left" marginL="12px" width="140px" paddingT="20px">hardest</Text>
                    <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="2.2rem" lineHeight=".693em"  color="#fff"  marginL="10px" width="140px" opacity=".2">Chart</Text>
                      <Link to={"/Song/PlayChart/VeryHard"}>
                      <Button MainPage color="#723434af" size="15px" backgroundcolor="#fff" display="inline-block" height="30px" borderradius="20px" texttransform="uppercase"  width="120px"  marginT="35px" marginL="12px" >{t("Main_Page.Full_Chart")}</Button>
                      </Link>
                    </div>
                  </Grid>

                  <Grid margin="auto">
                    <div class="header_main_hardiest_hardiest100">
                    <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1.3rem" lineHeight=".833em"  color="#fff" textalign="left" marginL="12px" width="140px" paddingT="20px">hard</Text>
                    <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="2.2rem" lineHeight=".693em"  color="#fff"  marginL="10px" width="140px" opacity=".2">Chart</Text>
                      <Link to={"/Song/PlayChart/HARD"}>
                      <Button MainPage color="#723434af" size="15px" backgroundcolor="#fff" display="inline-block" height="30px" borderradius="20px" texttransform="uppercase"  width="120px"  marginT="35px" marginL="12px" >{t("Main_Page.Full_Chart")}</Button>
                      </Link>
                    </div>
                  </Grid>

                  <Grid margin="auto">
                    <div class="header_main_hardiest_hardiest100">
                    <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1.3rem" lineHeight=".833em"  color="#fff" textalign="left" marginL="12px" width="140px" paddingT="20px">normal</Text>
                    <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="2.2rem" lineHeight=".693em"  color="#fff"  marginL="10px" width="140px" opacity=".2">Chart</Text>
                      <Link to={"/Song/PlayChart/Normal"}>
                        <Button MainPage color="#723434af" size="15px" backgroundcolor="#fff" display="inline-block" height="30px" borderradius="20px" texttransform="uppercase"  width="120px"  marginT="35px" marginL="12px" >{t("Main_Page.Full_Chart")}</Button>
                      </Link>
                    </div>
                  </Grid>

                  <Grid margin="auto">
                    <div class="header_main_hardiest_hardiest100">
                    <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1.3rem" lineHeight=".833em"  color="#fff" textalign="left" marginL="12px" width="140px" paddingT="20px">easy</Text>
                    <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="2.2rem" lineHeight=".693em"  color="#fff"  marginL="10px" width="140px" opacity=".2">Chart</Text> 
                      <Link to={"/Song/PlayChart/Easy"}>
                      <Button MainPage color="#723434af" size="15px" backgroundcolor="#fff" display="inline-block" height="30px" borderradius="20px" texttransform="uppercase"  width="120px"  marginT="35px" marginL="12px" >{t("Main_Page.Full_Chart")}</Button>
                      </Link>
                    </div>
                  </Grid>

                  <Grid margin="auto">
                    <div class="header_main_hardiest_hardiest100">
                    <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1.3rem" lineHeight=".833em"  color="#fff" textalign="left" marginL="12px" width="140px" paddingT="20px">easyest</Text>
                    <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="2.2rem" lineHeight=".693em"  color="#fff"  marginL="10px" width="140px" opacity=".2">Chart</Text> 
                      <Link to={"/Song/PlayChart/VeryEasy"}>
                        <Button MainPage color="#723434af" size="15px" backgroundcolor="#fff" display="inline-block" height="30px" borderradius="20px" texttransform="uppercase"  width="120px"  marginT="35px" marginL="12px" >{t("Main_Page.Full_Chart")}</Button>
                      </Link>
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <div class="Header_row3_banner_box2">
              <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1rem" lineHeight="1.5em" letterspacing=".3em" color="#8086a0" marginL="150px">Pianissimo</Text>
              <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1.8rem" lineHeight="1.1em" color="#723434" marginL="150px">{t("Main_Page.ARTIST_JOIN")}</Text> 
                <div class="header_main_artist_signup" id="hardiest">
                  <div class="header_main_banner_artistjoin_subbox">
                    <span class="header_main_banner_artistjoin_text">
                      아티스트 가입방법
                    </span>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <Footer/>
          <ModalContainer show={Modalopen} close={closeModal}/>
          <AlertModalContainer show={alert.show} close={closeAlertModal} type={alert.type}  text={alert.text} />


        </Grid>

      )}
    </Desktop>





    <Tablet>

{isLoading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        margin: "26rem 0",
      }}
    >
      <CircularProgress color="secondary" size="5rem" />
    </div>
  ) : (
    <Grid overflow= "hidden" position="relative"  width= "100%"  height="auto"  >
      <MainPageSlider key={MainPage.song?.length} data={MainPage.song} />
      <Grid position= "relative"  padding= "0 0" width= "90%"  margin= "0 auto" background-color= "#ffffff"  >
        <Grid   margin-bottom= "40px" >
          <div className="mainpage_top_song10">
              <Text  fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1rem" lineHeight="1.5em" letterspacing=".3em" color="#8086a0" marginL="0px">Pianissimo</Text>
              <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1.8rem" lineHeight="1.1em" color="#723434" marginL="0px">{t("Main_Page.Song10")}</Text>
           
            {/* <a href="shop/prd_hit_list.php" class="more">View All</a> */}

            <table className="Header_song_table">

            <colgroup>
            <col width="8%" />
                 <col width="42%" /> 
                 {/* 50 */}
                 <col width="16%" />
                 <col width="10%" />
                 <col width="9%" />
                 <col width="9%" />
                 <col width="9%" />
                 <col width="7%" />
              </colgroup>



                <thead className="Header_song_thead_tr">
                  <tr className="Header_song_thead_thead_tr">
                    <th className="Header_songhead_thead2">{t('Chart.Ranking')}</th>
                    <th className="Header_songhead_songname">
                    {t('Chart.Song_name')} / {t('Chart.Artist_name')}
                    </th>
                    <th className="Header_songhead_country">
                    {t('Chart.Genre')} / {t('Chart.Composition')}
                    </th>
                    <th className="Header_songhead_price">{t('Chart.Price')}</th>
                    <th className="Header_songhead_view">View</th>

                    <th className="Header_songhead_download">{t('Chart.Download')}</th>
                    <th className="Header_songhead_like">{t('Chart.Like')}</th>

                    {/* <td className= 'Header_songhead_listen'>{t('Main_Page.Listening')}</td>  */}
                    <th className="Header_songhead_damgi">{t('Chart.Add')}</th>
                  </tr>
                </thead>

                {MainPage.song?.map((song, index) =>
                  index < 10 ? (
                    <tbody className="Header_song_subtable"
                    onClick={() =>
                          gotoArtist_Product(song.id)
                            // history.push(`/Artist_product/${song.id}`)
                          }
                          >
                      <tr className="Header_song_tr">
                        <td className="Header_song_index">{index + 1}</td>

                        

                        <td
                          className="Header_song_td"
                          
                        >
                          <img
                            src={song.data.Elbum_url}
                            alt=""
                            className="Header_song__image"
                          />
                        
                        <span className="Header_song_songName">
                            {song.data.songName}
                            <span className="Header_song_artistname">
                              - {song.data.artistName}
                            </span>
                          </span>

                         
                        </td>

                        <td className="Header_song_country">
                          {song.data.genres} / {song.data.hardest}
                        </td>
                        {/* <td className= 'Very_Hard_price'>{song.data.price}</td> */}

                        {/* <div className="Header_song_Icon">
                          <ArrowDownwardIcon className="ArrowDownward__Icon" />
                        </div> */}

                        <td className="Header_song_price">
                        {t('Payment.Currency')}  {language_Currency_Amount(song)}
                        </td>
                    
                        <td className="Header_song_view">
                              {song.data.lookup}
                              <FontAwesomeIcon icon={farEye} className="farEye__Icon" />
                            </td>

                        <td className="Header_song_upgrade">
                          {song.data.update}
                          <ArrowDownwardIcon className="ArrowDownward__Icon" />
                        </td>

                        <td className="Header_song_like">
                        {song.data.faviorite}
                        <FavoriteBorderIcon className="ArrowDownward__Icon" />

                        </td>
                        <td className="Header_song_AddIcon">
                          <IconButton
                            aria-haspopup="true"
                            onClick={openOptions}
                          >
                            <AddIcon className="PlayArrowOutlinedIcon__Icon" />
                          </IconButton>
                          <Menu
                            className={classes.menu}
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(false)}
                            menuStyle={{ boxShadow: "none" }}
                          >
                      
                            <MenuItem className="Very_Hard_optionItem" onClick={() => Add_to_Basket(song.id , song)}>
                            {t('Chart.Shopping_Basket')}
                            </MenuItem>
                          </Menu>
                        </td>

                      </tr>
                    </tbody>
                  ) : null
                )}
            </table>
          </div>
          {/* 미디어부분기록 */}
        </Grid>

        <Grid marginT="50px">
        <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1rem" lineHeight="1.5em" letterspacing=".3em" color="#8086a0" marginL="2px">Pianissimo</Text>
        <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1.8rem" lineHeight="1.1em" color="#723434" > {t("Main_Page.TOP_CHARTS")}</Text>
     
      
        <Grid width="100%" display="flex" row marginT="20px" paddingB="40px" margin="0 auto">
          <Grid>

            <div class="header_main_banner_hot100">
            <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1.3rem" lineHeight=".833em"  color="#fff" textalign="left" marginL="24px" width="250px" paddingT="20px">Hot 100 Songs</Text>
            <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="2.9rem" lineHeight=".693em"  color="#fff"  marginL="20px" width="250px" opacity=".2">Chart</Text>

              <Grid display="flex" row marginL="12px" marginT="12px" >
                {MainPage.song?.map((song, index) =>
                  index < 3 ? (
                    <Grid marginL="10px">
                      <img
                        src={song.data.Elbum_url}
                        alt=""
                        className="header_main_banner_song__image"
                      />
                    </Grid>
                  ) : null
                )}
              </Grid>
              <Link to={"/Song/PlayChart/GlobalHot100"}>
                    <Button MainPage color="#723434" size="1.1rem" backgroundcolor="#fff" display="inline-block" height="36px" borderradius="20px" texttransform="uppercase" marginR="30px" width="170px" marginT="6px" marginL="20px" >{t("Main_Page.Full_Chart")}</Button>
              </Link>

         
            </div>
          </Grid>

          <Grid >
            <div class="header_main_banner_NEW100">
            <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1.3rem" lineHeight=".833em"  color="#fff" textalign="left" marginL="24px" width="250px" paddingT="20px">NEW 100 Songs</Text>
            <Text ontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="2.9rem" lineHeight=".693em"  color="#fff"  marginL="20px" width="250px" opacity=".2">Chart</Text>
              <Grid display="flex" marginL="12px" marginT="12px">
                {MainPage.song?.map((song, index) =>
                  index > 3 && index < 7 ? (
                    <Grid marginL="10px" >
                      <img
                        src={song.data.Elbum_url}
                        alt=""
                        className="header_main_banner_song__image"
                      />
                    </Grid>
                  ) : null
                )}
              </Grid>

              <Link to={"/Song/PlayChart/GlobalNew100"}>
              <Button MainPage color="#723434" size="1.1rem" backgroundcolor="#fff" display="inline-block" height="36px" borderradius="20px" texttransform="uppercase" marginR="30px" width="170px" marginT="6px" marginL="20px"  >{t("Main_Page.Full_Chart")}</Button>
              </Link>

            </div>
          </Grid>

          <Grid>
            <div class="header_main_banner_ARTIST100">
            <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1.3rem" lineHeight=".833em"  color="#fff" textalign="left" marginL="24px" width="250px" paddingT="20px">ARTIST 100</Text>
            <Text ontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="2.9rem" lineHeight=".693em"  color="#fff"  marginL="20px" width="250px" opacity=".2">Chart</Text>
              <Grid display="flex" marginL="12px" marginT="12px">
              {MainPage.artist?.map((song, index) =>
                      index > 3 && index < 7 ? (
                        <Grid marginL="10px" >
                        <img
                          src={song.data.photoURL}
                          alt=""
                          className="header_main_banner_song__image"
                        />
                      </Grid>
                      ) : null
                    )}
              </Grid>
              <Link to={"/Song/PlayChart/GlobalHot100"}>
              <Button MainPage color="#723434" size="1.1rem" backgroundcolor="#fff" display="inline-block" height="36px" borderradius="20px" texttransform="uppercase" marginR="30px" width="170px" marginT="6px" marginL="20px" >{t("Main_Page.Full_Chart")}</Button>
              </Link>
            </div>
          </Grid>
        </Grid>
        </Grid>   

        <Grid display= "flex" marginT= "50px" width= "100%" marginB= "100px">
          <Grid>
          <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1rem" lineHeight="1.5em" letterspacing=".3em" color="#8086a0" marginL="2px">Pianissimo</Text>
          <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1.8rem" lineHeight="1.1em" color="#723434" >{t("Main_Page.DIFFICULTY_CHARTS")}</Text>
     

            <Grid width="750px" display="flex" height="250px" bg="rgba(182, 182, 182, 0.2)" marginT="30px">

              <Grid margin="auto">
                <div class="header_main_hardiest_hardiest100">
                <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1.3rem" lineHeight=".833em"  color="#fff" textalign="left" marginL="12px" width="140px" paddingT="20px">hardest</Text>
                <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="2.2rem" lineHeight=".693em"  color="#fff"  marginL="10px" width="140px" opacity=".2">Chart</Text>
                  <Link to={"/Song/PlayChart/VeryHard"}>
                  <Button MainPage color="#723434af" size="15px" backgroundcolor="#fff" display="inline-block" height="30px" borderradius="20px" texttransform="uppercase"  width="120px"  marginT="35px" marginL="12px" >{t("Main_Page.Full_Chart")}</Button>
                  </Link>
                </div>
              </Grid>

              <Grid margin="auto">
                <div class="header_main_hardiest_hardiest100">
                <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1.3rem" lineHeight=".833em"  color="#fff" textalign="left" marginL="12px" width="140px" paddingT="20px">hard</Text>
                <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="2.2rem" lineHeight=".693em"  color="#fff"  marginL="10px" width="140px" opacity=".2">Chart</Text>
                  <Link to={"/Song/PlayChart/HARD"}>
                  <Button MainPage color="#723434af" size="15px" backgroundcolor="#fff" display="inline-block" height="30px" borderradius="20px" texttransform="uppercase"  width="120px"  marginT="35px" marginL="12px" >{t("Main_Page.Full_Chart")}</Button>
                  </Link>
                </div>
              </Grid>

              <Grid margin="auto">
                <div class="header_main_hardiest_hardiest100">
                <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1.3rem" lineHeight=".833em"  color="#fff" textalign="left" marginL="12px" width="140px" paddingT="20px">normal</Text>
                <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="2.2rem" lineHeight=".693em"  color="#fff"  marginL="10px" width="140px" opacity=".2">Chart</Text>
                  <Link to={"/Song/PlayChart/Normal"}>
                    <Button MainPage color="#723434af" size="15px" backgroundcolor="#fff" display="inline-block" height="30px" borderradius="20px" texttransform="uppercase"  width="120px"  marginT="35px" marginL="12px" >{t("Main_Page.Full_Chart")}</Button>
                  </Link>
                </div>
              </Grid>

              <Grid margin="auto">
                <div class="header_main_hardiest_hardiest100">
                <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1.3rem" lineHeight=".833em"  color="#fff" textalign="left" marginL="12px" width="140px" paddingT="20px">easy</Text>
                <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="2.2rem" lineHeight=".693em"  color="#fff"  marginL="10px" width="140px" opacity=".2">Chart</Text> 
                  <Link to={"/Song/PlayChart/Easy"}>
                  <Button MainPage color="#723434af" size="15px" backgroundcolor="#fff" display="inline-block" height="30px" borderradius="20px" texttransform="uppercase"  width="120px"  marginT="35px" marginL="12px" >{t("Main_Page.Full_Chart")}</Button>
                  </Link>
                </div>
              </Grid>

              <Grid margin="auto">
                <div class="header_main_hardiest_hardiest100">
                <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1.3rem" lineHeight=".833em"  color="#fff" textalign="left" marginL="12px" width="140px" paddingT="20px">easyest</Text>
                <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="2.2rem" lineHeight=".693em"  color="#fff"  marginL="10px" width="140px" opacity=".2">Chart</Text> 
                  <Link to={"/Song/PlayChart/VeryEasy"}>
                    <Button MainPage color="#723434af" size="15px" backgroundcolor="#fff" display="inline-block" height="30px" borderradius="20px" texttransform="uppercase"  width="120px"  marginT="35px" marginL="12px" >{t("Main_Page.Full_Chart")}</Button>
                  </Link>
                </div>
              </Grid>
            </Grid>
          </Grid>

          {/* <div class="Header_row3_banner_box2">
          <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1rem" lineHeight="1.5em" letterspacing=".3em" color="#8086a0" marginL="150px">Pianissimo</Text>
          <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1.8rem" lineHeight="1.1em" color="#723434" marginL="150px">{t("Main_Page.ARTIST_JOIN")}</Text> 
            <div class="header_main_artist_signup" id="hardiest">
              <div class="header_main_banner_artistjoin_subbox">
                <span class="header_main_banner_artistjoin_text">
                  아티스트 가입방법
                </span>
              </div>
            </div>
          </div> */}

        </Grid>
      </Grid>
      <Footer/>
      <ModalContainer show={Modalopen} close={closeModal}/>
      <AlertModalContainer show={alert.show} close={closeAlertModal} type={alert.type}  text={alert.text} />

    </Grid>

  )}
</Tablet>







<Mobile>

{isLoading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        margin: "26rem 0",
      }}
    >
      <CircularProgress color="secondary" size="5rem" />
    </div>
  ) : (
    <Grid position="relative"  width= "100%"   overflow= "hidden">
      <MainPageSlider key={MainPage.song?.length} data={MainPage.song} />
      <Grid position= "relative"  padding= "0 0" width= "90%"  margin= "0 auto" background-color= "#ffffff"  >
        <Grid  margin-bottom= "40px" >
          <div className="mainpage_top_song10">
              <Text  fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1rem" lineHeight="1.5em" letterspacing=".3em" color="#8086a0" marginL="0px">Pianissimo</Text>
              <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1.8rem" lineHeight="1.1em" color="#723434" marginL="0px">{t("Main_Page.Song10")}</Text>
           
            {/* <a href="shop/prd_hit_list.php" class="more">View All</a> */}

            <table className="Header_song_table">

            <colgroup>
                 <col width="7%" />
                 <col width="42%" /> 
                 {/* 50 */}
                 <col width="20%" />
                 <col width="15%" />
                 <col width="14%" />
                 <col width="12%" />
              </colgroup>



                <thead className="Header_song_thead_tr">
                  <tr className="Header_song_thead_thead_tr">
                    <th className="Header_songhead_thead2">{t('Mobile.Ranking')}</th>
                    <th className="Header_songhead_songname">
                    {t('Mobile.Song_name')} / {t('Mobile.Artist_name')}
                    </th>
                    <th className="Header_songhead_country">
                    {t('Chart.Genre')} / {t('Chart.Composition')}
                    </th>
                    <th className="Header_songhead_price">{t('Chart.Price')}</th>
                    <th className="Header_songhead_download">{t('Chart.Download')}</th>
                    {/* <th className="Header_songhead_like">{t('Chart.Like')}</th> */}
                    <th className="Header_songhead_damgi">{t('Chart.Add')}</th>
                  </tr>
                </thead>

                {MainPage.song?.map((song, index) =>
                  index < 10 ? (
                    <tbody className="Header_song_subtable"
                    onClick={() =>
                              gotoArtist_Product(song.id)
                            // history.push(`/Artist_product/${song.id}`)
                          }
                    >
                      <tr className="Header_song_tr">
                        <td className="Header_song_index">{index + 1}</td>

                        

                        <td
                          className="Header_song_td"
                        >
                          <img
                            src={song.data.Elbum_url}
                            alt=""
                            className="Header_song__image"
                          />
                        
                        <span className="Header_song_songName">
                            {song.data.songName}
                            <span className="Header_song_artistname">
                              - {song.data.artistName}
                            </span>
                          </span>

                         
                        </td>

                        <td className="Header_song_country">
                          {song.data.genres} / {song.data.hardest}
                        </td>
                        {/* <td className= 'Very_Hard_price'>{song.data.price}</td> */}

                        {/* <div className="Header_song_Icon">
                          <ArrowDownwardIcon className="ArrowDownward__Icon" />
                        </div> */}

                        <td className="Header_song_price">
                        {t('Payment.Currency')}  {language_Currency_Amount(song)}
                        </td>
                    
                        <td className="Header_song_upgrade">
                          {song.data.update}
                          <ArrowDownwardIcon className="ArrowDownward__Icon" />
                        </td>

                        {/* <td className="Header_song_like">
                        {song.data.faviorite}
                        <ArrowDownwardIcon className="ArrowDownward__Icon" />
                        </td> */}

                        <td className="Header_song_AddIcon">
                          <IconButton
                            aria-haspopup="true"
                            onClick={openOptions}
                          >
                            <AddIcon className="PlayArrowOutlinedIcon__Icon" />
                          </IconButton>
                          <Menu
                            className={classes.menu}
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(false)}
                            menuStyle={{ boxShadow: "none" }}
                          >
                      
                            <MenuItem className="Very_Hard_optionItem" onClick={() => Add_to_Basket(song.id , song)}>
                            {t('Chart.Shopping_Basket')}
                            </MenuItem>
                          </Menu>
                        </td>

                      </tr>
                    </tbody>
                  ) : null
                )}
            </table>
          </div>
          {/* 미디어부분기록 */}
        </Grid>

        <Grid marginT="50px">
        <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1rem" lineHeight="1.5em" letterspacing=".3em" color="#8086a0" marginL="2px">Pianissimo</Text>
        <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1.8rem" lineHeight="1.1em" color="#723434" > {t("Main_Page.TOP_CHARTS")}</Text>
     
      
        <Grid width="100%" display="flex" row marginT="20px" paddingB="40px" margin="0 auto">
          <Grid margin="auto" width="100%" display="flex" justify="center">

            <div class="header_main_banner_hot100">
            <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="90%" lineHeight=".833em"  color="#fff" textalign="left" marginL="10%"  paddingT="10px" >Hot 100</Text>
            <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1.8rem" lineHeight=".693em"  color="#fff"   marginL="9%"   opacity=".2">Chart</Text>

              <Grid display="flex" row marginL="21px" marginT="12px" >
                {MainPage.song?.map((song, index) =>
                  index < 3 ? (
                    <Grid marginL="12px">
                      <img
                        src={song.data.Elbum_url}
                        alt=""
                        className="header_main_banner_song__image"
                      />
                    </Grid>
                  ) : null
                )}
              </Grid>
              <Link to={"/Song/PlayChart/GlobalHot100"}>
                    <Button MainPage color="#723434" size="0.7rem" backgroundcolor="#fff" display="block" height="24px" borderradius="20px" texttransform="uppercase"  width="80%" marginT="8px" marginL="10%" justify="center">{t("Main_Page.Full_Chart")}</Button>
              </Link>

         
            </div>
          </Grid>

          <Grid  margin="auto" width="100%" display="flex" justify="center">
            <div class="header_main_banner_NEW100">
            <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="90%" lineHeight=".833em"  color="#fff" textalign="left" marginL="10%" paddingT="10px">NEW 100</Text>
            <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1.8rem"  lineHeight=".693em"  color="#fff" marginL="9%" opacity=".2">Chart</Text>
              <Grid display="flex" marginL="21px"  marginT="12px">
                {MainPage.song?.map((song, index) =>
                  index > 3 && index < 7 ? (
                    <Grid marginL="12px" >
                      <img
                        src={song.data.Elbum_url}
                        alt=""
                        className="header_main_banner_song__image"
                      />
                    </Grid>
                  ) : null
                )}
              </Grid>

              <Link to={"/Song/PlayChart/GlobalNew100"}>
              <Button MainPage color="#723434" size="0.7rem" backgroundcolor="#fff" display="block" height="24px" borderradius="20px" texttransform="uppercase"  width="80%" marginT="8px"  marginL="10%"  justify="center">{t("Main_Page.Full_Chart")}</Button>
              </Link>

            </div>
          </Grid>

          <Grid margin="auto" width="100%" display="flex" justify="center">
            <div class="header_main_banner_ARTIST100">
            <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="90%" lineHeight=".833em"  color="#fff" textalign="left" marginL="10%" paddingT="10px">ARTIST 100</Text>
            <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1.8rem"  lineHeight=".693em"  color="#fff"  marginL="9%" opacity=".2">Chart</Text>
              <Grid display="flex" marginL="21px" marginT="12px">
              {MainPage.artist?.map((song, index) =>
                      index > 3 && index < 7 ? (
                        <Grid marginL="12px" >
                        <img
                          src={song.data.photoURL}
                          alt=""
                          className="header_main_banner_song__image"
                        />
                      </Grid>
                      ) : null
                    )}
              </Grid>
              <Link to={"/Song/PlayChart/GlobalHot100"}>
              <Button MainPage color="#723434" size="0.7rem" backgroundcolor="#fff" display="block" height="24px" borderradius="20px" texttransform="uppercase"  width="80%" marginT="8px" marginL="10%"  justify="center">{t("Main_Page.Full_Chart")}</Button>
              </Link>
            </div>
          </Grid>
        </Grid>
        </Grid>   

        <Grid display= "flex" marginT= "50px" width= "100%" marginB= "100px">
          <Grid>
          <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1rem" lineHeight="1.5em" letterspacing=".3em" color="#8086a0" marginL="2px">Pianissimo</Text>
          <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1.8rem" lineHeight="1.1em" color="#723434" >{t("Main_Page.DIFFICULTY_CHARTS")}</Text>
     

            <Grid width="100%" display="flex"  bg="#fff" marginT="10px"  margin="auto" justify="center">

              <Grid margin="auto" width="100%" display="flex" justify="center" >
                <div class="header_main_hardiest_hardiest100">
                <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="90%" lineHeight=".833em"  color="#fff" textalign="left" marginL="12px" paddingT="20px">hard</Text>
                <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1.8rem" lineHeight=".693em"  color="#fff"  marginL="10px"  opacity=".2">Chart</Text>
                  <Link to={"/Song/PlayChart/HARD"}>
                  <Button MainPage color="#723434" size="0.7rem" backgroundcolor="#fff" display="block" height="24px" borderradius="20px" texttransform="uppercase"  width="80%" marginT="40px" marginL="10%"  justify="center" marginB="10px">{t("Main_Page.Full_Chart")}</Button>

                  </Link>
                </div>
              </Grid>

              <Grid margin="auto" width="100%" display="flex" justify="center">
                <div class="header_main_hardiest_hardiest100">
                <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="90%" lineHeight=".833em"  color="#fff" textalign="left" marginL="12px"  paddingT="20px">normal</Text>
                <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1.8rem" lineHeight=".693em"  color="#fff"  marginL="10px"  opacity=".2">Chart</Text>
                  <Link to={"/Song/PlayChart/Normal"}>
                  <Button MainPage color="#723434" size="0.7rem" backgroundcolor="#fff" display="block" height="24px" borderradius="20px" texttransform="uppercase"  width="80%" marginT="40px" marginL="10%"  justify="center" marginB="10px">{t("Main_Page.Full_Chart")}</Button>
                  </Link>
                </div>
              </Grid>

              <Grid margin="auto" width="100%" display="flex" justify="center">
                <div class="header_main_hardiest_hardiest100">
                <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="90%" lineHeight=".833em"  color="#fff" textalign="left" marginL="12px"  paddingT="20px">easy</Text>
                <Text fontfamily = "Poppins,sans-serif" texttransform="uppercase" bold display="block" size="1.8rem" lineHeight=".693em"  color="#fff"  marginL="10px"  opacity=".2">Chart</Text>
                  <Link to={"/Song/PlayChart/Easy"}>
                  <Button MainPage color="#723434" size="0.7rem" backgroundcolor="#fff" display="block" height="24px" borderradius="20px" texttransform="uppercase"  width="80%" marginT="40px" marginL="10%"  justify="center" marginB="10px">{t("Main_Page.Full_Chart")}</Button>
                  </Link>
                </div>
              </Grid>



              </Grid>

          </Grid>


        </Grid>
      </Grid>
      <Footer/>
      <ModalContainer show={Modalopen} close={closeModal}/>
      <AlertModalContainer show={alert.show} close={closeAlertModal} type={alert.type}  text={alert.text} />
    </Grid>

  )}
</Mobile>








    </>
  );
};

export default MainPage;
