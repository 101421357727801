import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import user from "../redux/modules/user";
import MainPage from "../redux/modules/MainPage";
import Hardisest from "../redux/modules/Hardisest";
import ArtistProduct from "../redux/modules/Artist_Product";
import Genre from "../redux/modules/Genre";
import ProductBasket from "../redux/modules/ProductBasket";
import CheckOut from "../redux/modules/CheckOut";
import PurchaseProduct from "../redux/modules/PurchaseProduct";
import SearchResult from "../redux/modules/SearchResult";
import Artist_MainPage from "../redux/modules/Artist_MainPage";
import Notice from "../redux/modules/Notice";
import BoardManagement from "../redux/modules/BoardManagement";
import Question from "../redux/modules/Question";
import CalculateAmount from "../redux/modules/CalculateAmount";
import PaymentHistory from "../redux/modules/PaymentHistory";


const persistConfig = {
    key: "root",
    storage,
    whitelist: ["CalculateAmount" , "Question" , "BoardManagement" , "user" , "MainPage" , "Hardisest" , "ArtistProduct" , "Genre" ,
     "ProductBasket" , "CheckOut" , "PurchaseProduct" , "SearchResult" , "Artist_MainPage" , "Notice" , "PaymentHistory"]
  };


const rootReducer = combineReducers({
  user,
  MainPage,
  Hardisest,
  ArtistProduct,
  Genre,
  ProductBasket,
  CheckOut,
  PurchaseProduct,
  SearchResult,
  Artist_MainPage,
  Notice,
  BoardManagement,
  Question,
  CalculateAmount,
  PaymentHistory,
});

export default persistReducer(persistConfig, rootReducer);
 