import React, { useEffect, useState, } from "react";
import { CircularProgress } from "@material-ui/core";
import { Link, useHistory, useParams, useLocation , Redirect } from "react-router-dom";
import { dbService , storageService  } from "../../shared/firebase";
import "./Artist_Product_EDIT.css";
import { SheetPDF, MidiPiano  , Editor  , ArtistProductEditor} from "../../components/index";
import YouTubeIcon from "@material-ui/icons/YouTube";
import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined";
import StraightenIcon from "@material-ui/icons/Straighten";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { Player } from "../../components/midi/Midi_Player";
import { Grid, Button, Text, Input2 } from "../../elements/index";
import Color, { Palette } from "color-thief-react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import YouTube from "react-youtube";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { actionCreators as ArtistProductActions } from "../../redux/modules/Artist_Product";
import { actionCreators as UserActions } from "../../redux/modules/user";

import LanguageDetector from "i18next-browser-languagedetector";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import { ModalContainer } from "../../components/index";
import { actionCreators as QuestionActions } from "../../redux/modules/Question";
import firebase from "firebase";


const MIN_WIDTH2 = 1040;

var getYouTubeID = require("get-youtube-id");
var ui, player;


const StyledRating = withStyles({
  iconFilled: {
    color: "#ff6d75",
  },
  iconHover: {
    color: "#ff3d47",
  },
})(Rating);

const StyledRatingPlayer = withStyles({
  iconFilled: {
    color: "#FFFFFF",
  },
  iconHover: {
    color: "#ff3d47",
  },
})(Rating);

function Artist_Product_EDIT() {
  const history = useHistory();
  const { Artistid ,  Productid } = useParams();
  // const [artist, setArtist] = useState(null);
  const [songs, setSongs] = useState();

  ///////////////////////////setting//////////////////////////////////
  const [midimode, setmidimode] = useState(false);
  const [sheetmode, setsheetmode] = useState(true);
  const [youtubemode, setyoutubemode] = useState(false);
  const user = useSelector((state) => state.user);
  const ArtistProduct = useSelector((state) => state.ArtistProduct);
  const [releasetime, setreleasetime] = useState();

  ///////////////////////////redux/////////////////////////////////
  // const current_song = useSelector((state) => state.current_song);
  ///////////////////////////rating//////////////////////////////////
  const [hover, setHover] = useState(-1);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [LoginModalopen, setLoginModalopen] = useState(false);
  ///////////////////////////youtube//////////////////////////////////
  const [youtubeVideo, setyoutubeVideo] = useState();
  const [rating_disabled, setrating_disabled] = useState(false);
  const [subscribe_disabled, setsubscribe_disabled] = useState(false);

  const [discrpction_EDIT, setdiscrpction_EDIT] = useState(false);
  const [discrpction_EDIT_content, setdiscrpction_EDIT_content] = useState(ArtistProduct.data.Discription);

  const [midiFile_EDIT, setmidiFile_EDIT] = useState(false);
  const [midifile, setmidiFile] = useState(null);
  const [midifileinfo, setmidifileinfo] = useState({ name: ArtistProduct.data.songName });

  const [sheetFile_EDIT, setsheetFile_EDIT] = useState(false);
  const [sheetfile, setsheetFile] = useState(null);
  const [sheetfileinfo, setsheetfileinfo] = useState({
    url: "",
    name: "No File",
  });

  const [ElbumFile_EDIT, setElbumFile_EDIT] = useState(false);
  const [Elbumfile, setElbumFile] = useState(null);


  const [YouTubeFile_EDIT, setYouTubeFile_EDIT] = useState(false);
  const [YouTubeFile, setYouTubeFile] = useState(ArtistProduct.data.youtubeUrl);

  // const { basket } = useSelector((state) => state.Basket);

  const [width, setWidth] = useState(Math.max(MIN_WIDTH2, Math.floor(window.innerWidth - 440)));  
  const [height10, setheight10] = useState( Math.floor(window.innerHeight) * 0.24 );
  const [height100, setheight100] = useState(Math.floor(window.innerHeight));
  const [height25, setheight25] = useState(
    Math.floor(window.innerHeight) * 0.24
  );
  const [height75, setheight75] = useState(
    Math.floor(window.innerHeight) * 0.75
  );

  const [Currency_Amount, setCurrency_Amount] = useState();
  const [youtube, setyoutube] = useState();

  const { t, i18n } = useTranslation();

  const [locale, setLocale] = useState(i18n.language);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const Loading = () => <span>Loading...</span>;
  const updateDimensions = () => {
    setWidth(Math.max(MIN_WIDTH2, Math.floor(window.innerWidth - 440)));
    setheight100(Math.floor(window.innerHeight));
    setheight75(Math.floor(window.innerHeight) * 0.75);
    setheight25(Math.floor(window.innerHeight) * 0.25);
    setheight10(Math.floor(window.innerHeight) * 0.24);
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    history.listen((location) => {
      if (location.pathname !== `/Artist_productEDIT/${Artistid}/${Productid}` && player) {
        player.stop();
      }
    });
  }, [history, Artistid, Productid]);



  useEffect(() => {
    dbService
      .collection("Song_All")
      .doc(Productid)
      .get()
      .then((snapshot) => {
        const fetchSong = snapshot.data();
        const fetchid = snapshot.id;
        dispatch(
          ArtistProductActions.set_Artist_Product_data(
            fetchSong,
            fetchid,
            songEdit(snapshot.data()?.songEdit),
            snapshot.data()?.rating,
            snapshot.data()?.update,
            snapshot.data()?.faviorite,
            time(snapshot.data()?.createdAt.toDate().toISOString()),
            // youtube_EmbedID(snapshot.data()?.youtubeUrl)
            youtube_EmbedID(snapshot.data()?.youtubeUrl)
            
          )
        );
    
      

        if (!fetchSong) {
          return;
        }

        dbService
          .collection("user_Public")
          .doc(fetchSong?.uid)
          .get()
          .then((snapshot) => {
            const fetchArtist = snapshot.data();
            dispatch(
              ArtistProductActions.set_Artist_Product_artist_data(
                snapshot.data()?.artistuser_subscribe,
                fetchArtist, 
              )
            );
            setIsLoading(false);

          });
      });
  }, [Productid, history ,dispatch]);


  useEffect(() => {
    if(user?.uid === Artistid || user?.uid !== null){
      history.push(`/Artist_productEDIT/${Artistid}/${Productid}`)
    } else {
      history.push(`/Artist_product/${Productid}`);
    }
    
  }, [Artistid, Productid , user ,history]);


  useEffect(() => {
    if (i18n.language === "kor") {
      setCurrency_Amount(songs?.data.KOR);
    } else if (i18n.language === "en") {
      setCurrency_Amount(songs?.data.USD);
    } else if (i18n.language === "ja") {
      setCurrency_Amount(songs?.data.JPY);
    }

    // i18n.on("languageChanged", (lng) => {
    //   if (lng === "kor") {
    //     setCurrency_Amount(songs?.data.KOR);
    //   } else if (lng === "en") {
    //     setCurrency_Amount(songs?.data.USD);
    //   } else if (lng === "ja") {
    //     setCurrency_Amount(songs?.data.JPY);
    //   }
    // });
  }, [songs, i18n]);

  
  const youtube_EmbedID = (data) => {
    if(data === undefined || data === "" || data === null){
      return;
    }
    const split1 = (data || "").split("=");
    var embedID = split1[1]
    // https://www.youtube.com/watch?v=9LOIFwjyC9c&t=3432s
    // wTv6aZ0pRkM
    if(embedID.includes("&t")){
      const split2 = (embedID).split("&t");
      return split2[0]
    };

    return embedID;
 
  }

  const time = (data) => {
    var split1 = (data || "").split("T");
    var replace = split1[0].replace(/\-/g, " ");
    var replace2 = replace.replace(" ", ".");
    var date = replace2.replace(" ", ".");
    setreleasetime(date);
    return date;
  };

  const songEdit = (songEdit) => {
    if (songEdit === undefined) {
      return "원곡";
    } else if (songEdit.NoEdit === true) {
      return "원곡";
    } else if (songEdit.SomeEdit === true) {
      return "편곡";
    } else if (songEdit.Edit === true) {
      return "작곡";
    }
  };



  async function midi_playground() {
    setmidimode(true);
    setsheetmode(false);
    setyoutubemode(false);
    // dispatch(MAIN_PLAYER_PAUSE())
    await init();
  }


  const goToArtistMaingPage = () => {
    history.push(`/Artist/main/${ArtistProduct?.data.uid}`);
  };


  const sheet_playground = () => {
    setmidimode(false);
    setsheetmode(true);
    setyoutubemode(false);

    if (player) {
      player.stop();
    }
  };

  const youtube_playground = () => {
    setmidimode(false);
    setsheetmode(false);
    setyoutubemode(true);
    if (player) {
      player.stop();
    }
  };

 

  
  const Add_favorite_Song = async () => {
    if(user.is_login === false || user.user === null){
      setLoginModalopen(true)
    }
  };

  
  const gotobasket_page = async () => {
    if(user.is_login === false || user.user === null){
      setLoginModalopen(true)
    }
  };
  const closeModal = () => {setLoginModalopen(false);};

  



const onEditorChange  = (value) =>{
    setdiscrpction_EDIT_content(value)
}
const onClickdiscrpction_EDIT = ()  => {
  setdiscrpction_EDIT(false)
  dispatch(ArtistProductActions.set_EDIT_DISCRIPTION(discrpction_EDIT_content))
  dbService
  .collection("Song_All")
  .doc(Productid)
  .update({
    Discription:discrpction_EDIT_content,
  })
 
}

const handlemidifile = (e) => {
  if (e.target.files.length !== 0) {
    setmidiFile(e.target.files[0]);
    setmidifileinfo({ name: e.target.files[0].name });
    // set_midi_filedisabled(true);
  }
};

const handlsheetfile = (e) => {
  if (e.target.files.length !== 0) {
    setsheetFile(e.target.files[0]);
    setsheetfileinfo({
      url: window.URL.createObjectURL(e.target.files[0]),
      name: e.target.files[0].name,
    });
  }
};

const handlYoutubefile = (value) => {
  setYouTubeFile(value)
};

const handleImageFile = (e) => {
  if (e.target.files.length !== 0) { 
    setElbumFile({
      url: URL.createObjectURL(e.target.files[0]),
      name: e.target.files[0].name,
      file: e.target.files[0],
    });
  }
};


const onClick_Midi_EDIT = ()  => {
  setmidiFile_EDIT(false)
  if(midifile === null){
    return;
  }
 
  const upload = storageService
  .ref(`songs/midi/${new Date().getTime() + midifile.name}`)
  .put(midifile);

  const id = dbService.collection("Song_All").doc(Productid);

  upload_Midi_Firebase(upload, id);
  dispatch(ArtistProductActions.set_Midi_url(midifile))

}


const onClick_Sheet_EDIT = ()  => {
  setsheetFile_EDIT(false)
  if(sheetfile === null){
    return;
  }
  
  const upload = storageService
  .ref(`songs/sheet/${new Date().getTime() + sheetfile.name}`)
  .put(sheetfile);
  
  const id = dbService.collection("Song_All").doc(Productid);

  upload_Sheet_Firebase(upload, id);
  dispatch(ArtistProductActions.set_Sheet_url(sheetfile))

}

const onClick_YouTube_EDIT = async()  => {
  setYouTubeFile_EDIT(false)
  if(YouTubeFile === undefined){
    return;
  }

  const id = dbService.collection("Song_All").doc(Productid);
  await id.update({youtubeUrl: YouTubeFile})
  await dispatch(ArtistProductActions.set_youtube_url(YouTubeFile))
}

const onClick_ImageFile_EDIT = ()  => {
  setElbumFile_EDIT(false)
  if(Elbumfile === undefined || Elbumfile === null){
    return;
  }
  dispatch(ArtistProductActions.set_Elbum_url(Elbumfile.file))

  const upload = storageService
  .ref(`songs/Elbum/${new Date().getTime() +  Elbumfile.name}`)
  .put(Elbumfile.file);
  
  const id = dbService.collection("Song_All").doc(Productid);

  upload_Elbumfile_Firebase(upload, id)




}

const upload_Midi_Firebase = (upload,  id) => {
   upload.on(
      "state_change",
      (snapshot) => {
      },
      (error) => {
        alert(error.message);
        console.log(error.message);
      },
      () => {
        storageService
          .ref("songs/midi")
          .child(midifile.name)
          .getDownloadURL()
          .then((url) => {
            id.update({ Midi_url : url });
          });
      }
    );
  };

  const upload_Sheet_Firebase = (upload,  id) => {
    upload.on(
       "state_change",
       (snapshot) => {
       },
       (error) => {
         alert(error.message);
         console.log(error.message);
       },
       () => {
         storageService
           .ref("songs/sheet")
           .child(sheetfile.name)
           .getDownloadURL()
           .then((url) => {
             id.update({ Sheet_url : url });
           });
       }
     );
   };

   const upload_Elbumfile_Firebase = (upload,  id) => {
    upload.on(
       "state_change",
       (snapshot) => {
       },
       (error) => {
         alert(error.message);
         console.log(error.message);
       },
       () => {
         storageService
           .ref("songs/Elbum")
           .child(Elbumfile.name)
           .getDownloadURL()
           .then((url) => {
               id.update({ Elbum_url : url })
              
           })
           .then(
           );
       }
     );
   };
  
  async function init() {
    player = new Player();
  }
  

  return (
    <>
    {isLoading ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "26rem 0",
        }}
      >
        <CircularProgress color="secondary" size="5rem" />
      </div>
 ) : (

    <Grid position= "relative"  width= "100%" overflow= "hidden">
      <Grid>
        {midimode && (
          <div id="sheet" class="contain2">
            <div class="Artist_Product_midiPiano">
              <MidiPiano
                key={ArtistProduct?.id}
                id={ArtistProduct?.id}
                data={ArtistProduct?.Midi_url}
                name={ArtistProduct.data?.songName}
                player={player}
              />
            </div>
          </div>
        )}

        {sheetmode && (
          <div id="pdf" class="contain3">
            <div
              class="Artist_Product_sheet_PDF"
              style={{ width: width, height: height75 }}
            >
              <SheetPDF key={ArtistProduct?.id} id={ArtistProduct?.id} data={ArtistProduct?.Sheet_url} />
            </div>
          </div>
        )}

        {youtubemode && (
          <div id="youtube" class="contain4">
            <div class="youtube_main" > 
                <div className="video-responsive">
                <iframe
                  width={width}
                  height={height75 }
                  autoplay={true}
                  src={`https://www.youtube.com/embed/${ArtistProduct?.youtube_EmbedID}?autoplay=1`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              </div>
            </div>
          </div>
        )}

        <div
          class="Artist_Product_Bottom_menu"
          style={{ marginTop: height75, height: height25 }}
        >
          <div class="Artist_Product_Bottom_menu_mainbox" style={{ width: width }}>
            <div class="Artist_Product_Bottom_menu_artist_user">
              <div class="Artist_Product_Bottom_menu_buttonGroup">
                <a onClick={goToArtistMaingPage}>
                  <Avatar
                  src={ArtistProduct.artistuser_data?.photoURL}
                  className="card-img-wrapper_artist"
                  >
                    {" "}
                  </Avatar>
                </a>
                <a class="card-title_artist" onClick={goToArtistMaingPage}>
                {ArtistProduct.artistuser_data?.displayName}
                </a>
              </div>

              <div className="favorite__group">
                <div className="favorite__move">
                  <FavoriteBorderIcon className="favorite__Icon" />
                </div>
                <h2 className="favorite__number">{ArtistProduct?.artistuser_subscribe}</h2>
              </div>
            </div>



            <Grid width="60%" height="100%" display="flex" column backgroundColor="#fff">
              

            {discrpction_EDIT ?(
              <div>
              <ArtistProductEditor value={discrpction_EDIT_content} onChange={onEditorChange} />
              <Grid display="flex" row justify="left" marginT="4px">
                  <div className="Artist_Product_discrpction_EDIT_button">
                  <Button  _onClick={() => onClickdiscrpction_EDIT()} token size="15px"  backgroundcolor="#ba4148" width="60px" height="30px" color="#fff" marginR="3px">저장</Button>
                  </div>
                </Grid>
              </div>
            ):(
              <>
              <Grid display="flex" row marginT="20px" marginL="40px">
                <Text display="flex" color="#7c7c7c" size="20px" marginL="20px" lineHeight="32px">{releasetime}</Text>
                <Text display="flex" color="#7c7c7c"  size="20px" marginL="5px" lineHeight="32px">Release</Text>
              </Grid>

     
              <div
                    className="Artist_Product_Bottom_menu_discrpction"
                    dangerouslySetInnerHTML={{
                      __html: ArtistProduct.Discription,
                    }}
                  ></div>

              <Grid display="flex" row justify="left" marginT="10px">
                  <div className="Artist_Product_EDIT_button_enrollment">
                  <Button  _onClick={() => setdiscrpction_EDIT(true)} token size="15px"  backgroundcolor="#9e9e9e" width="60px" height="30px" color="#fff" marginR="3px">수정</Button>
                  </div>
                </Grid>
              </>
            )}
           
            
            </Grid>

            <Grid width="25%" display="flex" column    position ="relative" >
                
                {midiFile_EDIT ? (
                  <Grid display="flex" marginT="10px" paddingL="20px" paddingR="20px">
                      <input
                                    name="midifile"
                                    type="file"
                                    id="ex_file"
                                    accept=".midi, .mid;"
                                    onChange={handlemidifile}
                                    // ref={formRef}
                                    // disabled={midifile !== null}
                                  />
                  <Button  _onClick={() => onClick_Midi_EDIT()} token size="15px"  backgroundcolor="#ba4148" width="60px" height="40px" color="#fff" marginR="3px">저장</Button>
                </Grid>
                ):(
                  <Grid marginT="10px" display="flex" paddingL="20px" paddingR="20px">
                    <Button display="block"    margin="auto" _onClick={() =>  setmidiFile_EDIT(true)} token size="15px"  backgroundcolor="#9e9e9e" width="100px" height="40px" color="#fff" marginR="3px">MIDI 수정</Button>
                  </Grid>
                )}

                {sheetFile_EDIT ? (
                  <Grid display="flex" marginT="10px" paddingL="20px" paddingR="20px">
                      <input
                                    name="sheetfile"
                                    type="file"
                                    id="ex_file2"
                                    accept=".pdf;"
                                    onChange={handlsheetfile}
                                    // ref={formRef}
                                    // disabled={midifile !== null}
                                  />
                  <Button  _onClick={() => onClick_Sheet_EDIT()} token size="15px"  backgroundcolor="#ba4148" width="60px" height="40px" color="#fff" marginR="3px">저장</Button>
                </Grid>
                ):(
                  <Grid marginT="10px" display="flex" paddingL="20px" paddingR="20px">
                    <Button display="block"    margin="auto" _onClick={() =>  setsheetFile_EDIT(true)} token size="15px"  backgroundcolor="#9e9e9e" width="100px" height="40px" color="#fff" marginR="3px">SHEET 수정</Button>
                  </Grid>
                )}

                {YouTubeFile_EDIT ? (
                  <Grid display="flex" marginT="10px" paddingL="20px" paddingR="20px">
                   
                  <Input2   height="40px"  display="block"  value={YouTubeFile} type="text"  plcholder="Youtube" width="250px"  _onChange={(e) => handlYoutubefile(e.target.value)} />

                  <Button  _onClick={() => onClick_YouTube_EDIT()} token size="15px"  paddingL="10px"  backgroundcolor="#ba4148" width="60px" height="40px" color="#fff" marginR="3px" display="block">저장</Button>
                </Grid>
                ):(
                  <Grid marginT="10px" display="flex" paddingL="20px" paddingR="20px">
                    <Button display="block"    margin="auto" _onClick={() =>  setYouTubeFile_EDIT(true)} token size="15px"  backgroundcolor="#9e9e9e" width="100px" height="40px" color="#fff" marginR="3px">YouTube 수정</Button>
                  </Grid>
                )}

                {ElbumFile_EDIT ? (
                  <Grid display="flex" marginT="10px" paddingL="20px" paddingR="20px">
                   
                  <input
                                    name="sheetfile"
                                    type="file"
                                    id="ex_file3"
                                    accept="image/*"
                                    onChange={handleImageFile}
                                    // ref={formRef}
                                    // disabled={midifile !== null}
                                  />
                  <Button  _onClick={() => onClick_ImageFile_EDIT()} token size="15px"  paddingL="10px"  backgroundcolor="#ba4148" width="60px" height="40px" color="#fff" marginR="3px" display="block">저장</Button>
                </Grid>
                ):(
                  <Grid marginT="10px" display="flex" paddingL="20px" paddingR="20px">
                    <Button display="block"    margin="auto" _onClick={() =>  setElbumFile_EDIT(true)} token size="15px"  backgroundcolor="#9e9e9e" width="100px" height="40px" color="#fff" marginR="3px">Elbum 수정</Button>
                  </Grid>
                )}

               
            </Grid>
            
          </div>
        </div>

        <Palette
          src={ArtistProduct.data?.Elbum_url}
          crossOrigin="anonymous"
          format="hex"
          colorCount={4}
        >
          {({ data, loading }) => {
            if (loading) return <Loading />;
            return (
              <div
                class="Artist_Product_Right_menu"
                style={{
                  marginLeft: width,
                  height: height100,
                  backgroundColor: `${data[0]}`,
                }}
              >
                {/* <ul>
                    {data.map((color, index) => (
                      <li key={index} style={{ color: color }}>
                        <strong>{color}</strong>
                      </li>
                    ))}
                  </ul> */}
                <div class="Artist_Product_Right_menu_song_name" style={{ color: `${data[1]}` }}>
                  {ArtistProduct.data?.songName}
                </div>
                <Grid column width="350px" height="350px" isflex margin="20px auto" borderRadius="300px" >
                  <img
                    src={ArtistProduct.data?.Elbum_url}
                    alt=""
                    className="product_song__image"
                  />

                  <Grid align  row width="280px" padding="0.5rem" justify="space-between" display="flex" margin="10px auto">
                   
                      <IconButton
                        className="playIcon_list__optionsIcon"
                        onClick={sheet_playground}
                      >
                        <PictureAsPdfOutlinedIcon
                          className="__Icon"
                          style={{ color: `${data[2]}` }}
                        />
                      </IconButton>
                  
                      <IconButton
                        className="playIcon_list__optionsIcon"
                        onClick={midi_playground}
                      >
                        <StraightenIcon
                          className="PlayArrowIcon"
                          style={{ color: `${data[2]}` }}
                        />
                      </IconButton>
                    
                      <IconButton
                        className="playIcon_list__optionsIcon"
                        onClick={youtube_playground}
                      >
                        <YouTubeIcon
                          className="__Icon"
                          style={{ color: `${data[2]}` }}
                        />
                      </IconButton>
                
                  </Grid>
                </Grid>

                <Grid display="flex" width="420px" margin="auto" paddingT="60px">
                  <Grid marginL="10px">

                    <div
                      class="Artist_Product_SubBox_Info"
                      style={{ backgroundColor: `${data[1]}` }}
                    >
                      <div class="Artist_Product_SubBox_Info_artist">
                        {ArtistProduct.data?.artistName}
                      </div>
                    </div>
                    <div
                      class="Artist_Product_SubBox_Info"
                      style={{ backgroundColor: `${data[1]}` }}
                    >
                      <Text margin="auto" textalign="center" color="#fff" size="15px" paddingT="3px" width="190px">{ArtistProduct.data?.genres}</Text>
                    </div>

                    <div
                      class="Artist_Product_SubBox_Info"
                      style={{ backgroundColor: `${data[1]}` }}
                    >
                      <Grid size="15px" paddingT="3px" width="190px" display="flex" margin="auto" align="center" justify="center">     
                       <ArrowDownwardIcon className="p_con_down_icon" />
                       <Text  textalign="center" color="#fff" size="15px" paddingT="0px" display="flex" >{ArtistProduct.data?.update}</Text> 
                      </Grid>      
                    </div>

                    <div
                      class="Artist_Product_SubBox_Info"
                      style={{ backgroundColor: `${data[1]}` }}
                    >
                     <Text margin="auto" textalign="center" color="#fff" size="15px" paddingT="3px" width="190px">{ArtistProduct.data?.hardest}</Text>
                    </div>

                  </Grid>

                  <Grid marginL="5px">
                    <div
                      class="Artist_Product_SubBox_Info"
                      style={{ backgroundColor: `${data[1]}` }}
                    >
                    <Text margin="auto" textalign="center" color="#fff" size="15px" paddingT="3px" width="190px">{ArtistProduct.data?.displayName}</Text>
                    </div>

                    <div
                      class="Artist_Product_SubBox_Info"
                      style={{ backgroundColor: `${data[1]}` }}
                    >
                     <Text margin="auto" textalign="center" color="#fff" size="15px" paddingT="3px" width="190px">{ArtistProduct?.edit}</Text>
                    </div>

                    <div
                      class="Artist_Product_SubBox_Info"
                      style={{ backgroundColor: `${data[1]}` }}
                    >
                      
                      <Grid size="15px" paddingT="3px" width="190px" display="flex" margin="auto" align="center" justify="center">     
                       <ArrowDownwardIcon className="p_con_down_icon" />
                       <Text  textalign="center" color="#fff" size="15px" paddingT="0px" display="flex" >{ArtistProduct.data?.faviorite}</Text> 
                      </Grid>

                    </div>
                    <div
                      class="Artist_Product_SubBox_Info"
                      style={{ backgroundColor: `${data[1]}` }}
                    >
          
                      <Grid width="190px" display="flex" margin="auto" align="center" justify="center" paddingT="3px">
                        <StyledRatingPlayer
                          name="hover-feedback"
                          readOnly
                          value={ArtistProduct?.ratingvalue}
                          precision={0.5}
                          // precision={0.5}
                          // onChange={(event, newValue) => {
                          //   setValue(songs?.ratingValue);
                          // }}
                          // onChangeActive={(event, newHover) => {
                          //   setHover(newHover);
                          // }}
                          size="medium"
                        />
                      </Grid>
                    </div>
                    {/* </table> */}
                  </Grid>
                </Grid>

                <Grid display="flex" width="400px" height="150px" margin="30px auto">

                  <Grid  column display="flex" width="190px" height="150px" bg={`${data[2]}`} borderRadius="15px">
                    <Text  margin="auto" textalign="center" color="#fff" bold size="36px" display="flex" width="190px" height="140px" paddingT="0px" >
                    {t("Payment. Currency")}
                    </Text>
                   
                  </Grid>
                 


                  <Grid display="flex" width="190px" height="150px" margin="auto" column>
                    <Grid width="190px" display="flex">
                        <Button
                       
                          MainPage
                          width="110px"
                          height="50px"
                          backgroundcolor={`${data[2]}`}
                          color="#fff"
                          borderradius="12px"
                          paddingL="20px"
                          display="flex"
                          _onClick={gotobasket_page}
                        >
                          장바구니
                        </Button>
          

                        <Button
                           MainPage
                          marginL="10px"
                          width="70px"
                          height="50px"
                          backgroundcolor={`${data[2]}`}
                          color="#fff"
                          borderradius="12px"
                          paddingL="20px"
                          _onClick={Add_favorite_Song}
                        >
                          좋아요
                        </Button>
                    </Grid>


                    <Grid  display="flex" height="140px"  width="250px" justify="space-around" column  display="flex" marginL=""  >
                      <Grid display="flex" marginL="10px">
                        <Text size="15px" color="rgb(151, 151, 151)">
                          ￦
                        </Text>
                        <Text  size="15px" color="rgb(151, 151, 151)"  marginL="5px">
                          {/* {currencyWon} */}
                        </Text>
                      </Grid>

                      <Grid display="flex" marginL="10px">
                        <Text size="15px" color="rgb(151, 151, 151)">
                          $
                        </Text>
                        <Text  size="15px" color="rgb(151, 151, 151)"  marginL="5px">
                          {/* {currencyUSD} */}
                        </Text>
                      </Grid>

                      <Grid display="flex" marginL="10px">
                        <Text size="15px" color="rgb(151, 151, 151)">
                          ¥
                        </Text>
                        <Text  size="15px" color="rgb(151, 151, 151)"  marginL="5px">
                          {/* {currencyJPY} */}
                        </Text>
                      </Grid>

                      <Grid display="flex" marginL="10px">
                        <Text size="15px" color="rgb(151, 151, 151)">
                          €
                        </Text>
                        <Text  size="15px" color="rgb(151, 151, 151)"  marginL="5px">
                          {/* {currencyEUR} */}
                        </Text>
                      </Grid>

                      <Grid display="flex" marginL="10px">
                        <Text size="15px" color="rgb(151, 151, 151)">
                          元
                        </Text>
                        <Text  size="15px" color="rgb(151, 151, 151)"  marginL="5px">
                          {/* {currencyCNY} */}
                        </Text>
                      </Grid>
                    </Grid>
                    {/* <div class="Artist_Product_checkoutbox" onClick={gotoCheckout}>
                      <span className="Artist_Product_checkoutbox_text">결제하기</span>
                    </div> */}
                  </Grid>
                </Grid>
              </div>
            );
          }}
        </Palette>
        <ModalContainer show = {LoginModalopen} close={closeModal}/>
      </Grid>
    </Grid>
     )}
     </>
  );
}

export default Artist_Product_EDIT;
