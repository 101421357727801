import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { Grid, Button, Text, Input2 } from "../../elements/index";
import queryString from "query-string";
import "./Checkout_Result.css";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { CircularProgress } from "@material-ui/core";

function Checkout_Result({ history }) {
  const { location } = history;
  const { search } = location;
  const history2 = useHistory();

  const query = queryString.parse(search);
  // console.log(search);
  // ?apply_num=&bank_name&buyer_addr=&buyer_email=&buyer_name=&buyer_postcode=&buyer_tel=&card_name&card_number=&card_quota=0&currency=KRW&custom_data&imp_uid=imp_637761683907&merchant_uid=product_1637651762503&name=Mclaughlin%20Bros.%20Jug%20Band%20%EC%99%B8%203%EA%B3%A1&paid_amount=9200&paid_at=1637651801&pay_method=point&pg_provider=kakaopay&pg_tid=T2967819657471089425&pg_type=payment&receipt_url=https%3A%2F%2Fmockup-pg-web.kakao.com%2Fv1%2Fconfirmation%2Fp%2FT2967819657471089425%2Fdbedf9277ed58a3ee040f34b2251e1f31db977b533c9d9c5ff4fc98e4dd79eff&status=paid&success=true



  // apply_num: ""
  // bank_name: null
  // buyer_addr: ""
  // buyer_email: ""
  // buyer_name: ""
  // buyer_postcode: ""
  // buyer_tel: ""
  // card_name: null
  // card_number: ""
  // card_quota: "0"
  // currency: "KRW"
  // custom_data: null
  // imp_uid: "imp_637761683907"
  // merchant_uid: "product_1637651762503"
  // name: "Mclaughlin Bros. Jug Band 외 3곡"
  // paid_amount: "9200"
  // paid_at: "1637651801"
  // pay_method: "point"
  // pg_provider: "kakaopay"
  // pg_tid: "T2967819657471089425"
  // pg_type: "payment"
  // receipt_url: "https://mockup-pg-web.kakao.com/v1/confirmation/p/T2967819657471089425/dbedf9277ed58a3ee040f34b2251e1f31db977b533c9d9c5ff4fc98e4dd79eff"
  // status: "paid"
  // success: "true"


  const [isLoading, setIsLoading] = useState(true);

  // firebase user에 checkout 물품 추가
  // 아티스트에 amount 추가 화폐단위 추가
  // checkout redux에 모든 물품 제거
  //산물품 장바구니에서 지우기
  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "26rem 0",
          }}
        >
          <CircularProgress color="secondary" size="5rem" />
        </div>
      ) : (
        <Grid
          display="flex"
          width="1600px"
          height="800px"
          // marginT="64px"
          margin="auto"
        >
          <Grid display="flex" width="1100px" height="800px" margin="auto">
            <div className="Checkout_Result_mainCircle">

              <Grid display="flex" width="200px" >
                <CheckCircleOutlineIcon className="Checkout_Result_CheckCircleOutline__Icon" />
              </Grid>
              <Grid display="flex" column width="500px" >
               <Text h2 textalign="start">{query.name}</Text>

                <Text h2 textalign="start">결제가 완료되었습니다</Text>
                <Text h4 textalign="start" marginT="10px">구매내역페이지에서 확인하세요</Text>
                <Grid row display="inline-block" justify="space-between" marginT="30px">
                  <Button main width="210px" _onClick={()=> history2.push("/Product/PurchaseProduct")}>구매내역으로 이동</Button>
                  <Button main width="210px" marginL="5px" _onClick={()=> history2.push("/")}>홈으로 가기</Button>
                </Grid>
              </Grid>

            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
}

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  top: 2rem;
  left: 2rem;
  right: 2rem;
  bottom: 2rem;
  padding: 2rem;

  > .anticon {
    font-size: 10rem;
    text-align: center;
    margin-bottom: 2rem;
    color: ${(props) => props.colorType};
  }
  p {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }

  ul {
    list-style: none;
    padding: 0;
    margin-bottom: 3rem;

    li {
      display: flex;
      line-height: 2;
      span:first-child {
        width: 8rem;
        color: #888;
      }
      span:last-child {
        width: calc(100% - 8rem);
        color: #333;
      }
    }
  }

  button,
  button:hover {
    border-color: ${(props) => props.colorType};
    color: ${(props) => props.colorType};
  }
  button:hover {
    opacity: 0.7;
  }
`;

export default Checkout_Result;
