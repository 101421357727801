import { createAction, handleActions } from "redux-actions";
import axios from "axios";
import { setLocal, deleteLocal } from "../../shared/Local";

 const QUESTION_DATA = "QUESTION_DATA";
 const QUESTION_CONTENT = "QUESTION_CONTENT"; 
 const QUESTION_LOOKUP = "QUESTION_LOOKUP"; 
 const QUESTION_SONG = "QUESTION_SONG"; 
 const REMONVE_QUESTION_SONG = "REMONVE_QUESTION_SONG"; 


const initialState = {
  QuestionData: "",
  QuestionContentID: "",
  QuestionContentData:"",
  QuestionTime: "",
  QuestionSong:"",
};

// 회원 가입
const set_Question_Data = (Question) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type:  QUESTION_DATA,
      QuestionData : Question,
    });  
  };
};

const set_Question_LookUP = (lookUp) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type:  QUESTION_LOOKUP,
      lookUp : lookUp,
    });  
  };
};

const set_Question_SongData = (Song) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type:  QUESTION_SONG,
      QuestionSong : Song,
    });  
  };
};

const remove_Question_SongData = () => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type:  REMONVE_QUESTION_SONG,
    });  
  };
};

const set_Question_Content = (QuestionContentID, QuestionContentData , QuestionTime) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type:  QUESTION_CONTENT,
      QuestionContentID : QuestionContentID,
      QuestionContentData: QuestionContentData,
      QuestionTime : QuestionTime,
    });  
  };
};




const Question = (state = initialState, action) => {
  switch (action.type) {
  
      case QUESTION_DATA:
        return {
          ...state,
          QuestionData : action.QuestionData,
        }

        case QUESTION_LOOKUP:
          const user_artistuser = Object.assign(state.noticeData, action.lookUp, );
        console.log(action.lookUp)
        console.log(user_artistuser)
        return {
          ...state,
          // noticeData : action.lookUp,
        }

        case QUESTION_CONTENT:
          return {
            ...state,
            QuestionContentID : action.QuestionContentID ,
            QuestionContentData : action.QuestionContentData,
            QuestionTime: action.QuestionTime,
          }
          case QUESTION_SONG:
            return {
              ...state,
              QuestionSong : action.QuestionSong,
            }
            case REMONVE_QUESTION_SONG:
              return {
                ...state,
                QuestionSong : "",
              }

    default:
      return state
  }
};

export default Question;


const actionCreators = {
  set_Question_Data,
  set_Question_Content,
  set_Question_LookUP,
  set_Question_SongData,
  remove_Question_SongData,
};

export { actionCreators };
