import React, { useEffect, useState } from "react";
import { useHistory, useParams , Redirect  } from "react-router-dom";
import { dbService } from "../../shared/firebase";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button, Text, Input2 } from "../../elements/index";
import "./Calculate_Amount.css";
import { CircularProgress } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { actionCreators as CalculateAmountactions } from "../../redux/modules/CalculateAmount";
import { Link, withRouter } from "react-router-dom";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import Chart from "react-google-charts";
import { ProgressBar , ArtistSideBar } from "../../components/index";
import firebase from "firebase";




function Calculate_Amount() {
  const CalculateAmount = useSelector((state) => state.CalculateAmount);
  const user = useSelector((state) => state.user);

  const history = useHistory();
  const { Artist } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [graphDate, setgraphDate] = useState("");
  const [graphWeek, setgraphWeek] = useState("");
  const [ComparegraphWeek2, setComparegraphWeek2] = useState("");
  const [completed, setCompleted] = useState(0);
  const [CalculateArtist_Amount, setCalculateArtist_Amount] = useState([]);
  const [sumamount, setsumamount] = useState();
  const [ progressbar_completed, setprogressbar_completed] = useState();
  const [CalculateButtonDisabled, setCalculateButtonDisabled] = useState(false);

 
  useEffect(() => {
    
    if(user){
    dbService
      .collection("Artist_amount")
      .doc(user?.uid)
      .get()
      .then((snapshot) => {

        const fetchdata = snapshot.data();
        const fetchSorteddata = snapshot.data()?.Song_purchaseProduct;
        const fetchid = snapshot.id;

        const  compareDate = (a, b) => {
          var nameA = time(a.date?.toDate().toISOString()); // ignore upper and lowercase
          var nameB = time(b.date?.toDate().toISOString()); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        }
        const SortedDate = fetchSorteddata.sort(compareDate)

        dispatch(CalculateAmountactions.set_CalculateAmount_Data(
          fetchdata,
          SortedDate
          ));

        setIsLoading(false);
      });
    }
  }, [history, dispatch, user]);


  useEffect(() => {
    let sum_amount_display = [];

    if(CalculateAmount.sortedData_DATE === undefined){
      return;
    }
    var KOR =`${CalculateAmount.KOR}`;
    var Kor_realprice = 0;
    if(KOR.indexOf(",") === 1){
      Kor_realprice = KOR.replace(/,/, "");
   } else {
    Kor_realprice = KOR;
    };
    var KOR_NumberPrice = Number(Kor_realprice);

    var USD = CalculateAmount.USD;
    var USD_realprice = 0;
    USD_realprice = USD;
    var USD_NumberPrice = Number(USD_realprice);

    var JPY= CalculateAmount.JPY;
    var JPY_realprice = 0;
    JPY_realprice = JPY;
    var Jpy_NumberPrice = Number(JPY_realprice);


    const Amountdata = {
      KOR : KOR_NumberPrice,
      USD : USD_NumberPrice,
      JPY : Jpy_NumberPrice,
  }
  setsumamount(Amountdata)

  var progressbar_completed = 0


  if (i18n.language === "kor") {
    var progressbar_KOR = (KOR_NumberPrice/30000) * 100;
    progressbar_completed =  progressbar_KOR
  } 
  else if (i18n.language === "en") {
    var progressbar_USD = (USD_NumberPrice/30) * 100;
    progressbar_completed =  progressbar_USD
  } 
  else if (i18n.language === "ja") {
    var progressbar_JPY = (Jpy_NumberPrice/3000) * 100;
    progressbar_completed =  progressbar_JPY
  } else{
    progressbar_completed =  progressbar_USD
  }
  console.log(progressbar_completed)
  setprogressbar_completed(progressbar_completed)

  i18n.on("languageChanged", (lng) => {
    if (lng === "kor") {
      var progressbar_KOR = (KOR_NumberPrice/30000) * 100;
      setprogressbar_completed(progressbar_KOR)
    } else if (lng === "en") {
      var progressbar_USD = (USD_NumberPrice/30) * 100;
      setprogressbar_completed(progressbar_USD)
    } else if (lng === "ja") {
      var progressbar_JPY = (Jpy_NumberPrice/3000) * 100;
      setprogressbar_completed(progressbar_JPY)
    } else{
      setprogressbar_completed(progressbar_USD)
    }
  
  });


  }, [CalculateAmount, i18n ]);

  const Calculate_sumamount = () => {
    if (i18n.language === "kor") {
      return sumamount.KOR;
    } 
    else if (i18n.language === "en") {
      return sumamount.USD;
    } 
    else if (i18n.language === "ja") {
      return  sumamount.JPY;
    }
    else {
      return sumamount.USD;
    }
  }



  useEffect(() => {
    if(progressbar_completed >= 100 ){
      setCalculateButtonDisabled(false);
    } else{
      setCalculateButtonDisabled(true);
    }
  console.log(progressbar_completed);
  }, [user, progressbar_completed]);





  useEffect(() => {
  
    const today = new Date()

    const today_Day_minus_1 = new Date(today)
    today_Day_minus_1.setDate(today.getDate() -1)

    const today_Day_minus_2 = new Date(today)
    today_Day_minus_2.setDate(today.getDate() -2)

    const today_Day_minus_3 = new Date(today)
    today_Day_minus_3.setDate(today.getDate() -3)

    const today_Day_minus_4 = new Date(today)
    today_Day_minus_4.setDate(today.getDate() -4)

    const today_Day_minus_5 = new Date(today)
    today_Day_minus_5.setDate(today.getDate() -5)

    const today_Day_minus_6 = new Date(today)
    today_Day_minus_6.setDate(today.getDate() -6)


    const date = {
        Day: `${today.getMonth() + 1}.${today.getDate()}`,
        Day_minus_1 : `${today_Day_minus_1.getMonth() + 1}.${today_Day_minus_1.getDate()}`,
        Day_minus_2 : `${today_Day_minus_2.getMonth() + 1}.${today_Day_minus_2.getDate()}`,
        Day_minus_3 : `${today_Day_minus_3.getMonth() + 1}.${today_Day_minus_3.getDate()}`,
        Day_minus_4 : `${today_Day_minus_4.getMonth() + 1}.${today_Day_minus_4.getDate()}`,
        Day_minus_5 : `${today_Day_minus_5.getMonth() + 1}.${today_Day_minus_5.getDate()}`,
        Day_minus_6 : `${today_Day_minus_6.getMonth() + 1}.${today_Day_minus_6.getDate()}`

      }


      const today_Week_minus_1 = new Date(today)
      today_Week_minus_1.setDate(today.getDate() -7)
      const today_Week_minus_2 = new Date(today)
      today_Week_minus_2.setDate(today.getDate() -14)
      const today_Week_minus_3 = new Date(today)
      today_Week_minus_3.setDate(today.getDate() -21)
      const today_Week_minus_4 = new Date(today)
      today_Week_minus_4.setDate(today.getDate() -28)

      const week = {
        week_minus_1: `${today_Week_minus_1.getMonth() + 1}.${today_Week_minus_1.getDate()} ~ ${today_Week_minus_1.getDate()+7}`,
        week_minus_2 : `${today_Week_minus_2.getMonth() + 1}.${today_Day_minus_2.getDate()} ~ ${today_Week_minus_2.getDate()+7}`,
        week_minus_3 : `${today_Week_minus_3.getMonth() + 1}.${today_Day_minus_3.getDate()} ~ ${today_Week_minus_3.getDate()+7}`,
        week_minus_4 : `${today_Week_minus_4.getMonth() + 1}.${today_Day_minus_4.getDate()} ~ ${today_Week_minus_4.getDate()+7}`,

      }
  

      const weekCompare2 = {
        today: today,
        week_minus_1: today_Week_minus_1,
        week_minus_2 : today_Week_minus_2,
        week_minus_3 : today_Week_minus_3,
        week_minus_4 : today_Week_minus_4,
      }
    setgraphDate(date)
    setgraphWeek(week)
    setComparegraphWeek2(weekCompare2)
    
  }, [CalculateAmount ]);




  const checkNumber = (array, value) =>{
    var count = 0;
    array.map((i) => {
      var productDate = new Date(i.date * 1000)
      
      var Date2 =  `${productDate.getMonth() + 1}.${productDate.getDate()}`
      if(value === Date2){
       count++
      }

    })
    return count
  }

  const checkWeekNumber = (array, value, string) =>{
    var count = 0;
    array.map((i) => {
      var productDate = new Date(i.date.toDate())
        if(productDate < value  && productDate > string){
          count++
        }
        else {
          count = 0;
        }
    })
    return count
  }

  const checkMonthNumber = (array, month) =>{
    var count = 0;
    array.map((i) => {
      var productDate = new Date(i.date.toDate())
        var getMonth =  productDate.getMonth()+1
        if(getMonth === month){
          count++
        }
        else {
          count = 0;
        }
    })
    return count
  }
 


  const time = (data) => {
    var split1 = (data || "").split("T");
    var replace = split1[0].replace(/\-/g, " ");
    var replace2 = replace.replace(" ", ".");
    var date = replace2.replace(" ", ".");
    return date;
  };

 
 
  const Save_calculate = () => {

    const data ={
        user_uid : user?.uid,
        calculate_uid: `calculate_${new Date().getTime()}`,
        calculate_complete : "N",
        calculate_demandAt: firebase.firestore.FieldValue.serverTimestamp(),
        calculate_Month: new Date().getMonth()+1,
        calculate_amount:sumamount,
        calculate_Currency:t("Payment.Currency"),
        //아티스트 계좌 추가하기   
    }
    function financial(x) {
      return Number.parseFloat(x).toFixed(1);
    }

    dbService
    .collection("calculate_amount")
    .doc(`calculate_${new Date().getTime()}`)
    .set(data)
    .then(
      setCalculateButtonDisabled(true),
      dbService
      .collection("Artist_amount")
      .doc(user.uid)
      .update({
        KOR: firebase.firestore.FieldValue.increment(-sumamount.KOR),
        JPY: firebase.firestore.FieldValue.increment(-sumamount.JPY),
        USD: firebase.firestore.FieldValue.increment(financial(-sumamount.USD)),
      }),
      setsumamount(0),
      setprogressbar_completed(0)
    )
  };








  return (
    <Grid width="100%">
      <>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "26rem 0",
            }}
          >
            <CircularProgress color="secondary" size="5rem" />
          </div>
        ) : (
          <>
            <Grid
              display="flex"
              margin="0 auto"
              width="100%"
              paddingT="20px"
              paddingB="10px"
            >
             <ArtistSideBar/>

            <Grid column display="flex" width="86%">
              <div class="CalculateAmount_main_card">
              <Grid paddingL="30px" paddingR="30px">
                <Text bold  textalign="left" display="block" size="21px"  marginT="24px" color="#434343">정산하기</Text>
                <Grid width="100%" bdrBottom="1px solid #EAEAEA" marginT="16px" display="flex"></Grid>
              </Grid>
                <Grid width="100%" column margin="auto">
                <div className="CalculateAmount_main_song">
                {/* <div className="CalculateAmount_main_Circle">
                      정산하기
                  </div> */}
                {/* <Text size="24px" textAlign="left" marginT="20px" width="90%" color="rgb(83, 83, 83)" margin="auto">판매 분석</Text> */}
                <Grid display="flex" row margin="auto" width="96%" justify="space-between" marginT="24px" paddingL="10px" paddingR="10px">
                <Chart
                    width='450px'
                    height={'300px'}
                    chartType="AreaChart"
                    loader={  <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              margin: "26rem 0",
                            }}
                          >
                            <CircularProgress color="secondary" size="5rem" />
                          </div>}
                    data={[
                      ['Day', 'Day',],
                      [graphDate.Day_minus_6 , checkNumber(CalculateAmount.sortedData_DATE , graphDate.Day_minus_6)],
                      [graphDate.Day_minus_5, checkNumber(CalculateAmount.sortedData_DATE , graphDate.Day_minus_5)],
                      [graphDate.Day_minus_4, checkNumber(CalculateAmount.sortedData_DATE , graphDate.Day_minus_4)],
                      [graphDate.Day_minus_3, checkNumber(CalculateAmount.sortedData_DATE , graphDate.Day_minus_3)],
                      [graphDate.Day_minus_2, checkNumber(CalculateAmount.sortedData_DATE , graphDate.Day_minus_2)],
                      [graphDate.Day_minus_1, checkNumber(CalculateAmount.sortedData_DATE , graphDate.Day_minus_1)],
                      [graphDate.Day , checkNumber(CalculateAmount.sortedData_DATE , graphDate.Day)],
                    ]}
                    options={{
                      title: '일별 판매량',
                      hAxis: { title: 'Day', titleTextStyle: { color: '#333' } },
                      vAxis: { minValue: 0 },
                      chartArea: { width: '65%', height: '60%' },
                      colors:  ["#ba4148"]
                    }}
                    className="Google_Chart"
                    // For tests
                    rootProps={{ 'data-testid': '1' }}
                    style={{ justifyContent:"left", display:"blcok"  }}
                  />
                    <Chart
                    width='450px'
                    height={'300px'}
                    chartType="AreaChart"
                    loader={  <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              margin: "26rem 0",
                            }}
                          >
                            <CircularProgress color="secondary" size="5rem" />
                          </div>}
                    data={[
                      ['Week', 'Week',],
                      [graphWeek.week_minus_4, checkWeekNumber(CalculateAmount.sortedData_DATE , ComparegraphWeek2.week_minus_3  , ComparegraphWeek2.week_minus_4)],
                      [graphWeek.week_minus_3, checkWeekNumber(CalculateAmount.sortedData_DATE , ComparegraphWeek2.week_minus_2  , ComparegraphWeek2.week_minus_3)],
                      [graphWeek.week_minus_2, checkWeekNumber(CalculateAmount.sortedData_DATE , ComparegraphWeek2.week_minus_1 , ComparegraphWeek2.week_minus_2)],
                      [graphWeek.week_minus_1 , checkWeekNumber(CalculateAmount.sortedData_DATE , ComparegraphWeek2.today , ComparegraphWeek2.week_minus_1 )],

                    ]}
                    options={{
                      title: '주별 판매량',
                      hAxis: { title: 'Week', titleTextStyle: { color: '#333' } },
                      vAxis: { minValue: 0 },
                      chartArea: { width: '65%', height: '60%' },
                      colors:  ["#ba4148"]
                    }}
                    className="Google_Chart"

                    rootProps={{ 'data-testid': '1' }}
                    style={{ justifyContent:"left", display:"blcok" , alignitems: "                    // For testsflex-start"  ,left:"600px" }}
                  />

              <Chart
                     width='450px'
                    height={'300px'}
                    chartType="AreaChart"
                    loader={  <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              margin: "26rem 0",
                            }}
                          >
                            <CircularProgress color="secondary" size="5rem" />
                          </div>}
                    data={[
                      ['Month', 'Month',],
                      ["January" , checkMonthNumber(CalculateAmount.sortedData_DATE , 1)],
                      ["February", checkMonthNumber(CalculateAmount.sortedData_DATE , 2)],
                      ["March", checkMonthNumber(CalculateAmount.sortedData_DATE , 3 )],
                      ["April", checkMonthNumber(CalculateAmount.sortedData_DATE , 4)],
                      ["May", checkMonthNumber(CalculateAmount.sortedData_DATE , 5)],
                      ["June", checkMonthNumber(CalculateAmount.sortedData_DATE , 6)],
                      ["July" , checkMonthNumber(CalculateAmount.sortedData_DATE , 7 )],
                      ["August", checkMonthNumber(CalculateAmount.sortedData_DATE , 8)],
                      ["September", checkMonthNumber(CalculateAmount.sortedData_DATE , 9)],
                      ["October", checkMonthNumber(CalculateAmount.sortedData_DATE , 10)],
                      ["November", checkMonthNumber(CalculateAmount.sortedData_DATE , 11)],
                      ["December" , checkMonthNumber(CalculateAmount.sortedData_DATE , 12)],
                    ]}
                    options={{
                      title: '월별 판매량',
                      hAxis: { title: 'Month', titleTextStyle: { color: '#333' } },
                      vAxis: { minValue: 0 },
                      chartArea: { width: '65%', height: '60%' },
                      colors:  ["#ba4148"]

                    }}
                    className="Google_Chart"
                    // For tests
                    rootProps={{ 'data-testid': '1' }}
                    style={{ justifyContent:"left", display:"blcok" , alignitems: "flex-start"  ,left:"600px" }}
                  />

                   </Grid>
                </div>
                </Grid>
              </div>
              <Grid row display="flex">
                    <Grid column display='flex' width="35%" marginL="1%">
                      <div class="CalculateAmount_Calculate_ProgressBar_card">
                      <Grid column width="90%" margin="auto" display="flex" paddingT="20px">
                          <Text color="rgb(83, 83, 83)" size="15px"   width="100%" textAlign="left" margin="5px auto" >30달러이상만 정산가능</Text>

                          
                          {/* <ProgressBar bgcolor={ "#ba4148"} completed={progressbar_completed}  /> */}

                        
                          </Grid>
                      </div>
                      <div class="CalculateAmount_Calculate_card">
                          <Grid width="90%" column margin="auto" paddingT="20px">
                             <Text  size="15px" textAlign="left"   width="100%" color="rgb(83, 83, 83)"  margin="10px auto">정산하기</Text>
                            <Grid row display="flex" marginT="10px">
                            <Grid column width="100%" margin="auto" display="flex" bdr="1px solid rgba(107, 107, 107, 0.353)"  borderRadius="12px" height="180px">
                            <Text bold size="50px" textAlign="center" width="90%" margin="10px auto" justify="center" marginT="20%">{t("Payment.Currency")} {Calculate_sumamount()}</Text>
                  
                                      
                            </Grid>
                            <Grid column width="100%" margin="auto" display="flex" height="180px">
                              {CalculateButtonDisabled === true ? (
                                <Button disabled width="90%" margin="auto" >정산하기</Button>

                              ) : (
                                <Button Edit _onClick={()=> Save_calculate()} width="90%" margin="auto" borderradius="15px" backgroundcolor="#ba4148" color="#fff" >정산하기</Button>
                              )}

                            </Grid>
                          </Grid>
                    </Grid>
                </div>

                    </Grid>
          

              <Grid  display='flex' width="62%" marginL="1%" marginR="1%">
                <div class="CalculateAmount_CalculateSong_card">
                      {/* /song_purchaseproduce song별로 filter해서 갯수 몇개 팔렷는지 액수 다더하기  */}
                        <Text margin="180px auto" size="24px">Coming soon</Text>

                </div>
                </Grid>

              </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </>
    </Grid>
  );
}

export default Calculate_Amount;
