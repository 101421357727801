import React, { useEffect, useState , useRef} from "react";
import { useHistory, useParams } from "react-router-dom";
import { dbService  , storageService } from "../../shared/firebase";
import { useDispatch, useSelector } from "react-redux";
import "./PurchaseProduct.css";
import { actionCreators as PurchaseProduct_Actions } from "../../redux/modules/PurchaseProduct";
import { actionCreators as CheckOutActions } from "../../redux/modules/CheckOut";
import { DownloadOutlined} from '@ant-design/icons';
import { Grid, Button, Text, Input2 } from "../../elements/index";
import { CircularProgress } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import {Pagination , PublicSideBar ,ArtistSideBar} from "../../components/index";
import {
  useTranslation,
  withTranslation,
  Trans,
  I18nContext,
} from "react-i18next";
import i18next from "i18next";


function PurchaseProduct() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const user = useSelector((state) => state.user);
  const PurchaseProduct = useSelector((state) => state.PurchaseProduct);

  const { t, i18n } = useTranslation();
  const [posts, setPosts] = useState([]); 
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setpostPerPage] = useState(14); 
  const [releasetime, setreleasetime] = useState();


  useEffect(() => {
    if (user) {
      dbService
        .collection("user_Public")
        .doc(user.uid)
        .collection("Purchase_Product")
        .get()
        .then((snapshot) => {
          const product_basket = snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
            // PurchaseAt: time(doc.data()?.PurchaseAt.toDate().toISOString()),
            Dday: Dday(doc.data()?.PurchaseAt.toDate().toISOString() ,  doc.id)
          }));
          dispatch(PurchaseProduct_Actions.set_Purchase_Product(
            product_basket,
            ));
          setIsLoading(false);
        });
    }
  }, [user, dispatch, history]);


  useEffect(() => {
    console.log(PurchaseProduct.PurchaseProdcut + "Ssss");

  }, [PurchaseProduct]);

 



  const indexOfLastPost = currentPage * postPerPage // 10번 포스트 2
  const indexOfFirstPost =  indexOfLastPost - postPerPage //0번포스트 1
  const currentPosts = PurchaseProduct.PurchaseProdcut.slice(indexOfFirstPost, indexOfLastPost) //0~10번포스트
  const paginate = pageNum => setCurrentPage(pageNum)




  const Dday = (data, id) => {

    
    var split1 = (data || "").split("T");
    var replace = split1[0].replace(/\-/g, " ");
    var replace2 = replace.replace(" ", ".");
    var date = replace2.replace(" ", ".");
    var Purchase= date;
    var PurchaseSplit = Purchase.split('.')
    var PurchaseDate =  new Date(`${PurchaseSplit[1]}/${PurchaseSplit[2]}/${PurchaseSplit[0]}`);


    var TimeNow = new Date();
    var difference= Math.abs(TimeNow - PurchaseDate);

    var Oneday = 1000*60*60*24;
    var days = difference/ Oneday 
    var qw= days.toFixed(0)

 
    return `${366-qw}`
  };


  const Ddaytodelete = (Dday , id) => {
    if(Dday === "0"){
        dbService
          .collection("user_Public")
          .doc(user.uid)
          .collection("Purchase_Product")
          .doc(id)
          .delete();
          return 0;
        } else {
          return Dday
        } 
  };


  const DownLoadPDF = async(sheet , songName) => {
    const response = await fetch(sheet);
    const blob = await response.blob();
    const filename = songName+ ".pdf";
    const newFile = new File([blob], filename, { type: blob.type });

    const link = document.createElement('a');
    link.setAttribute('href', URL.createObjectURL(newFile));
    link.setAttribute('download', newFile.name );
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const DownLoadMidi = async(midi , songName) => {
    const response = await fetch(midi);
    const blob = await response.blob();
    const filename = songName+ ".midi";
    const newFile = new File([blob], filename, { type: blob.type });

    const link = document.createElement('a');
    link.setAttribute('href', URL.createObjectURL(newFile));
    link.setAttribute('download', newFile.name );
    document.body.appendChild(link);
    link.click();
    link.remove();
  };



  return (
    <Grid>

      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "26rem 0",
          }}
        >
          <CircularProgress color="secondary" size="5rem" />
        </div>
      ) : (


        
    <Grid width="100%" overflow="auto">
    <Grid display ="flex" margin = "0 auto" width="1870px"  paddingT="20px"  paddingB="20px">
        {user.user?.role === 'Public' ? (
          <>
          <PublicSideBar />
    </>
        ) : (
          <>
              <ArtistSideBar/>
              </>
        )}
   

        <div class="PurchaseProduct_upload">
        <Grid paddingL="30px" paddingR="30px">
                    <Text
                      bold
                      textalign="left"
                      display="block"
                      size="21px"
                      marginT="24px"
                      color="#434343"
                    >
                      Purchase Product
                    </Text>
                    <Grid
                      width="100%"
                      bdrBottom="1px solid #EAEAEA"
                      marginT="16px"
                      display="flex"
                    ></Grid>
                  </Grid>
            <Grid marginT="3px" height="00px" width="90%" display="flex"  row>

                {
                  currentPosts.map((item) => (
                   
                    <div className = 'PurchaseProduct_product_card' style={{background: "#fff" }}>
                           <div className="Artist100_avater_height" >
                           <img src={item.data.Elbum_url}  alt="" className="PurchaseProduct_product_card_image" />
                           </div>
                            <div  className = 'PurchaseProduct_songName'>{item.data.songName}
                              <span>/ {item.data.artistName}</span>
                            </div>

                           <div class="PurchaseProduct_buttonGroup">
                              <div class="PurchaseProduct_button_Grid">
                                <span class="PurchaseProduct_button_text">PDF</span>
                              {/* <a href='' download onClick={() => DownLoadPDF(item.data.Sheet_url , item.data.songName)}> */}
                               <DownloadOutlined src="" className="PurchaseProduct_download_icon" onClick={() => DownLoadPDF(item.data.Sheet_url , item.data.songName)}/> 
                                {/* </a>    */}
                             
                              </div>
                              <div class="PurchaseProduct_button_Grid">
                              <span class="PurchaseProduct_button_text">MIDI</span>
                               <DownloadOutlined className="PurchaseProduct_download_icon"  onClick={() => DownLoadMidi(item.data.Midi_url, item.data.songName)}/>     
                              </div>                        
                           </div>

                           <div class="PurchaseProduct_Dday">
                           {/* {item.Dday} */}
                           D-day {Ddaytodelete(item.Dday, item.id)}
                           </div>
                    </div>
                
                )
                )}
            </Grid>
            <Pagination
                postPerPage={postPerPage}
                totalPosts={PurchaseProduct.PurchaseProdcut.length}
                paginate={paginate}
            />
         </div>      
      </Grid>
    </Grid>
      )}
    </Grid>
  
  );
}

export default PurchaseProduct;
