import React, { useRef, useMemo, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import {  Footer } from "../../components/index";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { Avatar } from "@material-ui/core";
import { Grid, Button, Text, Input2 } from "../../elements/index";
import { Pagination } from "../../components/index";
import "./Question.css"
import { dbService, storageService } from "../../shared/firebase";
import ReactPaginate from 'react-paginate';
import { actionCreators as QuestionActions } from "../../redux/modules/Question";
import { CircularProgress } from "@material-ui/core";

const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
// 글쓰기버튼 관리자만보이게

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  return isDesktop ? children : null;
};


const Question = (props) => {
  const histroy = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const Question = useSelector((state) => state.Question);
  const [itemOffset, setItemOffset] = useState(0);
  const [Question_PerPage, setQuestion_PerPage] = useState(10); 
  const [LookUp, setLookUp] = useState();
  const [SearchPosts, setSearchPosts] = useState("");
   
   useEffect(() => {
      dbService
        .collection("Question")
        .orderBy('Question_createdAt',  "desc")
        .get()
        .then((snapshot) => {
          const Questiondata = snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
            time: time(doc.data().Question_createdAt?.toDate().toISOString()),
          }));

          dispatch(QuestionActions.set_Question_Data(Questiondata))
        
          setIsLoading(false);
        });
    
  }, [dispatch, histroy]);

  const endOffset = itemOffset + Question_PerPage;
  const songSearchPosts =  (Question.QuestionData.slice(itemOffset, endOffset));
  const Song_PageCount = (Math.ceil(Question.QuestionData.length / Question_PerPage));

  useEffect(() => {
  
    setSearchPosts(Question.QuestionData.slice(itemOffset, endOffset))

}, [dispatch, histroy , Question , itemOffset, endOffset]);

const timeControl = (data) => {
  if(data){
  const str2 = data.substring(0, data.length - 1);
  var regexFor = /￦./gi;     // c 문자 전체
  var str3 = str2.replace(regexFor, '/');
    return str3;
  }
    // console.log(data.toLocaleDateString())
};



  const time = (data) => {
    var split1 = (data || "").split("T");
    var replace = split1[0].replace(/\-/g, " ");
    var replace2 = replace.replace(" ", ".");
    var date = replace2.replace(" ", ".");
    return date;
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * Question_PerPage) % items.length;
    setItemOffset(newOffset);
  };


  const gotoNoticeWrite = () => {
    dispatch(QuestionActions.remove_Question_SongData())
    histroy.push("/Home/QuestionWrite")
 

  };


  const gotoNoticeSearch = async (id) => {


    var db = dbService.collection("Question").doc(id);
    return dbService
      .runTransaction((transaction) => {
        // This code may get re-run multiple times if there are conflicts.
        return transaction.get(db).then((sfDoc) => {
          if (!sfDoc.exists) {
            throw "Document does not exist!";
          }
          var newLookUP = sfDoc.data().Question_LookUP + 1;
          setLookUp(newLookUP)
          transaction.update(db, { Question_LookUP: newLookUP });
        });
      })
      .then((    
      ) => {
        // dispatch(NoticeActions.set_Notice_LookUP(LookUp)) //바로바로 업데이트되게해야하는데
        histroy.push(`/Home/QuestionSearch/${id}`)
        // setrating_disabled(true)
      })
      .catch((error) => {});
  };



  return (
    <>

{isLoading ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "26rem 0",
        }}
      >
        <CircularProgress color="secondary" size="5rem" />
      </div>
 ) : (


 
      <Desktop>   
      <Grid position="relative"  width= "100%"   marginT= "64px" height="auto"  display= "flex">
        <Grid display= "flex" margin="auto" width="1200px" height="800px" >
         <Grid marginT="120px">
          <Grid row display="flex" justify="space-between"  padding="10px">     
            <Text h2 textAlign="left" width="300px" alignItem="center" marginT="10px">공지사항</Text>
             <Button main  noflex width="100px" height="30px" display="inline-block" marginT="15px"  _onClick={() => gotoNoticeWrite()}>글쓰기</Button>
            </Grid>
      
            <table className="notice-table">
            {/* <div className="Hardisest_song_chart"> */}

                 <colgroup>
                     <col width="5%" />
                     <col width="10%" />
                     <col width="50%" />
                     <col width="15%" />
                     <col width="10%" />
                     <col width="10%" />
                  </colgroup>
                  <thead className="Notice_table_thead">
                      <tr>
                        <th>번호</th>
                        <th>구분</th>
                        <th>제목</th>
                        <th>작성자</th>
                        <th>작성일시</th>
                        <th>조회수</th>
                     </tr>
                  </thead>


                     {SearchPosts && SearchPosts.map((post , index) => (
                      <tbody className="Notice_table_tbody" 
                      // onClick={() => {histroy.push(`/Home/NoticeSearch/${post.id}`)}}
                      onClick={() => gotoNoticeSearch(post.id)}
                      
                      >
                        <tr  className= "Notice_tr_table"  key={index + 1}>
                           <td>{index + 1}</td>
                           <td>{post.data.Question__Type}</td>
                           <td className="text-left">{post.data.Question_Title}</td>
                           <td>{post.data.Question_Writer}</td>
                           <td>{timeControl(post.data.Question_createdAt_date)}</td>
                           <td>{post.data.Question_LookUP}</td>
                           {/* <td>
                              {post.fileList.length > 0 &&
                                 <img src="/images/board_attach.gif" />
                              }
                           </td> */}
                        </tr>
                        </tbody>
                     ))}
               

                  {/* </div> */}
               </table>
               <div className ='notice_table_ReactPaginate'>
                  <ReactPaginate
                      breakLabel="..."
                      nextLabel=">"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={Song_PageCount}
                      previousLabel="<"
                      renderOnZeroPageCount={null}
                    />
                </div>

         </Grid>
       
        </Grid>
      </Grid>
      <Footer/>

    </Desktop>
    )}
    </>
  );
};


export default Question;
