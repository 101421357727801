import React, { useRef, useEffect, useState , useMemo} from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';



const Artist_Product_Editor = ({value, onChange }) => {
  const dispatch = useDispatch();
  const [currentLocation, setCurrentLocation] = useState("/");



  const modules = {
    toolbar: [
      //[{ 'font': [] }],
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],

      [{ 'align': [] }, { 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      ['clean']
    ],
  }

  const formats = [
    //'font',
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'align', 'color', 'background',        
  ]




  return (
        <div style={{height: "150px" , marginLeft:"20px", marginTop:"8px"}}>
                <ReactQuill 
                    style={{height: "100px"}} 
                    theme="snow" 
                    modules={modules} 
                    formats={formats} 
                    value={value || ''} 
                    onChange={(content, delta, source, editor) => onChange(editor.getHTML())} />
            </div>
  );
};



	
export default Artist_Product_Editor;
