import React, { useEffect, useState, useRef, useMemo } from "react";
import { authService, dbService } from "../../shared/firebase";
import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import "./ArtistJoin.css";
import Sidebar from "react-sidebar";
import { Avatar } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { Grid, Input, Button, Text } from "../../elements/index";
import styled from "styled-components";
import { Color } from "../../shared/DesignSys";
import Select from "react-select";
import { Footer, ModalContainer , ArtistModalContainer , PublicSideBar} from "../../components/index";
import ArtistJoin_piano from  "../../images/ArtistJoIn_piano.svg"
import wllpaper from "../../images/wall.png"
const ArtistJoin = ({ userObj }) => {
  const dispatch = useDispatch();
  const histroy = useHistory();
  const user = useSelector((state) => state.user);
  const [Modalopen, setModalOpen] = useState(false);
  const [Artistopen, setArtistOpen] = useState(false);

  const openModal = () => {setModalOpen(true)};
  const closeModal = () => {setModalOpen(false)};
  const closeArtistModal = () => {setArtistOpen(false)};
  const openArtistModal = () => {setArtistOpen(true)
  };

  useEffect(() => {
    var dd = console.log(user);
    // console.log(user.user?.displayName )

    return dd;
  }, [dispatch, user]);


  return (
    <Grid width="100%"  position="relative">
      <Grid display="flex" height="800px">
        <Grid margin="auto">
          {user.is_login === true && user !== null ? (
            <>
              {user.user?.role === "Public" ? (

                <div id="Artist_Join_background">
                  <Grid row display="flex" justify="center">
                  <Grid row display="flex" margin="auto"  width="800px"  height="600px"  justify="center" bg="#ffffff86" borderRadius="30px">
                  
                  <Grid column width="600px" justify="left"  display="flex"  padding="60px 0" marginR="auto"  paddingL="60px" >
                  <Text h1 color="#434343" >
                      <span className="PIANISSIMO_text">PIANISSIMO</span>
                      에서 <br />
                      곡을 판매해 수익을 올려보세요!
                    </Text>
                    <Text  marginT="30px"  color="#434343" bold>
                    1. 아티스트 계정을 만들고<br />
                       당신만의 페이지를 운영해보세요.
                     
                    </Text>
                    <Text  marginT="30px"  color="#434343" bold>
                    2. Midi, Pdf, Youtube로 <br />
                       당신의 곡을 전세계 사람들이 연주할수 있게 해주세요.
                    </Text>
                    <Text  marginT="30px"  color="#434343" bold>
                    3. 아티스트 본인이 직접 편곡 및 채보 한  <br />
                       파일만 판매할 수 있습니다.
                    </Text>
                    <Text  marginT="30px"  color="#434343" bold>
                    4. 한국저작권협회의 관리 저작물을  판매할 수 있으며, <br />
                       원저작자의 요청 등으로 저작권 보호조치가 시행될 수 있습니다.
                    </Text>
                  
                      <Button
                        
                        main
                        width="180px"
                        height="30px"
                        marginT="30px"
                        display="block"
                        _onClick={openArtistModal}
                      >
                        ARTIST JOIN
                      </Button>
                     
                    </Grid>


                    {/* <img class="ArtistJoin_piano_img" src={ArtistJoin_piano}/> */}
                  </Grid>
                 </Grid>
                </div>
              ) : (
                <div>
                  {/* {history.push} */}
                </div>
              )}
            </>
          ) : (
            <div id="Artist_Join_background">
            <Grid row display="flex" justify="center">

            <Grid row display="flex" margin="auto"  width="800px"  height="600px"  justify="center" bg="#ffffff86" borderRadius="30px">
                  
                  <Grid column width="450px" justify="center"  display="inline-block"  padding="80px 0"   >
                    <Text h1 color="#434343" >
                      <span className="PIANISSIMO_text">PIANISSIMO</span>
                      에서 <br />
                      곡을 판매해 수익을 올려보세요!
                    </Text>
                    <Text  marginT="30px"  color="#434343" bold>
                    1. 아티스트 계정을 만들고<br />
                       당신만의 페이지를 운영해보세요.
                     
                    </Text>
                    <Text  marginT="30px"  color="#434343" bold>
                    2. Midi, Pdf, Youtube로 <br />
                       당신의 곡을 전세계 사람들이 연주할수 있게 해주세요.
                    </Text>
                    <Text  marginT="30px"  color="#434343" bold>
                    3. 아티스트 본인이 직접 편곡 및 채보 한  <br />
                       파일만 판매할 수 있습니다.
                    </Text>
                    <Text  marginT="30px"  color="#434343" bold>
                    4. 한국저작권협회의 관리 저작물을  판매할 수 있으며, <br />
                       원저작자의 요청 등으로 저작권 보호조치가 시행될 수 있습니다.
                    </Text>
                  

                    <Button
                  noflex
                  main
                  width="180px"
                  height="50px"
                  marginT="30px"
                  paddingB="4px"
                  _onClick={openModal}
                >
                  LOGIN
                </Button>
                   
                  </Grid>
                  <img class="ArtistJoin_piano_img" src={ArtistJoin_piano}/>
                </Grid>


         
            </Grid>
            </div>
          )}

          <ModalContainer show={Modalopen} close={closeModal} />
          <ArtistModalContainer show={Artistopen} close={closeArtistModal} />
       
        </Grid>
      
      </Grid>
      <Footer/>
    </Grid>
  );
};

export default ArtistJoin;
