import { createAction, handleActions } from "redux-actions";
import axios from "axios";
import { setLocal, deleteLocal } from "../../shared/Local";


const SET_ARTISTUSER = "SET_ARTISTUSER";
const NO_USER = "NO_USER";
const LOGIN_CHECK = "LOGIN_CHECK";
const LOGIN_MSG = "LOGIN_MSG";

 const MAINPAGE_HOT_ARTIST_10 = "MAINPAGE_HOT_ARTIST_10";
 const MAINPAGE_HOT_SONG_10 = "MAINPAGE_HOT_SONG_10";


const loginCheck = createAction(LOGIN_CHECK, (token) => ({ token }));
const loginMsg = createAction(LOGIN_MSG, (msg) => ({ msg }));

const initialState = {
  artist: "",
  song: "",
};

// 회원 가입
const set_Artist_10 = (artist) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: MAINPAGE_HOT_ARTIST_10,
      artist: artist,
    });  
  };
};
const set_song_10 = (song) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: MAINPAGE_HOT_SONG_10,
      song: song,
    });  
  };
};


const Artist_userdb = (artistuser) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: SET_ARTISTUSER,
      artistuser: artistuser,
    });  
  };
};

const no_userdb = (user) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: NO_USER,
      // setUser(user)
    });  
  };
};



const MainPage = (state = initialState, action) => {
  switch (action.type) {
    case MAINPAGE_HOT_ARTIST_10:
      return {
        ...state,
        artist : action.artist,
      }
      case MAINPAGE_HOT_SONG_10:
        return {
          ...state,
          song : action.song,
        }

    default:
      return state
  }
};

export default MainPage;


const actionCreators = {
  loginCheck,
  loginMsg,
  no_userdb,
  Artist_userdb,
  set_Artist_10,
  set_song_10,
};

export { actionCreators };
