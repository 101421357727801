// Design System Page
// https://www.figma.com/file/IHXkOEs8BBUgnjmxXiqtPL/OKU-Design-System?node-id=0%3A1

import React from "react";

const Color = {
  // saturation
  Primary: "#ba4148",
  Secondary_1: "#dd7c80",
  Secondary_2: "#F7E5FF",
  // Tag: "#ce66ff",
  // greys `#dark
  Dark_1: "#434343",
  Dark_2: "#5D5D5D",
  Dark_3: "#787878",
  Dark_4: "#8F8F8F",
  Dark_5: "#303030",
  // greys #light
  Light_1: "#F8F8F8",
  Light_2: "#F2F2F2",
  Light_3: "#EAEAEA",
  Light_4: "#DADADA",
  // black white
  Holder: "#C0C0C0",

  White_1: "rgb(255, 255, 255)"
};

const Size = {
  Inbox: "14px",
};

export { Color };
