import React, { useRef, useMemo, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import { Editor } from "../../components/index";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { Avatar } from "@material-ui/core";
import { Grid, Button, Text, Input2 } from "../../elements/index";
import { Pagination } from "../../components/index";
import firebase from "firebase";
import Select from "@material-ui/core/Select";
import "./QuestionWrite.css"
import { dbService, storageService } from "../../shared/firebase";
import { actionCreators as QuestionActions } from "../../redux/modules/Question";


const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  return isDesktop ? children : null;
};


const QuestionWrite = (props) => {

  const [Edit_title , setEdit_title] = useState();
  const [Upload_Type , setUpload_Type] = useState();
  const dispatch = useDispatch();
  const histroy = useHistory();
   const [desc, setDesc] = useState('');
   const Question = useSelector((state) => state.Question);

   function onEditorChange(value) {
       setDesc(value)
   }
   const user = useSelector((state) => state.user);


  const onClickSearch = ()  => {

  // if (title.trim() == '') {
  //     alert('제목을 입력해주세요'); return;
  // }

  // if (desc.trim() == '') {
  //     alert('내용을 입력해주세요'); return;
  // }
  var date = new Date();


    const data = {
      Question_createdAt_date : date.toLocaleDateString('ko-KR'),
      Question_createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      Question_Desc:desc,
      Question_Title:Edit_title,
      Question_LookUP:0,
      Question_Writer: user?.user.displayName,
      Question_Song_SongName: `${Question.QuestionSong?.songName !== undefined ? (Question.QuestionSong?.songName): ("")}`,
      Question_Song_ArtistName:`${Question.QuestionSong?.artistName !== undefined ? (Question.QuestionSong?.artistName): ("")}`,
      Question_Song_uid: `${Question.QuestionSong.uid !== undefined ? (Question.QuestionSong.uid): ("")}`,
      Question_Song_Album: `${Question.QuestionSong.Elbum_url !== undefined ? (Question.QuestionSong.Elbum_url): ("")}`,
      Question__Type:`${Question.QuestionSong?.songName !== undefined ? ("곡문의"): ("일반문의")}`,
    };

    const QuestionCollection = dbService.collection("Question").doc()
    const HistoryPage = QuestionCollection.set(data);

    if(HistoryPage){
      histroy.push("/Home/Question")
      dispatch(QuestionActions.remove_Question_SongData())
    }

}



  return (
    <>
      <Desktop>   
      <Grid position="relative"  width= "100%"   marginT= "64px" height="auto"  display= "flex">
        <Grid display= "flex" margin="auto" width="1200px" height="800px" >
         <Grid marginT="60px">
          <Grid row display="flex" marginB="10px">     
            <Text h2 textAlign="left" width="120px" marginT="8px">문의하기</Text>
        

            </Grid>

           {Question.QuestionSong &&
             <Grid display="flex" bg="rgba(224, 224, 224, 0.226)" marginB="20px" >
            <img
                    src={Question.QuestionSong?.Elbum_url}
                    alt=""
                    className="Question_song__image"
                  />
                  <Grid display="flex" column width="300px" marginL="40px" textAlign="left" 
                  justify="center" >
                  <Text size="24px"  bold>{Question.QuestionSong?.songName} </Text>
                  <Text size="16px" >{Question.QuestionSong?.artistName}</Text>

                  </Grid>
              

            
             </Grid>
            }

            <Input2  value={Edit_title} type="text" plcholder="제목"  marginB="40px" marginT="10px" _onChange={(e) => {setEdit_title(e.target.value)}} />

      
            <Editor value={desc} onChange={onEditorChange} />
            <Button Edit  noflex width="100px" _onClick={onClickSearch}>저장</Button>
            <Button Edit  noflex width="100px" display="inline-block">목록으로</Button>

         </Grid>
        </Grid>
      </Grid>
    </Desktop>
    </>
  );
};



export default QuestionWrite;
