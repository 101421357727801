import React, { useRef, useMemo, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import { Footer } from "../../components/index";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { Avatar } from "@material-ui/core";
import { actionCreators as SearchResultActions } from "../../redux/modules/SearchResult";
import { Grid, Button, Text, Input2 } from "../../elements/index";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  return isDesktop ? children : null;
};

const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
  return isTablet ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

const Copyright = () => {
  const dispatch = useDispatch();
  const [currentLocation, setCurrentLocation] = useState("/");
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const [keyword, setKeyword] = useState("");
  const [mainct, setMainct] = useState("");
  const [subct, setSubct] = useState("");
  const user = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  const SearchProduct = () => {
    dispatch(SearchResultActions.setSearch_Keyword(keyword));
    history.push("/Search/Result");
    setKeyword("");
  };

  const SearchProduct2 = () => {};

  return (
    <>
      <Desktop>
        <div className="wrap">
          <div div className="menuBar">
            <ul className="tabs">
              <li
                onClick={() => {
                  history.push("/Support/Policy");
                }}
              >
                이용약관
              </li>
              <li
                onClick={() => {
                  history.push("/Support/Privacy");
                }}
              >
                개인정보보호정책
              </li>
              <li
                onClick={() => {
                  history.push("/Support/copyright");
                }}
              >
                Copyright
              </li>
            </ul>
          </div>

          <Grid width="1200px" bg="#fff" display="flex" column margin="auto">
            <Text
              h2
              color="#202020"
              size="32px"
              textAlign="left"
              padding="20px"
            >
              Copyright
            </Text>

            <Grid
              width="1200px"
              bdr="1px solid #4b4b4b59"
              borderRadius="20px"
              padding="20px"
              marginB="20px"
            >
              <Text
                color="#202020"
                bold
                size="18px"
                textAlign="left"
                flexdirection="row"
              >
                저작권 위반 - DMCA 통지
              </Text>
              <Grid
                bdrBottom="1px solid #4b4b4b59"
                margin="auto"
                marginT="10px"
                justify="center"
              ></Grid>

              <Text
                color="#363636"
                display="block"
                size="15px"
                marginT="15px"
                lineHeight="24px"
              >
                디지털 밀레니엄 저작권법(Digital Millennium Copyright Act, DMCA)
                은 미국 저작권법으로 자사 사이트에 콘텐츠가 있는 온라인 서비스
                제공자가 저작권 소유자 또는 그의 지정된 대리인으로부터 일단 위반
                주장을 통보받은 후 문제가 되는 콘텐츠를 즉시 삭제하는 경우 이에
                따라 저작권 침해 “피난처”로서의 책임이 면제됩니다. PIANISSIMO 가 게시한
                콘텐츠가 미국 저작권법에 따라 귀하의 권리를 침해하고 있다고
                판단되는 경우 다음과 같이 우편 또는 이메일로 PIANISSIMO 및 지정
                대리인에게 문제를 제기할 수 있습니다.
              </Text>
              <Grid
                // width="1200px"
                display="flex"
                bg="#dbdbdba1"
                height="250px"
                marginT="15px"
        
                column
              >
                <Text
                  lineHeight="21px"
                  display="block"
                  size="12px"
                  marginT="20px"
                  marginL="20px"
                >
            
                  우편: <br />
                  Copyright Agent (DMCA) <br />
                  {/* AMD Law Department */}
                  <br />
                  {/* 7171 Southwest Parkway */}
                  <br />
                  {/* M/S B100.4 */}
                  <br />
                  {/* Austin, TX, 78735 */}
                  <br />
                  SOUTH KOREA.
                  <br />
                  <br />
                  이메일:
                  <br />
                  {/* DMCA@amd.com */}
                  <br />
                </Text>
              </Grid>

              <Text
                color="#363636"
                display="block"
                size="15px"
                marginT="15px"
                lineHeight="24px"
              >
                저작권 소유자가 자신의 저작권물이 허가 없이 복제되었다고
                판단되는 경우, 다음과 같은 정보를 PIANISSIMO에 제공해야 합니다. DMCA
                위반 통지에는 다음 정보가 포함되어야 합니다.
                <br />
                저작권 소유자 또는 지정된 대리인은 다음을 수행해야 합니다.
                <br />
                <br />
                1. 전자 또는 실제 서명을 제공합니다.
                <br />
                2. 위반한 것으로 판단되는 저작권물에 대한 설명을 제공합니다.
                <br />
                3. 위반된 저작권물이 PIANISSIMO  웹사이트의 어디에 있는지 위치를
                확인합니다. 가급적이면 PIANISSIMO의 URL을 제공하되 어떤 경우에도 자료를 정확하게 식별하기에 충분한 정보를 제공합니다.
                <br />
                4. 연락 정보(이름, 주소, 전화번호, 이메일 주소)를 제공합니다.
                <br />
                5. 저작권 소유자, 그의 지정된 대리인 또는 법에 의해 사용이
                허가되지 않았다고 확신하는 문장을 제공합니다.
                <br />
                6. 위증 죄에 따라 근거의 정보가 정확하고 자신이 저작권 소유자
                또는 저작권 소유자를 대행하는 공인된 대리인임을 알리는 문장을
                제공합니다.
                <br />   <br />
                자료가 귀하의 저작권을 침해한다고 실질적으로 허위 진술하는 경우 손해(비용 및 변호사 수임료 포함)에 대해 책임을 질 수 있습니다. 따라서 온라인에서 제공되는 자료가 귀하의 저작권을 침해하는지 여부가 확실하지 않은 경우 먼저 변호사에게 문의하는 것이 좋습니다.
              </Text>
            </Grid>
          </Grid>

        </div>
        <Footer />
      </Desktop>
    </>
  );
};

export default Copyright;
