import { createAction, handleActions } from "redux-actions";
import axios from "axios";
import { setLocal, deleteLocal } from "../../shared/Local";

const VERY_HARD_PLAY_CHART = "VERY_HARD_PLAY_CHART";
const HARD_PLAY_CHART = "HARD_PLAY_CHART";
const NORMAL_PLAY_CHART = "NORMAL_PLAY_CHART";
const ARTIST_PRODUCT_ALL = "ARTIST_PRODUCT_ALL";
const ARTIST_PRODUCT_ARTIST_SUBSCRIBE = "ARTIST_PRODUCT_ARTIST_SUBSCRIBE";
const ARTIST_PRODUCT_RATING_VALUE = "ARTIST_PRODUCT_RATING_VALUE";
const ARTIST_PRODUCT_FAVORITE_VALUE = "AARTIST_PRODUCT_FAVORITE_VALUE";

const ARTIST_PRODUCT_SUBSCRIBE_VALUE = "ARTIST_PRODUCT_SUBSCRIBE_VALUE";
const ARTIST_PRODUCT_DISCRIPTION = "ARTIST_PRODUCT_DISCRIPTION";
const ARTIST_PRODUCT_MIDI_URL = "ARTIST_PRODUCT_MIDI_URL";
const ARTIST_PRODUCT_SHEET_URL = "ARTIST_PRODUCT_SHEET_URL";
const ARTIST_PRODUCT_YOUTUBE_URL = "ARTIST_PRODUCT_YOUTUBE_URL";
const ARTIST_PRODUCT_ALBUM_URL = "ARTIST_PRODUCT_ALBUM_URL";

const initialState = {
  data: "",
  id: "",
  edit: "",
  ratingvalue: "",
  update: "",
  faviorite: "",
  createdAt: "",
  youtube_EmbedID: "",
  artistuser_subscribe: "",
  artistuser_data: "",
  Discription: "",
  Midi_url: "",
  Sheet_url: "",
  Elbum_url: "",
};


const set_Artist_Product_data = (
  data,
  id,
  edit,
  ratingvalue,
  update,
  faviorite,
  createdAt,
  youtube_EmbedID
) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: ARTIST_PRODUCT_ALL,
      data: data,
      id: id,
      edit: edit,
      ratingvalue: ratingvalue,
      update: update,
      faviorite: faviorite,
      createdAt: createdAt,
      youtube_EmbedID: youtube_EmbedID,
      Discription: data.Discription,
      Midi_url: data.Midi_url,
      Sheet_url: data.Sheet_url,
      Elbum_url: data.Elbum_url,
    });
  };
};


const set_Artist_Product_artist_data = (
  artistuser_subscribe,
  artistuser_data
) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: ARTIST_PRODUCT_ARTIST_SUBSCRIBE,
      artistuser_subscribe: artistuser_subscribe,
      artistuser_data: artistuser_data,
    });
  };
};

const set_New_RatingValue = (ratingvalue) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: ARTIST_PRODUCT_RATING_VALUE,
      ratingvalue: ratingvalue,
    });
  };
};

const set_New_Favorite = (Favorite) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: ARTIST_PRODUCT_FAVORITE_VALUE,
      Faviorite: Favorite,
    });
  };
};


const set_New_artist_subscribe_Value = (subscribe) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: ARTIST_PRODUCT_SUBSCRIBE_VALUE,
      subscribe: subscribe,
    });
  };
};
const set_EDIT_DISCRIPTION = (discription) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: ARTIST_PRODUCT_DISCRIPTION,
      discription: discription,
    });
  };
};

const set_Midi_url = (Midi_url) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: ARTIST_PRODUCT_MIDI_URL,
      Midi_url: Midi_url,
    });
  };
};

const set_Sheet_url = (Sheet_url) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: ARTIST_PRODUCT_SHEET_URL,
      Sheet_url: Sheet_url,
    });
  };
};

const set_youtube_url = (youtube_EmbedID) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: ARTIST_PRODUCT_YOUTUBE_URL,
      youtube_EmbedID: youtube_EmbedID,
    });
  };
};

const set_Elbum_url = (Elbum_url) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: ARTIST_PRODUCT_ALBUM_URL,
      Elbum_url: Elbum_url,
    });
  };
};
const Artist_Product = (state = initialState, action) => {
  switch (action.type) {
    case ARTIST_PRODUCT_ALL:
      return {
        ...state,
        data: action.data,
        id: action.id,
        edit: action.edit,
        ratingvalue: action.ratingvalue,
        update: action.update,
        faviorite: action.faviorite,
        createdAt: action.createdAt,
        youtube_EmbedID: action.youtube_EmbedID,
        Discription: action.Discription,
        Midi_url: action.Midi_url,
        Sheet_url: action.Sheet_url,
        youtubeUrl: action.youtubeUrl,
        Elbum_url: action.Elbum_url

      };
    case ARTIST_PRODUCT_ARTIST_SUBSCRIBE:
      return {
        ...state,
        artistuser_subscribe: action.artistuser_subscribe,
        artistuser_data: action.artistuser_data,
      };

    case ARTIST_PRODUCT_RATING_VALUE:
      return {
        ...state,
        ratingvalue: action.ratingvalue,
      };

    case ARTIST_PRODUCT_SUBSCRIBE_VALUE:
      return {
        ...state,
        artistuser_subscribe: action.subscribe,
      };

    case ARTIST_PRODUCT_DISCRIPTION:
      return {
        ...state,
        Discription: action.discription,
      };
    case ARTIST_PRODUCT_MIDI_URL:
      return {
        ...state,
        Midi_url: action.Midi_url,
      };
    case ARTIST_PRODUCT_SHEET_URL:
      return {
        ...state,
        Sheet_url: action.Sheet_url,
      };

    case ARTIST_PRODUCT_YOUTUBE_URL:
      return {
        ...state,
        youtube_EmbedID: action.youtube_EmbedID,
      };

      case ARTIST_PRODUCT_ALBUM_URL:
        return {
          ...state,
          Elbum_url: action.Elbum_url,
        };

        case ARTIST_PRODUCT_FAVORITE_VALUE:      
        return {
          ...state,
          faviorite: action.Faviorite,
        };


    default:
      return state;
  }
};

export default Artist_Product;

const actionCreators = {
  set_Artist_Product_data,
  set_Artist_Product_artist_data,
  set_New_RatingValue,
  set_New_artist_subscribe_Value,
  set_EDIT_DISCRIPTION,
  set_Midi_url,
  set_Sheet_url,
  set_youtube_url,
  set_Elbum_url,
  set_New_Favorite,
};

export { actionCreators };
