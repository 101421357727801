
export const Change_Language_currncy = (i18n, item) => {
  if (i18n.language === "kor") {
    return item.data.KOR;
  } else if (i18n.language === "en") {
    return item.data.USD;
  } else if (i18n.language === "ja") {
    return item.data.JPY;
  } else{
    return item.data.USD;
  }
};