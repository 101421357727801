import React, { useEffect, useState } from "react";
import { useHistory, useParams , Redirect  } from "react-router-dom";
import { dbService } from "../../shared/firebase";
import { useDispatch, useSelector } from "react-redux";
import { Avatar } from "@material-ui/core";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Button, Text, Input2 } from "../../elements/index";
import "./BoardManagement.css";
import { CircularProgress } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { actionCreators as BoardManagementactions } from "../../redux/modules/BoardManagement";
import AddIcon from "@material-ui/icons/Add";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { Link, withRouter } from "react-router-dom";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import { ArtistSideBar  } from "../../components/index";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: -10,
    position: "absolute",
    marginRight: 10,
  },
  favoirte_button: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    margin: 0,
  },
}));

const StyledRating = withStyles({
  iconFilled: {
    color: "#ff6d75",
  },
  iconHover: {
    color: "#ff3d47",
  },
})(Rating);

function BoardManagement() {
  const BoardManagement = useSelector((state) => state.BoardManagement);

  const history = useHistory();
  const { Artist } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const user = useSelector((state) => state.user);

  const classes = useStyles();

  useEffect(() => {
    dbService
      .collection("Song_All")
      .where("uid", "==", user?.uid)
      .orderBy("createdAt", "desc")
      .get()
      .then((snapshot) => {
        const update_song = snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
          edit: songEdit(doc.data()?.songEdit),
          time: time(doc.data()?.createdAt.toDate().toISOString()),
        }));
        dispatch(BoardManagementactions.set_BOARD_MANAGEMENT_Song(update_song));
     
      });
  }, [history, dispatch, user]);

  useEffect(() => {
    console.log(BoardManagement, "data");
    // console.log(artist_main_song_update, "data");
  }, [BoardManagement]);

  const time = (data) => {
    var split1 = (data || "").split("T");
    var replace = split1[0].replace(/\-/g, " ");
    var replace2 = replace.replace(" ", ".");
    var date = replace2.replace(" ", ".");
    return date;
  };

  const songEdit = (songEdit) => {
    if (songEdit === undefined) {
      return "원곡";
    } else if (songEdit.NoEdit === true) {
      return "원곡";
    } else if (songEdit.SomeEdit === true) {
      return "편곡";
    } else if (songEdit.Edit === true) {
      return "작곡";
    }
  };

  const Language_song_Edit = (song) => {
    if (song.edit === "원곡") {
      return `${t("Chart.Original_song")}`;
    } else if (song.edit === "편곡") {
      return `${t("Chart.Arrangement_song")}`;
    } else if (song.edit === "작곡") {
      return `${t("Chart.Composition_song")}`;
    }
  };



  const language_Currency_Amount = (song) => {
    if (i18n.language === "kor") {
      return song.data.KOR;
    } else if (i18n.language === "en") {
      return song.data.USD;
    } else if (i18n.language === "ja") {
      return song.data.JPY;
    }
  };

  const removeSong = async (song) => {
    alert("정말삭제하시겠습니까?")
    dispatch(BoardManagementactions.remove_Song(song));
    dbService
      .collection("Song_All")
      .doc(song.id)
      .delete();

  };

  const checkArtistid = async (song) => {
    if(user?.uid === song.data.uid){
      history.push(`/Artist_productEDIT/${user?.uid}/${song.id}`)
    } else
    {
      history.push(`/Artist_product/${song?.id}`);

    }
  };
  

  return (
    <Grid width="100%">
      <>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "26rem 0",
            }}
          >
            <CircularProgress color="secondary" size="5rem" />
          </div>
        ) : (
          <>
            <Grid
              display="flex"
              margin="0 auto"
              width="100%"
              paddingT="20px"
              paddingB="20px"
              height="100%"
            >
             <ArtistSideBar />
              <div class="BoardManagement_main_card">
                <div className="BoardManagement_main_song">
                {/* <div className="BoardManagement_main_Circle">
                      게시판관리
                  </div> */}
                  
                  
                  <table className="BoardManagement_table">
                    {/* <div className="Artist_song_chart"> */}
                    <colgroup>
                      <col width="5%" />
                      <col width="35%" />
                      <col width="15%" />
                      <col width="10%" />
                      {/* 70 */}
                      {/* <col width="6%" /> */}
                      <col width="12%" />
                      <col width="6%" />
                      <col width="13%" />
                    </colgroup>

                    <thead className="BoardManagement_table_thead">
                      <tr className="BoardManagement_table_thead_tr">
                        <th className="BoardManagement_tableHead_index">
                          {t("Chart.Ranking")}
                        </th>
                        <th className="BoardManagement_tableHead_songname">
                          {t("Chart.Song_name")} / {t("Chart.Artist_name")}
                        </th>
                        <th className="BoardManagement_tableHead_genres_edit">
                          {t("Chart.Genre")} / {t("Chart.Composition")}
                        </th>
                        <th className="BoardManagement_tableHead_price">
                          {t("Chart.Price")}
                        </th>
                        <th className="BoardManagement_tableHead_download">
                          수정하기
                        </th>
                        
                        <th className="BoardManagement_tableHead_damgi">
                          삭제
                        </th>
                        <th className="BoardManagement_tableHead_rating">
                          제작일자
                        </th>
                      </tr>
                    </thead>
                    {BoardManagement.Song?.map((song, index) =>
                      index < 11 ? (
                        <tbody className="BoardManagement_tbody_table">
                          <tr className="BoardManagement_tbody_tr">
                            <td className="BoardManagement_tbody_index">
                              {index + 1}
                            </td>

                            <td
                              className="BoardManagement_tbody_artist"
                              onClick={() =>
                                checkArtistid(song)
                              }
                            >
                              {/* <Link to={`/artist_product/${song.id}`}> */}
                              <img
                                src={song.data.Elbum_url}
                                alt=""
                                className="BoardManagement_tbody_image"
                              />

                              <span className="BoardManagement_songName">
                                {song.data.songName}
                                <span className="BoardManagement_artistname">
                                  - {song.data.artistName}
                                </span>
                              </span>
                            </td>

                            <td className="BoardManagement_tbody_genres_edit">
                              {song.data.genres} / {Language_song_Edit(song)}
                            </td>
                            <td className="BoardManagement_tbody_price">
                              {t("Payment.Currency")}{" "}
                              {language_Currency_Amount(song)}
                            </td>

                            {/* <td className="BoardManagement_tbody_upgrade">
                              {song.data.update}
                              <div className="BoardManagement_tbody_download_Icon">
                                <ArrowDownwardIcon className="BoardManagement__Icon" />
                              </div>
                            </td>

                            <td className="BoardManagement_tbody_favorite">
                              {song.data.faviorite}
                              <div className="BoardManagement_tbody_favorite_Icon">
                                <FavoriteBorderIcon className="BoardManagement__Icon" />
                              </div>
                            </td> */}
                            <td className="BoardManagement_tbody_favorite">
                            <Button display="block"   _onClick={() => checkArtistid(song)}                          
                               margin="auto"  token size="15px"  backgroundcolor="#9e9e9e" width="140px" height="40px" color="#fff" justify="center">수정하기</Button>

                              
                            </td>
                            <td className="BoardManagement_tbody_damgi">
                              {/* {song.data.faviorite} */}
                              {/* <div className="BoardManagement_tbody_Look_Icon">
                                <FavoriteBorderIcon className="BoardManagement__Icon" />
                              </div> */}
                              <IconButton
                            className="BoardManagement_tbody_Look_Icon"
                            onClick={() => removeSong(song)}
                          >
                            <CloseIcon className="close__Icon" />
                          </IconButton>
                            </td>

                            <td className="BoardManagement_tbody_date">
                              {song.time}
                            </td>
                          </tr>
                        </tbody>
                      ) : null
                    )}
                    {/* </div> */}
                  </table>
                </div>
              </div>
            </Grid>
          </>
        )}
      </>
    </Grid>
  );
}

export default BoardManagement;
