import React, { useEffect, useState } from "react";
import { Player } from "./Player";
import { Render } from "./Rendering/Render";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { UI } from "./ui/UI.js";
import { InputListeners } from "./InputListeners.js";
import { getLoader } from "./ui/Loader.js";
import { useHistory } from "react-router-dom";
import ReactDOM from "react-dom";

var ui, player, loading, listeners, render, controlui;

function Midi_main({ id, data,  name, player}) {
  const [loading, setloading] = useState(false);
  const [midi, setmidi] = useState(data);
  const [songName, setsongName] = useState(name);
  const [Name, setName] = useState(name);


  const history = useHistory();

  useEffect(() => {
    ss();
    console.log(midi)
  }, []);

  async function ss() {
    await init();
  }

  async function init() {
    if (player) {
      render = new Render(player, Name);
      
      // player.startPlay()
      ui = new UI(player, render);
      listeners = new InputListeners(player, ui, render);

      renderLoop();
      loadStartingSong();
      setloading(true);
    }

    // console.log(Name)
  }

  var render;

  function renderLoop() {
    let playerState = player.getState();
    // console.log(playerState.bpm)
    render.render(playerState);
    windowrender();
  }

  function windowrender() {
    return window.requestAnimationFrame(renderLoop);
  }

  async function loadStartingSong() {
    loadSongFromURL(midi, songName); // Local: "../mz_331_3.mid")
  }

  async function loadSongFromURL(url, title) {
    getLoader().setLoadMessage("Loading Song");
    let response = fetch(url, {
      method: "GET",
    }).then((response) => {
      let filename = title || url;
      response.blob().then((blob) => {
        let reader = new FileReader();
        reader.onload = function (theFile) {
          player.loadSong(reader.result, filename, () => {});
        };
        reader.readAsDataURL(blob);
      });
    });
  }

  return <div></div>;
}

export default Midi_main;
