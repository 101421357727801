import React, { useRef, useMemo, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import { Footer } from "../../components/index";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { Avatar } from "@material-ui/core";
import { Grid, Button, Text, Input2 } from "../../elements/index";
import Privacy from "./Privacy";
import Copyright from "./copyright";
import "./Policy.css";


const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  return isDesktop ? children : null;
};

const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
  return isTablet ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

const Policy = (props) => {
  const dispatch = useDispatch();
  const [currentLocation, setCurrentLocation] = useState("/");
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const { showHeader } = props;
  const [keyword, setKeyword] = useState("");
  const [mainct, setMainct] = useState("");
  const [subct, setSubct] = useState("");
  const user = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const [obj, setobj] = useState({
    0: <Privacy />,
    1: <Policy />,
    2: <Copyright />,
  });

  const [activeId, setactiveId] = useState();

  const clickHandler = (id) => {
    setactiveId({ activeId: id });
  };

  const SearchProduct2 = () => {};

  return (
    <>
      <Desktop>
        <div className="wrap">
          <div div className="menuBar">
            <ul className="tabs">
              <li
                onClick={() => {
                  history.push("/Support/Policy");
                }}
              >
                이용약관
              </li>
              <li
                onClick={() => {
                  history.push("/Support/Privacy");
                }}
              >
                개인정보보호정책
              </li>
              <li
                onClick={() => {
                  history.push("/Support/copyright");
                }}
              >
                Copyright
              </li>
            </ul>
          </div>

          <Grid width="1200px" bg="#fff" display="flex" column margin="auto">
            <Text h2   color="#202020" size="32px" textAlign="left" padding="20px">
              이용약관
            </Text>

            <Grid
              width="1200px"
              bdr="1px solid #4b4b4b59"
              borderRadius="20px"
              padding="20px"
            >
              <Text
                color="#202020"
                bold
                size="18px"
                textAlign="left"
                flexdirection="row"
              >
                제 1조 (목적)
              </Text>
              <Grid
                bdrBottom="1px solid #4b4b4b59"
                margin="auto"
                marginT="10px"
                justify="center"
              ></Grid>

              <Text
                color="#363636"
                display="block"
                size="15px"
                marginT="15px"
                lineHeight="24px"
              >
                이 약관은 전기통신 사업법 및 동 법 시행령에 의하여 PIANISSIMO
                (이하 "회사" 라 합니다.)가 제공하는 인터넷 홈페이지
                서비스 (이하 "서비스" 라 합니다.)의 이용조건 및 절차에 관한
                사항, 회사와 이용자의 권리와 의무 및 책임사항을 규정함을
                목적으로 합니다.
              </Text>
            </Grid>

            <Grid
              width="1200px"
              bdr="1px solid #4b4b4b59"
              borderRadius="20px"
              padding="20px"
              marginT="15px"
            >
              <Text
                color="#202020"
                bold
                size="18px"
                textAlign="left"
                flexdirection="row"
              >
                제 2조 (약관의 효력과 개정)
              </Text>
              <Grid
                bdrBottom="1px solid #4b4b4b59"
                margin="auto"
                marginT="10px"
                justify="center"
              ></Grid>

              <Text
                color="#363636"
                display="block"
                size="15px"
                marginT="15px"
                lineHeight="24px"
              >
                1. 이 약관은 전기통신사업법 제 31 조, 동 법 시행규칙 제 21조의
                2에 따라 공시절차를 거친 후 홈페이지를 통하여 이를 공지하거나
                전자우편 기타의 방법으로 이용자에게 통지함으로써 효력을
                발생합니다.
                <br />
                2. 회사는 본 약관을 사전고지 없이 개정할 수 있으며, 개정된
                약관은 제9조에 정한 방법으로 공지합니다.
                <br />
                3. 회사가 개정약관을 공지하면서 회원에게 거부의사를 표시하지
                아니할 경우 약관의 변경에 동의한 것으로 본다는 뜻을 명확하게
                공지 또는 통지하였음에도 회원이 명시적으로 개정약관에 거부의사를
                표시하지 않으면 회사는 회원이 개정약관에 동의한 것으로 봅니다.
                <br />
                4. 회원은 개정된 약관에 동의하지 아니하는 경우 본인의 회원등록을
                취소(회원탈퇴)할 수 있으며, 개정된 약관은 공지와 동시에 그
                효력이 발생됩니다. <br />
              </Text>
            </Grid>

            <Grid
              width="1200px"
              bdr="1px solid #4b4b4b59"
              borderRadius="20px"
              padding="20px"
              marginT="15px"
            >
              <Text
                color="#202020"
                bold
                size="18px"
                textAlign="left"
                flexdirection="row"
              >
                {" "}
                제 3조 (약관이외의 준칙)
              </Text>
              <Grid
                bdrBottom="1px solid #4b4b4b59"
                margin="auto"
                marginT="10px"
                justify="center"
              ></Grid>

              <Text
                color="#363636"
                display="block"
                size="15px"
                marginT="15px"
                lineHeight="24px"
              >
                이 약관에 명시되어 있지 않은 사항은 전기통신 기본법, 전기통신
                사업법, 기타 관련법령의 규정 및 회사가 정한 내부 규정에
                따릅니다.
              </Text>
            </Grid>

            <Grid
              width="1200px"
              bdr="1px solid #4b4b4b59"
              borderRadius="20px"
              padding="20px"
              marginT="15px"
            >
              <Text
                color="#202020"
                bold
                size="18px"
                textAlign="left"
                flexdirection="row"
              >
                {" "}
                제 4조 (용어의 정의)
              </Text>
              <Grid
                bdrBottom="1px solid #4b4b4b59"
                margin="auto"
                marginT="10px"
                justify="center"
              ></Grid>

              <Text
                color="#363636"
                display="block"
                size="15px"
                marginT="15px"
                lineHeight="24px"
              >
                이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
              </Text>
              <Grid
                // width="1200px"
                display="flex"
                bg="#dbdbdba1"
                height="250px"   
                 marginT="15px"
                column
              >
                <Text
                  lineHeight="21px"
                  display="block"
                  size="12px"
                  marginT="10px"
                  marginL="20px"
                >
                  0. 서비스: PC, TV, 휴대형단말기 등의 각종 유무선 장치를 포함한
                  단말기를 통하여 회사가 제공하는 컨텐츠 및 관련 서비스를
                  의미합니다.
                  <br />
                  1. 회원 : 서비스에 개인정보를 제공하여 회원등록을 한 자로서,
                  서비스의 정보를 지속적으로 제공받으며 이용할 수 있는 자를
                  말합니다.
                  <br />
                  2. 이용자 : 본 약관에 따라 회사가 제공하는 서비스를 받는 회원
                  및 비회원을 말합니다.
                  <br />
                  3. 아이디 (ID) : 회원 식별과 회원의 서비스 이용을 위하여
                  회원이 선정하고 회사가 승인하는 문자와 숫자의 조합을 말합니다.
                  <br />
                  4. 비밀번호 : 회원이 통신상의 자신의 비밀을 보호하기 위해
                  선정한 문자와 숫자의 조합을 말합니다.
                  <br />
                  5. 전자우편 (E-mail) : 인터넷을 통한 우편입니다.
                  <br />
                  6. 해지 : 회사 또는 회원이 서비스 이용 이후 그 이용계약을 종료
                  시키는 의사표시를 말합니다.
                  <br />
                  7. 홈페이지 : 회사가 이용자에게 서비스를 제공하기 위하여
                  컴퓨터 등 정보통신설비를 이용하여 이용자가 열람 및 이용할 수
                  있도록 설정한 가상의 서비스 공간을 말합니다.
                  <br />
                  8. 콘텐츠: 디지털콘텐츠 및 관련 서비스를 의미하며 이는 회사가
                  제공하는 정보통신망에서 사용됩니다.
                  <br />
                  9. 포인트: 서비스 내 컨텐츠를 구입/이용하기 위한 인터넷 선불
                  전자화폐의 일종으로써 서비스 내 결제 수단 및 결제 금액에 따라
                  제공되는 전자화폐를 말합니다.
                  <br />
                  10. 게시물: 회원이 서비스를 이용함에 있어 서비스상에 게시 또는
                  등록하는 부호(URL포함), 동영상, 문자, 음성, 음향 등 정보형태의
                  글, 사진, 동영상 및 각종 파일과 링크 등을 말합니다. <br />
                </Text>
              </Grid>
            </Grid>

            <Grid
              width="1200px"
              bdr="1px solid #4b4b4b59"
              borderRadius="20px"
              padding="20px"
              marginT="15px"
            >
              <Text
                color="#202020"
                bold
                size="18px"
                textAlign="left"
                flexdirection="row"
              >
                {" "}
                제 5조 (서비스의 제공 및 변경)
              </Text>
              <Grid
                bdrBottom="1px solid #4b4b4b59"
                margin="auto"
                marginT="10px"
                justify="center"
              ></Grid>

              <Text
                color="#363636"
                display="block"
                size="15px"
                marginT="15px"
                lineHeight="24px"
              >
                1. 회사가 제공하는 서비스는 다음과 같습니다.
              </Text>
              <Grid
                display="flex"
                bg="#dbdbdba1"
                height="130px"
                column
              >
                <Text
                  lineHeight="21px"
                  display="block"
                  size="12px"
                  marginT="15px"
                  marginL="20px"
                >
                  1) 회사에 대한 홍보 내용
                  <br />
                  2) 회사가 판매하는 콘텐츠 안내
                  <br />
                  3) 기타 회사가 제공하는 각종 정보
                  <br />
                  4) 고객 상담 서비스
                  <br />
                  5) 회원 이용 서비스
                  <br />
                </Text>
              </Grid>

              <Text display="block" size="15px" marginT="10px">
                2. 회사는 필요한 경우 서비스의 내용을 추가 또는 변경하여 제공할
                수 있습니다.
              </Text>
              <Text display="block" size="15px" marginT="10px">
                3. 서비스의 이용은 연중무휴(1일 24시간)을 원칙으로 합니다. 다만
                업무상의 장애 및 서비스 운영정책 또는 기술상 서비스가 중지될 수
                있으며 회사는 이를 사전 또는 사후에 공지합니다.
              </Text>
            </Grid>

            <Grid
              width="1200px"
              bdr="1px solid #4b4b4b59"
              borderRadius="20px"
              padding="20px"
              marginT="15px"
            >
              <Text
                color="#202020"
                bold
                size="18px"
                textAlign="left"
                flexdirection="row"
              >
                제 6조 (서비스의 중단)
              </Text>
              <Grid
                bdrBottom="1px solid #4b4b4b59"
                margin="auto"
                marginT="10px"
                justify="center"
              ></Grid>

              <Text
                color="#363636"
                display="block"
                size="15px"
                marginT="15px"
                lineHeight="24px"
              >
                1. 회사는 컴퓨터 등 정보통신설비의 보수점검/교체 및 고장, 통신의
                두절 등의 기술상의 장애 또는 업무상의 사유가 발생한 경우에는
                서비스의 제공을 일시적으로 중단할 수 있습니다.
                <br />
                2. 제 1항에 의한 서비스 중단의 경우에는 제 9조에 정한 방법으로
                이용자에게 통지합니다. 통지한 경우에도 회사의 안내에 따르지 않아
                생기는 불이익에 대해 회사는 책임지지 않습니다.
                <br />
                3. 회사는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로
                인하여 이용자 또는 제3자가 입은 손해에 대하여 배상하지
                아니합니다. 단, 회사에 고의 또는 중과실이 있는 경우에는 그러하지
                아니합니다.
                <br />
              </Text>
            </Grid>

            <Grid
              width="1200px"
              bdr="1px solid #4b4b4b59"
              borderRadius="20px"
              padding="20px"
              marginT="20px"
            >
              <Text
                color="#202020"
                bold
                size="18px"
                textAlign="left"
                flexdirection="row"
              >
                제 7조 (회원가입)
              </Text>
              <Grid
                bdrBottom="1px solid #4b4b4b59"
                margin="auto"
                marginT="10px"
                justify="center"
              ></Grid>

              <Text
                color="#363636"
                display="block"
                size="15px"
                marginT="15px"
                lineHeight="24px"
              >
                1. 이용자는 회사가 정한 가입양식에 따라 회원정보를 기입한 후 이
                약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.
                <br />
                2. 이용자는 실명, 또는 닉네임으로 회원가입을 하여야 하며, 1개의
                이름에 대해 1건의 회원가입 신청을 할 수 있습니다.
                <br />
                3. 회사는 제 1항과 같이 회원으로 가입할 것을 신청한 이용자 중
                다음 각 호에 해당하지 않는 한 회원으로 등록합니다.
              </Text>

              <Grid
                display="flex"
                bg="#dbdbdba1"
                height="120px"
                column
              >
                <Text
         
                  display="block"
                  size="12px"
                  marginT="15px"
                  marginL="20px"
                  lineHeight="21px"
                >
                  1) 등록 내용에 허위, 기재누락, 오기가 있는 경우
                  <br />
                  2) 타인의 명의를 사용하여 신청한 경우
                  <br />
                  3) 가입신청자가 이 약관 제 8조 3항에 의하여 이전에 회원자격을
                  상실한 적이 있는 경우(단, 제 8조 3항에 의한 회원자격 상실 후
                  3년이 경과한 자로서 회사의 회원 재가입 승낙을 얻은 경우는
                  예외로 합니다.)
                  <br />
                  4) 기타 회원으로 회사 소정의 이용신청요건을 충족하지 못하는
                  경우
                  <br />
                </Text>
              </Grid>
            </Grid>

            <Grid
              width="1200px"
              bdr="1px solid #4b4b4b59"
              borderRadius="20px"
              padding="20px"
              marginT="20px"
            >
              <Text
                color="#202020"
                bold
                size="18px"
                textAlign="left"
                flexdirection="row"
              >
                제 8조 (회원탈퇴 및 자격 상실/ 이용제한 등)
              </Text>
              <Grid
                bdrBottom="1px solid #4b4b4b59"
                margin="auto"
                marginT="10px"
                justify="center"
              ></Grid>

              <Text
                color="#363636"
                display="block"
                size="15px"
                marginT="15px"
                lineHeight="24px"
              >
                1. 회원은 언제든지 회원의 탈퇴를 홈페이지에 요청할 수 있으며,
                홈페이지는 즉시 이에 응합니다.
                <br />
                2. 회원이 다음 각 호의 사유에 해당하는 경우, 회사는 회원자격을
                제한 및 정지시킬 수 있습니다. 또한 해당 회원은 관계법령에 따라
                처벌을 받을 수 있습니다.
              </Text>

              <Grid
   
                display="flex"
                bg="#dbdbdba1"
                height="120px"
                column
              >
                <Text
 
                  display="block"
                  size="12px"
                  marginT="15px"
                  marginL="20px"
                  lineHeight="21px"
                >
                  1) 등록 내용에 허위, 기재누락, 오기가 있는 경우
                  <br />
                  2) 타인의 명의를 사용하여 신청한 경우
                  <br />
                  3) 가입신청자가 이 약관 제 8조 3항에 의하여 이전에 회원자격을
                  상실한 적이 있는 경우(단, 제 8조 3항에 의한 회원자격 상실 후
                  3년이 경과한 자로서 회사의 회원 재가입 승낙을 얻은 경우는
                  예외로 합니다.)
                  <br />
                  4) 기타 회원으로 회사 소정의 이용신청요건을 충족하지 못하는
                  경우
                  <br />
                </Text>
              </Grid>
              <Text
                color="#363636"
                display="block"
                size="15px"
                marginT="15px"
                lineHeight="24px"
              >
                3. 회사는 이용자 또는 회원이 이 약관의 의무를 위반하거나
                서비스의 정상적인 운영을 방해한 경우, 제 13조 규정에 위반하는
                경우 서비스 이용을 즉시 제한할 수 있습니다.
                <br />
                4. 회사가 회원자격을 상실 시키는 경우 회원에게 이를 통지하고
                탈퇴를 처리합니다. 이 경우 회원에게 이를 통지하고, 탈퇴 전에
                소명할 기회를 부여합니다.
                <br />
                5. 회사는 회원이 12개월 이상 로그인하지 않는 경우, 운영의 효율성
                및 회원정보의 보호를 위해 이용을 제한할 수 있습니다.
                <br />
                6. 명의도용 및 결제도용, 저작권법 위반, 악성프로그램 배포,
                정보통신망법을 위반한 불법통신 및 해킹, 접속권한 초과행위 등과
                같이 관련법을 위반한 경우에는 영구이용정지를 할 수 있습니다. 본
                항에 따른 영구이용정지 시 서비스 이용을 위해 획득한 포인트, 기타
                혜택 등도 모두 소멸되며 회사는 이에 책임지지 않으며 별도로
                보상하지 않습니다.
                <br />
                7. 이용자는 본 이용제한 등에 대해 회사가 정한 절차에 따라
                이의신청을 할 수 있습니다. 해당 이의가 정당하다고 회사가
                판단하는 경우 회사는 서비스의 이용을 재개합니다.
              </Text>
            </Grid>

            <Grid
              width="1200px"
              bdr="1px solid #4b4b4b59"
              borderRadius="20px"
              padding="20px"
              marginT="20px"
            >
              <Text
                color="#202020"
                bold
                size="18px"
                textAlign="left"
                flexdirection="row"
              >
                제 9조 (이용자에 대한 통지)
              </Text>
              <Grid
                bdrBottom="1px solid #4b4b4b59"
                margin="auto"
                marginT="10px"
                justify="center"
              ></Grid>

              <Text
                color="#363636"
                display="block"
                size="15px"
                marginT="15px"
                lineHeight="24px"
              >
                1. 회사가 이용자에 대한 통지를 하는 경우, 이용자가 서비스에
                제출한 전자우편 주소로 할 수 있습니다. 2. 회사가 불특정 다수
                이용자에 대한 통지의 경우 1주일 이상 서비스 게시판에
                게시함으로써 개별 통지에 갈음할 수 있습니다.
              </Text>
            </Grid>

            <Grid
              width="1200px"
              bdr="1px solid #4b4b4b59"
              borderRadius="20px"
              padding="20px"
              marginT="20px"
            >
              <Text
                color="#202020"
                bold
                size="18px"
                textAlign="left"
                flexdirection="row"
              >
                제 10조 (개인 정보 보호)
              </Text>

              <Grid
                bdrBottom="1px solid #4b4b4b59"
                margin="auto"
                marginT="10px"
                justify="center"
              ></Grid>

              <Text
                color="#363636"
                display="block"
                size="15px"
                marginT="15px"
                lineHeight="24px"
              >
                1. 회사는 이용자 정보 수집 시 회사측이 필요한 최소한의 정보를
                수집합니다.
              </Text>
              <Grid
                display="flex"
                bg="#dbdbdba1"
                height="60px"
                column
              >
                <Text
                  display="block"
                  size="12px"
                  marginT="15px"
                  marginL="20px"
                  lineHeight="24px"
                >
                  1) 성명 2) 희망 ID 3) 비밀번호 4) E-mail 5) 주소 6) 전화번호
                </Text>
              </Grid>
              <Text display="block" size="15px" marginT="10px">
                2. 회사가 이용자의 개인식별이 가능한 개인정보를 수집하는 때에는
                반드시 당해 이용자의 동의를 받습니다.
                <br />
                3. 제공된 개인정보는 당해 이용자의 동의 없이 제 3자에게 제공할
                수 없으며, 이에 대한 모든 책임은 회사가 집니다. 다만 다음의
                경우에는 예외로 합니다.
              </Text>
              <Grid
                display="flex"
                bg="#dbdbdba1"
                height="150px"
                column
              >
                <Text
                  lineHeight="24px"
                  display="block"
                  size="12px"
                  marginT="15px"
                  marginL="20px"
                >
                  1) 배송업무상 배송업체에게 배송에 필요한 최소한의 이용자의
                  정보(성명, 주소, 전화번호)를 알려주는 경우
                  <br />
                  2) 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서
                  특정 개인을 식별할 수 없는 형태로 제공하는 경우
                  <br />
                  3) 관계법령에 의하여 국가기관으로부터 요구 받은 경우
                  <br />
                  4) 범죄에 대한 수사상의 목적이 있거나, 정보통신 윤리위원회의
                  요청이 있는 경우
                  <br />
                  5) 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우
                </Text>
              </Grid>

              <Text display="block" size="15px" marginT="10px">
                4. 이용자는 언제든지 회사가 가지고 있는 자신의 개인정보에 대해
                열람 및 오류정정을 할 수 있습니다.
                <br />
                5. 회사로부터 개인정보를 제공받은 제 3자는 개인정보를 제공받은
                목적을 달성한 때에는 당해 개인정보를 지체없이 파기합니다.
                <br />
                6. 회사는 이용자의 귀책 사유로 인해 노출된 이용자 정보에 대해서
                일체의 책임을 부담하지 않습니다.
                <br />
                7. 회사의 공식 사이트 이외의 링크된 사이트에서는 회사의
                개인정보처리방침이 적용되지 않습니다.
                <br />
                8. 회사는 회원정보의 취급 및 관리 등의 업무를 스스로 수행함을
                원칙으로 하나, 필요한 경우 업무의 일부 또는 전체를 회사가 선정한
                회사에 위탁할 수 있으며 회원정보의 취급 및 관리에 대한 업무를
                위탁하는 경우에는 개인정보취급방침에 공지합니다.
              </Text>
            </Grid>

            <Grid
              width="1200px"
              bdr="1px solid #4b4b4b59"
              borderRadius="20px"
              padding="20px"
              marginT="20px"
            >
              <Text
                color="#202020"
                bold
                size="18px"
                textAlign="left"
                flexdirection="row"
              >
                제 11조 (회사의 의무)
              </Text>
              <Grid
                bdrBottom="1px solid #4b4b4b59"
                margin="auto"
                marginT="10px"
                justify="center"
              ></Grid>

              <Text
                color="#363636"
                display="block"
                size="15px"
                marginT="15px"
                lineHeight="24px"
              >
                1. 회사는 이 약관에서 정한 바에 따라 계속적, 안정적으로 서비스를
                제공할 수 있도록 최선의 노력을 다하여야만 합니다.
                <br />
                2. 회사는 서비스에 관련된 설비를 항상 운용할 수 있는 상태로
                유지/보수하고, 장애가 발생하는 경우 지체 없이 이를 수리/복구할
                수 있도록 최선의 노력을 다하여야 합니다.
                <br />
                3. 회사는 이용자가 안전하게 서비스를 이용할 수 있도록 이용자의
                개인정보보호를 위한 보안시스템을 갖추어야 합니다.
                <br />
                4. 회사는 이용자가 원하지 않는 영리목적의 광고성 전자우편을
                발송하지 않습니다.
                <br />
                5. 회사는 회원 및 제 3자의 저작권을 보호하고 위반 게시물 제재를
                위하여 노력합니다.
              </Text>
            </Grid>

            <Grid
              width="1200px"
              bdr="1px solid #4b4b4b59"
              borderRadius="20px"
              padding="20px"
              marginT="20px"
            >
              <Text
                color="#202020"
                bold
                size="18px"
                textAlign="left"
                flexdirection="row"
              >
                제 12조 (회원의 ID 및 비밀번호에 대한 의무)
              </Text>
              <Grid
                bdrBottom="1px solid #4b4b4b59"
                margin="auto"
                marginT="10px"
                justify="center"
              ></Grid>

              <Text
                color="#363636"
                display="block"
                size="15px"
                marginT="15px"
                lineHeight="24px"
              >
                1. 회원에게 부여된 아이디(ID)와 비밀번호의 관리책임은 회원에게
                있으며 관리 소홀, 부정사용에 의하여 발생하는 모든 결과에 대한
                책임은 회원에게 있습니다.
                <br />
                2. 회원이 자신의 ID 및 비밀번호를 도난 당하거나 제 3자가
                사용하고 있음을 인지한 경우에는 바로 회사에 통보하고 회사의
                안내가 있는 경우에는 그에 따라야 합니다.
                <br />
                3. 제 2항의 경우에 해당 회원이 회사에 그 사실을 통지하지 않거나,
                통지한 경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대해
                회사는 책임지지 않습니다.
                <br />
                4. 회원의 아이디(ID)가 개인정보 유출 우려가 있거나 반사회적 또는
                미풍양속에 어긋나거나 회사 및 회사의 운영자로 오인한 우려가 있는
                경우 해당 아이디(ID)사용을 제한할 수 있습니다.
              </Text>
            </Grid>

            <Grid
              width="1200px"
              bdr="1px solid #4b4b4b59"
              borderRadius="20px"
              padding="20px"
              marginT="20px"
            >
              <Text
                color="#202020"
                bold
                size="18px"
                textAlign="left"
                flexdirection="row"
              >
                제 13조 (회원의 의무)
              </Text>
              <Grid
                bdrBottom="1px solid #4b4b4b59"
                margin="auto"
                marginT="10px"
                justify="center"
              ></Grid>

              <Text
                color="#363636"
                display="block"
                size="15px"
                marginT="15px"
                lineHeight="24px"
              >
                1. 회원은 관계법령, 본 약관의 규정, 이용안내 및 주의사항 등
                회사가 통지하는 사항을 준수하여야 하며, 기타 회사의 업무에
                방해되는 행위를 하여서는 안됩니다.
                <br />
                2. 회원은 회사의 사전승낙 없이 서비스를 이용하여 어떠한
                영리행위도 할 수 없습니다.
                <br />
                3. 회원은 서비스를 이용하여 얻은 정보를 회사의 사전승낙 없이
                복사, 복제, 변경, 번역, 출판/방송 기타의 방법으로 사용하거나
                이를 타인에게 제공할 수 없습니다.
                <br />
                4. 회원은 자기 신상정보의 변경사항 발생시 즉각 변경하여야
                합니다. 회원정보를 수정하지 않아 발생하는 모든 결과에 대한
                책임은 회원에게 있습니다.
                <br />
                5. 회원은 서비스 이용과 관련하여 다음 각 호의 행위를 하지 않아야
                하며, 다음 행위를 함으로 발생하는 모든 결과에 대한 책임은
                회원에게 있습니다.
              </Text>
              <Grid
                display="flex"
                bg="#dbdbdba1"
                height="290px"
                column
              >
                <Text
                  lineHeight="24px"
                  display="block"
                  size="12px"
                  marginT="15px"
                  marginL="20px"
                >
                  1) 다른 회원의 아이디(ID) 및 비밀번호를 도용, 부정하게
                  사용하는 행위
                  <br />
                  2) 다른 회원의 E-mail 주소를 취득하여 스팸메일을 발송하는 행위
                  <br />
                  3) 범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위
                  <br />
                  4) 선량한 풍속, 기타 사회질서를 해하는 행위
                  <br />
                  5) 회사 및 타인의 명예를 훼손하거나 모욕하는 행위
                  <br />
                  6) 회사 및 타인의 지적재산권 등의 권리를 침해하는 행위
                  <br />
                  7) 해킹행위 또는 컴퓨터 바이러스의 유포행위
                  <br />
                  8) 타인의 의사에 반하여 광고성 정보 등 일정한 내용을
                  지속적으로 전송하는 행위
                  <br />
                  9) 서비스의 안정적인 운영에 지장을 주거나 줄 우려가 있는
                  일체의 행위
                  <br />
                  10) 회사가 제공하는 서비스의 내용을 변경하는 행위
                  <br />
                  11) 기타 관계법령에 위배되는 행위
                </Text>
              </Grid>
            </Grid>

            <Grid
              width="1200px"
              bdr="1px solid #4b4b4b59"
              borderRadius="20px"
              padding="20px"
              marginT="20px"
            >
              <Text
                color="#202020"
                bold
                size="18px"
                textAlign="left"
                flexdirection="row"
              >
                제 14조 (게시물 삭제)
              </Text>
              <Grid
                bdrBottom="1px solid #4b4b4b59"
                margin="auto"
                marginT="10px"
                justify="center"
              ></Grid>

              <Text
                color="#363636"
                display="block"
                size="15px"
                marginT="15px"
                lineHeight="24px"
              >
                1. 회원은 관계법령, 본 약관의 규정, 이용안내 및 주의사항 등
                회사가 통지하는 사항을 준수하여야 하며, 기타 회사의 업무에
                방해되는 행위를 하여서는 안됩니다.
                <br />
                2. 회원은 회사의 사전승낙 없이 서비스를 이용하여 어떠한
                영리행위도 할 수 없습니다.
                <br />
                3. 회원은 서비스를 이용하여 얻은 정보를 회사의 사전승낙 없이
                복사, 복제, 변경, 번역, 출판/방송 기타의 방법으로 사용하거나
                이를 타인에게 제공할 수 없습니다.
                <br />
                4. 회원은 자기 신상정보의 변경사항 발생시 즉각 변경하여야
                합니다. 회원정보를 수정하지 않아 발생하는 모든 결과에 대한
                책임은 회원에게 있습니다.
                <br />
                5. 회원은 서비스 이용과 관련하여 다음 각 호의 행위를 하지 않아야
                하며, 다음 행위를 함으로 발생하는 모든 결과에 대한 책임은
                회원에게 있습니다.
              </Text>
              <Grid
                display="flex"
                bg="#dbdbdba1"
                height="320px"
                column
              >
                <Text
                  display="block"
                  size="12px"
                  marginT="15px"
                  marginL="20px"
                  lineHeight="24px"
                >
                  1) 다른 이용자 또는 제 3자를 비방하거나 중상모략으로 명예를
                  손상시키는 내용
                  <br />
                  2) 공공질서 또는 미풍양속에 위반되는 내용
                  <br />
                  3) 범죄적 행위에 결부된다고 인정되는 내용
                  <br />
                  4) 제 3자의 저작권 , 회사의 저작권 등 기타 권리를 침해하는
                  내용
                  <br />
                  5) 서비스의 안정적인 운영에 지장을 주거나 줄 우려가 있는 내용
                  <br />
                  6) 근거나 확인절차 없이 회사를 비난하거나 유언비어를 유포한
                  내용
                  <br />
                  7) 타인의 정보를 도용하여 작성한 내용
                  <br />
                  8) 법령을 위반하거나 타인의 권리를 침해하는 방식으로 정치적,
                  종교적 분쟁을 야기하는 내용
                  <br />
                  9) 동일한 내용을 반복하여 다수 게시하는 등 게시의 목적에
                  어긋나는 내용
                  <br />
                  10) 불필요하거나 회사에서 승인되지 않은 광고 판촉물을 게재하는
                  내용
                  <br />
                  11) 정보통신망이용 촉진 및 정보보호 등에 관한 법률 또는 청소년
                  보호법에 의거하여 청소년 유해 매체물로 지정 예고되거나 지정된
                  경우 및 이에 준하는 내용
                  <br />
                  12) 기타 관계법령에 의거하여 위반된다고 판단되는 내용
                  <br />
                </Text>
              </Grid>
            </Grid>

            <Grid
              width="1200px"
              bdr="1px solid #4b4b4b59"
              borderRadius="20px"
              padding="20px"
              marginT="20px"
              marginB="20px"
            >
              <Text
                color="#202020"
                bold
                size="18px"
                textAlign="left"
                flexdirection="row"
              >
                부칙
              </Text>
              <Grid
                bdrBottom="1px solid #4b4b4b59"
                margin="auto"
                marginT="10px"
                justify="center"
              ></Grid>

              <Text
                color="#363636"
                display="block"
                size="15px"
                marginT="15px"
                lineHeight="24px"
              >
                이 약관은 2022년 12월 6일부터 시행합니다.
              </Text>
            </Grid>
          </Grid>
        </div>
        <Footer/>
      </Desktop>
    </>
  );
};


export default Policy;
