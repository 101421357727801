import { createAction, handleActions } from "redux-actions";
import axios from "axios";
import { setLocal, deleteLocal } from "../../shared/Local";

 const PURCHASE_PRODUCT = "PURCHASE_PRODUCT";
 const RECENT_PURCHASE_PRODUCT_MYHOME = "RECENT_PURCHASE_PRODUCT_MYHOME";
 const ARTIST_AMOUNT = "ARTIST_AMOUNT";


const initialState = {
  PurchaseProdcut: "",
  Recent_PurchaseProdcut_Song: "",
  Artist_Amount: "",
  // PurchaseProdcutID: "",
  // PurchaseAt:""
};

// 회원 가입
const set_Purchase_Product = (song) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: PURCHASE_PRODUCT,
      PurchaseProdcut : song,
      // PurchaseProdcutID: id,
      // PurchaseAt : PurchaseAt,
    });  
  };
};

const set_RecentSong = (song) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: RECENT_PURCHASE_PRODUCT_MYHOME,
      Recent_PurchaseProdcut_Song : song,
    });  
  };
};


const set_Artist_Amount = (amount) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: ARTIST_AMOUNT,
      Artist_Amount : amount,
    });  
  };
};


const PurchaseProduct = (state = initialState, action) => {
  switch (action.type) {
  
      case PURCHASE_PRODUCT:
        return {
          ...state,
          PurchaseProdcut : action.PurchaseProdcut,
          // PurchaseAt: action.PurchaseAt
        }
        case RECENT_PURCHASE_PRODUCT_MYHOME:
          return {
            ...state,
            Recent_PurchaseProdcut_Song : action.Recent_PurchaseProdcut_Song,
            // PurchaseAt: action.PurchaseAt
          }
          case ARTIST_AMOUNT:
            return {
              ...state,
              Artist_Amount : action.Artist_Amount,
            }


    default:
      return state
  }
};

export default PurchaseProduct;


const actionCreators = {
  set_Purchase_Product,
  set_RecentSong,
  set_Artist_Amount,
};

export { actionCreators };
