import React, { useRef, useEffect, useState , useMemo} from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';



const Product_Upload_Editor = ({value, onChange }) => {
  const dispatch = useDispatch();
  const [currentLocation, setCurrentLocation] = useState("/");



  const modules = {
    toolbar: [
      //[{ 'font': [] }],
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike',],
      [{'list': 'ordered'}, {'list': 'bullet'}],

      [{ 'align': [] }, { 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      ['clean']
    ],
  }

  const formats = [
    //'font',
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'align', 'color', 'background',        
  ]




  return (
        <div style={{  marginLeft:"0px", marginTop:"18px"}}>
                <ReactQuill 
                    style={{height: "170px"}} 
                    theme="snow" 
                    modules={modules} 
                    formats={formats} 
                    value={value || ''} 
                    onChange={(content, delta, source, editor) => onChange(editor.getHTML())} />
            </div>
  );
};



	
export default Product_Upload_Editor;
