import React from "react";
import styled from "styled-components";

const Text = (props) => {
  const {
    children,
    onClick,
    onMouseEnter,
    color,
    size,
    bold,
    flexGrow,
    margin,
    marginB,
    marginT,
    marginL,
    marginR,
    padding,
    paddingB,
    paddingT,
    paddingL,
    paddingR,
    textAlign,
    center,
    full,
    lineHeight,
    weight,
    h0,
    h1,
    h2,
    h3,
    h4,
    body,
    maintext,
    subBody,
    price,
    won,
    span,
    TextClick,
    width,
    border,
    pointer,
    flexdirection,
    textalign,
    position,
    display,
    fontfamily,
    letterspacing,
    texttransform,
    wordbreak,
    liststyle,
    opacity,
    hoverColor,
  } = props;

  const styles = {
    color: color,
    flexGrow: flexGrow,
    margin: margin,
    marginB: marginB,
    marginT: marginT,
    marginL: marginL,
    marginR: marginR,
    textAlign: textAlign,
    lineHeight: lineHeight,
    weight: weight,
    width: width,
    border: border,
    pointer: pointer,
    flexdirection: flexdirection,
    textalign: textalign,
    position : position,
    display : display,
    fontfamily: fontfamily,
    letterspacing:letterspacing,
    texttransform:texttransform,
    wordbreak:wordbreak,
    liststyle:liststyle,
    padding: padding,
    paddingB: paddingB,
    paddingT: paddingT,
    paddingL: paddingL,
    paddingR: paddingR,
    opacity: opacity,
    hoverColor:hoverColor,

  };
  // page에서 바로골인

  const preSetting = {
    bold: bold,
    center: center,
    full: full,
    // pointer: pointer,
  };

  // h1, h2, h3, SubHeading(h4) 디자인 시스템에 맞춘 폰트
  // h1 : 메인배너
  if (h0) {
    return (
      <TextBox size="40px" onClick={onClick} {...styles} bold>
        {children}
      </TextBox>
    );
  }
  // h2 : 카테고리 제목 / 상세페이지 상품명
  if (h1) {
    return (
      <TextBox size="30px" onClick={onClick} {...styles} bold>
        {children}
      </TextBox>
    );
  }
  // h3 : 상세페이지 카테고리 제목
  if (h2) {
    return (
      <TextBox size="24px" onClick={onClick} {...styles} bold>
        {children}
      </TextBox>
    );
  }

  //
  if (h3) {
    return (
      <TextBox size="18px" onClick={onClick} {...styles} bold>
        {children}
      </TextBox>
    );
  }

  // sub h4: 가격명
  if (h4) {
    return (
      <TextBox size="14px" onClick={onClick} {...styles} {...preSetting}>
        {children}
      </TextBox>
    );
  }

  if (maintext) {
    return (
      <TextBox  size="32px" letterspacing= "-0.5pt"  bold onClick={onClick} {...styles}>
        {children}
      </TextBox>
    );
  }



  // 조회수, 카테고리 설명
  if (body) {
    return (
      <TextBox onClick={onClick} {...styles}>
        {children}
      </TextBox>
    );
  }
  // 조회수, 카테고리 설명
  if (subBody) {
    return (
      <TextBox size="12px" onClick={onClick} onMouseEnter={onMouseEnter} {...styles} {...preSetting}>
        {children}
      </TextBox>
    );
  }

  // 가격, 디자인 시스템에는 따로 없으나 코드에서 따로 뺌
  if (price) {
    return (
      <TextBox size="30px" bold onClick={onClick} {...styles}>
        {children}
      </TextBox>
    );
  }
  // 원
  if (won) {
    return (
      <WonBox size={size} onClick={onClick} {...styles}>
        {children}
      </WonBox>
    );
  }
  // 원
  if (span) {
    return (
      <SpanBox size={size} onClick={onClick} {...styles} {...preSetting}>
        {children}
      </SpanBox>
    );
  }

  if (TextClick) {
    return (
      <TextBoxClick size={size} onClick={onClick} {...styles} {...preSetting}>
      {children}
    </TextBoxClick>
    );
  }


  return (
    <TextBox size={size} onClick={onClick} {...styles} {...preSetting}>
      {children}
    </TextBox>
  );
};

// body 기준 default임
// defaultProps P대문자!!!
Text.defaultProps = {
  color: "black",
  size: "14px",
  weight: "400",
  flexGrow: false,
  margin: false,
  marginB: false,
  marginT: false,
  marginL: false,
  width: false,
  border: false,
  children: "",
  onClick: null,
  pointer: false,
  flexdirection: "row",
};

const TextBox = styled.div`
  ${(props) => (props.full ? "display: flex; flex-direction: row; width: 100%;" : "")}
  flex-grow:${(props) => (props.flexGrow ? props.flexGrow : "")};

  align-items: center;

  height: fit-content;
  width: ${(props) => props.width};
  border: ${(props) => props.border};
  margin: ${(props) => props.margin};
  margin-bottom: ${(props) => props.marginB};
  margin-top: ${(props) => props.marginT};
  margin-left: ${(props) => props.marginL};
  margin-right: ${(props) => props.marginR};
  flex-direction: ${(props) => props.flexdirection};
  font-family: ${(props) => props.fontfamily};
  color: ${(props) => props.color};
  text-transform: ${(props) => props.texttransform};
  word-break: ${(props) => props.wordbreak};
  list-style: ${(props) => props.liststyle};

  padding: ${(props) => props.padding};
  padding-bottom: ${(props) => props.paddingB};
  padding-top: ${(props) => props.paddingT};
  padding-left: ${(props) => props.paddingL};
  padding-right: ${(props) => props.paddingR};
  opacity: ${(props) => props.opacity};

 
  font-size: ${(props) => props.size};
  font-weight: ${(props) => (props.bold ? "600" : "400")};

  ${'' /* line-height: ${(props) => (props.lineHeight ? props.lineHeight : "")}; */}
  ${(props) => (props.textAlign ? `text-align: ${props.textAlign};` : "")}

  word-spacing : -1.5px;
  
  letter-spacing: ${(props) => props.letterspacing};
  line-height: ${(props) => props.lineHeight};

  cursor: ${(props) => (props.onClick ? "pointer" : "")};
  text-align: ${(props) => props.textalign};
  position: ${(props) => props.position};
  display: ${(props) => props.display};


  ${'' /* &:hover {
    background-color: ${(props) => props.contrast};
   
  } */}

`;

const WonBox = styled.span`
  font-size: 18px;
  margin-left: 5px;
`;

const SpanBox = styled.span`
  font-size: ${(props) => (props.size ? props.size : "12px")};
  color: ${(props) => props.color};
  font-weight: ${(props) => (props.bold ? "700" : "400")};
`;



const TextBoxClick = styled.div`
  ${(props) => (props.full ? "display: flex; flex-direction: row; width: 100%;" : "")}
  flex-grow:${(props) => (props.flexGrow ? props.flexGrow : "")};

  align-items: center;

  height: fit-content;
  width: ${(props) => props.width};
  border: ${(props) => props.border};
  margin: ${(props) => props.margin};
  margin-bottom: ${(props) => props.marginB};
  margin-top: ${(props) => props.marginT};
  margin-left: ${(props) => props.marginL};
  flex-direction: ${(props) => props.flexdirection};
  font-family: ${(props) => props.fontfamily};
  color: ${(props) => props.color};
  text-transform: ${(props) => props.texttransform};
  word-break: ${(props) => props.wordbreak};
  list-style: ${(props) => props.liststyle};

  padding: ${(props) => props.padding};
  padding-bottom: ${(props) => props.paddingB};
  padding-top: ${(props) => props.paddingT};
  padding-left: ${(props) => props.paddingL};
  padding-right: ${(props) => props.paddingR};
  opacity: ${(props) => props.opacity};

 
  font-size: ${(props) => props.size};
  font-weight: ${(props) => (props.bold ? "700" : "400")};

  ${'' /* line-height: ${(props) => (props.lineHeight ? props.lineHeight : "")}; */}
  ${(props) => (props.textAlign ? `text-align: ${props.textAlign};` : "")}

  word-spacing : -1.5px;
  
  letter-spacing: ${(props) => props.letterspacing};
  line-height: ${(props) => props.lineHeight};

  cursor: ${(props) => (props.onClick ? "pointer" : "")};
  text-align: ${(props) => props.textalign};
  position: ${(props) => props.position};
  display: ${(props) => props.display};


  &:hover {
    color: ${(props) => props.hoverColor};
   
  }

`;


export default Text;
