import { createAction, handleActions } from "redux-actions";
import axios from "axios";
import { setLocal, deleteLocal } from "../../shared/Local";

 const NOTICE_DATA = "NOTICE_DATA";
 const NOTICE_CONTENT_PUT = "NOTICE_CONTENT_PUT"; 
 const NOTICE_LOOKUP = "NOTICE_LOOKUP"; 

const initialState = {
  noticeData: "",
  noticeContentID: "",
  noticeContentData:"",
  noticeTime: "",
};

// 회원 가입
const set_Notice_Data = (notice) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type:  NOTICE_DATA,
      noticeData : notice,
    });  
  };
};

const set_Notice_LookUP = (lookUp) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type:  NOTICE_LOOKUP,
      lookUp : lookUp,
    });  
  };
};


const set_Notice_Content = (noticeContentID, noticeContentData , noticeTime) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type:  NOTICE_CONTENT_PUT,
      noticeContentID : noticeContentID,
      noticeContentData: noticeContentData,
      noticeTime : noticeTime,
    });  
  };
};




const Notice = (state = initialState, action) => {
  switch (action.type) {
  
      case NOTICE_DATA:
        return {
          ...state,
          noticeData : action.noticeData,
        }

        case NOTICE_LOOKUP:
          const user_artistuser = Object.assign(state.noticeData, action.lookUp, );
        console.log(action.lookUp)
        console.log(user_artistuser)
        return {
          ...state,
          // noticeData : action.lookUp,
        }

        case NOTICE_CONTENT_PUT:
          return {
            ...state,
            noticeContentID : action.noticeContentID,
            noticeContentData : action.noticeContentData,
            noticeTime: action.noticeTime,
          }

    default:
      return state
  }
};

export default Notice;


const actionCreators = {
  set_Notice_Data,
  set_Notice_Content,
  set_Notice_LookUP,
};

export { actionCreators };
