import React, { useRef, useMemo, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import TranslateIcon from "@material-ui/icons/Translate";
import MenuIcon from "@material-ui/icons/Menu";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import { AvatarModalContainer, ModalContainer , PublicSideBar} from "../../components/index";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { Avatar } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { actionCreators as SearchResultActions } from "../../redux/modules/SearchResult";
import Logo from "../../images/Logo192P.png";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import {
  Grid,
  Input,
  Line,
  Button,
  Tag,
  Modal,
  Text,
} from "../../elements/index";
import Select from "react-select";
import "./Header.css";
import { Color } from "../../shared/DesignSys";
import { CenterFocusStrong } from "@material-ui/icons";
import { authService } from "../../shared/firebase";
import { actionCreators as userActions } from "../../redux/modules/user";
import { divide } from "lodash";

const DropDownHeader = styled("div")`
   padding: 4px;
  box-shadow:0px 0px 10px rgba(102,102,102,0.2); 
  background:rgba(255,255,255,0.95 );
  border-radius:5px;
`;

const DropDownListContainer = styled("div")``;

const DropDownList = styled("ul")`
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 500;
   border-radius:15px;
  &:first-child {
    ${'' /* padding-top: 0.8em; */}
  }
  &:last-child {
    padding-top: 0.8em;
  }
`;

const ListItem = styled("li")`
  list-style: none;
  margin-bottom: 0.8em;
  text-decoration: none;
  padding:3px 10px;

`;

const StyledLink = styled(Link)`
  box-sizing: border-box;
  display: block;
  color:#666;
  margin: 0 auto;
  text-align: left;
  text-decoration: none;
  list-style:none;
  &:hover{
    color:rgb(252, 140, 140); 
  }
`;


const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1367 });
  return isDesktop ? children : null;
};

const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 916, maxWidth: 1366 });
  return isTablet ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 915 });
  return isMobile ? children : null;
};

const Header = (props) => {
  const dispatch = useDispatch();
  const [currentLocation, setCurrentLocation] = useState("/");
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const [avatarModalopen, setavatarModalopen] = useState(false);
  const { showHeader } = props;
  const [keyword, setKeyword] = useState("");
  const user = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const [openMenuBar, setopenMenuBar] = useState(true);


  const wrapperRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);


 

  useEffect(() => {
    if (isDesktop && showHeader) {
      window.addEventListener("scroll", headerChange);
      return () => window.removeEventListener("scroll", headerChange);
    }
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [isDesktop, showHeader, isVisible]);

  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setIsVisible(false);
    }
  };


  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsVisible(false);
    }
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };

  const openAvatarModal = () => {
    setavatarModalopen(true);
  };
  const closeAvatarModal = () => {
    setavatarModalopen(false);
  };

  const logout = () => {
    if (window.confirm("로그아웃 하시겠습니까?")) {
      window.location.reload();
    }
  };

  const SearchProduct = () => {
    dispatch(SearchResultActions.setSearch_Keyword(keyword));
    history.push("/Search/Result");
    setKeyword("");
  };

  const navbox = useRef();
  const up = useRef();
  const hide = useRef();
  const leftLogo = useRef();
  const lengthen = useRef();

  const searchChange = () => {
    lengthen.current.focus();
  };

  const user_logout = async () => {
    try {
      await authService.signOut();
      dispatch(userActions.no_userdb());
      history.push("/");
      alert("로그아웃!");
      // window.location.href = '/';
    } catch (error) {
      alert(error.message);
    }
  };

  const headerChange = () => {
    // style null 에러페이지 방지

    if (navbox === null || up === null || hide === null || leftLogo === null) {
      return;
    }
    if (window.scrollY < 150) {
      navbox.current.style.position = "fixed";
      navbox.current.style.zIndex = "999";
      navbox.current.style.height = "125px";
      navbox.current.style.boxShadow = `0 4px 15px 0 ${Color.Light_4}77`;
      up.current.style.marginTop = "0px";

      hide.current.style.alignItems = "center";
      hide.current.style.opacity = "1";
      hide.current.style.width = "110px";

      leftLogo.current.style.width = "0";
      leftLogo.current.style.marginRight = "0";
    } else if (window.scrollY > 150) {
      navbox.current.style.position = "fixed";
      navbox.current.style.zIndex = "999";
      navbox.current.style.height = "80px";
      navbox.current.style.boxShadow = `0 4px 15px 0 ${Color.Secondary_2}77`;
      up.current.style.marginTop = "-42px";

      hide.current.style.alignItems = "center";
      hide.current.style.opacity = "0";
      hide.current.style.width = "0px";

      leftLogo.current.style.maxWidth = "70px";
      leftLogo.current.style.width = "60px";
      leftLogo.current.style.marginRight = "3vw";
    }
  };

  return (
    <>
      <Desktop>
      <SlidingPane
          closeIcon={<div></div>}
          isOpen={openMenuBar}
          title={<h2 style={{ color: "#ffffff" }}> PIANISSIMO</h2>}
          from="left"
          width="300px"
          onRequestClose={() => setopenMenuBar(!openMenuBar)}
          className="hamburger_menu"
        >
          <Grid className="hamburger_Mainmenu_Top">
            <ul class="hamburger_menu">   
              <li
                style={{
                  paddingTop: "10px",
                  fontSize: "18px",
                  color: "#888888",
                  fontWeight: "600",
                }}
              ><Link to={"/"}>HOME</Link></li>
                
              
              {/* <li><Link to={"/"}>HOME</Link></li> */}
              {/* <li><Link to={"/MyHome"}>My Page</Link></li> */}
                                
                            
              <li
                style={{
                  borderBottom: "1px solid #c2c2c2ad",
                  marginLeft: "4px",
                  marginRight: "4px",
                  paddingTop: "10px",
                }}
              ></li>
              <li
                style={{
                  paddingTop: "10px",
                  fontSize: "18px",
                  color: "#888888",
                  fontWeight: "600",
                }}
              >
                CHART
              </li>

              <li><Link to={"/Song/PlayChart/GlobalNew100"}>NEW 100</Link></li>
              <li><Link to={"/Song/PlayChart/GlobalHot100"}>HOT 100</Link></li> 
              <li>
                <Link to={"/Song/PlayChart/VeryHard"}>HARDEST HOT 100</Link>
              </li>
              <li>
                <Link to={"/Song/PlayChart/HARD"}>HARD HOT 100</Link>
              </li>
              <li>
                <Link to={"/Song/PlayChart/Normal"}>NORMAL HOT 100</Link>
              </li>
              <li>
                <Link to={"/Song/PlayChart/Easy"}>EASY HOT 100</Link>
              </li>
              <li>
                <Link to={"/Song/PlayChart/VeryEasy"}>EASYEST HOT 100</Link>
              </li>

              <li
                style={{
                  borderBottom: "1px solid #c2c2c2ad",
                  marginLeft: "4px",
                  marginRight: "4px",
                  paddingTop: "10px",
                }}
              ></li>
              <li
                style={{
                  paddingTop: "10px",
                  fontSize: "18px",
                  color: "#888888",
                  fontWeight: "600",
                }}
              >
                Genre
              </li>

              <li>
                <Link to={"/Song/Genre/Pop"}>Pop</Link>
              </li>
              <li>
                <Link to={"/Song/Genre/KPop"}>K-Pop</Link>
              </li>
              <li>
                <Link to={"/Song/Genre/JPop"}>J-Pop</Link>
              </li>
              <li>
                <Link to={"/Song/Genre/Classic"}>Classic</Link>
              </li>
              <li>
                <Link to={"/Song/Genre/Ani"}>Ani</Link>
              </li>
              <li>
                <Link to={"/Song/Genre/OST"}>OST</Link>
              </li>
              <li>
                <Link to={"/Song/Genre/ROCKMETAL"}>Rock/Metal</Link>
              </li>
              <li>
                <Link to={"/Song/Genre/DANCEELEC"}>Dance/Elec</Link>
              </li>
              <li>
                <Link to={"/Song/Genre/ETC"}>Etc</Link>
              </li>

              <li
                style={{
                  borderBottom: "1px solid #c2c2c2ad",
                  marginLeft: "4px",
                  marginRight: "4px",
                  paddingTop: "10px",
                }}
              ></li>
              {/* <li><Link to={"/Product/PurchaseProduct"}>결제 곡 다운로드</Link></li> */}
              {/* <li><Link to={`/MyHome/PaymentHistory/${user?.uid}`}>결제내역</Link></li> */}
              {/* <li><Link to={"/Checkout/ProductBasket"}>장바구니</Link></li>
                        <li><Link to={"/Payment/CheckoutResult"}>구매내역</Link></li> */}
              <li>
                <Link to={"/Home/Notice"}>공지사항</Link>
              </li>
              <li>
                <Link to={"/Home/Question"}>문의사항</Link>
              </li>
              <li>
                <Link to={"/ArtistJoin"}>아티스트 가입하기</Link>
              </li>
              {/* 구독한 아티스트 */}
            </ul>
          </Grid>
        </SlidingPane>
        <header className="Header_wrapper">
        
          <Grid display="flex">
            <div className="Header_wrapper_center_margin">   

            <Grid
                justify="center"
                className="Header_wrapper_menu"
                align="center"
                width="80px"
                display="flex"
               >
                <MenuIcon
                  className="menu_svgIcon"
                  onClick={() => setopenMenuBar(!openMenuBar)}
                />
              </Grid>




              <div className="Header_wrapper_center_left">
                <Text
                  maintext
                  color={Color.White_1}
                  lineHeight="64px"
                  onClick={() => {
                    history.push("/");
                  }}
                >
                  PIANISSIMO
                </Text>
              </div>



              <div className="Header_menu_search_wrap">
                <input
                  className="Header_menu_search"
                  // ref={lengthen}
                  id="search"
                  type="text"
                  value={keyword}
                  placeholder="검색하기"
                  onChange={(e) => {
                    setKeyword(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (window.event.keyCode === 13) {
                      SearchProduct();
                    }
                  }}
                />
                <SearchIcon
                  className="search__barSearchIcon"
                  // onClick={SearchProduct()}
                />
              </div>

              <div className="Header_menu_center_right">
                <TranslateIcon className="languge_svgIcon" />
                <ul class="Header_menu">
                  <li>
                    <span className={"Header_menu_top_right_menu"}>
                      <a href="/shop/prd_list.php?type=brand">Language</a>
                      {/* SPAN으로 바꾸기 */}
                    </span>

                    <div class="Header_menu_depth">
                      <ul>
                        <li>
                          <span onClick={() => changeLanguage("kor")}>
                            한국어
                          </span>
                        </li>
                        <li>
                          <span onClick={() => changeLanguage("en")}>
                            English
                          </span>
                        </li>
                        {/* <li><span onClick={() => changeLanguage('en')}>繁體中文</span></li>
                        <li><span onClick={() => changeLanguage('en')}>简体中文</span></li> */}
                        <li>
                          <span onClick={() => changeLanguage("ja")}>
                            日本語
                          </span>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>

                {/* {user.is_login === true && user !== null ? (
                  <>
                    <Avatar src={user?.photoURL} className="navbar__avatar">
                      {" "}
                    </Avatar>

                    <ul class="Header_menu">
                      <li>
                        <span className="Header_menu_top_right_user_displayName">
                          <span>{user?.displayName}</span>
                        </span>
                        <div class="Header_menu_depth">
                          <ul>
                            <li>
                              <span onClick={user_logout}>로그아웃</span>
                            </li>
                            <li>
                              <span onClick={openAvatarModal}>사용자수정</span>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </>
                ) : (
                  <ul class="Header_menu_login">
                    <ExitToAppIcon className="languge_svgIcon" />
                    <li>
                      <span className={"Header_menu_top_right_menu"}>
                        <span onClick={openModal}>Login</span>
                      </span>
                    </li>
                  </ul>
                )} */}
                
                {user.is_login === true && user !== null ? (
                  <Grid
                    __click={() => setIsVisible(!isVisible)}
                    className="menuTab"
                    is_flex
                  >
                    <Grid>
                      <Avatar src={user?.photoURL} className="navbar__avatar">
                        {" "}
                      </Avatar>
                    </Grid>
                    <Grid className="Header_menu_top_right_user_displayName">
                      <div onClick={() => setIsVisible(!isVisible)}>{user?.displayName}</div>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    __click={() => openModal()}
                    class="Header_menu_login"
                    is_flex
                    height="64px"
                  >
                    <Grid marginT="4px">
                      <ExitToAppIcon className="login_svgIcon" />
                    </Grid>

                    <Grid>
                      <div className="Header_menu_Login_font">Login</div>
                    </Grid>
                  </Grid>
                )}

                {user.is_login === true && user !== null && isVisible ? (
                  <div className="menuList_Tap" ref={wrapperRef}>
                    <DropDownHeader>
                      <DropDownListContainer>
                        <DropDownList>
                          {/* <ListItem
                            onClick={() => {
                              setIsVisible(false);
                            }}
                          >
                            {" "}
                            <StyledLink to={`/Artist/main/${user?.uid}`}>
                              아티스트 페이지
                            </StyledLink>{" "}
                          </ListItem> */}

                          <ListItem
                            onClick={() => {
                              setIsVisible(false);
                            }}
                          >
                            {" "}
                            <StyledLink to={"/MyHome"}>My Page</StyledLink>{" "}
                          </ListItem>
                          <ListItem
                            onClick={() => {
                              setIsVisible(false);
                            }}
                          >
                            {" "}
                            <StyledLink to={"/Payment/CheckoutResult"}>
                              구매내역{" "}
                            </StyledLink>{" "}
                          </ListItem>
                          <ListItem
                            onClick={() => {
                              setIsVisible(false);
                            }}
                          >
                            <StyledLink to={"/Checkout/ProductBasket"}>
                              장바구니
                            </StyledLink>
                          </ListItem>

                          <ListItem
                            onClick={() => {
                              user_logout();
                              setIsVisible(false);
                            }}
                          >
                            <StyledLink>로그아웃</StyledLink>
                          </ListItem>
                        </DropDownList>
                      </DropDownListContainer>
                    </DropDownHeader>
                  </div>
                ) : null}




              </div>
            </div>
          </Grid>
        </header>

        
        <ModalContainer show={open} close={closeModal} />
                <AvatarModalContainer
                  show={avatarModalopen}
                  close={closeAvatarModal}
                />
      </Desktop>

      <Tablet>
        <SlidingPane
          closeIcon={<div></div>}
          isOpen={openMenuBar}
          title={<h2 style={{ color: "#ffffff" }}> PIANISSIMO</h2>}
          from="left"
          width="300px"
          onRequestClose={() => setopenMenuBar(!openMenuBar)}
          className="hamburger_menu"
        >
          <Grid className="hamburger_Mainmenu_Top">
            <ul class="hamburger_menu">
              {/* <li><Link to={"/"}>HOME</Link></li> */}
              <li
                style={{
                  paddingTop: "10px",
                  fontSize: "18px",
                  color: "#888888",
                  fontWeight: "600",
                }}
              >
                MY
              </li>

              {/* <li><Link to={"/MyHome"}>My Page</Link></li>     
                  <li><Link to={`/Artist/main/${user?.uid}`}>아티스트 페이지</Link></li> */}
              {/* IF LOGIN NULL */}

              <li
                style={{
                  borderBottom: "1px solid #c2c2c2ad",
                  marginLeft: "4px",
                  marginRight: "4px",
                  paddingTop: "10px",
                }}
              ></li>
              <li
                style={{
                  paddingTop: "10px",
                  fontSize: "18px",
                  color: "#888888",
                  fontWeight: "600",
                }}
              >
                CHART
              </li>

              <li>
                <Link to={"/Song/PlayChart/GlobalNew100"}>NEW 100</Link>
              </li>
              <li>
                <Link to={"/Song/PlayChart/GlobalHot100"}>HOT 100</Link>
              </li>
              <li>
                <Link to={"/Song/PlayChart/VeryHard"}>HARDEST HOT 100</Link>
              </li>
              <li>
                <Link to={"/Song/PlayChart/HARD"}>HARD HOT 100</Link>
              </li>
              <li>
                <Link to={"/Song/PlayChart/Normal"}>NORMAL HOT 100</Link>
              </li>
              <li>
                <Link to={"/Song/PlayChart/Easy"}>EASY HOT 100</Link>
              </li>
              <li>
                <Link to={"/Song/PlayChart/VeryEasy"}>EASYEST HOT 100</Link>
              </li>

              <li
                style={{
                  borderBottom: "1px solid #c2c2c2ad",
                  marginLeft: "4px",
                  marginRight: "4px",
                  paddingTop: "10px",
                }}
              ></li>
              <li
                style={{
                  paddingTop: "10px",
                  fontSize: "18px",
                  color: "#888888",
                  fontWeight: "600",
                }}
              >
                Genre
              </li>

              <li>
                <Link to={"/Song/Genre/Pop"}>Pop</Link>
              </li>
              <li>
                <Link to={"/Song/Genre/KPop"}>K-Pop</Link>
              </li>
              <li>
                <Link to={"/Song/Genre/JPop"}>J-Pop</Link>
              </li>
              <li>
                <Link to={"/Song/Genre/Classic"}>Classic</Link>
              </li>
              <li>
                <Link to={"/Song/Genre/Ani"}>Ani</Link>
              </li>
              <li>
                <Link to={"/Song/Genre/OST"}>OST</Link>
              </li>
              <li>
                <Link to={"/Song/Genre/ROCKMETAL"}>Rock/Metal</Link>
              </li>
              <li>
                <Link to={"/Song/Genre/DANCEELEC"}>Dance/Elec</Link>
              </li>
              {/* <li><Link to={"/Song/Genre/MEDLEY"}>Medley</Link></li> */}
              <li>
                <Link to={"/Song/Genre/ETC"}>Etc</Link>
              </li>

              {/* <li><Link to={"/MyHome/ProductUpload"}>악보 업로드</Link></li>
                        <li><Link to={`/MyHome/BoardManagement/${user?.uid}`}>게시판관리</Link></li>
                        <li><Link to={`/MyHome/CalculateAmount/${user?.uid}`}>정산하기</Link></li> */}

              <li
                style={{
                  borderBottom: "1px solid #c2c2c2ad",
                  marginLeft: "4px",
                  marginRight: "4px",
                  paddingTop: "10px",
                }}
              ></li>
              {/* <li><Link to={"/Product/PurchaseProduct"}>결제 곡 다운로드</Link></li> */}
              {/* <li><Link to={`/MyHome/PaymentHistory/${user?.uid}`}>결제내역</Link></li> */}
              {/* <li><Link to={"/Checkout/ProductBasket"}>장바구니</Link></li>
                        <li><Link to={"/Payment/CheckoutResult"}>구매내역</Link></li> */}
              <li>
                <Link to={"/Home/Notice"}>공지사항</Link>
              </li>
              <li>
                <Link to={"/Home/Question"}>문의사항</Link>
              </li>
              <li>
                <Link to={"/ArtistJoin"}>아티스트 가입하기</Link>
              </li>
              {/* 구독한 아티스트 */}
            </ul>
          </Grid>
        </SlidingPane>

        <div className="Header_wrapper">
          <div className="Header_wrapper_center">
            <div className="Header_wrapper_center_margin">
              <Grid
                // margin="0px auto"
                justify="center"
                className="Header_wrapper_menu"
                align="center"
                width="80px"
                display="flex"
                // is_flex
              >
                <MenuIcon
                  className="menu_svgIcon"
                  onClick={() => setopenMenuBar(!openMenuBar)}
                />
              </Grid>

              {/* Tablet_Hamburger_menu */}

              <Text
                maintext
                color={Color.White_1}
                lineHeight="64px"
                onClick={() => {
                  history.push("/");
                }}
                // size = "32px"
              >
                PIANISSIMO
              </Text>
              <Grid display="flex">
                {/* <img src={Logo} alt=""  className="Header_logo"/> */}
              </Grid>

              <div className="Header_menu_search_wrap">
                <input
                  className="Header_menu_search"
                  // ref={lengthen}
                  id="search"
                  type="text"
                  value={keyword}
                  placeholder="검색하기"
                  onChange={(e) => {
                    setKeyword(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (window.event.keyCode === 13) {
                      SearchProduct();
                    }
                  }}
                />
                <SearchIcon
                  className="search__barSearchIcon"
                  // onClick={SearchProduct()}
                />
              </div>

              <div className="Header_menu_center_right">
                <TranslateIcon className="languge_svgIcon" />
                <ul class="Header_menu">
                  <li>
                    <span className={"Header_menu_top_right_menu"}>
                      <a href="">Language</a>
                      {/* SPAN으로 바꾸기 */}
                    </span>

                    <div class="Header_menu_depth">
                      <ul>
                        <li>
                          <span onClick={() => changeLanguage("kor")}>
                            한국어
                          </span>
                        </li>
                        <li>
                          <span onClick={() => changeLanguage("en")}>
                            English
                          </span>
                        </li>
                        {/* <li><span onClick={() => changeLanguage('en')}>繁體中文</span></li>
                        <li><span onClick={() => changeLanguage('en')}>简体中文</span></li> */}
                        <li>
                          <span onClick={() => changeLanguage("ja")}>
                            日本語
                          </span>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>

                {user.is_login === true && user !== null ? (
                  <Grid
                    __click={() => setIsVisible(!isVisible)}
                    className="menuTab"
                    is_flex
                  >
                    <Grid>
                      <Avatar src={user?.photoURL} className="navbar__avatar">
                        {" "}
                      </Avatar>
                    </Grid>
                    <Grid className="Header_menu_top_right_user_displayName">
                      <div>{user?.displayName}</div>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    __click={() => openModal()}
                    class="Header_menu_login"
                    is_flex
                    height="64px"
                  >
                    <Grid marginT="4px">
                      <ExitToAppIcon className="login_svgIcon" />
                    </Grid>

                    <Grid>
                      <div className="Header_menu_Login_font">Login</div>
                    </Grid>
                  </Grid>
                )}

                {user.is_login === true && user !== null && isVisible ? (
                  <div className="menu5" ref={wrapperRef}>
                    <DropDownHeader>
                      <DropDownListContainer>
                        <DropDownList>
                          <ListItem
                            onClick={() => {
                              setIsVisible(false);
                            }}
                          >
                            {" "}
                            <StyledLink to={`/Artist/main/${user?.uid}`}>
                              아티스트 페이지
                            </StyledLink>{" "}
                          </ListItem>

                          <ListItem
                            onClick={() => {
                              setIsVisible(false);
                            }}
                          >
                            {" "}
                            <StyledLink to={"/MyHome"}>My Page</StyledLink>{" "}
                          </ListItem>
                          <ListItem
                            onClick={() => {
                              setIsVisible(false);
                            }}
                          >
                            {" "}
                            <StyledLink to={"/Payment/CheckoutResult"}>
                              구매내역{" "}
                            </StyledLink>{" "}
                          </ListItem>
                          <ListItem
                            onClick={() => {
                              setIsVisible(false);
                            }}
                          >
                            <StyledLink to={"/Checkout/ProductBasket"}>
                              장바구니
                            </StyledLink>
                          </ListItem>

                          <ListItem
                            onClick={() => {
                              user_logout();
                              setIsVisible(false);
                            }}
                          >
                            <StyledLink>로그아웃</StyledLink>
                          </ListItem>
                        </DropDownList>
                      </DropDownListContainer>
                    </DropDownHeader>
                  </div>
                ) : null}

                <ModalContainer show={open} close={closeModal} />
                <AvatarModalContainer
                  show={avatarModalopen}
                  close={closeAvatarModal}
                />
              </div>
            </div>
          </div>
        </div>
      </Tablet>

      <Mobile>
        <SlidingPane
          closeIcon={<div></div>}
          isOpen={openMenuBar}
          title={<h2 style={{ color: "#ffffff" }}> PIANISSIMO</h2>}
          from="left"
          width="300px"
          onRequestClose={() => setopenMenuBar(!openMenuBar)}
          className="hamburger_menu"
        >
          <Grid className="hamburger_Mainmenu_Top">
            <ul class="hamburger_menu">
              {/* <li><Link to={"/"}>HOME</Link></li> */}
              <li
                style={{
                  paddingTop: "10px",
                  fontSize: "18px",
                  color: "#888888",
                  fontWeight: "600",
                }}
              >
                MY
              </li>

              <li>
                <Link to={"/MyHome"}>My Page</Link>
              </li>
              <li>
                <Link to={`/Artist/main/${user?.uid}`}>아티스트 페이지</Link>
              </li>
              {/* IF LOGIN NULL */}

              <li
                style={{
                  borderBottom: "1px solid #c2c2c2ad",
                  marginLeft: "4px",
                  marginRight: "4px",
                  paddingTop: "10px",
                }}
              ></li>
              <li
                style={{
                  paddingTop: "10px",
                  fontSize: "18px",
                  color: "#888888",
                  fontWeight: "600",
                }}
              >
                CHART
              </li>

              <li>
                <Link to={"/Song/PlayChart/GlobalNew100"}>NEW 100</Link>
              </li>
              <li>
                <Link to={"/Song/PlayChart/GlobalHot100"}>HOT 100</Link>
              </li>
              <li>
                <Link to={"/Song/PlayChart/VeryHard"}>HARDEST HOT 100</Link>
              </li>
              <li>
                <Link to={"/Song/PlayChart/HARD"}>HARD HOT 100</Link>
              </li>
              <li>
                <Link to={"/Song/PlayChart/Normal"}>NORMAL HOT 100</Link>
              </li>
              <li>
                <Link to={"/Song/PlayChart/Easy"}>EASY HOT 100</Link>
              </li>
              <li>
                <Link to={"/Song/PlayChart/VeryEasy"}>EASYEST HOT 100</Link>
              </li>

              <li
                style={{
                  borderBottom: "1px solid #c2c2c2ad",
                  marginLeft: "4px",
                  marginRight: "4px",
                  paddingTop: "10px",
                }}
              ></li>
              <li
                style={{
                  paddingTop: "10px",
                  fontSize: "18px",
                  color: "#888888",
                  fontWeight: "600",
                }}
              >
                Genre
              </li>

              <li>
                <Link to={"/Song/Genre/Pop"}>Pop</Link>
              </li>
              <li>
                <Link to={"/Song/Genre/KPop"}>K-Pop</Link>
              </li>
              <li>
                <Link to={"/Song/Genre/JPop"}>J-Pop</Link>
              </li>
              <li>
                <Link to={"/Song/Genre/Classic"}>Classic</Link>
              </li>
              <li>
                <Link to={"/Song/Genre/Ani"}>Ani</Link>
              </li>
              <li>
                <Link to={"/Song/Genre/OST"}>OST</Link>
              </li>
              <li>
                <Link to={"/Song/Genre/ROCKMETAL"}>Rock/Metal</Link>
              </li>
              <li>
                <Link to={"/Song/Genre/DANCEELEC"}>Dance/Elec</Link>
              </li>
              {/* <li><Link to={"/Song/Genre/MEDLEY"}>Medley</Link></li> */}
              <li>
                <Link to={"/Song/Genre/ETC"}>Etc</Link>
              </li>

              {/* <li><Link to={"/MyHome/ProductUpload"}>악보 업로드</Link></li>
                        <li><Link to={`/MyHome/BoardManagement/${user?.uid}`}>게시판관리</Link></li>
                        <li><Link to={`/MyHome/CalculateAmount/${user?.uid}`}>정산하기</Link></li> */}

              <li
                style={{
                  borderBottom: "1px solid #c2c2c2ad",
                  marginLeft: "4px",
                  marginRight: "4px",
                  paddingTop: "10px",
                }}
              ></li>
              {/* <li><Link to={"/Product/PurchaseProduct"}>결제 곡 다운로드</Link></li> */}
              {/* <li><Link to={`/MyHome/PaymentHistory/${user?.uid}`}>결제내역</Link></li> */}
              {/* <li><Link to={"/Checkout/ProductBasket"}>장바구니</Link></li>
                        <li><Link to={"/Payment/CheckoutResult"}>구매내역</Link></li> */}
              <li>
                <Link to={"/Home/Notice"}>공지사항</Link>
              </li>
              <li>
                <Link to={"/Home/Question"}>문의사항</Link>
              </li>
              <li>
                <Link to={"/ArtistJoin"}>아티스트 가입하기</Link>
              </li>
              {/* 구독한 아티스트 */}
            </ul>
          </Grid>
        </SlidingPane>

        <div className="Header_wrapper">
          <div className="Header_wrapper_center">
            <div className="Header_wrapper_center_margin">
              <Grid
                margin="auto"
                justify="center"
                className="Header_wrapper_menu"
                width="50px"
                is_flex
                // marginL="10px"
                align="center"
              
                // padding ="10px"
              >
                <MenuIcon
                  className="menu_svgIcon"
                  onClick={() => setopenMenuBar(!openMenuBar)}
                />
              </Grid>
              <Text
                // maintext
                marginL="10px"
                fontfamily="Poppins,sans-serif"
                texttransform="uppercase"
                color={Color.White_1}
                lineHeight="64px"
                onClick={() => {
                  history.push("/");
                }}
                size="28px"
                bold
              >
                PIANISSIMO
              </Text>

              {/* <Grid row paddingL="10px" paddingR="10px"> */}
                <div className="Header_menu_search_wrap">
                  <input
                  className="Header_menu_search"
                  id="search"
                  type="text"
                  value={keyword}
                  placeholder="검색하기"
                  onChange={(e) => {
                    setKeyword(e.target.value);
                  }}
                  onKeyPress={(e) => {
                        if (window.event.keyCode === 13) {
                          SearchProduct();
                        }
                      }}
                />
                  <SearchIcon
                    className="search__barSearchIcon"
                    // onClick={SearchProduct()}
                  />
                </div>

                <div className="Header_menu_navbar">
                  <Grid padding="8px">
                    <TranslateIcon className="languge_svgIcon" />
                  </Grid>

                  <Grid padding="8px">
                    <Avatar src={user?.photoURL} className="navbar__avatar">
                      {" "}
                    </Avatar>
                  </Grid>
                </div>
              {/* </Grid> */}

              {/* 
              <div className="Header_menu_center_right">
                <TranslateIcon className="languge_svgIcon" />
                <ul class="Header_menu">
                  <li>
                    <div class="Header_menu_depth">
                      <ul>
                        <li>
                          <span onClick={() => changeLanguage("kor")}>
                            한국어
                          </span>
                        </li>
                        <li>
                          <span onClick={() => changeLanguage("en")}>
                            English
                          </span>
                        </li>
                        <li>
                          <span onClick={() => changeLanguage("ja")}>
                            日本語
                          </span>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
                </div>
 */}
              {/* 
              <div className="Header_menu_navbar">
                <ul class="Header_menu">
                  {user.is_login === true && user !== null ? (
                    <>
                      <Avatar src={user?.photoURL} className="navbar__avatar">
                        {" "}
                      </Avatar>

                      <ul class="Header_menu">
                        <li>
                          <div class="Header_menu_depth">
                            <ul>
                              <li>
                                <span onClick={user_logout}>로그아웃</span>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </>
                  ) : (
                    <ul class="Header_menu_login">
                      <ExitToAppIcon className="login_svgIcon" />
                      <li>
                        <span className={"Header_menu_top_right_menu"}>
                          <span onClick={openModal}>Login</span>
                        </span>
                      </li>
                    </ul>
                  )}
                </ul>
              </div> */}
            </div>
          </div>
        </div>

        <ModalContainer show={open} close={closeModal} />
        <AvatarModalContainer show={avatarModalopen} close={closeAvatarModal} />
      </Mobile>
    </>
  );
};

export default Header;
