import { createAction, handleActions } from "redux-actions";
import axios from "axios";
import { setLocal, deleteLocal } from "../../shared/Local";

const VERY_HARD_PLAY_CHART = "VERY_HARD_PLAY_CHART";
const HARD_PLAY_CHART = "HARD_PLAY_CHART";
const NORMAL_PLAY_CHART = "NORMAL_PLAY_CHART";
const EASY_PLAY_CHART = "EASY_PLAY_CHART";
const VERY_EASY_PLAY_CHART = "VERY_EASY_PLAY_CHART";

const GLOBAL_HOT_100 = "GLOBAL_HOT_100";
const GLOBAL_NEW_100 = "GLOBAL_NEW_100";

const NO_USER = "NO_USER";

const initialState = {
  VeryHard: "",
  Hard: "",
  Normal: "",
  Easy: "",
  VeryEasy: "",
  Global_hot_100:"",
  Global_new_100:"",
};

const set_GLOBAL_HOT_100_song = (song) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: GLOBAL_HOT_100,
      Global_hot_100: song,
    });
  };
};

const set_GLOBAL_NEW_100_song = (song) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: GLOBAL_NEW_100,
      Global_new_100: song,
    });
  };
};

const set_VERY_HARD_song = (song) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: VERY_HARD_PLAY_CHART,
      VeryHard: song,
    });
  };
};

const set_HARD_song = (song) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: HARD_PLAY_CHART,
      Hard: song,
    });
  };
};

const set_Normal_song = (song) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: NORMAL_PLAY_CHART,
      Normal: song,
    });
  };
};

const set_EASY_song = (song) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: EASY_PLAY_CHART,
      Easy: song,
    });
  };
};

const set_VERY_EASY_song = (song) => {
  return function (dispatch, getState, { history }) {
    dispatch({
      type: VERY_EASY_PLAY_CHART,
      VeryEasy: song,
    });
  };
};

const Hardisest = (state = initialState, action) => {
  switch (action.type) {
    case NORMAL_PLAY_CHART:
      return {
        ...state,
        Normal: action.Normal,
      };
    case VERY_HARD_PLAY_CHART:
      return {
        ...state,
        VeryHard: action.VeryHard,
      };
    case HARD_PLAY_CHART:
      return {
        ...state,
        Hard: action.Hard,
      };
    case EASY_PLAY_CHART:
      return {
        ...state,
        Easy: action.Easy,
      };
    case VERY_EASY_PLAY_CHART:
      return {
        ...state,
        VeryEasy: action.VeryEasy,
      };
      case GLOBAL_HOT_100:
      return {
        ...state,
        Global_hot_100: action.Global_hot_100,
      };
      case GLOBAL_NEW_100:
      return {
        ...state,
        Global_new_100: action.Global_new_100,
      };
    default:
      return state;
  }
};

export default Hardisest;

const actionCreators = {
  set_GLOBAL_HOT_100_song,
  set_GLOBAL_NEW_100_song,
  set_VERY_HARD_song,
  set_HARD_song,
  set_Normal_song,
  set_EASY_song,
  set_VERY_EASY_song,
};

export { actionCreators };
