import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { dbService } from "../../shared/firebase";
import { useDispatch, useSelector } from "react-redux";
import "./Hardisest.css";
import { CircularProgress } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import AddIcon from "@material-ui/icons/Add";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import PlayArrowOutlinedIcon from "@material-ui/icons/PlayArrowOutlined";
import { actionCreators as HardisestActions } from "../../redux/modules/Hardisest";
import { Grid, Button, Text } from "../../elements/index";
import { HardisestSlider  , AlertModalContainer , ChartTable, Footer} from "../../components/index";
import { Link, withRouter } from "react-router-dom";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import { useMediaQuery } from "react-responsive";


const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1367 });
  return isDesktop ? children : null;
};

const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 916, maxWidth: 1366 });
  return isTablet ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 915 });
  return isMobile ? children : null;
};


function Global_New_100() {
  const user = useSelector((state) => state.user);
  const Hardisest = useSelector((state) => state.Hardisest);
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const [Currency_Amount, setCurrency_Amount] = useState();
  const [alert, setAlert] = useState({
    type: 'error',
    text: 'This is a alert message',
    show: false
  })
  const [tableTitle, settableTitle] = useState("Global NEW 100");


  useEffect(() => {
    dbService
      .collection("Song_All")
      .orderBy("createdAt" , "desc" )
      .get()
      .then((snapshot) => {
        const update_song = snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
          edit: songEdit(doc.data()?.songEdit),
        }));
        dispatch(HardisestActions.set_GLOBAL_NEW_100_song(update_song));
        setIsLoading(false);
      });
  }, [history, dispatch]);



  // useEffect(() => {
  //   console.log(Hardisest)
  // }, [Hardisest]);

  

  const songEdit = (songEdit) => {
    if(songEdit.NoEdit === true){
      return "원곡";
    } 
    else if (songEdit.SomeEdit === true){
      return "편곡";
    } 
    else if (songEdit.Edit === true){
      return "작곡";
    }
  };



  return (
    <>
    <Desktop>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "26rem 0",
          }}
        >
          <CircularProgress color="secondary" size="5rem" />
        </div>
      ) : (
        <Grid positions="relative" width="100%" height="100%">
            <Grid width="100%" margin="auto" display="flex">
            <Grid
                width="100%"
                column
                marginB="90px"
              >
                <HardisestSlider 
                key={Hardisest.Global_new_100?.length} 
                data={Hardisest.Global_new_100} />
                <ChartTable
                  tableData={Hardisest.Global_new_100}
                  tableTitle={tableTitle}
                />
              </Grid>
          </Grid>
        </Grid>
      )}
      <Footer/>
      </Desktop>

      <Tablet>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "26rem 0",
          }}
        >
          <CircularProgress color="secondary" size="5rem" />
        </div>
      ) : (
        <Grid positions="relative" width="100%" height="100%">
            <Grid width="100%" margin="auto" display="flex">
            <Grid
                width="100%"
                column
                marginB="90px"
              >
              
                <HardisestSlider key={Hardisest.Global_new_100?.length} data={Hardisest.Global_new_100} />
                <ChartTable
                  tableData={Hardisest.Global_new_100}
                  tableTitle={tableTitle}
                />          
              </Grid>
          </Grid>
        </Grid>
      )}
      <Footer/>
      </Tablet>


      <Mobile>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "26rem 0",
          }}
        >
          <CircularProgress color="secondary" size="5rem" />
        </div>
      ) : (
        <Grid positions="relative" width="100%" height="100%">
            <Grid width="100%" margin="auto" display="flex">
            <Grid
                width="100%"
                column
                marginB="90px"
              >
                <HardisestSlider key={Hardisest.Global_new_100?.length} data={Hardisest.Global_new_100} />                
                <ChartTable
                  tableData={Hardisest.Global_new_100}
                  tableTitle={tableTitle}
                />  
              </Grid>
          </Grid>
        </Grid>
      )}
      <Footer/>
      </Mobile>
    </>
  );
}

export default Global_New_100;
