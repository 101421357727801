import React, { useEffect, useState, useMemo,} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Public_SideBar.css';
import { useHistory,  Link } from 'react-router-dom';
import { Grid, Button,  Text } from "../../../elements/index";
import styled from "styled-components";


const Public_SideBar = () => {

  const user = useSelector((state) => state.user);
  const history = useHistory();






  return (
          <>
          <div id="MyHome_sidebar_wrapper" class="MyHome_sidebar">
                <Text color = "rgb(99, 99, 99)" size= "32px"  textalign="left" marginT="30px" lineHeight="50px" paddingL="30px">Public</Text>
                <Text color = "rgb(99, 99, 99)" size= "32px"  textalign="left" marginT="4px" marginB="30px" lineHeight="14px" paddingL="30px">Dashboard</Text>
                <div id="navigation">
                  <ul class="MyHome_list">
                    <li><Link to={"/MyHome"}>홈</Link></li>         
                    <li><Link to={"/Product/PurchaseProduct"}>결제 곡 다운로드</Link></li>
                    <li><Link to={`/MyHome/PaymentHistory/${user?.uid}`}>결제내역</Link></li>
                    <li><Link to={"/Checkout/ProductBasket"}>장바구니</Link></li>
                    {/* <li><Link to="./upload">문의하기</Link></li> */}
                    <li><Link to={"/ArtistJoin"}>아티스트가입하기</Link></li>
                    {/* 구독한 아티스트 */}
                  </ul>
                </div>
              </div>

    
         
              {/* <div id="MyHome_sidebar_wrapper" class="MyHome_sidebar">            
                <Text color = "rgb(99, 99, 99)" size= "40px" width="250px" textalign="center" marginT="30px" lineHeight="50px">Artist Dashboard</Text>
                <div id="navigation">
                  <ul class="MyHome_list">
                    <li><Link to={"/MyHome"}>HOME</Link></li>
                    <li><Link to={`/Artist/main/${user?.uid}`}>아티스트 페이지</Link></li>
                    <li><Link to="/MyHome/ProductUp1load">악보 업로드</Link></li>
                    <li><Link to="./upload/product_upload">마케팅분석</Link></li>
                    <li><Link to={`/MyHome/BoardManagement/${user?.uid}`}>게시판관리</Link></li>
                    <li><Link to="./ArtistJoin">아티스트가입하기</Link></li>
                  </ul>
                </div>
              </div> */}
    
       </>
     
     

  );
};


export default Public_SideBar;

