import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { dbService } from "../../shared/firebase";
import { useDispatch, useSelector } from "react-redux";
// import "../../assets/css/popChart.css";
import { CircularProgress } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import { Grid, Button, Text } from "../../elements/index";
import { actionCreators as GenreActions } from "../../redux/modules/Genre";
import {
  GenresSlider,
  GenresTable,
  AlertModalContainer,
} from "../../components/index";
import { useMediaQuery } from "react-responsive";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1367 });
  return isDesktop ? children : null;
};

const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 916, maxWidth: 1366 });
  return isTablet ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 915 });
  return isMobile ? children : null;
};


function PopChart() {
  const Genre = useSelector((state) => state.Genre);
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const [tableTitle, settableTitle] = useState("Pop Chart");
  const [alert, setAlert] = useState({
    type: "error",
    text: "This is a alert message",
    show: false,
  });

  useEffect(() => {
    dbService
      .collection("Song_All")
      .where("genres", "==", "Pop")
      .orderBy("update", "desc")
      .get()
      .then((snapshot) => {
        const update_song = snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
          edit: songEdit(doc.data()?.songEdit),
        }));
        dispatch(GenreActions.set_Pop_Chart(update_song));
        setIsLoading(false);
      });
  }, [history, dispatch]);


  const songEdit = (songEdit) => {
    if (songEdit === undefined) {
      return "원곡";
    } else if (songEdit.NoEdit === true) {
      return "원곡";
    } else if (songEdit.SomeEdit === true) {
      return "편곡";
    } else if (songEdit.Edit === true) {
      return "작곡";
    }
  };


  return (
    <>
     <Desktop>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "26rem 0",
          }}
        >
          <CircularProgress color="secondary" size="5rem" />
        </div>
      ) : (
        <>
          <Grid positions="relative" width="100%" height="100%">
            <Grid width="100%" margin="auto" display="flex">
              <Grid
                width="100%"
                overflow="hidden"
                column
                display="flex"
                marginT="40px"
                marginB="110px"
              >
                <GenresSlider
                  key={Genre.Pop_Chart?.length}
                  data={Genre.Pop_Chart}
                />
                <GenresTable
                  tableData={Genre.Pop_Chart}
                  tableTitle={tableTitle}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      </Desktop>


      <Tablet>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "26rem 0",
          }}
        >
          <CircularProgress color="secondary" size="5rem" />
        </div>
      ) : (
        <>
          <Grid marginT="30px"  positions="relative" width="100%" height="100%">
            <Grid width="100%" margin="auto" display="flex" overflow="hidden">
            <Grid
                width="100%"
                column
                marginB="110px"
              >
                <GenresSlider
                  key={Genre.Pop_Chart?.length}
                  data={Genre.Pop_Chart}
                />
                <GenresTable
                  tableData={Genre.Pop_Chart}
                  tableTitle={tableTitle}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      </Tablet>

      <Mobile>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "26rem 0",
          }}
        >
          <CircularProgress color="secondary" size="5rem" />
        </div>
      ) : (
        <>
          <Grid positions="relative" width="100%" height="100%" marginT="30px">
            <Grid width="100%" margin="auto" display="flex" overflow="hidden">
            <Grid
                width="100%"
                column
                marginB="110px"
              >
                <GenresSlider
                  key={Genre.Pop_Chart?.length}
                  data={Genre.Pop_Chart}
                />
                <GenresTable
                  tableData={Genre.Pop_Chart}
                  tableTitle={tableTitle}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      </Mobile>
    </>
  );
}

export default PopChart;
